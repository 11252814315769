import React from "react";
type PropsType = {
  text: string;
  reflectPromptHistory: (text: string) => void;
};
export const DocumentPromptHistoryItem = ({
  text,
  reflectPromptHistory,
}: PropsType) => {
  return (
    <button
      className="w-full truncate text-start px-4 py-2 text-sm rounded-sm bg-blueGray-0"
      type="button"
      onClick={() => reflectPromptHistory(text)}
    >
      <span>{text}</span>
    </button>
  );
};
