import React, { ChangeEvent, FC, ReactNode } from "react";

type Props = {
  size: "sm" | "md" | "lg";
  disabled?: boolean;
  checked?: boolean;
  isError?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  children?: ReactNode;
  value?: string | number;
  required?: boolean;
  name: string;
};

const SIZE_CLASSES = {
  sm: "h-[14px] w-[14px]",
  md: "h-4 h-4",
  lg: "h-[18px] w-[18px]",
};

export const Checkbox: FC<Props> = ({
  size = "md",
  checked = false,
  disabled = false,
  isError = false,
  className,
  onChange,
  name,
  required = false,
  value,
}: Props) => {
  return (
    <input
      id={name}
      name={name}
      required={required}
      className={`form-checkbox border-solid border border-gray-300 rounded-sm focus:outline-none
        ${SIZE_CLASSES[size]}
        ${className}
        ${
          disabled
            ? "opacity-70 cursor-not-allowed bg-gray-200"
            : "cursor-pointer"
        }
        ${isError ? "border-red-300 bg-red-50" : ""}
      `}
      disabled={disabled}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      value={value}
    />
  );
};
