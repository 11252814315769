import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import React from "react";
import { INode } from "react-flow-builder";

import { FlowChartEditor } from "@spesill/components/organisms";

import { DocumentKind } from "@spesill/models/document";

export default function TiptapFlowchartComponent({
  node: {
    attrs: {
      state,
      learningDatabaseId,
      headingTitle,
      documentKind,
      freeInputKind,
    },
  },
  updateAttributes,
}: {
  node: {
    attrs: {
      state: string;
      learningDatabaseId?: string;
      headingTitle?: string;
      documentKind?: DocumentKind;
      freeInputKind?: string;
    };
  };
  updateAttributes: NodeViewProps["updateAttributes"];
}) {
  const onUpdateState = (state: INode[]) => {
    updateAttributes({
      state: JSON.stringify(state),
    });
  };
  return (
    <NodeViewWrapper>
      <FlowChartEditor
        headingTitle={headingTitle}
        documentKind={documentKind}
        freeInputKind={freeInputKind}
        learningDatabaseId={learningDatabaseId}
        state={JSON.parse(state)}
        onUpdateState={onUpdateState}
      />
    </NodeViewWrapper>
  );
}
