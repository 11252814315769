import React, { FC } from "react";

import { ButtonColorType, ButtonVariantType, ButtonColor } from "./constants";

type PropsType = {
  text: string;
  outline?: boolean;
  disabled?: boolean;
  className?: string;
  color: ButtonColorType;
  variant: ButtonVariantType;
};

export const FormSubmitButton: FC<PropsType> = ({
  text,
  className = "",
  disabled = false,
  color,
  variant,
}: PropsType) => {
  return (
    <input
      disabled={disabled}
      type="submit"
      className={`${ButtonColor[variant][color]} ${
        disabled ? "cursor-not-allowed opacity-80" : "cursor-pointer"
      }  px-6 py-2.5 rounded ${className}`}
      value={text}
    />
  );
};
