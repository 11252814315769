import React, { FC, MouseEvent, ReactElement, useEffect, useRef } from "react";

import { Icon } from "@spesill/components/atoms";

import { useBoolean } from "@spesill/hooks";
import { Document } from "@spesill/models";

import { DocumentInsertTextForm } from "./DocumentInsertTextForm/DocumentInsertTextForm";

type PropsType = {
  systemName: string;
  documentInfo?: Document;
  heading: ReactElement;
  headingTitle: string;
  learningDatabaseId?: string;
  requestAi: boolean;
  setInsertChartTrue: () => void;
  setInsertText: ({
    matchHTMLElement,
    insertText,
  }: {
    matchHTMLElement: HTMLElement;
    insertText: string;
  }) => void;
  addToQueue: (func: () => Promise<void>) => void;
  removeFromRequestHeadingTitles: (headingTitle: string) => void;
};

export const DocumentHeadingTitleForm: FC<PropsType> = ({
  systemName,
  documentInfo,
  heading,
  headingTitle,
  learningDatabaseId,
  requestAi,
  setInsertChartTrue,
  setInsertText,
  addToQueue,
  removeFromRequestHeadingTitles,
}: PropsType) => {
  const headingRef = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const {
    isChecked: isFormOpen,
    toggle: setToggle,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  const handleFormOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setToggle();
  };

  const handleReflectInsertText = (
    value: string,
    isHtml?: boolean,
    isChart?: boolean,
  ) => {
    const headingChildElement = headingRef.current?.children[0] as HTMLElement;
    if (!headingChildElement) return;

    if (isHtml) {
      const divElem = document.createElement("div");
      divElem.innerHTML = value;
      divRef.current?.appendChild(divElem);
      if (isChart) {
        setInsertChartTrue();
      }
    } else {
      const pElem = document.createElement("p");
      pElem.textContent = value;
      const segments = value.split("\n");
      const pElems = segments.map((segment) => {
        const pElem = document.createElement("p");
        pElem.textContent = segment;
        return pElem;
      });

      pElems.map((pElem) => {
        divRef.current?.appendChild(pElem);
      });
    }
    setInsertText({
      matchHTMLElement: headingChildElement,
      insertText: value,
    });
    removeFromRequestHeadingTitles(headingTitle);
    setClose();
  };

  useEffect(() => {
    if (!requestAi) return;

    setOpen();
  }, [requestAi, setOpen]);

  return (
    <div ref={divRef}>
      <button
        type="button"
        className="flex items-center group space-x-1 w-full"
        onClick={handleFormOpen}
      >
        <Icon
          icon="tbPencilPlus"
          size="1.7rem"
          color="text-white"
          className="bg-primary-400 p-1 shadow rounded-full hidden group-hover:block"
        />
        <div ref={headingRef} className="heading w-full text-left">
          {heading}
        </div>
      </button>
      <div className="remove-element">
        {isFormOpen && (
          <DocumentInsertTextForm
            systemName={systemName}
            documentInfo={documentInfo}
            learningDatabaseId={learningDatabaseId}
            headingTitle={headingTitle}
            requestAi={requestAi}
            setInsertText={handleReflectInsertText}
            addToQueue={addToQueue}
            removeFromRequestHeadingTitles={removeFromRequestHeadingTitles}
          />
        )}
      </div>
    </div>
  );
};
