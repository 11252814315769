import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { EvaluateDatabase } from "@spesill/models";
import { EvaluateDatabaseRepository } from "@spesill/repository/db/evaluateDatabase.repository";

const evaluateDatabaseRepository = new EvaluateDatabaseRepository();

export const useEvaluateDatabase = (id: string) => {
  const { currentUser } = useCurrentUser();

  const [evaluateDatabase, setEvaluateDatabase] = useState<EvaluateDatabase>();

  const fetchEvaluateDatabase = useCallback(async () => {
    if (!currentUser?.tenantId || !id) return;

    const evaluateDatabase =
      await evaluateDatabaseRepository.findByTenantIdAndId(
        currentUser.tenantId,
        id,
      );
    if (evaluateDatabase.deletedAt) {
      setEvaluateDatabase(undefined);
      return;
    }
    setEvaluateDatabase(evaluateDatabase);
  }, [currentUser?.tenantId, id]);

  useEffect(() => {
    fetchEvaluateDatabase();
  }, [fetchEvaluateDatabase]);

  return { fetchEvaluateDatabase, evaluateDatabase };
};
