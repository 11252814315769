import React, { FC, ReactNode } from "react";

import { Icon, IconType } from "@spesill/components/atoms";

type PropsType = {
  className?: string;
  title: string;
  icon: IconType;
  setSelectAiRequestMenu: () => void;
  children?: ReactNode;
};

export const DocumentInsertMenuButton: FC<PropsType> = ({
  className = "",
  setSelectAiRequestMenu,
  title,
  icon,
  children,
}: PropsType) => {
  return (
    <li className={`rounded list-none ${className}`}>
      <button
        type="button"
        className="w-full px-2 py-1 flex items-center space-x-2 hover:bg-blueGray-0"
        onClick={() => setSelectAiRequestMenu()}
      >
        <Icon icon={icon} size="1.2rem" color="text-blueGray-300" />
        <span>{title}</span>
      </button>
      {children}
    </li>
  );
};
