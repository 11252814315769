import { useState, useCallback } from "react";

import { MultiValueType, SingleValueType } from "@spesill/components/atoms";

import { DisplayRole } from "@spesill/models";

type ReturnType = {
  dropdownValue?: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (newValue: SingleValueType | null) => void;
};

export const useRoleDropdownField = (): ReturnType => {
  const [value, setValue] = useState<SingleValueType>();

  const [dropdownSelectableValue] = useState<MultiValueType>([
    { value: "ADMIN", label: DisplayRole["ADMIN"] },
    { value: "MEMBER", label: DisplayRole["MEMBER"] },
  ]);

  const onChangeDropDown = useCallback((newValue: SingleValueType | null) => {
    setValue(newValue);
  }, []);

  return {
    dropdownValue: value,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
  };
};
