import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton, TextField } from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import { useBoolean, useInput } from "@spesill/hooks";
import { TenantDocumentSettings } from "@spesill/models";
import { TenantDocumentSettingsRepository } from "@spesill/repository/db/tenantDocumentSettings.repository";

type PropsType = {
  tenantDocumentSettings: TenantDocumentSettings;
  defaultName?: string;
  onClose: () => void;
  onSuccess: () => void;
};
export const UpdateTenantNameModal = ({
  tenantDocumentSettings,
  onClose,
  onSuccess,
}: PropsType) => {
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);
  const [{ value: tenantName, onChange: onChangeName }] = useInput(
    tenantDocumentSettings.tenantName,
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRequestingTrue();
    const tenantDocumentSettingsRepository =
      new TenantDocumentSettingsRepository();
    await tenantDocumentSettingsRepository
      .updateById(tenantDocumentSettings.id, {
        tenantName: tenantName,
      })
      .then(() => {
        onSuccess();
        toast.success("会社名を更新しました");
      })
      .catch((e) => {
        toast.error(
          e instanceof Error ? e.message : "会社名の更新に失敗しました",
        );
      })
      .finally(() => {
        onClose();
        setRequestingFalse();
      });
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="会社名">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <TextField
            type="text"
            name="tenantName"
            required
            placeholder="会社名を入力してください。"
            value={tenantName}
            onChange={onChangeName}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
              disabled={isRequesting}
            />
            <FormSubmitButton text="保存" color="primary" variant="contained" />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
