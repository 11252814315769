import React, { ComponentProps, FC, MouseEvent } from "react";

import { Icon, Tooltip } from "@spesill/components/atoms";
import {
  ButtonColor,
  ButtonColorType,
} from "@spesill/components/atoms/Button/constants";

type TooltipProps = {
  text: string;
  direction: "top" | "right" | "bottom" | "left";
  className?: string;
};

type PropsType = {
  disabled?: boolean;
  className?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  srOnlyText?: string;
  icon: ComponentProps<typeof Icon>;
  color?: ButtonColorType;
  tooltip?: TooltipProps;
};

export const IconButton: FC<PropsType> = ({
  className = "",
  srOnlyText = "",
  disabled = false,
  icon,
  color = "gray",
  onClick,
  tooltip,
}: PropsType) => {
  const iconButton = (
    <button
      type="button"
      className={`rounded ${ButtonColor["text"][color]} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {srOnlyText && <span className="sr-only">{srOnlyText}</span>}
      <Icon {...icon} />
    </button>
  );

  return (
    <>
      {tooltip ? (
        <Tooltip
          text={tooltip.text}
          direction={tooltip.direction}
          className={tooltip.className}
        >
          {iconButton}
        </Tooltip>
      ) : (
        iconButton
      )}
    </>
  );
};
