import React, { FC } from "react";
import { FileRejection } from "react-dropzone";

import { Icon, DropzoneFileField } from "@spesill/components/atoms";
import { ACCEPT_DATABASE_IMPORT_FILE_TYPES } from "@spesill/components/atoms/DropzoneFileField/constants";
import { IconButton } from "@spesill/components/molecules";

import { convertReadableByte } from "@spesill/utils";

type PropsType = {
  className?: string;
  handleDropFile: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => void;
  files?: {
    url: string;
    name: string;
    size: number;
  }[];
  maxFiles?: number;
  acceptFileTypes?: Record<string, string[]>;
  handleDeleteFileByIndex: (index: number) => void;
};

export const DocumentImportForm: FC<PropsType> = ({
  handleDropFile,
  handleDeleteFileByIndex,
  files = [],
  maxFiles,
  className = "",
  acceptFileTypes = ACCEPT_DATABASE_IMPORT_FILE_TYPES,
}: PropsType) => {
  const supportedFileTypes = Object.values(acceptFileTypes).flat();
  return (
    <div>
      {files.length > 0 ? (
        <div className="h-[320px] overflow-y-auto p-4 gap-2 flex flex-col">
          {files.map((file, index) => (
            <div
              key={file.url}
              className="flex justify-center items-center rounded"
            >
              <div className="rounded  flex border w-full justify-between border-primary-400 text-primary-400 p-4">
                <div className="justify-center items-center">
                  <span>{file.name}</span>
                  <p className="text-sm text-blueGray-400">
                    {convertReadableByte(file.size)}
                  </p>
                </div>
                <IconButton
                  icon={{
                    icon: "aiOutlineClose",
                    size: "1.5rem",
                    color: "text-primary-400",
                  }}
                  onClick={() => handleDeleteFileByIndex(index)}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <DropzoneFileField
          onDropFiles={handleDropFile}
          maxFiles={maxFiles}
          multiple
          acceptFileTypes={acceptFileTypes}
          fileClassName={`flex justify-center items-center cursor-pointer rounded px-4 py-2.5 bg-white ${className}`}
        >
          <div className="rounded border border-dashed border-blueGray-50 w-full h-full text-center flex flex-col justify-center py-10 px-6">
            <Icon
              icon="tbFileUpload"
              size="2rem"
              color="text-blueGray-500"
              className="w-full"
            />
            <p className="text-blueGray-500 text-h6 mt-2">
              ここにファイルをドロップ
            </p>
            <p className="text-blueGray-500 text-caption mt-2">
              または<span className="link">ファイルを選択</span>
            </p>
            <p className="text-blueGray-500 text-caption mt-4">
              (ファイル形式: {supportedFileTypes.join(" / ")} )
            </p>
          </div>
        </DropzoneFileField>
      )}
    </div>
  );
};
