import React, { FC, ChangeEvent } from "react";

type PropsType = {
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  rows?: number;
  maxRows?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
};

export const TextArea: FC<PropsType> = ({
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  className = "",
  rows = 7,
  maxRows,
  onChange,
  onBlur,
}: PropsType) => {
  return (
    <div className={`relative ${className}`}>
      <div
        style={{
          minHeight: rows + "rem",
          maxHeight: maxRows ? maxRows + "rem" : undefined,
        }}
        className={`px-4 py-2 overflow-hidden invisible break-keep whitespace-pre-wrap ${className}`}
        aria-hidden="true"
      >
        {value}\u200b
      </div>
      <textarea
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        rows={rows}
        style={{
          minHeight: rows + "rem",
          maxHeight: maxRows ? maxRows + "rem" : undefined,
          font: "inherit",
        }}
        className={`px-4 py-2 absolute top-0 left-0 w-full h-full text-inherit overflow-auto block resize-none border border-solid rounded ${className}`}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};
