import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { OrderByDirection } from "@spesill/libs/firebase";
import { Document } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";

const documentRepository = new DocumentRepository();

type SortType = {
  field: "deletedAt";
  order: OrderByDirection;
};

export const useDeletedDocuments = () => {
  const { currentUser } = useCurrentUser();

  const [documents, setDocuments] = useState<Document[]>([]);

  const [sort, setSort] = useState<SortType>({
    field: "deletedAt",
    order: "desc",
  });

  const fetchDocuments = useCallback(async () => {
    if (!currentUser?.tenantId) return;
    const docs = await documentRepository.findByTenantId(
      currentUser.tenantId,
      {},
      sort,
    );
    const sortedDocs = docs.filter((doc) => doc.deletedAt);
    setDocuments(sortedDocs);
  }, [currentUser, sort]);

  const restoreDocument = useCallback(
    async (id: string) => {
      if (!currentUser?.tenantId) return;
      await documentRepository.restoreById(id);

      await fetchDocuments();
    },
    [currentUser, fetchDocuments],
  );

  const deleteDocument = useCallback(
    async (id: string) => {
      if (!currentUser?.tenantId) return;
      const document = await documentRepository.findByTenantIdAndId(
        currentUser.tenantId,
        id,
      );

      const permission = await document.userPermission(currentUser);
      if (permission !== "editor") {
        throw new Error("権限がありません");
      }
      await Document.deleteWithStorage({
        document: document,
        user: currentUser,
      });
      await fetchDocuments();
    },
    [currentUser, fetchDocuments],
  );

  const changeSortOrderDirection = (order: OrderByDirection) => {
    setSort((prev) => ({ ...prev, order }));
  };

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return {
    fetchDocuments,
    restoreDocument,
    deleteDocument,
    changeSortOrderDirection,
    documents,
    sort,
  };
};
