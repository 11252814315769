import React from "react";

import { TabSwitcherLinks } from "@spesill/components/molecules";

type Props = {
  className?: string;
  currentPage: string;
};

export const SettingsMenuTabs = ({ currentPage, className = "" }: Props) => {
  return (
    <TabSwitcherLinks
      currentPage={currentPage}
      className={className}
      items={[
        {
          text: "アカウント情報",
          link: "/settings/profile",
        },
        {
          text: "ユーザー管理",
          link: "/settings/users",
        },
        {
          text: "ご契約プラン",
          link: "/settings/plan",
        },
        {
          text: "文書設定",
          link: "/settings/document",
        },
        {
          text: "ポンチ絵設定",
          link: "/settings/punch",
        },
        {
          text: "IPアドレス制限",
          link: "/settings/security",
        },
      ]}
    />
  );
};
