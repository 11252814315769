import React, { FC } from "react";

type Props = {
  selected: boolean;
  text: string;
  className?: string;
};

export const TabItem: FC<Props> = ({ selected, text, className = "" }) => {
  return (
    <div
      className={`pb-1.5 ${
        selected && "border-b-2 border-primary-400 border-solid"
      } ${className}`}
    >
      {text}
    </div>
  );
};
