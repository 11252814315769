import React from "react";
import { toast } from "react-toastify";

import Image from "next/image";

import {
  DotLoadingAnimation,
  InitialNameIcon,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";

import { SourceType } from "@spesill/models/source";

import { MarkdownRenderer } from "../..";
import { DocumentSource } from "../../Document/ConvertedHTMLDetail/DocumentHeadingTitleForm/DocumentInsertTextForm/DocumentSource";

type PropsType = {
  isMe?: boolean;
  isRequesting?: boolean;
  lastName?: string;
  message: string;
  sources?: SourceType[];
  isInitial?: boolean;
};

export const MessageBox = ({
  isMe = false,
  isRequesting = false,
  message,
  lastName = "",
  sources,
  isInitial = false,
}: PropsType) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(message || "")
      .then(() => toast.success("テキストをコピーしました"))
      .catch(() => toast.error("コピーに失敗しました"));
  };

  return (
    <li className="flex flex-row gap-2 items-start w-full text-sm">
      {isMe ? (
        <InitialNameIcon className="shrink-0 w-10 h-10" name={lastName} />
      ) : (
        <Image
          src="/logo-circle.png"
          alt="SPESILL LOGO"
          width="40"
          height="40"
        />
      )}
      {}
      {isRequesting ? (
        <div
          className={`p-6 rounded-r-lg rounded-bl-lg w-full mt-5 whitespace-pre-wrap bg-primary-50
          `}
        >
          <DotLoadingAnimation />
        </div>
      ) : (
        <div
          className={`p-6 rounded-r-lg rounded-bl-lg w-full mt-5 whitespace-pre-wrap space-y-4 ${
            isMe ? "bg-white" : "bg-primary-50"
          }`}
        >
          <MarkdownRenderer text={message} />
          <DocumentSource sources={sources} />
          {!isMe && !isInitial && (
            <div className="flex items-end justify-end">
              <IconButton
                icon={{
                  icon: "mdContentCopy",
                  size: "1.25rem",
                  color: "text-blueGray-500",
                }}
                onClick={handleCopy}
                tooltip={{
                  text: "コピーする",
                  direction: "top",
                  className: "text-xs",
                }}
              />
            </div>
          )}
        </div>
      )}
    </li>
  );
};
