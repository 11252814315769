import React, { useEffect } from "react";

import { ConvertedHTMLDetail } from "@spesill/components/organisms";

import {
  useStorageDownloadBlob,
  useWordDocumentsConvertToHtml,
} from "@spesill/hooks";
import { DocumentLocationType } from "@spesill/models/source";

type PropsType = {
  path: string;
  systemName: string;
  location?: DocumentLocationType;
  sourceText?: string;
};

export const DocxFileViewer = ({
  path,
  systemName,
  location,
  sourceText,
}: PropsType) => {
  const { file } = useStorageDownloadBlob(path);
  const { convertToHtml, html } = useWordDocumentsConvertToHtml();

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".highlight")?.scrollIntoView();
    }, 400);
  }, []);

  useEffect(() => {
    if (file) {
      convertToHtml(file, location, sourceText);
    }
  }, [convertToHtml, file, location, sourceText]);

  return (
    <div className="bg-blueGray-50 p-4 w-full">
      {html && <ConvertedHTMLDetail html={html} systemName={systemName} />}
    </div>
  );
};
