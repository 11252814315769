import React, { FC, ChangeEvent } from "react";

type PropsType = {
  name: string;
  value?: number;
  defaultValue?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  min?: number;
  max?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const NumberField: FC<PropsType> = ({
  name,
  value,
  defaultValue,
  placeholder = "",
  required = false,
  disabled = false,
  min,
  max,
  className = "",
  onChange,
}: PropsType) => {
  return (
    <input
      type="number"
      name={name}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      min={min}
      max={max}
      className={`w-full px-4 py-2 rounded border border-solid border-blueGray-400 ${className} ${
        disabled ? "cursor-not-allowed" : ""
      }`}
      onChange={onChange}
    />
  );
};
