import React, { FC, ReactNode } from "react";
import ReactDOM from "react-dom";

import { IconButton } from "../IconButton/IconButton";

type PropsType = {
  onClose: () => void;
  headerTitle?: string;
  children?: ReactNode;
  isWhite?: boolean;
  wrapperClassName?: string;
  className?: string;
  fullContentWidth?: boolean;
  isPortal?: boolean;
  allowBackgroundScroll?: boolean;
};

export const ModalWrapper: FC<PropsType> = ({
  onClose,
  children,
  headerTitle,
  isWhite = false,
  wrapperClassName = "",
  className = "",
  fullContentWidth = false,
  isPortal = false,
  allowBackgroundScroll = false,
}: PropsType) => {
  const modalContent = (
    <>
      <div
        className={`
          fixed
          inset-0
          flex
          items-center
          justify-center
          z-10
          ${allowBackgroundScroll ? "pointer-events-none" : "overflow-y-auto"}
          py-16
          ${isWhite ? "" : "bg-blueGray-900/50"}
          ${wrapperClassName}
        `}
        onClick={onClose}
      />
      {children && (
        <dialog
          className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 my-auto p-6
            inline-block bg-white border-solid border border-blueGray-300 rounded-lg shadow z-20
            overflow-y-auto h-auto max-h-full ${
              fullContentWidth ? "" : "w-8/12"
            } ${className} ${allowBackgroundScroll ? "pointer-events-auto" : ""}`}
        >
          <div className="max-w-[80vw]">
            {headerTitle && (
              <div className="flex justify-between items-center py-2 rounded-t border-b">
                <p className="text-xl font-semibold">{headerTitle}</p>
                <IconButton
                  onClick={onClose}
                  className="inline-flex items-center"
                  icon={{
                    icon: "aiOutlineClose",
                    size: "1.5rem",
                  }}
                />
              </div>
            )}
            {children}
          </div>
        </dialog>
      )}
    </>
  );

  return isPortal
    ? ReactDOM.createPortal(modalContent, document.body)
    : modalContent;
};
