import { useState, useCallback } from "react";

import { MultiValueType, SingleValueType } from "@spesill/components/atoms";

import { UserPermissionType } from "@spesill/models/permittedUser";

type ReturnType = {
  dropdownValue?: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (newValue: SingleValueType) => void;
};

const PermissionLabelHash = {
  editor: "編集者",
  viewer: "閲覧者",
};

export const useUserPermissionDropdownField = (
  initialPermission: UserPermissionType,
): ReturnType => {
  const initialLabel = PermissionLabelHash[initialPermission];

  const [value, setValue] = useState<SingleValueType>({
    value: initialPermission,
    label: initialLabel,
  });
  const [dropdownSelectableValue] = useState<MultiValueType>([
    { value: "viewer", label: "閲覧者" },
    { value: "editor", label: "編集者" },
  ]);

  const onChangeDropDown = useCallback((newValue: SingleValueType) => {
    setValue(newValue);
  }, []);

  return {
    dropdownValue: value,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
  };
};
