import { useEffect, useState } from "react";

import { PunchImage } from "@spesill/models";
import { PunchImageRepository } from "@spesill/repository/db/punchImage.repository";

const punchImageRepository = new PunchImageRepository();

export const usePunchImage = (punchImageId: string) => {
  const [punchImage, setPunchImage] = useState<PunchImage | null>(null);

  useEffect(() => {
    const fetchPunchImage = async () => {
      try {
        const image = await punchImageRepository.findById(punchImageId);
        setPunchImage(image);
      } catch (error) {
        console.error("Failed to fetch punch image:", error);
      }
    };

    fetchPunchImage();
  }, [punchImageId]);

  return punchImage;
};
