import React, { FC } from "react";

type PropsType = {
  name: string;
  className?: string;
};

const COLORS = [
  "bg-cyan-600",
  "bg-purple-400",
  "bg-pink-500",
  "bg-lightBlue-500",
  "bg-brown-500",
  "bg-primary-500",
  "bg-blueGray-500",
  "bg-status-warning",
  "bg-status-success",
  "bg-status-danger",
];

export const InitialNameIcon: FC<PropsType> = ({
  name,
  className = "",
}: PropsType) => {
  const initial = name.slice(0, 1);
  const colorIndex: number =
    String(initial.charCodeAt(0))
      .split("")
      .reduce((a, b) => Number(a) + Number(b), 0) % 10;
  return (
    <div
      className={`flex items-center justify-center px-2 py-1 rounded-full text-white ${className} ${COLORS[colorIndex]}`}
    >
      {initial}
    </div>
  );
};
