import React, { FC } from "react";

export type Props = {
  checked: boolean;
  onChange: () => void;
};

export const Toggle: FC<Props> = ({ checked, onChange }) => {
  return (
    <div
      className={`relative flex items-center justify-center w-10 h-6 rounded-full cursor-pointer ${
        checked ? "bg-primary-400" : "bg-gray-300"
      }`}
      onClick={onChange}
    >
      <div
        className={`absolute left-[1px] w-5 h-5 rounded-full bg-white transition-transform duration-300 ease-in-out ${
          checked ? "transform translate-x-[calc(100%-2px)]" : ""
        }`}
      />
    </div>
  );
};
