import React, { FormEvent } from "react";

import { Button, FormSubmitButton, TextArea } from "@spesill/components/atoms";
import { TextTemplates } from "@spesill/components/organisms";

import { useInput } from "@spesill/hooks";

type PropsType = {
  className?: string;
  onClose: () => void;
  onSubmit?: (
    figureType: "2DLayout" | "2DFlowchart",
    formData: { query: string },
  ) => void;
};

const TemplateTexts = [
  "・商談時の名刺交換からクロージングまでの流れ",
  `・以下のピッキングロボットシステムの動作フローを考えて
・搬送コンベアから流れてくる製品をピッキングして容器に入れる、製品が入った容器を払出コンベアが搬送する`,
];

export const FlowChartForm = ({
  onClose,
  className = "",
  onSubmit,
}: PropsType) => {
  const [{ value: query, onChange: onChangeFlowchartPrompt, setValue }] =
    useInput("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit && onSubmit("2DFlowchart", { query });
  };

  return (
    <form className={`${className}`} onSubmit={handleSubmit}>
      <p className="body-lg">AIにどのような図を作成して欲しいですか？</p>
      <p className="body-base text-blueGray-500">
        シナリオ・流れ / フォーマット
      </p>
      <div className="mt-2">
        <TextArea
          name="insertText"
          value={query}
          placeholder={`例文：
  ・搬送コンベアからピッキングし、払出コンベアへセットをおこなう
  ・ロボットシステムのフローチャートをYES,NOで進む流れで作成してください
                `}
          onChange={onChangeFlowchartPrompt}
          rows={8}
        />
      </div>
      <TextTemplates
        texts={TemplateTexts}
        onSelect={(text: string) => setValue(text)}
        className="mt-3"
      />
      <hr className="mt-8 border-blueGray-50" />
      <div className="space-x-4 text-right mt-4">
        <Button
          text={"キャンセル"}
          color={"gray"}
          variant={"contained"}
          onClick={onClose}
        />
        <FormSubmitButton
          text="作成"
          color="primary"
          variant="contained"
          className="text-sm"
        />
      </div>
    </form>
  );
};
