import { useCallback } from "react";
import { toast } from "react-toastify";

import { useCurrentUser } from "@spesill/hooks";
import { aiCounterFieldType } from "@spesill/models/tenantAiUsage";
import { AiRequestHistoryRepository } from "@spesill/repository/db/aiRequestHistory.reoisitory";
import { TenantAiUsageRepository } from "@spesill/repository/db/tenantAiUsage.repository";

const REQUEST_COUNT = 1;
const tenantAiUsageRepository = new TenantAiUsageRepository();
const aiRequestHistoryRepository = new AiRequestHistoryRepository();

export const useIncrementAiUsage = () => {
  const { currentUser } = useCurrentUser();
  const incrementAiCallCount = useCallback(
    async (field: aiCounterFieldType) => {
      if (!currentUser) return;
      try {
        await tenantAiUsageRepository.incrementCountField(
          currentUser.tenantId,
          field,
          REQUEST_COUNT,
        );
        await aiRequestHistoryRepository.add({
          tenantId: currentUser.tenantId,
          userId: currentUser.id,
          aiRequest: field,
        });
      } catch (error) {
        toast.error("AIの利用回数のカウントに失敗しました");
      }
    },
    [currentUser],
  );

  return { incrementAiCallCount };
};
