import React, { useEffect, useRef, useState } from "react";

type UseHoverType<T extends HTMLElement> = [React.RefObject<T>, boolean];

export const useHover = <T extends HTMLElement>(): UseHoverType<T> => {
  const [isHover, setIsHover] = useState(false);

  const ref = useRef<T>(null);

  const handleMouseOver = () => setIsHover(true);
  const handleMouseOut = () => setIsHover(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);

      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [ref]);

  return [ref, isHover];
};
