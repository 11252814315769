import React, { MouseEvent } from "react";
import { toast } from "react-toastify";

import { IconButton } from "@spesill/components/molecules";

import { PunchImage } from "@spesill/models";

type PropsType = {
  punchImage: ExcludeMethods<PunchImage>;
  className?: string;
  handleLogicalDelete: (id: string) => void;
  handlePunchSelectEdit: (punchImage: ExcludeMethods<PunchImage>) => void;
};
export const PunchIcon = ({
  handleLogicalDelete,
  handlePunchSelectEdit,
  punchImage,
  className = "",
}: PropsType) => {
  const onLogicalDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const result = window.confirm("削除してもよろしいですか？");
    if (!result) return;

    handleLogicalDelete(punchImage.id);
    toast.success("削除しました");
  };

  const onSelectEdit = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    handlePunchSelectEdit(punchImage);
  };

  return (
    <div
      className={`rounded-lg relative border-blueGray-50 border ${className}`}
      onClick={onSelectEdit}
    >
      <img
        src={punchImage.path}
        className="text-center rounded-t-lg h-32 w-full object-cover"
        alt="icon"
        height={120}
      />
      <IconButton
        icon={{ icon: "bsTrash", size: "1.3rem" }}
        onClick={onLogicalDelete}
        className="absolute top-2 right-2"
      />
      <div className="p-3 border-blueGray-50 border-t">
        <span className="text-body-lg-bold">{punchImage.name}</span>
        <p className="text-body-base text-blueGray-400">
          <span>{punchImage.width}マス</span>
          <span>×</span>
          <span>{punchImage.height}マス</span>
        </p>
      </div>
    </div>
  );
};
