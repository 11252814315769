import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { User } from "@spesill/models";
import { EvaluateDatabase } from "@spesill/models";
import { EvaluateDatabaseRepository } from "@spesill/repository/db/evaluateDatabase.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

import type { OrderByDirection } from "@spesill/libs/firebase";

const userRepository = new UserRepository();
const evaluateDatabaseRepository = new EvaluateDatabaseRepository();

type EvaluateDatabaseWithUser = EvaluateDatabase & { user?: User };

type FilterType = {
  createUserId?: string;
};

type SortType = {
  field: "updatedAt";
  order: OrderByDirection;
};

export const useEvaluateDatabasesWithUser = () => {
  const { currentUser } = useCurrentUser();
  const [evaluateDatabases, setEvaluateDatabases] = useState<
    EvaluateDatabaseWithUser[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType>();
  const [sort, setSort] = useState<SortType>({
    field: "updatedAt",
    order: "desc",
  });

  const fetchEvaluateDatabasesAndUsers = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const users = await userRepository.findByTenantId(currentUser.tenantId);
    const docs = await evaluateDatabaseRepository.findByTenantId(
      currentUser.tenantId,
      {
        createUserId: selectedFilter?.createUserId,
      },
      sort,
    );

    const docsWithUser = docs
      .filter((doc) => !doc.deletedAt)
      .map((doc) => {
        const user = users.find((user) => user.id === doc.createUserId);
        return Object.defineProperty(doc, "user", {
          value: user,
          writable: true,
          enumerable: true,
          configurable: true,
        });
      });
    setEvaluateDatabases(docsWithUser);

    return docsWithUser;
  }, [currentUser?.tenantId, selectedFilter, sort]);

  useEffect(() => {
    fetchEvaluateDatabasesAndUsers();
  }, [currentUser?.tenantId, fetchEvaluateDatabasesAndUsers]);

  const logicalDeleteEvaluateDatabase = useCallback(
    async (id: string) => {
      if (!currentUser?.tenantId) return;

      await evaluateDatabaseRepository.logicalDeleteById(id);

      await axios.post("/api/mail/notification", {
        notificationType: "delete-EvaluateDatabase",
        systemName: (
          evaluateDatabases.find((db) => db.id === id) as EvaluateDatabase
        ).systemName,
        operatorUserId: currentUser.id,
        resourceId: id,
      });
      await fetchEvaluateDatabasesAndUsers();
    },
    [currentUser, evaluateDatabases, fetchEvaluateDatabasesAndUsers],
  );

  const selectCreateUserId = (createUserId: string) => {
    setSelectedFilter({ createUserId });
  };

  const resetFilterCreateUserId = () => {
    setSelectedFilter({ createUserId: undefined });
  };

  const changeSortOrderDirection = (order: OrderByDirection) => {
    setSort((prev) => ({ ...prev, order }));
  };

  return {
    evaluateDatabases,
    selectedFilter,
    sort,
    fetchEvaluateDatabasesAndUsers,
    logicalDeleteEvaluateDatabase,
    selectCreateUserId,
    resetFilterCreateUserId,
    changeSortOrderDirection,
  };
};
