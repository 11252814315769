import React, { useEffect, useState } from "react";

import { useStorageDownloadBlob } from "@spesill/hooks";

type PropsType = {
  path: string;
};

export const OfficeFileViewer = ({ path }: PropsType) => {
  const { url } = useStorageDownloadBlob(path);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (url) {
      setPreviewUrl(
        `https://docs.google.com/gview?url=${encodeURIComponent(
          url,
        )}&embedded=true`,
      );
    }
  }, [url]);

  return (
    <div className="bg-blueGray-50  h-hull w-full">
      {previewUrl ? (
        <iframe
          src={previewUrl}
          style={{ width: "80vw", height: "90vh", border: "none" }}
          title="Excel Preview"
        ></iframe>
      ) : (
        <p>Loading file...</p>
      )}
    </div>
  );
};
