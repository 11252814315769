import React from "react";

import { DropdownField, SingleValueType } from "@spesill/components/atoms";
import { FloatingMenu, ListMenu } from "@spesill/components/molecules";

import { useBoolean } from "@spesill/hooks";
import { PunchImage } from "@spesill/models";

import { CELL_DEFAULT_PX_SIZE } from "../PunchKeys";

type PropsType = {
  onAddItem?: (
    punchImage: PunchImage,
    position: { x: number; y: number },
  ) => void;
  isEditable?: boolean;
  punchImages?: PunchImage[];
  position: { x: number; y: number };
};
export const PunchGridEmptyCell = ({
  onAddItem,
  isEditable = false,
  punchImages = [],
  position,
}: PropsType) => {
  const {
    isChecked: isContextMenuOpen,
    setTrue: setContextMenuOpen,
    setFalse: setContextMenuClose,
  } = useBoolean(false);
  const {
    isChecked: isOpenItemMenu,
    setTrue: setItemMenuOpen,
    setFalse: setItemMenuClose,
  } = useBoolean(true);

  const cellStyle = {
    width: `${CELL_DEFAULT_PX_SIZE}px`,
    height: `${CELL_DEFAULT_PX_SIZE}px`,
  };

  const handleContextMenuOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setContextMenuOpen();
  };

  const menuItems = [
    {
      label: "アイテムを追加",
      onClick: () => {
        setItemMenuOpen();
      },
      selected: isOpenItemMenu,
      icon: {
        icon: "aiOutlinePlus" as const,
        size: "1rem" as const,
      },
      className: "list-none",
    },
  ];

  const dropdownSelectableValueItem = punchImages?.map((punchImage) => ({
    value: punchImage.id,
    label: punchImage.name,
  }));

  const handleClose = () => {
    setContextMenuClose();
    setItemMenuClose();
  };

  const handleAddItem = (newValue: string) => {
    const newItem = punchImages?.find(
      (punchImage) => punchImage.id === newValue,
    );
    onAddItem &&
      newItem &&
      onAddItem(newItem, { x: position.x, y: position.y });
    handleClose();
  };

  return (
    <div>
      <div
        className={`border ${
          isContextMenuOpen && isEditable
            ? " border-primary-500 border-dashed bg-primary-50"
            : "border-blueGray-50"
        }`}
        style={cellStyle}
        onContextMenu={handleContextMenuOpen}
      ></div>
      <div className="relative">
        {isContextMenuOpen && isEditable && (
          <div>
            <ListMenu items={menuItems} onClose={setContextMenuClose} />
            {isOpenItemMenu && (
              <FloatingMenu onClose={handleClose} className="right-8">
                <DropdownField
                  name={"punchImage"}
                  className="w-40"
                  options={dropdownSelectableValueItem}
                  placeholder="検索"
                  isClearable={false}
                  menuIsOpen={true} //初期表示でドロップダウンを開く
                  onChange={(newValue: SingleValueType, action) => {
                    if (action.action === "select-option") {
                      handleAddItem(newValue?.value || "");
                    }
                  }}
                />
              </FloatingMenu>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
