import React, { MouseEvent } from "react";
import { toast } from "react-toastify";

import Link from "next/link";

import { Icon, Tooltip } from "@spesill/components/atoms";
import {
  MenuItemType,
  IconButton,
  ListMenu,
} from "@spesill/components/molecules";

import { useBoolean } from "@spesill/hooks";
import { formatDate } from "@spesill/libs";
import { LearningDatabase } from "@spesill/models";
import { User } from "@spesill/models";

type PropsType = {
  learningDatabase: LearningDatabase & { user?: User };
  disabled?: boolean;
  deleteLearningDatabase: (id: string) => void;
};

export const LearningDatabaseListRow = ({
  learningDatabase,
  disabled = false,
  deleteLearningDatabase,
}: PropsType) => {
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  const handleDeleteLearningDatabase = async () => {
    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;
    const deletingStatus = toast.loading("削除中です。しばらくお待ちください");

    try {
      deleteLearningDatabase(learningDatabase.id);
      toast.update(deletingStatus, {
        render: "削除が完了しました。",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      toast.dismiss(deletingStatus);
      toast.error("削除に失敗しました。");
    }
  };

  const menuItems: MenuItemType[] = [
    {
      label: "削除",
      className: "text-status-danger",
      onClick: () => handleDeleteLearningDatabase(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpen();
  };

  return (
    <div className="bg-white flex text-blueGray-700 py-3 px-4 rounded-md h-16 hover:bg-blueGray-50">
      <Link
        key={learningDatabase.id}
        href={`/learnings/${learningDatabase.id}`}
        className="flex-1 gap-x-4 flex items-center"
      >
        <div className="flex items-center space-x-2 flex-1 gap-x-4">
          <Tooltip
            direction="top"
            style={{ left: "-0.75rem" }}
            text={
              learningDatabase.structureType === "table"
                ? "表形式データです"
                : "文章形式データなどのデータです"
            }
          >
            <div className="bg-blueGray-0 w-8 h-8 flex items-center justify-center rounded shadow">
              {learningDatabase.structureType === "table" ? (
                <Icon icon={"bsTable"} size="1.5rem" />
              ) : (
                <Icon icon={"ioDocumentOutline"} size="1.5rem" />
              )}
            </div>
          </Tooltip>
          <span className="text-body-base-bold">
            {learningDatabase.systemName}
          </span>
        </div>
        <div className="text-body-base w-1/6">
          {formatDate(learningDatabase.updatedAt)}
        </div>
        <div className="text-body-base w-1/6">
          {learningDatabase.user?.fullName}
        </div>
      </Link>

      <div className="flex gap-6 items-center justify-end w-1/12">
        <div className="relative flex items-center">
          <IconButton
            icon={{
              icon: "bsThreeDots",
              color: "text-blueGray-500",
              size: "1.5rem",
            }}
            srOnlyText="メニューを開く"
            onClick={handleOpen}
          />
          {isOpen && (
            <ListMenu
              items={menuItems}
              onClose={onClose}
              disabled={disabled}
              className="right-full mr-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};
