import DOMPurify from "dompurify";
import parse, {
  DOMNode,
  HTMLReactParserOptions,
  domToReact,
  Element,
  Text,
} from "html-react-parser";
import htmlToDocx from "html-to-docx";
import React, {
  FC,
  ReactNode,
  useRef,
  MouseEvent,
  useState,
  useEffect,
} from "react";
import { INode } from "react-flow-builder";
import { toast } from "react-toastify";

import {
  Button,
  DropdownField,
  SingleValueType,
} from "@spesill/components/atoms";
import { LabelWithCheckbox } from "@spesill/components/molecules";
import {
  RightFloatSidebar,
  FlowChartEditor,
} from "@spesill/components/organisms";

import {
  useBoolean,
  useRegisterDocumentToLearningDatabase,
} from "@spesill/hooks";
import { useRequestQueue } from "@spesill/hooks/functions/useRequestQue";
import { Document } from "@spesill/models";
import { ResponseGeneratePunchImage } from "@spesill/models/api/generate_punch_image";
import { PunchItemsType } from "@spesill/models/punchLayout";

import styles from "./ConvertedHTMLDetail.module.css";
import { DocumentHeadingTitleForm } from "./DocumentHeadingTitleForm/DocumentHeadingTitleForm";
import { ErrorBoundary } from "./ErrorBoundary";
import { PunchGridLayoutEditor } from "../../Punch/PunchLayoutEditor/PunchLayoutEditor";

const bodyTagStart = "<body>";
const bodyTagEnd = "</body>";
const bodyStartTagLength = bodyTagStart.length;

type PropsType = {
  html: string;
  document?: Document;
  fileName?: string;
  className?: string;
  systemName?: string;
  currentDatabaseValue?: SingleValueType;
  handleUploadFile?: (html: string) => Promise<void>;
  setInsertText?: ({
    matchHTMLElement,
    insertText,
  }: {
    matchHTMLElement: HTMLElement;
    insertText: string;
  }) => void;
  isEditor?: boolean;
  isFreePlan?: boolean;
};

const headersMap = {
  h1: (children: ReactNode) => <h1>{children}</h1>,
  h2: (children: ReactNode) => <h2>{children}</h2>,
  h3: (children: ReactNode) => <h3>{children}</h3>,
  h4: (children: ReactNode) => <h4>{children}</h4>,
  h5: (children: ReactNode) => <h5>{children}</h5>,
  h6: (children: ReactNode) => <h6>{children}</h6>,
};

type HeadingType = keyof typeof headersMap;

const headingTags = ["h1", "h2", "h3", "h4", "h5", "h6"];

export const ConvertedHTMLDetail: FC<PropsType> = ({
  html,
  document,
  fileName = "",
  systemName = "",
  className = "",
  currentDatabaseValue,
  handleUploadFile,
  setInsertText,
  isEditor = false,
  isFreePlan = false,
}: PropsType) => {
  const headings = useRef<string[]>([]);
  const learningDatabaseId = currentDatabaseValue?.value;
  const divRef = useRef<HTMLDivElement>(null);
  const [defaultHtml, setDefaultHtml] = useState<string>(html);
  const [requestHeadingTitles, setRequestHeadingTitles] = useState<string[]>(
    [],
  );
  const {
    isChecked: isRegisterToLearningDatabase,
    onChange: onChangeRegisterToLearningDatabase,
  } = useBoolean(false);
  const [headingTitles, setHeadingTitles] = useState<string[]>([]);
  const {
    dropdownValueLearningDatabase,
    dropdownSelectableValueLearningDatabase,
    onChangeDropdownLearningDatabase,
    handleRegisterDocument,
  } = useRegisterDocumentToLearningDatabase({
    systemName: fileName,
    html,
    currentDatabaseValue,
    htmlToDocx,
  });
  const { isChecked: isChanged, setTrue, setFalse } = useBoolean(false);
  const {
    isChecked: isInsertChart,
    setTrue: setInsertChartTrue,
    setFalse: setInsertChartFalse,
  } = useBoolean(false);
  const {
    isChecked: isSaving,
    setTrue: setSavingTrue,
    setFalse: setSavingFalse,
  } = useBoolean(false);

  const { addToQueue } = useRequestQueue();

  const removeFromRequestHeadingTitles = (headingTitle: string) => {
    const newRequestHeadingTitles = requestHeadingTitles.filter(
      (title) => title !== headingTitle,
    );
    setRequestHeadingTitles(newRequestHeadingTitles);
  };

  const updateCheckBoxes = (element: HTMLElement) => {
    const checkBoxes = Array.from(
      element.querySelectorAll("input[type='checkbox']"),
    );
    checkBoxes.forEach((checkbox) => {
      const input = checkbox as HTMLInputElement;
      input.checked
        ? input.setAttribute("checked", "checked")
        : input.removeAttribute("checked");
    });
  };

  const replaceButtonsWithHeadings = (element: HTMLElement) => {
    const buttonElements = Array.from(
      element.querySelectorAll("div > button:has(h1, h2, h3, h4, h5, h6)"),
    );
    buttonElements.forEach((button) => {
      const heading = button.querySelector("h1, h2, h3, h4, h5, h6");
      heading && button.replaceWith(heading);
    });
  };

  const removeElements = (element: HTMLElement) => {
    const removeElements = Array.from(
      element.querySelectorAll(".remove-element"),
    );
    removeElements.forEach((el) => el.remove());
  };

  //FIXME: 「暫定対応」保存時にフローチャートが重複して保存されるので、フローチャートの要素を削除する
  function removeUnwantedFlowBuilderContent(element: HTMLElement) {
    const allFlowBuilderContents =
      element.querySelectorAll(".flow-builder-wrap");

    allFlowBuilderContents.forEach((el) => {
      el.remove();
    });
  }

  const onSaveFile = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!handleUploadFile) return;

    const divElement = divRef.current;
    if (!divElement) return;
    setSavingTrue();
    const uploadStatus = toast.loading(
      "アップロード中です。しばらくお待ちください",
    );
    updateCheckBoxes(divElement);
    replaceButtonsWithHeadings(divElement);
    removeElements(divElement);
    //FIXME: 「暫定対応」保存時にフローチャートが重複して保存されるので、フローチャートを削除する
    removeUnwantedFlowBuilderContent(divElement);
    const sanitizedHtml = DOMPurify.sanitize(divElement.innerHTML || "");
    try {
      if (isRegisterToLearningDatabase) {
        if (!dropdownValueLearningDatabase?.value) {
          toast.error("データベースを選択してください。");
          return;
        }
        await handleRegisterDocument();
      }
      await handleUploadFile(sanitizedHtml);
      setDefaultHtml(sanitizedHtml);
      setRequestHeadingTitles([]);
      setFalse();
      toast.update(uploadStatus, {
        render: "アップロードが完了しました。",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch {
      toast.error("アップロードに失敗しました。");
      toast.dismiss(uploadStatus);
    } finally {
      setSavingFalse();
    }
  };

  // NOTE: bodyタグの中身があれば、bodyタグの中身のみを取得します
  const parseTargetHtml =
    defaultHtml.includes(bodyTagStart) && defaultHtml.includes(bodyTagEnd)
      ? defaultHtml.substring(
          defaultHtml.indexOf(bodyTagStart) + bodyStartTagLength,
          defaultHtml.indexOf(bodyTagEnd),
        )
      : defaultHtml;

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (!setInsertText) return;
      if (!(domNode instanceof Element)) return;
      if (domNode.type !== "tag") return;
      if (
        domNode.name === "svg" &&
        domNode.attribs.class ===
          "bg-primary-400 p-1 shadow rounded-full hidden group-hover:block text-white"
      ) {
        return <></>;
      }
      const chartNode = domNode.children.find(
        (child) => child instanceof Element && child.attribs["data-chart-type"],
      );
      if (!headingTags.includes(domNode.name) && !chartNode) return;

      if (chartNode && chartNode instanceof Element) {
        const state: INode[] | ResponseGeneratePunchImage = JSON.parse(
          chartNode.attribs["data-chart-state"] || JSON.stringify([]),
        );
        switch (chartNode.attribs["data-chart-type"]) {
          case "flowchart":
            return (
              <div contentEditable={false}>
                <FlowChartEditor
                  headingTitle={headings.current[headings.current.length - 1]}
                  documentKind={document?.kind}
                  freeInputKind={document?.freeInputKind}
                  learningDatabaseId={learningDatabaseId}
                  state={state as INode[]}
                />
              </div>
            );
          case "punchLayout":
            return (
              <div contentEditable={false}>
                <PunchGridLayoutEditor
                  width={(state as ResponseGeneratePunchImage)?.width as number}
                  height={
                    (state as ResponseGeneratePunchImage)?.height as number
                  }
                  punchItems={
                    (state as ResponseGeneratePunchImage)
                      ?.punch_items as PunchItemsType[]
                  }
                  learningDatabaseId={learningDatabaseId}
                />
              </div>
            );
          default:
            return <></>;
        }
      }

      const headingDom = domNode.children.find(
        (child) => child.type === "text",
      );
      const headingTitle = headingDom instanceof Text ? headingDom?.data : "";

      headings.current.push(headingTitle || "");
      return (
        <DocumentHeadingTitleForm
          systemName={systemName}
          documentInfo={document}
          heading={headersMap[domNode.name as HeadingType](
            domToReact(domNode.children as DOMNode[]),
          )}
          setInsertChartTrue={setInsertChartTrue}
          headingTitle={headingTitle}
          learningDatabaseId={learningDatabaseId}
          requestAi={requestHeadingTitles.includes(headingTitle || "")}
          setInsertText={setTrue}
          addToQueue={addToQueue}
          removeFromRequestHeadingTitles={removeFromRequestHeadingTitles}
        />
      );
    },
  };

  useEffect(() => {
    const elements =
      divRef.current?.querySelectorAll(headingTags.join(",")) || [];
    const titles = Array.from(elements).map((element) => {
      const headingTitle = element.textContent;
      return headingTitle || "";
    });
    setHeadingTitles(titles);
  }, []);

  useEffect(() => {
    setDefaultHtml(html);
  }, [html]);

  useEffect(() => {
    if (isInsertChart) {
      const divElement = divRef.current;
      if (!divElement) return;
      setDefaultHtml(divElement.innerHTML);
      setInsertChartFalse();
    }
  }, [isInsertChart, setInsertChartFalse]);

  return (
    <div className={`relative ${className}`}>
      {document && isEditor && (
        <RightFloatSidebar
          document={document}
          headingTitles={headingTitles}
          setRequestHeadingTitles={setRequestHeadingTitles}
        />
      )}
      <div
        ref={divRef}
        contentEditable={!!setInsertText}
        onInput={setTrue}
        suppressContentEditableWarning
        className={`max-h-[90vh] bg-white overflow-y-auto mx-auto p-10 whitespace-pre-wrap ${styles.allRevert}`}
      >
        <ErrorBoundary>{parse(parseTargetHtml, options)}</ErrorBoundary>
      </div>
      {/* NOTE: 初めに受け取ったHTMLが変化したら保存ボタンを表示 */}
      {handleUploadFile && isChanged && (
        <div className="sticky bottom-0 ">
          <div className="bg-primary-400 w-full py-4 opacity-90 flex flex-row justify-between items-center pl-6 pr-20">
            <Button
              text="編集内容を保存"
              color="primary"
              variant="contained"
              onClick={onSaveFile}
              disabled={isSaving}
            />
            {!isFreePlan && (
              <div className="flex gap-4 flex-row items-center">
                <LabelWithCheckbox
                  labelText="学習データベースとして登録"
                  name="isRegisterToLearningDatabase"
                  checked={isRegisterToLearningDatabase}
                  onChange={onChangeRegisterToLearningDatabase}
                  value="isRegisterToLearningDatabase"
                  labelClassName="text-white"
                  size="lg"
                  labelEnd
                />
                <DropdownField
                  disabled={!isRegisterToLearningDatabase}
                  value={dropdownValueLearningDatabase}
                  options={dropdownSelectableValueLearningDatabase}
                  onChange={onChangeDropdownLearningDatabase}
                  required
                  name="learningDatabase"
                  menuPlacement="top"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
