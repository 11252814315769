import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { LearningDatabase } from "@spesill/models";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";

const learningDatabaseRepository = new LearningDatabaseRepository();

export const useLearningDatabase = (id: string) => {
  const { currentUser } = useCurrentUser();

  const [learningDatabase, setLearningDatabase] = useState<LearningDatabase>();

  const fetchLearningDatabase = useCallback(async () => {
    if (!currentUser?.tenantId || !id) return;

    const learningDatabase =
      await learningDatabaseRepository.findByTenantIdAndId(
        currentUser.tenantId,
        id,
      );
    if (learningDatabase.deletedAt) {
      setLearningDatabase(undefined);
      return;
    }
    setLearningDatabase(learningDatabase);
  }, [currentUser?.tenantId, id]);

  useEffect(() => {
    fetchLearningDatabase();
  }, [fetchLearningDatabase]);

  return { fetchLearningDatabase, learningDatabase };
};
