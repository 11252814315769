import { v4 } from "uuid";

import { Entity } from "./__common__/entity";

export class ChatRoom extends Entity {
  id: string;
  title: string;
  tenantId: string;
  createUserId: string;
  learningDatabaseId?: string;
  deletedAt?: Date;

  constructor(arg: ExcludeMethods<ChatRoom>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.title = arg.title;
    this.learningDatabaseId = arg.learningDatabaseId;
    this.createUserId = arg.createUserId;
    this.deletedAt = arg.deletedAt;
  }

  static create(args: Omit<ConstructorParameters<typeof ChatRoom>[0], "id">) {
    return new ChatRoom({
      id: v4(),
      ...args,
    });
  }
}
