import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { ChatRoomMessage } from "@spesill/models";
import { ChatRoomRepository } from "@spesill/repository/db/chatRoom.repository";
import { ChatRoomMessageRepository } from "@spesill/repository/db/chatRoomMesage.repository";

const chatRoomMessageRepository = new ChatRoomMessageRepository();
const chatRoomRepository = new ChatRoomRepository();

export const useChatRoomMessages = (chatRoomId: string) => {
  const { currentUser } = useCurrentUser();
  const [chatRoomMessages, setChatRoomMessages] = useState<ChatRoomMessage[]>(
    [],
  );

  const fetchChatRoomMessages = useCallback(async () => {
    if (!currentUser?.tenantId) return;
    const chat = await chatRoomRepository.findById(chatRoomId);
    if (chat.createUserId !== currentUser.id) {
      setChatRoomMessages([]);
      return;
    }

    const docs =
      await chatRoomMessageRepository.findByChatRoomMessageId(chatRoomId);
    setChatRoomMessages(docs);
  }, [chatRoomId, currentUser]);

  useEffect(() => {
    fetchChatRoomMessages();
  }, [currentUser?.tenantId, fetchChatRoomMessages]);

  return {
    chatRoomMessages,
    fetchChatRoomMessages,
  };
};
