import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import TiptapPunchLayoutComponent from "./TiptapPunchLayout";

export const TiptapPunchLayoutExtension = ({
  learningDatabaseId,
}: {
  learningDatabaseId?: string;
}) =>
  Node.create({
    name: "punchLayoutComponent",

    group: "block",

    atom: true,

    addAttributes() {
      return {
        state: {
          default: "",
        },
        learningDatabaseId: {
          default: learningDatabaseId,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: "punchLayout",
        },
        // ConvertedHtmlDetailで作成されたポンチ絵との互換性を保つために、div[data-chart-type='punchLayout']を追加
        {
          tag: "div[data-chart-type='punchLayout']",
          getAttrs: (dom) => {
            const element = dom as HTMLElement;
            const chartState = element.getAttribute("data-chart-state");
            if (!chartState) {
              return {};
            }
            try {
              const parsedState = JSON.parse(chartState);

              return {
                state: JSON.stringify({
                  width: parsedState.width,
                  height: parsedState.height,
                  punchItems: parsedState.punch_items,
                }),
              };
            } catch (error) {
              console.error("Error parsing chart state:", error);
              return {};
            }
          },
        },
      ];
    },
    renderHTML({ HTMLAttributes }) {
      return [
        "punchLayout",
        mergeAttributes(HTMLAttributes, { "data-chart-type": "punchLayout" }), //htmlをクエリする時に都合がいいので、data-chart-typeを追加
      ];
    },

    addNodeView() {
      return ReactNodeViewRenderer(TiptapPunchLayoutComponent);
    },
  });
