import axios from "axios";

import { useBoolean, useCurrentUser } from "@spesill/hooks";
import { PostsNotification } from "@spesill/models";
import { EvaluateDatabaseRepository } from "@spesill/repository/db/evaluateDatabase.repository";

type UploadFileType = {
  systemName: string;
  onSuccess?: (id: string) => void;
};

type ReturnType = {
  isRequesting: boolean;
  createEvaluateDatabase: ({
    systemName,
  }: UploadFileType) => Promise<{ id: string } | null>;
};

export const useCreateEvaluateDatabase = (): ReturnType => {
  const { currentUser } = useCurrentUser();
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);

  const createEvaluateDatabase = async ({
    systemName,
    onSuccess,
  }: UploadFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    setRequestingTrue();
    const evaluateDatabaseRepository = new EvaluateDatabaseRepository();
    const evaluateDatabase = await evaluateDatabaseRepository.add({
      tenantId: currentUser.tenantId,
      systemName,
      createUserId: currentUser.id,
      lastUpdateUserId: currentUser.id,
    });
    await PostsNotification.insertNewNotification({
      resource: evaluateDatabase,
      createUser: currentUser,
      action: "create",
    });
    await axios.post("/api/mail/notification", {
      notificationType: "create-EvaluateDatabase",
      systemName,
      operatorUserId: currentUser.id,
      resourceId: evaluateDatabase.id,
    });
    onSuccess && onSuccess(evaluateDatabase.id);
    setRequestingFalse();
    return evaluateDatabase;
  };

  return {
    createEvaluateDatabase,
    isRequesting,
  };
};
