import { v4 } from "uuid";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "@spesill/libs/firebase";
import { ChatRoom } from "@spesill/models/chatRoom";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class ChatRoomRepository extends DBRepositoryBase<
  ExcludeMethods<ChatRoom>
> {
  private PATH = "chatRooms";

  add = async (
    chatRoom: Omit<ExcludeMethods<ChatRoom>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const docId = v4();
    const newPage = {
      ...chatRoom,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
      deletedAt: undefined,
    };

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newPage));

    return { id: docId };
  };

  findByUserId = async (userId: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("createUserId", "==", userId),
      orderBy("createdAt", "desc"),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    return docs.map((doc) => new ChatRoom(this.docToObject(doc)));
  };

  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const chatRoom = await getDoc(ref);

    if (!chatRoom.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      return new ChatRoom(this.docToObject(chatRoom));
    }
  };

  updateById = async (
    id: string,
    chatRoom: Omit<ExcludeMethods<ChatRoom>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const ref = doc(this.db, this.PATH, id);
    const documentData = await getDoc(ref);

    if (!documentData.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        ...this.objectToDoc(chatRoom),
        updatedAt: new Date(),
      });
      return { id };
    }
  };

  logicalDeleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const chatRoom = await getDoc(ref);

    if (!chatRoom.exists()) {
      throw new Error("学習データベースが見つかりませんでした");
    } else {
      await updateDoc(ref, { deletedAt: new Date() });
    }
  };
}
