import React, { FormEvent, MouseEvent, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  DropdownField,
  FormSubmitButton,
  NumberField,
  SingleValueType,
  TextArea,
  TextField,
} from "@spesill/components/atoms";
import { HintTooltip } from "@spesill/components/molecules";
import { TextTemplates } from "@spesill/components/organisms";

import {
  useArray,
  useCurrentUser,
  useInput,
  usePunchImages,
} from "@spesill/hooks";
import {
  RequestGeneratePunchImageInDetail,
  ResponseGeneratePunchImageInDetail,
} from "@spesill/models/api/generate_punch_image_in_detail";
import { PunchLayout } from "@spesill/models/punchLayout";
import { PunchLayoutRequestHistory } from "@spesill/models/punchLayoutRequestHistory";

import { GRID_CELL_SIZE_CM } from "../PunchKeys";
import { TemplateTexts } from "../utils/LayoutTemplateText";

type PropsType = {
  className?: string;
  onClose: () => void;
  onSubmit: (
    formData: RequestGeneratePunchImageInDetail,
  ) => Promise<ResponseGeneratePunchImageInDetail | undefined>;
};

type ItemType = {
  dropdownValueItem?: {
    value: string;
    label: string;
  };
  itemName?: string;
};

type ArrangementItemType = {
  dropdownValueItem?: {
    value: string;
    label: string;
  };
};

const defaultItem: ItemType = {
  itemName: "",
};

export const PunchLayoutDetailForm = ({
  onClose,
  onSubmit,
  className = "",
}: PropsType) => {
  const { currentUser } = useCurrentUser();

  const [
    { value: baseInfo, onChange: onChangeBaseInfo, setValue: setBaseInfo },
  ] = useInput("");
  const [{ value: featureInfo, onChange: onChangeFeatureInfo }] = useInput("");

  const { items, pushItem, updateIndexItem } = useArray<ItemType>([
    defaultItem,
  ]);

  const {
    items: horizontalArrangementItems,
    pushItem: pushHorizontalArrangementItem,
    updateIndexItem: updateHorizontalArrangementItem,
  } = useArray<ArrangementItemType>([]);

  const {
    items: verticalArrangementItems,
    pushItem: pushVerticalArrangementItem,
    updateIndexItem: updateVerticalArrangementItem,
  } = useArray<ArrangementItemType>([]);

  const [layoutSize, onChangeLayoutSize] = useState({ width: 0, height: 0 });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!currentUser) return;

    if (baseInfo === "") {
      toast.error("基本情報を入力してください");
      return;
    }

    if (!layoutSize.width || !layoutSize.height) {
      toast.error("スペース広さを入力してください");
      return;
    }

    if (!items.length) {
      toast.error("アイテム構成情報を入力してください");
      return;
    }

    // 1マスの基準値(cm)からセル数を計算
    const cellWidth = (100 / GRID_CELL_SIZE_CM) * layoutSize.width;
    const cellHeight = (100 / GRID_CELL_SIZE_CM) * layoutSize.height;

    const res = await onSubmit({
      base_info: baseInfo,
      feature_info: featureInfo,
      item_configurations: items.map((item) => ({
        punch_image_id: item.dropdownValueItem?.value || "",
        name: item.itemName || "",
      })),
      // horizontalArrangementItemsからstring[]に変換する
      horizontal_items: horizontalArrangementItems.map((item) => {
        return item.dropdownValueItem?.value || "";
      }),
      vertical_items: verticalArrangementItems.map((item) => {
        return item.dropdownValueItem?.value || "";
      }),
      width: cellWidth,
      height: cellHeight,
      tenant_id: currentUser.tenantId,
    });
    if (!res) return;

    const request = await PunchLayoutRequestHistory.create({
      tenantId: currentUser.tenantId,
      userId: currentUser.id,
      baseInfo: baseInfo,
      featureInfo: featureInfo,
      itemConfigurations: items.map((item) => ({
        punchImageId: item.dropdownValueItem?.value || "",
        punchImageName: item.itemName || "",
      })),
      horizontalItems: horizontalArrangementItems.map((item) => ({
        punchImageId: item.dropdownValueItem?.value || "",
      })),
      verticalItems: verticalArrangementItems.map((item) => ({
        punchImageId: item.dropdownValueItem?.value || "",
      })),
      width: cellWidth,
      height: cellHeight,
    });
    PunchLayout.create({
      ...res,
      punchItems: res.punch_items,
      tenantId: currentUser.tenantId,
      punchLayoutRequestHistoryId: request.id,
    });
  };

  const { punchImages } = usePunchImages();

  const handleAddItem = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    pushItem({ itemName: "" });
  };

  const handleChangeHorizontalItemName = (
    newValue: SingleValueType,
    index: number,
  ) => {
    if (!newValue) return;

    const newItem = {
      ...horizontalArrangementItems[index],
      dropdownValueItem: {
        value: newValue?.value,
        label: newValue?.label,
      },
    };

    updateHorizontalArrangementItem(newItem, index);
  };

  const handleChangeVerticalItemName = (
    newValue: SingleValueType,
    index: number,
  ) => {
    if (!newValue) return;

    const newItem = {
      ...verticalArrangementItems[index],
      dropdownValueItem: {
        value: newValue?.value,
        label: newValue?.label,
      },
    };

    updateVerticalArrangementItem(newItem, index);
  };

  const handleAddHorizonTalItem = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    pushHorizontalArrangementItem({});
  };

  const handleAddVerticalItem = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    pushVerticalArrangementItem({});
  };

  const handleChangeItemName = (
    e: FormEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newItem = {
      ...items[index],
      itemName: e.currentTarget.value,
    };
    updateIndexItem(newItem, index);
  };

  const handleDropdownChange = (newValue: SingleValueType, index: number) => {
    if (!newValue) return;

    const newItem = {
      ...items[index],
      dropdownValueItem: {
        value: newValue?.value,
        label: newValue?.label,
      },
    };

    updateIndexItem(newItem, index);
  };

  const dropdownSelectableValueItem = punchImages?.map((punchImage) => ({
    value: punchImage.id,
    label: punchImage.name,
  }));

  const dropdownSelectableValueItemInHorizontalAndVertical = items.map(
    (item) => ({
      value: item.dropdownValueItem?.value || "",
      label: item.dropdownValueItem?.label || "",
    }),
  );

  return (
    <form
      className={`flex flex-col gap-y-5 ${className}`}
      onSubmit={handleSubmit}
    >
      <div>
        <h3 className="flex items-center bg-blueGray-0 text-body-lg-bold py-1 px-2">
          基本情報
          <HintTooltip
            text="どのような図なのか基本となる情報"
            direction="right"
            className="ml-2"
          />
        </h3>
        <div className="mt-3">
          <TextArea
            name="baseInfo"
            value={baseInfo}
            placeholder={
              "業界名、工程名、製品名を入力\n例: 自動車部品製造、組立生産ライン、エンジン部品"
            }
            onChange={onChangeBaseInfo}
            rows={6}
          />
        </div>
        <TextTemplates
          texts={TemplateTexts}
          onSelect={(text: string) => setBaseInfo(text)}
          className="mt-3"
        />
      </div>
      <div>
        <h3 className="flex items-center bg-blueGray-0 text-body-lg-bold py-1 px-2">
          アイテム構成情報
          <HintTooltip
            text="図に使用するアイテムの選択"
            direction="right"
            className="ml-2"
          />
        </h3>
        <span className="text-body-base text-blueGray-500 mt-1">
          選択されたアイテムに対し、自由に命名することができます
        </span>
        <div className="mt-3">
          <div className="space-y-3">
            {items.map((item, index) => (
              <div key={index} className="flex items-center gap-x-3">
                <DropdownField
                  value={item.dropdownValueItem}
                  options={dropdownSelectableValueItem || []}
                  onChange={(newValue) => handleDropdownChange(newValue, index)}
                  required
                  name={`punchImage-${index}`}
                  parentClassName="shrink-0"
                  className="w-52"
                />
                <TextField
                  name={`itemName-${index}`}
                  value={item.itemName}
                  placeholder={"自由に命名できます"}
                  onChange={(e) => handleChangeItemName(e, index)}
                  required
                  type={"text"}
                />
              </div>
            ))}
          </div>
          <Button
            text={"アイテムを追加"}
            color={"primary"}
            variant={"contained"}
            className="mt-3"
            onClick={handleAddItem}
            icon={{
              icon: "aiOutlinePlus",
              size: "1.5rem",
              color: "text-white",
            }}
          />
        </div>
      </div>
      <div>
        <h3 className="bg-blueGray-0 text-body-lg-bold py-1 px-2">
          スペース広さ
        </h3>
        <div className="mt-3 flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-3 w-full">
            <NumberField
              name="width"
              value={layoutSize.width}
              placeholder={"幅"}
              max={20}
              min={1}
              onChange={(e) =>
                onChangeLayoutSize({
                  ...layoutSize,
                  width: parseInt(e.target.value),
                })
              }
            />
            <span>m</span>
          </div>
          <span className="w-min">×</span>
          <div className="flex items-center gap-x-3 w-full">
            <NumberField
              name="height"
              value={layoutSize.height}
              placeholder={"高さ"}
              max={20}
              min={1}
              onChange={(e) =>
                onChangeLayoutSize({
                  ...layoutSize,
                  height: parseInt(e.target.value),
                })
              }
            />
            <span>m</span>
          </div>
        </div>
      </div>
      <div>
        <h3 className="flex items-center bg-blueGray-0 text-body-lg-bold py-1 px-2">
          特徴情報
          <span className="text-blueGray-500 text-body-lg ml-2 font-normal">
            (任意)
          </span>
          <HintTooltip
            text="基本情報の補足的な情報"
            direction="right"
            className="ml-2"
          />
        </h3>
        <div className="mt-3">
          <TextArea
            name="featureInfo"
            value={featureInfo}
            placeholder={
              "例： ロボットは供給用コンベアと払出用コンベアの間に設置します"
            }
            onChange={onChangeFeatureInfo}
            rows={6}
          />
        </div>
      </div>
      <div>
        <h3 className="flex items-center bg-blueGray-0 text-body-lg-bold py-1 px-2">
          配置・設置向き情報
          <span className="text-blueGray-500 text-body-lg ml-2 font-normal">
            (任意)
          </span>
          <HintTooltip
            text="アイテムを順番に横もしくは縦に並べる"
            direction="right"
            className="ml-2"
          />
        </h3>
        <span className="text-body-base text-blueGray-500 mt-1">
          上記の「アイテム構成情報」で設定したアイテムの位置関係を指定できます
        </span>
        <div className="mt-3 gap-y-3">
          <div className="flex items-center gap-x-3">
            <div className="text-body-lg text-blueGray-900">→ 横並び</div>
            {horizontalArrangementItems.map((item, index) => (
              <div key={index} className="flex items-center gap-x-3">
                <DropdownField
                  value={item.dropdownValueItem}
                  options={
                    dropdownSelectableValueItemInHorizontalAndVertical || []
                  }
                  onChange={(newValue) =>
                    handleChangeHorizontalItemName(newValue, index)
                  }
                  required
                  name={`punchImage-${index}`}
                  parentClassName="shrink-0"
                  className="w-52"
                />
              </div>
            ))}
            <Button
              text={"アイテムを追加"}
              color={"primary"}
              variant={"contained"}
              onClick={handleAddHorizonTalItem}
              icon={{
                icon: "aiOutlinePlus",
                size: "1.5rem",
                color: "text-white",
              }}
            />
          </div>
          <div className="flex items-center gap-x-3 mt-3">
            <div className="text-body-lg text-blueGray-900">↓ 縦並び</div>
            {verticalArrangementItems.map((item, index) => (
              <div key={index} className="flex items-center gap-x-3">
                <DropdownField
                  value={item.dropdownValueItem}
                  options={
                    dropdownSelectableValueItemInHorizontalAndVertical || []
                  }
                  onChange={(newValue) =>
                    handleChangeVerticalItemName(newValue, index)
                  }
                  required
                  name={`punchImage-${index}`}
                  parentClassName="shrink-0"
                  className="w-52"
                />
              </div>
            ))}
            <Button
              text={"アイテムを追加"}
              color={"primary"}
              variant={"contained"}
              onClick={handleAddVerticalItem}
              icon={{
                icon: "aiOutlinePlus",
                size: "1.5rem",
                color: "text-white",
              }}
            />
          </div>
        </div>
      </div>
      <div className="space-x-4 text-right mt-4 border-t border-blueGray-50 pt-4">
        <Button
          text={"キャンセル"}
          color={"gray"}
          variant={"contained"}
          onClick={onClose}
        />
        <FormSubmitButton
          text="作成"
          color="primary"
          variant="contained"
          className="text-sm"
        />
      </div>
    </form>
  );
};
