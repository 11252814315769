import { useCallback, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { LearningDatabase } from "@spesill/models";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";

const learningDatabaseRepository = new LearningDatabaseRepository();

export const useLearningDatabaseTypeDefault = (id: string) => {
  const { currentUser } = useCurrentUser();

  const [learningDatabase, setLearningDatabase] = useState<LearningDatabase>();

  const fetchLearningDatabaseByTypeDefault = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const learningDatabase = await learningDatabaseRepository.findByIdAndType(
      id,
      "DEFAULT",
    );
    if (learningDatabase.deletedAt) {
      setLearningDatabase(undefined);
      return;
    }
    setLearningDatabase(learningDatabase);
    return learningDatabase;
  }, [currentUser?.tenantId, id]);

  return {
    learningDatabase,
    fetchLearningDatabaseByTypeDefault,
  };
};
