import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Document } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";
import { SharedDocumentRepository } from "@spesill/repository/db/sharedDocument.repository";

const sharedDocumentRepository = new SharedDocumentRepository();
const documentRepository = new DocumentRepository();

export const useFetchDocumentBySharedDocument = (
  id: string,
  autCheck: boolean,
) => {
  const [document, setDocument] = useState<Document>();

  const fetchDocument = useCallback(async () => {
    if (!id || !autCheck) return; // 認証がfalseの場合、ドキュメントの取得をスキップ
    const shared = await sharedDocumentRepository.findById(id);

    if (!shared) {
      toast.error("公開されたドキュメントが見つかりませんでした");
      return;
    }

    const doc = await documentRepository.findByTenantIdAndId(
      shared.tenantId,
      shared.documentId,
    );

    if (!doc) {
      toast.error("ドキュメントが見つかりませんでした");
      return;
    }

    setDocument(doc);
  }, [autCheck, id]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  return { fetchDocument, document };
};
