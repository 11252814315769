import React, { FC, MouseEvent } from "react";

import { TabItem } from "@spesill/components/atoms";

type PropsType = {
  items: {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    text: string;
  }[];
  currentTabText: string;
  className?: string;
  buttonClassName?: string;
};

export const TabSwitcherButtons: FC<PropsType> = ({
  currentTabText,
  items,
  className = "",
  buttonClassName = "",
}: PropsType) => {
  return (
    <div className={`flex items-center ${className}`}>
      {items.map((item) => (
        <button
          key={item.text}
          onClick={item.onClick}
          type="button"
          className={buttonClassName}
        >
          <TabItem
            text={item.text}
            selected={item.text === currentTabText}
            className={"px-4 font-bold"}
          />
        </button>
      ))}
    </div>
  );
};
