import React, { FC } from "react";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { IconType, NavLink } from "@spesill/components/atoms";

import { useSidebar } from "@spesill/hooks";

import { SupportSection } from "./SupportSection";
import { IconButton } from "../IconButton/IconButton";

type PropsType = {
  className?: string;
  isFreePlan?: boolean;
};

type SidebarContent = {
  logoLink: string;
  navLinks: {
    href: string;
    icon: IconType;
    text: string;
  }[];
};

export const Sidebar: FC<PropsType> = ({ className = "", isFreePlan }) => {
  const { pathname } = useRouter();
  const isDocumentPage = pathname === "/" || pathname.startsWith("/documents");
  const isPageActive = (href: string) =>
    href === "/" ? isDocumentPage : pathname.startsWith(href);

  const { isCollapsed, toggleCollapse } = useSidebar();

  const helpLink =
    "https://firstam.notion.site/b7f5e82e35484b6d888863fd05925635?v=faf8a182e750454081f02e3f70473e31";

  const DefaultSidebarContent: SidebarContent = {
    logoLink: "/",
    navLinks: [
      { href: "/chat", icon: "hiOutlineChatAlt", text: "AIチャット" },
      { href: "/", icon: "aiOutlineFileText", text: "文書データ" },
      { href: "/learnings", icon: "mdOutlineSchool", text: "学習データベース" },
      { href: "/trash", icon: "bsTrash", text: "ゴミ箱" },
      { href: helpLink, icon: "bsQuestionCircle", text: "使い方" },
    ],
  };

  const freePlanSidebarContent: SidebarContent = {
    logoLink: "/",
    navLinks: [
      { href: "/", icon: "aiOutlineFileText", text: "文書データ" },
      { href: helpLink, icon: "bsQuestionCircle", text: "使い方" },
    ],
  };

  const sidebarContent = isFreePlan
    ? freePlanSidebarContent
    : DefaultSidebarContent;

  return (
    <aside
      className={`bg-blueGray-900 h-screen max-h-screen flex flex-col ${
        isCollapsed ? "w-[4rem]" : "w-[15rem]"
      } transition-all duration-300 ${className}`}
    >
      <div className=" flex items-end justify-end">
        <IconButton
          className="rounded-full h-7 w-7 flex items-center justify-center"
          icon={{
            icon: isCollapsed
              ? "tbLayoutSidebarLeftExpand"
              : "tbLayoutSidebarLeftCollapse",
            size: "1.2rem",
            color: "text-planning-500",
          }}
          onClick={toggleCollapse}
          color={"planning"}
          tooltip={{
            direction: "right",
            text: isCollapsed ? "メニューを拡大" : "メニューを縮小",
            className: "text-xs",
          }}
        />
      </div>
      <div className={"flex justify-center items-center py-4 px-2 "}>
        <Link href={sidebarContent.logoLink}>
          {isCollapsed ? (
            <Image
              src="/logo-circle.png"
              alt="SPESILL LOGO"
              width={"50"}
              height={"50"}
            />
          ) : (
            <Image
              src="/logo-square.png"
              alt="SPESILL LOGO"
              width={"89"}
              height={"84"}
            />
          )}
        </Link>
      </div>
      <ul
        className={`mt-8 flex flex-col flex-1 ${isCollapsed ? "items-center" : "ml-4"}`}
      >
        {sidebarContent.navLinks.map((link) => (
          <NavLink
            key={link.href}
            href={link.href}
            icon={link.icon}
            isActive={isPageActive(link.href)}
            text={link.text}
            isCollapsed={isCollapsed}
          />
        ))}
      </ul>
      {!isCollapsed && <SupportSection />}
    </aside>
  );
};
