import ip from "ip";

// IPv4 CIDR パターン
export const ipv4Pattern =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[12][0-9]|3[0-2]))?$/;

// IPv6 CIDR パターン (省略形式もサポート)
export const ipv6Pattern =
  /^((?:[0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,7}:|(?:[0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,5}(?::[0-9a-fA-F]{1,4}){1,2}|(?:[0-9a-fA-F]{1,4}:){1,4}(?::[0-9a-fA-F]{1,4}){1,3}|(?:[0-9a-fA-F]{1,4}:){1,3}(?::[0-9a-fA-F]{1,4}){1,4}|(?:[0-9a-fA-F]{1,4}:){1,2}(?::[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:(?::[0-9a-fA-F]{1,4}){1,6}|:(?::[0-9a-fA-F]{1,4}){1,7}|::)(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/;

export const validateCIDR = (cidr: string) => {
  return ipv4Pattern.test(cidr) || ipv6Pattern.test(cidr);
};

export const getClientIp = (xForwardedFor?: string | string[]) => {
  let ip;

  try {
    if (typeof xForwardedFor === "string") {
      ip = xForwardedFor?.split(",")[0]?.trim();
    } else if (Array.isArray(xForwardedFor)) {
      ip = xForwardedFor[0]?.trim();
    }
  } catch (error) {
    console.error("Error getting client IP", error);
  }
  return ip;
};

export const isIpAllowed = (userIp: string, allowedIps: string[]): boolean => {
  return allowedIps.some((allowedIp) => {
    if (ip.isV4Format(allowedIp) || ip.isV6Format(allowedIp)) {
      return ip.isEqual(userIp, allowedIp);
    } else {
      try {
        const subnet = ip.cidrSubnet(allowedIp);
        return subnet.contains(userIp);
      } catch (error) {
        console.error(`Invalid CIDR format: ${allowedIp}`, error);
        return false;
      }
    }
  });
};
