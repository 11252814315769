import React, { FormEvent, useEffect } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import {
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import { useBoolean, useCurrentUser, useInput } from "@spesill/hooks";
import { UserRepository } from "@spesill/repository/db/user.repository";

type PropsType = {
  onClose: () => void;
};
export const ChangeProfileModal = ({ onClose }: PropsType) => {
  const { currentUser, refetchCurrentUser } = useCurrentUser();
  const [{ value: firstName, onChange: onChangeFirstName }, setFirstName] =
    useInput(currentUser?.firstName || "");
  const [{ value: lastName, onChange: onChangeLastName }, setLastName] =
    useInput(currentUser?.lastName || "");
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);

  const handleUpdateProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!currentUser) return;
    setRequestingTrue();
    const userRepository = new UserRepository();
    await userRepository
      .update(currentUser.id, {
        firstName,
        lastName,
        updatedAt: new Date(),
      })
      .then(() => {
        refetchCurrentUser();
        toast.success("プロフィールを更新しました");
      })
      .catch((e) => {
        toast.error(
          e instanceof Error ? e.message : "プロフィールの更新に失敗しました",
        );
      })
      .finally(() => {
        onClose();
        setRequestingFalse();
      });
  };

  useEffect(() => {
    if (!currentUser) return;
    setFirstName(currentUser?.firstName || "");
    setLastName(currentUser?.lastName || "");
  }, [currentUser, setFirstName, setLastName]);

  return (
    <ModalWrapper onClose={onClose} headerTitle="プロフィール変更">
      <form
        onSubmit={handleUpdateProfile}
        className="mt-6 space-y-6 mx-auto w-full"
      >
        <LabelWithTextField
          labelText="姓"
          type="text"
          name="lastName"
          required
          autoComplete="lastName"
          value={lastName}
          onChange={onChangeLastName}
        />
        <LabelWithTextField
          labelText="名"
          type="text"
          name="firstName"
          required
          autoComplete="firstName"
          value={firstName}
          onChange={onChangeFirstName}
        />
        <div className="space-x-4 text-right">
          <Button
            text={"キャンセル"}
            color={"gray"}
            variant={"contained"}
            onClick={onClose}
          />
          <FormSubmitButton
            text="変更"
            color="primary"
            variant="contained"
            disabled={isRequesting}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};
