import React, { FC, MouseEvent } from "react";

type PropsType = {
  text: string;
  outline?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const TextButton: FC<PropsType> = ({
  text,
  className = "",
  disabled = false,
  onClick,
}: PropsType) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={` ${
        disabled ? "cursor-not-allowed opacity-80" : "cursor-pointer"
      } text-primary-400 text-center border-b border-primary-400 ${className}`}
    >
      {text}
    </button>
  );
};
