import React from "react";

import { useRouter } from "next/router";

import {
  ChatList,
  ChatDetail,
  MessageBox,
} from "@spesill/components/organisms";

export const ChatPage = () => {
  const { query } = useRouter();
  const chatId = query.id as string;

  return (
    <div className="w-full flex flex-row h-full">
      <ChatList />

      {chatId ? (
        <ChatDetail chatId={chatId} />
      ) : (
        <div className="h-full flex flex-col p-4 mb-11  max-h-screen overflow-auto">
          <ul className="w-full pb-20">
            <MessageBox
              isMe={false}
              isInitial={true}
              message="こんにちは。このチャットではChat-GPTもしくはデータベースに蓄積された文書データをもとに、AIがあなたの課題や知識の抽出をサポートします。"
            />
            <>
              <MessageBox
                isMe={false}
                isInitial={true}
                message="左側のメニューの『新しいチャット』ボタンからチャットを開始してください。"
              />
            </>
          </ul>
        </div>
      )}
    </div>
  );
};
