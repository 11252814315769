import React, { FC } from "react";

import { Icon } from "@spesill/components/atoms";

type PropsType = {
  currentIndex: number;
  totalCount: number;
  onPageChange: (index: 1 | -1) => void;
};

export const DocumentAnswerSwitcher: FC<PropsType> = ({
  currentIndex,
  totalCount,
  onPageChange,
}) => (
  <>
    <button
      type="button"
      className="flex items-center justify-center w-8 h-8"
      onClick={() => onPageChange(-1)}
    >
      <Icon icon="aiOutlineLeft" size="1.2rem" color="text-blueGray-500" />
    </button>
    <span className="text-body-lg-bold">
      {currentIndex + 1}/{totalCount}
    </span>
    <button
      type="button"
      className="flex items-center justify-center w-8 h-8"
      onClick={() => onPageChange(1)}
    >
      <Icon icon="aiOutlineRight" size="1.2rem" color="text-blueGray-500" />
    </button>
  </>
);
