import React, { ComponentProps, FC } from "react";

import Link from "next/link";

import { Icon } from "@spesill/components/atoms";

import { ButtonColorType, ButtonVariantType, ButtonColor } from "./constants";

type PropsType = {
  href: string;
  text: string;
  outline?: boolean;
  className?: string;
  color: ButtonColorType;
  variant: ButtonVariantType;
  icon?: ComponentProps<typeof Icon>;
};

export const LinkButton: FC<PropsType> = ({
  href,
  text,
  className = "",
  color,
  variant,
  icon,
}: PropsType) => {
  return (
    <Link
      href={href}
      className={`${
        ButtonColor[variant][color]
      } px-4 py-2.5 rounded ${className} ${
        icon && "flex items-center space-x-2"
      }`}
    >
      {icon && <Icon {...icon} />}
      <span>{text}</span>
    </Link>
  );
};
