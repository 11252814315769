import { useCallback, useMemo } from "react";

import { TenantRepository } from "@spesill/repository/db/tenant.repository";

import { useFetchTenant } from "../firestore/tenants/useFetchTenant";

const tenantRepository = new TenantRepository();

export const useEnableIpRestriction = () => {
  const { tenant, FetchTenant } = useFetchTenant();

  const isEnableIpRestriction = useMemo(
    () => tenant?.isEnableIpRestriction,
    [tenant],
  );

  const toggleEnableIpRestriction = useCallback(async () => {
    if (!tenant) {
      return;
    }
    await tenantRepository.update(tenant.id, {
      isEnableIpRestriction: !tenant?.isEnableIpRestriction,
    });
    FetchTenant();
  }, [FetchTenant, tenant]);

  return { toggleEnableIpRestriction, isEnableIpRestriction };
};
