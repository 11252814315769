import axios from "axios";
import htmlToDocx from "html-to-docx";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { useRouter } from "next/router";

import { SingleValueType, MultiValueType } from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import {
  useBoolean,
  useCurrentUser,
  useFetchTenantDocumentSettings,
  useFile,
  useIncrementAiUsage,
  useInput,
  useLanguageDropdownField,
  useStorageUploadFile,
  useTenantAiCallRestriction,
  useTenantLimitManager,
  useWordDocumentsConvertToHtml,
} from "@spesill/hooks";
import { apiClient } from "@spesill/libs/apiClient";
import { LearningDatabase, LearningDocument } from "@spesill/models";
import {
  Document,
  DocumentKind,
  DocumentLanguageType,
} from "@spesill/models/document";
import { getDocumentType, getFilename } from "@spesill/utils/fileHelper";

import { FormatGeneratorFromAi } from "./FormatGenerator/FormatGeneratorFromAi";
import { FormatPreview } from "./FormatPreview";
import { SelectDB } from "./SelectDB";
import { SelectFile } from "./SelectFile";
import { CreateLearningDatabaseModal } from "../CreateLearningDatabaseModal";

export enum DocumentFormatStep {
  FormatUpload = "FormatUpload",
  SelectDB = "SelectDB",
  SelectFile = "SelectFile",
  Preview = "Preview",
  CreateNew = "CreateNew",
}

type PropsType = {
  onClose: () => void;
  dropdownValueDocumentType: SingleValueType;
  dropdownSelectableValueDocumentType: MultiValueType;
  onChangeDropdownDocumentType: (newValue: SingleValueType) => void;
};

export const GenerateDocumentFormatModal = ({
  onClose,
  dropdownValueDocumentType,
  dropdownSelectableValueDocumentType,
  onChangeDropdownDocumentType,
}: PropsType) => {
  const { currentUser } = useCurrentUser();
  const { canCallAi } = useTenantAiCallRestriction();
  const { incrementAiCallCount } = useIncrementAiUsage();
  const [currentStep, setCurrentStep] = useState<DocumentFormatStep>(
    DocumentFormatStep.FormatUpload,
  );
  const { html, setHtml } = useWordDocumentsConvertToHtml();
  const { files, handleDropFile } = useFile();
  const selectedFile = files[0];
  const [{ value: systemName, onChange: onChangeSystemName }] = useInput("");
  const { isChecked: isRequesting, setTrue, setFalse } = useBoolean(false);
  const { isUploadable } = useTenantLimitManager();
  const {
    isChecked: isUploading,
    setTrue: setUploadTrue,
    setFalse: setUploadFalse,
  } = useBoolean(false);
  const [selectedDB, setSelectedDB] = useState<LearningDatabase[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<
    LearningDocument[]
  >([]);
  const [isCreateDB, setIdCreateDB] = useState(false);

  const { uploadFile } = useStorageUploadFile();

  const { tenantDocumentSettings } = useFetchTenantDocumentSettings();
  const [{ value: freeInputKind, onChange: onChangeFreeInputKind }] =
    useInput("");

  const {
    dropdownValue: dropdownValueLanguage,
    dropdownSelectableValue: dropdownSelectableValueLanguage,
    onChange: onChangeDropdownLanguage,
  } = useLanguageDropdownField();

  const router = useRouter();

  const handleUploadFile = async (currentHtml?: string) => {
    if (!currentUser) return;
    if (!systemName) {
      return toast.error("ファイル名を入力してください。");
    }
    if (!dropdownValueLanguage) {
      return toast.error("言語を入力してください。");
    }
    if (!dropdownValueDocumentType) {
      return toast.error("文書の種類を入力してください。");
    }
    if (dropdownValueDocumentType.value === "others" && !freeInputKind) {
      return toast.error("文書の種類を入力してください。");
    }
    const documentType = "word";
    if (!documentType) return toast.error("ファイルの種類が不正です。");
    const fileName = getFilename(
      systemName,
      documentType === "word" ? "docx" : "xlsx",
    );
    if (!fileName) {
      return toast.error("ファイル名が不正です。");
    }

    setUploadTrue();
    const uploadStatus = toast.loading(
      "アップロード中です。しばらくお待ちください",
    );

    try {
      const uploadedFile: Blob = await htmlToDocx(currentHtml);
      const canAddFile = await isUploadable(uploadedFile.size);
      if (!canAddFile) {
        return toast.error(
          "データ容量がプランの上限に達しました。プランをアップグレードしてください",
        );
      }
      const learningDatabaseId = selectedDB[0]?.id;
      const documentProps = {
        file: uploadedFile,
        fileName: fileName,
        systemName,
        kind: dropdownValueDocumentType.value,
        freeInputKind: freeInputKind,
        tenantName: tenantDocumentSettings?.tenantName || "",
        tenantBusinessDetails: tenantDocumentSettings?.businessDetails || "",
        userDepartment: currentUser.department || "",
        userJobDescription: currentUser.jobDescription || "",
        language: dropdownValueLanguage?.value as DocumentLanguageType,
        learningDatabaseId: learningDatabaseId,
        referenceDocumentIds:
          selectedDocuments.length > 0
            ? selectedDocuments.map((document) => document.id)
            : undefined,
        onSuccess: async (document: Document) => {
          await axios.post("/api/mail/notification", {
            notificationType: "create-Document",
            systemName,
            operatorUserId: currentUser.id,
            resourceId: document.id,
          });
          toast.update(uploadStatus, {
            render: "アップロードが完了しました。",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        },
      };

      if (!currentHtml && documentType === "word") {
        return toast.error("ファイルが不正です");
      }

      const uploadProps =
        documentType === "word"
          ? {
              ...documentProps,
              documentType: "word" as const,
              html: currentHtml || "",
            }
          : {
              ...documentProps,
              documentType: "excel" as const,
              html: undefined,
            };

      const document = await uploadFile(uploadProps);

      if (document) {
        router.push(`/documents/${document.id}`);
        onClose();
      } else {
        toast.dismiss(uploadStatus);
        toast.error("アップロードに失敗しました。");
      }
    } catch (e) {
      toast.dismiss(uploadStatus);
      toast.error(
        e instanceof Error ? e.message : "アップロードに失敗しました。",
      );
    } finally {
      setUploadFalse();
    }
  };

  const createHeaders = (headersData: [number, string][]): string => {
    return headersData
      .map((headerData) => {
        const [level, text] = headerData;
        return `<h${level}>${text}</h${level}>`;
      })
      .join("");
  };

  const createHtmlWithHeaders = (headersData: [number, string][]): string => {
    const headersHtml = createHeaders(headersData);
    const html = `<html>\n<head>\n</head>\n<body>\n${headersHtml}\n</body>\n</html>`;
    return html;
  };

  const handleGenerateFormat = async () => {
    if (!currentUser) return;
    if (!systemName) return toast.error("ファイル名を入力してください。");
    if (!dropdownValueDocumentType)
      return toast.error("文書の種類を入力してください。");
    if (dropdownValueDocumentType.value === "others" && !freeInputKind) {
      return toast.error("文書の種類を入力してください。");
    }
    if (!canCallAi) {
      toast.error(
        "AIの利用回数がプランの上限に達しました。プランをアップグレードしてください",
      );
      return;
    }
    setTrue();

    try {
      const res = await apiClient().generate_spec_headings.$post({
        body: {
          tenant_id: currentUser.tenantId,
          file_name: systemName,
          kind: dropdownValueDocumentType.value as DocumentKind,
          free_input: freeInputKind,
          language: dropdownValueLanguage?.value
            ? (dropdownValueLanguage?.value as DocumentLanguageType)
            : "DEFAULT",
          firestore_document_ids:
            selectedDocuments.length > 0
              ? selectedDocuments.map((document) => document.id)
              : undefined,
        },
      });

      incrementAiCallCount("generateSpecHeadings");
      const headers = createHtmlWithHeaders(res.headings);
      setHtml(headers);
      setCurrentStep(DocumentFormatStep.CreateNew);
    } catch (error) {
      toast.error(
        "フォーマット生成に失敗しました。再度お試しいただくか、サポートにお問い合わせください。",
      );
    } finally {
      setFalse();
    }
  };

  if (isCreateDB) {
    return (
      <CreateLearningDatabaseModal
        onClose={() => setIdCreateDB(false)}
        onSuccess={() => setIdCreateDB(false)}
      />
    );
  }

  return (
    <ModalWrapper onClose={onClose} className="min-w-[80vw]" fullContentWidth>
      {(() => {
        switch (currentStep) {
          case DocumentFormatStep.FormatUpload:
            return (
              <FormatGeneratorFromAi
                handleDropFile={handleDropFile}
                file={selectedFile}
                fileUrl={
                  selectedFile ? URL.createObjectURL(selectedFile) : undefined
                }
                onClose={onClose}
                onSubmit={() => setCurrentStep(DocumentFormatStep.SelectDB)}
                systemName={systemName}
                onChangeSystemName={onChangeSystemName}
                freeInputKind={freeInputKind}
                onChangeFreeInputKind={onChangeFreeInputKind}
                dropdownValueDocumentType={dropdownValueDocumentType}
                dropdownSelectableValueDocumentType={
                  dropdownSelectableValueDocumentType
                }
                onChangeDropdownDocumentType={onChangeDropdownDocumentType}
                dropdownValueLanguage={dropdownValueLanguage}
                dropdownSelectableValueLanguage={
                  dropdownSelectableValueLanguage
                }
                onChangeDropdownLanguage={onChangeDropdownLanguage}
              />
            );
          case DocumentFormatStep.SelectDB:
            return (
              <SelectDB
                selectedDB={selectedDB}
                onChangeDB={(db) => {
                  if (selectedDB.includes(db)) {
                    setSelectedDB(
                      selectedDB.filter((selected) => selected !== db),
                    );
                    return;
                  }
                  setSelectedDB([...selectedDB, db]);
                }}
                onClose={onClose}
                onBack={() => setCurrentStep(DocumentFormatStep.FormatUpload)}
                onSubmit={() => setCurrentStep(DocumentFormatStep.SelectFile)}
                onSkip={() => {
                  setSelectedDB([]);
                  handleGenerateFormat();
                }}
                onCreateDB={() => setIdCreateDB(true)}
                isLoading={isRequesting}
              />
            );
          case DocumentFormatStep.SelectFile:
            return (
              <SelectFile
                selectedDB={selectedDB}
                selectedDocuments={selectedDocuments}
                onChangeDocuments={(document) => {
                  if (selectedDocuments.includes(document)) {
                    setSelectedDocuments(
                      selectedDocuments.filter(
                        (selected) => selected !== document,
                      ),
                    );
                    return;
                  }
                  setSelectedDocuments([...selectedDocuments, document]);
                }}
                onClose={onClose}
                onBack={() => {
                  setSelectedDB([]);
                  setCurrentStep(DocumentFormatStep.SelectDB);
                }}
                onSubmit={handleGenerateFormat}
                isLoading={isRequesting}
              />
            );
          case DocumentFormatStep.CreateNew:
            return (
              <FormatPreview
                onBack={() =>
                  setCurrentStep(
                    selectedDB.length > 0
                      ? DocumentFormatStep.SelectFile
                      : DocumentFormatStep.SelectDB,
                  )
                }
                handleUploadFile={handleUploadFile}
                systemName={systemName}
                onChangeSystemName={onChangeSystemName}
                html={html}
                file={selectedFile}
                isUploading={isUploading}
                dropdownValueDocumentType={dropdownValueDocumentType}
                dropdownSelectableValueDocumentType={
                  dropdownSelectableValueDocumentType
                }
                dropdownValueLanguage={dropdownValueLanguage}
                dropdownSelectableValueLanguage={
                  dropdownSelectableValueLanguage
                }
                onChangeDropdownLanguage={onChangeDropdownLanguage}
                onChangeFreeInputKind={onChangeFreeInputKind}
                freeInputKind={freeInputKind}
                onChangeDropdownDocumentType={onChangeDropdownDocumentType}
                documentType={
                  selectedFile?.name
                    ? getDocumentType(selectedFile.name)
                    : "word"
                }
              />
            );
        }
      })()}
    </ModalWrapper>
  );
};
