import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  FormSubmitButton,
  Icon,
} from "@spesill/components/atoms";

import {
  useLearningDatabase,
  useCurrentUser,
  useLearningDocument,
} from "@spesill/hooks";
import { apiClient } from "@spesill/libs/apiClient";
import { LearningDatabase, LearningDocument } from "@spesill/models";

import Preview from "./Preview";

type Props = {
  documentId: string;
  isLoading?: boolean;
  selectedDocuments: LearningDocument[];
  onChangeDocuments: (learningDocument: LearningDocument) => void;
  setPreviewDocument: (arg: {
    dbName: string;
    document: LearningDocument;
  }) => void;
};

const DocumentItem = ({
  documentId,
  isLoading,
  selectedDocuments,
  onChangeDocuments,
  setPreviewDocument,
}: Props) => {
  const { learningDocument } = useLearningDocument(documentId);
  const { learningDatabase } = useLearningDatabase(
    learningDocument?.learningDatabaseId || "",
  );

  if (!learningDocument) {
    return null;
  }

  return (
    <div
      key={`file-${learningDocument.id}`}
      className={`flex items-center justify-between px-4 py-2.5 rounded-md ${!selectedDocuments.includes(learningDocument) ? "border border-blueGray-50" : "border-primary-800 border-2"}`}
    >
      <div className="flex flex-row items-center gap-x-4">
        <Checkbox
          name={"file1"}
          checked={selectedDocuments.includes(learningDocument)}
          onChange={() => onChangeDocuments(learningDocument)}
          size="md"
          disabled={isLoading}
        />
        {learningDocument.isExcel() ? (
          <Icon
            icon="riFileExcel2Fill"
            size="2rem"
            color="text-green-700"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
        ) : learningDocument.isPdf() ? (
          <Icon
            icon="tbPdf"
            size="2rem"
            color="text-status-danger"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
        ) : (
          <Icon
            icon="riFileWord2Fill"
            size="2rem"
            color="text-primary-400"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
        )}
        <div>
          <p className="text-sm font-bold text-blueGray-900 mb-1">
            {learningDatabase?.systemName}
          </p>
          <p className="text-xs text-blueGray-500">
            {learningDocument.systemName}
          </p>
        </div>
      </div>
      <button
        type="button"
        className="text-primary-800 text-sm"
        onClick={() =>
          setPreviewDocument({
            dbName: learningDatabase?.systemName || "",
            document: learningDocument,
          })
        }
      >
        プレビュー
      </button>
    </div>
  );
};

type PropsType = {
  selectedDB: LearningDatabase[];
  selectedDocuments: LearningDocument[];
  onChangeDocuments: (learningDocument: LearningDocument) => void;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
};

const SHOW_KEYWORDS_LIMIT = 9;

export const SelectFile = ({
  selectedDB,
  selectedDocuments,
  onChangeDocuments,
  onClose,
  onBack,
  onSubmit,
  isLoading,
}: PropsType) => {
  const { currentUser } = useCurrentUser();

  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [previewDocument, setPreviewDocument] = useState<{
    dbName: string;
    document: LearningDocument;
  }>();
  const [isShowMoreKeywords, setIsShowMoreKeywords] = useState<boolean>(false);
  const [isLoadingKeywords, setIsLoadingKeywords] = useState<boolean>(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);

  const onChangeKeyword = useCallback(
    async (v: string) => {
      const newKeywords = selectedKeywords.includes(v)
        ? selectedKeywords.filter((keyword) => keyword !== v)
        : [...selectedKeywords, v];
      setSelectedKeywords(newKeywords);
      setIsLoadingDocuments(true);
      try {
        const res = await apiClient().search_documents.$post({
          body: {
            tenant_id: currentUser?.tenantId || "",
            group_ids: selectedDB.map((db) => db.id),
            keywords: newKeywords,
          },
        });
        if (res.documents) {
          setDocumentIds(res.documents.map((doc) => doc.firestore_document_id));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoadingDocuments(false);
      }
    },
    [selectedKeywords, currentUser?.tenantId, selectedDB],
  );

  const getKeywords = useCallback(async () => {
    setIsLoadingKeywords(true);
    try {
      const res = await apiClient().get_keywords.$post({
        body: {
          tenant_id: currentUser?.tenantId || "",
          group_ids: selectedDB.map((db) => db.id),
        },
      });
      if (res.keywords.length > 0) {
        setKeywords(res.keywords);
        setIsShowMoreKeywords(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingKeywords(false);
    }
  }, [currentUser?.tenantId, selectedDB]);

  useEffect(() => {
    getKeywords();
  }, [getKeywords]);

  if (previewDocument) {
    return (
      <Preview
        dbName={previewDocument.dbName || ""}
        document={previewDocument.document}
        onBack={() => setPreviewDocument(undefined)}
      />
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <h4 className="text-h4 text-blueGray-900 px-3">文章を作成</h4>
      <div className="pt-8 px-3">
        <div className="flex flex-row justify-between items-center pb-6">
          <h5 className="text-lg font-bold text-blueGray-900">
            参考にしたいファイルを選択してください
          </h5>
        </div>
        <div className="mb-6">
          <div className="flex flex-row gap-x-1 items-center mb-2">
            <Icon
              icon="mdFilterListAlt"
              size="1rem"
              color="text-blueGray-500"
            />
            <span className="text-blueGray-500 text-sm">キーワード</span>
          </div>
          <div className="flex flex-row flex-wrap gap-2 items-center">
            {isLoadingKeywords ? (
              <div className="text-blueGray-400 text-sm">
                キーワードを取得中...
              </div>
            ) : keywords.length > 0 ? (
              keywords
                .slice(
                  0,
                  isShowMoreKeywords ? keywords.length : SHOW_KEYWORDS_LIMIT,
                )
                .map((keyword) => (
                  <button
                    key={keyword}
                    type="button"
                    className={`px-4 py-1 rounded-full border border-blueGray-200 text-sm ${selectedKeywords.includes(keyword) ? "bg-primary-800 border-primary-800 text-white" : ""}`}
                    onClick={() => onChangeKeyword(keyword)}
                    disabled={isLoading}
                  >
                    {keyword}
                  </button>
                ))
            ) : (
              <div>関連するキーワードはありません。</div>
            )}
            {!isShowMoreKeywords && keywords.length > SHOW_KEYWORDS_LIMIT && (
              <button
                type="button"
                className="text-sm text-primary-400"
                onClick={() => setIsShowMoreKeywords(true)}
              >
                関連するキーワードをさらに表示
              </button>
            )}
          </div>
        </div>
        <div className="pb-8 flex flex-col gap-y-2 max-h-96 overflow-y-auto">
          {isLoadingDocuments ? (
            <div className="text-sm text-blueGray-400">ファイルを取得中...</div>
          ) : selectedKeywords.length > 0 && documentIds.length === 0 ? (
            <div>関連するファイルがありません</div>
          ) : (
            documentIds.map((documentId) => (
              <DocumentItem
                key={`file-${documentId}`}
                documentId={documentId}
                isLoading={isLoading}
                selectedDocuments={selectedDocuments}
                onChangeDocuments={onChangeDocuments}
                setPreviewDocument={setPreviewDocument}
              />
            ))
          )}
        </div>
      </div>
      <div className="-translate-x-6 w-[calc(100%+3rem)] px-4 pt-4 border-t border-t-blueGray-50">
        <div className="flex justify-between items-center">
          {isLoading ? (
            <p className="text-blueGray-500 text-sm font-medium animate-pulse">
              生成中...
            </p>
          ) : (
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
          )}
          <div className="flex flex-row space-x-4">
            <Button
              text={"戻る"}
              color={"gray"}
              variant={"contained"}
              onClick={onBack}
              disabled={isLoading}
            />
            <FormSubmitButton
              text={"次へ"}
              color={"primary"}
              variant={"contained"}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
