import React, { ComponentProps, FC, MouseEvent } from "react";

import { Icon } from "@spesill/components/atoms";
import { FloatingMenu } from "@spesill/components/molecules";

export type MenuItemType = {
  label: string;
  icon?: ComponentProps<typeof Icon>;
  selected?: boolean;
  className?: string;
  onClick: () => void;
};

type PropsType = {
  className?: string;
  items: MenuItemType[];
  disabled?: boolean;
  onClose: () => void;
};

export const ListMenu: FC<PropsType> = ({
  className,
  items,
  disabled = false,
  onClose,
}) => (
  <FloatingMenu onClose={onClose} className={className}>
    {items.map((item) => {
      const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        item.onClick();
        onClose();
      };

      return (
        <li
          key={item.label}
          className={`cursor-pointer min-w-[6rem]${
            item.selected ? "bg-blueGray-0" : ""
          } ${item.className}`}
        >
          <button
            type="button"
            className={`flex py-1.5  px-2 items-center hover:bg-primary-50  w-full space-x-2 text-sm ${
              item.selected ? "text-primary-500" : ""
            } ${disabled ? "cursor-not-allowed" : ""}`}
            disabled={disabled}
            onClick={handleClick}
          >
            {item.icon && <Icon {...item.icon} />}
            <span className="truncate">{item.label}</span>
          </button>
        </li>
      );
    })}
  </FloatingMenu>
);
