import React from "react";

import { MenuButton } from "@spesill/components/molecules";
import { GenerateDocumentFormatModal } from "@spesill/components/organisms/Modal/DocumentFormatModal/GenerateDocumentFormatModal";

import { useBoolean, useDocumentTypeDropDownField } from "@spesill/hooks";

export const GenerateDocumentButton = () => {
  const {
    dropdownValue: dropdownValueDocumentType,
    dropdownSelectableValue: dropdownSelectableValueDocumentType,
    onChange: onChangeDropdownDocumentType,
  } = useDocumentTypeDropDownField();
  const {
    isChecked: isModalOpen,
    setTrue: setModalOpen,
    setFalse: setModalClose,
  } = useBoolean(false);

  const documentKindMenus = dropdownSelectableValueDocumentType.map((item) => ({
    label: item.label,
    onClick: () => {
      onChangeDropdownDocumentType(item);
      setModalOpen();
    },
  }));

  return (
    <>
      <MenuButton
        text={"新規作成"}
        color={"primary"}
        variant={"contained"}
        menuItems={documentKindMenus}
        icon={{
          icon: "aiOutlinePlus",
          size: "1rem",
          color: "text-white",
        }}
      />
      {isModalOpen && (
        <GenerateDocumentFormatModal
          onClose={setModalClose}
          dropdownValueDocumentType={dropdownValueDocumentType}
          dropdownSelectableValueDocumentType={
            dropdownSelectableValueDocumentType
          }
          onChangeDropdownDocumentType={onChangeDropdownDocumentType}
        />
      )}
    </>
  );
};
