import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

import { useCurrentUser } from "@spesill/hooks";
import { User } from "@spesill/models";
import { DocumentPermissionType } from "@spesill/models/document";
import {
  PermittedUser,
  UserPermissionType,
  UserWithPermission,
} from "@spesill/models/permittedUser";
import { DocumentRepository } from "@spesill/repository/db/document.repository";
import { PermittedUserRepository } from "@spesill/repository/db/PermittedUser.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

const userRepository = new UserRepository();
const documentRepository = new DocumentRepository();
const permittedUserRepository = new PermittedUserRepository();

export const useDocumentPermittedUsers = (documentId: string) => {
  const { currentUser } = useCurrentUser();
  const [users, setUsers] = useState<User[]>([]);
  const [permittedUsers, setPermittedUsers] = useState<UserWithPermission[]>(
    [],
  );

  const fetchUsersAndPermissions = useCallback(async () => {
    try {
      const fetchedUsers = await userRepository.findByTenantId(
        currentUser?.tenantId || "",
      );
      const documentPermissions =
        await permittedUserRepository.findByDocumentId(documentId);

      const usersPermitted = fetchedUsers.filter((user) =>
        documentPermissions.some((permission) => permission.id === user.id),
      );

      const usersWithPermission = usersPermitted.map((userPermitted) => {
        const permission = documentPermissions.find(
          (permission) => permission.id === userPermitted.id,
        )?.permission;
        return {
          ...userPermitted,
          permission: permission,
        } as UserWithPermission;
      });

      setPermittedUsers(usersWithPermission);
      setUsers(fetchedUsers);
    } catch (e) {
      toast.error("ユーザーまたは許可されたユーザーの取得に失敗しました");
    }
  }, [documentId, currentUser?.tenantId]);

  useEffect(() => {
    if (currentUser) fetchUsersAndPermissions();
  }, [fetchUsersAndPermissions, currentUser]);

  const updatePermittedUsers = useCallback(
    async (permittedUsers: UserWithPermission[]) => {
      // 既存の権限を取得し削除する必要のある権限を特定
      await PermittedUser.updatePermittedUsers(permittedUsers, documentId);
    },
    [documentId],
  );

  const updatePermissions = useCallback(
    async (
      permissionLevel: DocumentPermissionType,
      permittedUsers: UserWithPermission[],
    ) => {
      if (!currentUser?.tenantId || !documentId) return;

      try {
        await documentRepository.updatePermissionLevel(
          documentId,
          permissionLevel,
        );
        await updatePermittedUsers(permittedUsers);
      } catch (e) {
        toast.error("権限の変更に失敗しました");
      }
    },
    [currentUser?.tenantId, documentId, updatePermittedUsers],
  );

  const addPermittedUser = useCallback(
    (userId: string) => {
      const userToAdd = users.find((user) => user.id === userId);
      if (userToAdd) {
        const permittedUser = {
          ...userToAdd,
          permission: "viewer",
        } as UserWithPermission;
        setPermittedUsers((prevUsers) => [...prevUsers, permittedUser]);
      }
    },
    [users],
  );
  // 指定されたuserIdをpermittedUsersから削除する関数
  const removePermittedUser = (userId: string) => {
    setPermittedUsers((prevUsers) =>
      prevUsers.filter((user) => user.id !== userId),
    );
  };

  const ChangePermittedUsers = useCallback(
    (userId: string) => {
      const IsPermittedUser = permittedUsers
        .map((user) => user.id)
        .includes(userId);
      IsPermittedUser ? removePermittedUser(userId) : addPermittedUser(userId);
    },
    [addPermittedUser, permittedUsers],
  );

  const ChangePermissions = useCallback(
    (userId: string, permission: UserPermissionType) => {
      const newPermittedUsers = permittedUsers.map((user) => {
        if (user.id === userId) {
          // 既存のユーザーの属性をコピーし、必要な変更を加える
          const updatedUser = {
            ...user,
            permission: permission,
          } as UserWithPermission;
          return updatedUser;
        }
        return user;
      });
      setPermittedUsers(newPermittedUsers);
    },
    [permittedUsers],
  );

  return {
    permittedUsers,
    onChangePermittedUsers: ChangePermittedUsers,
    onChangePermissions: ChangePermissions,
    updatePermissions,
  };
};
