import GC from "@grapecity/spread-sheets";
import React, { ChangeEvent, useRef } from "react";

import dynamic from "next/dynamic";

import {
  Button,
  FormSubmitButton,
  MultiValueType,
  SingleValueType,
  TextField,
} from "@spesill/components/atoms";
import {
  LabelWithDropdownField,
  LabelWithTextField,
} from "@spesill/components/molecules";
import { ConvertedHTMLDetail } from "@spesill/components/organisms";

import { DocumentType } from "@spesill/models/document";

const DocumentExcelEditor = dynamic(
  () =>
    import(
      "@spesill/components/organisms/Document/DocumentEditor/DocumentExcelEditor/DocumentExcelEditor"
    ).then((mod) => mod.DocumentExcelEditor),
  {
    ssr: false,
  },
);

type PropsType = {
  onBack: () => void;
  handleUploadFile: (html: string) => void;
  systemName: string;
  onChangeSystemName: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  freeInputKind?: string;
  onChangeFreeInputKind?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  html?: string;
  file?: File;
  isUploading: boolean;
  dropdownValueDocumentType: SingleValueType;
  dropdownSelectableValueDocumentType: MultiValueType;
  onChangeDropdownDocumentType: (newValue: SingleValueType) => void;
  dropdownValueLanguage?: SingleValueType;
  dropdownSelectableValueLanguage: MultiValueType;
  onChangeDropdownLanguage: (newValue: SingleValueType) => void;
  documentType: DocumentType;
};

export const FormatPreview = ({
  onBack,
  handleUploadFile,
  systemName,
  onChangeSystemName,
  freeInputKind,
  onChangeFreeInputKind,
  dropdownValueLanguage,
  dropdownSelectableValueLanguage,
  onChangeDropdownLanguage,
  html,
  file,
  isUploading,
  dropdownValueDocumentType,
  dropdownSelectableValueDocumentType,
  onChangeDropdownDocumentType,
  documentType,
}: PropsType) => {
  const spreadSheetRef = useRef<GC.Spread.Sheets.Workbook | null>(null);

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleUploadFile(html || "");
  };

  return (
    <>
      <div className="flex">
        <form
          className="shrink flex flex-col justify-between w-1/3"
          onSubmit={handleSubmit}
        >
          <div className="mt-8 mx-6 flex-glow">
            <h4 className="text-h4 text-blueGray-900">文書を作成</h4>
            <p className="my-4 text-blueGray-300 text-sm">
              文書のファイル名を入力し、インポートしてください。
            </p>
            <div className="space-y-3 flex flex-col">
              <LabelWithTextField
                labelText="文書のタイトル"
                type="text"
                name="systemName"
                required
                autoComplete="systemName"
                placeholder="文書タイトル"
                value={systemName}
                disabled={isUploading}
                onChange={onChangeSystemName}
              />
              <LabelWithDropdownField
                labelText="文書種類"
                name="kind"
                value={dropdownValueDocumentType}
                options={dropdownSelectableValueDocumentType}
                onChange={onChangeDropdownDocumentType}
                required
              />
              {dropdownValueDocumentType?.value === "others" && (
                <TextField
                  name="freeInputKind"
                  required
                  placeholder="文書の種類を入力してください"
                  className="mt-4"
                  type={"text"}
                  value={freeInputKind}
                  onChange={onChangeFreeInputKind}
                />
              )}
              <LabelWithDropdownField
                labelText="言語"
                name="kind"
                value={dropdownValueLanguage}
                options={dropdownSelectableValueLanguage}
                onChange={(newValue, meta) => {
                  if (meta.action !== "create-option" && newValue) {
                    onChangeDropdownLanguage(newValue);
                  }
                }}
              />
            </div>
          </div>
          <div className="mt-4 mx-6 flex space-x-2">
            <Button
              text={"戻る"}
              color={"gray"}
              variant={"contained"}
              onClick={onBack}
            />
            <FormSubmitButton
              text={"インポート"}
              color={"primary"}
              variant={"contained"}
              disabled={isUploading}
            />
          </div>
        </form>
        {documentType === "word"
          ? html && (
              <div className="bg-blueGray-50 px-6 py-8 w-full">
                <ConvertedHTMLDetail html={html} systemName={systemName} />
              </div>
            )
          : file && (
              <div className="bg-blueGray-50 text-blueGray-300 text-sm w-2/3">
                <div className="w-full">
                  <DocumentExcelEditor
                    spreadRef={spreadSheetRef}
                    file={file}
                    isEditable={false}
                    isFreePlan
                  />
                </div>
              </div>
            )}
      </div>
    </>
  );
};
