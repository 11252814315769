import React, { FormEvent } from "react";

import Image from "next/image";

import { Button, Icon, Label } from "@spesill/components/atoms";
import { FormSubmitButton } from "@spesill/components/atoms";
import {
  IconButton,
  LabelWithNumberField,
  LabelWithTextArea,
  LabelWithTextField,
} from "@spesill/components/molecules";

import { convertReadableByte } from "@spesill/utils";

type PropsType = {
  onClose: () => void;
  modeBack: () => void;
  file?: File;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  itemName: string;
  onChangeItemName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  itemDescription: string;
  onChangeItemDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  itemHeight: number;
  onChangeItemHeight: (e: number) => void;
  arrangementType: "single" | "multi";
  onChangeArrangementType: (e: "single" | "multi") => void;
  isRequesting: boolean;
};
export const PunchIconForm = ({
  handleSubmit,
  file,
  itemName,
  onChangeItemName,
  itemDescription,
  onChangeItemDescription,
  itemHeight,
  onChangeItemHeight,
  arrangementType,
  onChangeArrangementType,
  isRequesting,
  modeBack,
  onClose,
}: PropsType) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-8 px-8 py-10">
        {file && (
          <div className="flex justify-center items-center rounded">
            <div className="rounded flex items-center border w-full space-x-4 border-primary-400 text-primary-400 p-4">
              <img
                src={URL.createObjectURL(file)}
                className="w-20 h-20"
                alt="icon"
              />
              <div className="flex justify-between items-top w-full h-full">
                <div className="">
                  <span>{file.name}</span>
                  <p className="text-sm text-blueGray-400">
                    {convertReadableByte(file.size)}
                  </p>
                </div>
                <IconButton
                  icon={{
                    icon: "aiOutlineClose",
                    size: "1.5rem",
                    color: "text-primary-400",
                  }}
                  onClick={() => modeBack()}
                />
              </div>
            </div>
          </div>
        )}
        <LabelWithTextField
          labelText="アイテムの名称"
          type="text"
          name="itemName"
          required
          value={itemName}
          onChange={onChangeItemName}
        />
        <LabelWithTextArea
          labelText="アイテムの説明"
          name="itemDescription"
          value={itemDescription}
          onChange={onChangeItemDescription}
          caption="※どのようなアイテムなのかを文章で記載してください。単語で登録される場合はエラーが出る可能性があります。"
        />
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-end space-x-2">
              <LabelWithNumberField
                labelText="縦のサイズ"
                name="itemHeight"
                required
                value={itemHeight}
                onChange={(e) => onChangeItemHeight(parseInt(e.target.value))}
              />
              <span className="block text-body-lg text-blueGray-900">マス</span>
            </div>
            <Icon
              icon="aiOutlineClose"
              size="1.5rem"
              color="text-blueGray-900"
            />
            <div className="flex items-end space-x-2">
              <LabelWithNumberField
                labelText="横のサイズ"
                name="itemWidth"
                required
                disabled
                placeholder=""
                value={itemHeight}
                onChange={() => {}}
              />
              <span className="block text-body-lg text-blueGray-900">マス</span>
            </div>
          </div>
          <p className="mt-2 text-caption text-blueGray-500">
            1マスの基準値: 1m x 1m
            <br />※ 現在正方形のみ利用可能となっています
          </p>
        </div>
        <div>
          <Label required htmlFor="single" labelText="アイテムのタイプ" />
          <div className="flex mt-2 gap-x-3 w-full">
            <label
              htmlFor="single"
              className={`flex-1 p-3 rounded border-blueGray-50 cursor-pointer ${
                arrangementType === "single"
                  ? "border-primary-400 border-2"
                  : "border-blueGray-50 border"
              }`}
            >
              <input
                type="radio"
                id="single"
                name="single"
                value="single"
                checked={arrangementType === "single"}
                onChange={(e) =>
                  onChangeArrangementType(e.target.value as "single")
                }
                className="hidden"
              />
              <div className="mb-3 relative w-full aspect-[238/160]">
                <Image
                  src={require("@spesill/components/organisms/Punch/utils/images/select-type/single.svg")}
                  fill
                  alt="arrangement-type-single"
                />
              </div>
              <span>単体で使う（デフォルト）</span>
            </label>
            <label
              htmlFor="multi"
              className={`flex-1 p-3 rounded border-blueGray-50 cursor-pointer ${
                arrangementType === "multi"
                  ? "border-primary-400 border-2"
                  : "border-blueGray-50 border"
              }`}
            >
              <input
                type="radio"
                id="multi"
                name="multi"
                value="multi"
                checked={arrangementType === "multi"}
                onChange={(e) =>
                  onChangeArrangementType(e.target.value as "multi")
                }
                className="hidden"
              />
              <div className="mb-3 relative w-full aspect-[238/160]">
                <Image
                  src={require("@spesill/components/organisms/Punch/utils/images/select-type/multi.svg")}
                  fill
                  alt="arrangement-type-multi"
                />
              </div>
              <span>アイテムを並べて使う</span>
            </label>
          </div>

          {arrangementType === "multi" && (
            <div className="mt-4 p-2 border border-status-warning bg-status-warning-50 rounded">
              <p className="text-sm">
                もしこの
                <span className="font-bold">
                  アイテムを繋ぎ合わせて使用したい
                </span>
                場合は、繋ぎ合わせる面が上に来るように画像を登録してください
              </p>
            </div>
          )}
        </div>
      </div>
      <hr className="border-blueGray-50" />
      <div className="mt-4 space-x-4 text-right">
        <Button
          text={"キャンセル"}
          color={"gray"}
          variant={"contained"}
          onClick={onClose}
        />
        <FormSubmitButton
          text="追加"
          color="primary"
          variant="contained"
          disabled={!file || isRequesting}
        />
      </div>
    </form>
  );
};
