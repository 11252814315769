import React, { FC } from "react";

type PropsType = {
  count: number;
  className?: string;
};

export const CountBudge: FC<PropsType> = ({ count, className = "" }) => {
  const displayCount = count > 99 ? "99+" : count;

  return (
    <span
      className={`block text-center text-caption bg-status-danger text-white rounded-full w-5 h-5 ${className}`}
    >
      {displayCount}
    </span>
  );
};
