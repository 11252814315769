import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton, TextField } from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import { useBoolean, useInput } from "@spesill/hooks";
import { User } from "@spesill/models";
import { UserRepository } from "@spesill/repository/db/user.repository";

type PropsType = {
  user: User;
  defaultDepartment?: string;
  onClose: () => void;
  onSuccess: () => void;
};
export const UpdateUserDepartmentModal = ({
  user,
  onClose,
  onSuccess,
}: PropsType) => {
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);
  const [{ value: department, onChange: onChangeDepartment }] = useInput(
    user.department,
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRequestingTrue();
    const userRepository = new UserRepository();
    await userRepository
      .update(user.id, {
        department: department,
        updatedAt: new Date(),
      })
      .then(() => {
        onSuccess();
        toast.success("部署を更新しました");
      })
      .catch((e) => {
        toast.error(
          e instanceof Error ? e.message : "部署の更新に失敗しました",
        );
      })
      .finally(() => {
        onClose();
        setRequestingFalse();
      });
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="部署">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <TextField
            type="text"
            name="department"
            required
            placeholder="部署を入力してください。"
            value={department}
            onChange={onChangeDepartment}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
              disabled={isRequesting}
            />
            <FormSubmitButton text="保存" color="primary" variant="contained" />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
