import { useCallback, useEffect, useState } from "react";

const BATCH_SIZE = 15;
const MAX_QUEUE_SIZE = 100;

export function useRequestQueue() {
  const [queue, setQueue] = useState<(() => Promise<void>)[]>([]);
  const [activeCount, setActiveCount] = useState(0);

  const existingRequests = new WeakSet();

  useEffect(() => {
    const availableSlots = BATCH_SIZE - activeCount;

    if (queue.length === 0 || availableSlots <= 0) return;

    const tasksToExecute = queue.slice(0, availableSlots);
    setQueue((prevQueue) => prevQueue.slice(availableSlots));

    tasksToExecute.forEach((task) => {
      const executeTask = async () => {
        setActiveCount((count) => count + 1);

        try {
          await task();
        } finally {
          setActiveCount((count) => count - 1);
        }
      };

      executeTask();
    });
  }, [queue, activeCount]);

  const addToQueue = useCallback((task: () => Promise<void>) => {
    // 重複するタスクはキューに追加しない。
    if (!existingRequests.has(task)) {
      existingRequests.add(task);
      // キューが最大サイズを超えた場合、最古のタスクを削除。
      setQueue((prevQueue) => {
        if (prevQueue.length >= MAX_QUEUE_SIZE) {
          return [...prevQueue.slice(1), task];
        }
        return [...prevQueue, task];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { addToQueue };
}
