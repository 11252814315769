import { useBoolean, useCurrentUser } from "@spesill/hooks";
import { ChatRoomRepository } from "@spesill/repository/db/chatRoom.repository";

type CreateChatRoomArgs = {
  onSuccess?: (id: string) => void;
};

type ReturnType = {
  isRequesting: boolean;
  createChatRoom: (args: CreateChatRoomArgs) => Promise<{ id: string } | null>;
};

export const useCreateChatRoom = (): ReturnType => {
  const { currentUser } = useCurrentUser();
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);

  const createChatRoom = async ({
    onSuccess,
  }: CreateChatRoomArgs): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    setRequestingTrue();
    const chatRoomRepository = new ChatRoomRepository();
    const chatRoom = await chatRoomRepository.add({
      tenantId: currentUser.tenantId,
      title: "New Chat",
      createUserId: currentUser.id,
    });

    onSuccess && onSuccess(chatRoom.id);
    setRequestingFalse();
    return chatRoom;
  };

  return {
    createChatRoom,
    isRequesting,
  };
};
