import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { OrderByDirection } from "@spesill/libs/firebase";
import { EvaluateDatabase, LearningDatabase } from "@spesill/models";
import { EvaluateDatabaseRepository } from "@spesill/repository/db/evaluateDatabase.repository";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";

const learningDatabaseRepository = new LearningDatabaseRepository();
const evaluateDatabaseRepository = new EvaluateDatabaseRepository();

type SortType = {
  field: "deletedAt";
  order: OrderByDirection;
};

type Database = LearningDatabase | EvaluateDatabase;

export const useDeletedLearningDatabases = () => {
  const { currentUser } = useCurrentUser();

  const [databases, setDatabases] = useState<Database[]>([]);

  const [sort, setSort] = useState<SortType>({
    field: "deletedAt",
    order: "desc",
  });

  const fetchDatabases = useCallback(async () => {
    if (!currentUser?.tenantId) return;
    const learningDatabases = await learningDatabaseRepository.findByTenantId(
      currentUser.tenantId,
      {},
      sort,
    );
    const sortedLearningDatabases = learningDatabases.filter(
      (doc) => doc.deletedAt,
    );

    const evaluateDatabases = await evaluateDatabaseRepository.findByTenantId(
      currentUser.tenantId,
      {},
      sort,
    );
    const sortedEvaluateDatabases = evaluateDatabases.filter(
      (doc) => doc.deletedAt,
    );

    const newDatabases = [
      ...sortedLearningDatabases,
      ...sortedEvaluateDatabases,
    ].sort((a, b) => {
      const currentSort = sort.order === "asc" ? 1 : -1;
      if (!a.deletedAt || !b.deletedAt) return 0;

      return currentSort * (a.deletedAt.getTime() - b.deletedAt.getTime());
    });
    setDatabases(newDatabases);
  }, [currentUser, sort]);

  const restoreDatabase = useCallback(
    async (id: string, type: "LearningDatabase" | "EvaluateDatabase") => {
      if (!currentUser?.tenantId) return;

      if (type === "LearningDatabase") {
        await learningDatabaseRepository.restoreById(id);
      } else {
        await evaluateDatabaseRepository.restoreById(id);
      }
      await fetchDatabases();
    },
    [currentUser, fetchDatabases],
  );

  const deleteLearningDatabase = useCallback(
    async (id: string) => {
      if (!currentUser) return;

      const learningDatabase =
        await learningDatabaseRepository.findByTenantIdAndId(
          currentUser.tenantId,
          id,
        );

      await LearningDatabase.deleteWithLearningDocuments({
        learningDatabase,
        user: currentUser,
      });
      fetchDatabases();
    },
    [currentUser, fetchDatabases],
  );

  const deleteEvaluateDatabase = useCallback(
    async (id: string) => {
      if (!currentUser) return;

      const evaluateDatabase =
        await evaluateDatabaseRepository.findByTenantIdAndId(
          currentUser.tenantId,
          id,
        );

      await EvaluateDatabase.deleteWithEvaluateDocuments({
        evaluateDatabase,
        user: currentUser,
      });
      fetchDatabases();
    },
    [currentUser, fetchDatabases],
  );

  const deleteDatabase = useCallback(
    async (id: string, type: "LearningDatabase" | "EvaluateDatabase") => {
      if (!currentUser) return;

      type === "LearningDatabase"
        ? await deleteLearningDatabase(id)
        : await deleteEvaluateDatabase(id);
      fetchDatabases();
    },
    [
      currentUser,
      deleteEvaluateDatabase,
      deleteLearningDatabase,
      fetchDatabases,
    ],
  );

  const changeSortOrderDirection = (order: OrderByDirection) => {
    setSort((prev) => ({ ...prev, order }));
  };

  useEffect(() => {
    fetchDatabases();
  }, [fetchDatabases]);

  return {
    fetchDatabases,
    restoreDatabase,
    deleteDatabase,
    changeSortOrderDirection,
    databases,
    sort,
  };
};
