import React from "react";

type PropsType = {
  className?: string;
  progress: number;
};

export const ProgressBar = ({ className = "", progress }: PropsType) => {
  const progressWidth = Math.min(progress, 100);
  const progressColor = progress >= 100 ? "bg-status-danger" : "bg-primary-400";

  return (
    <div
      className={`flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden ${className}`}
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <div
        className={`flex flex-col justify-center rounded-full overflow-hidden ${progressColor} text-xs text-white text-center whitespace-nowrap transition duration-500`}
        style={{ width: `${progressWidth}%` }}
      />
    </div>
  );
};
