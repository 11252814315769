import React from "react";

import { useStorageDownloadBlob } from "@spesill/hooks";

type PropsType = {
  path: string;
};

export const ImageViewer = ({ path }: PropsType) => {
  const { url } = useStorageDownloadBlob(path);

  return (
    <div className="bg-blueGray-50 h-full w-full flex items-center justify-center">
      {url && (
        <img
          src={url}
          alt="Preview"
          className="max-w-full max-h-full object-contain"
        />
      )}
    </div>
  );
};
