import React from "react";
import { toast } from "react-toastify";

import { usePunchImages } from "@spesill/hooks";
import { PunchImage } from "@spesill/models";
import { PunchItemsType } from "@spesill/models/punchLayout";

import { PunchGridCell } from "./PunchGrideCell";
import { PunchGridEmptyCell } from "./PunchGridEmptyCell";
import { CELL_DEFAULT_PX_SIZE } from "../PunchKeys";

type PropsType = {
  width: number;
  height: number;
  punchItems: PunchItemsType[];
  onChangePunchItems?: (items: PunchItemsType[]) => void;
  isEditable?: boolean;
};

export const PunchGridLayout: React.FC<PropsType> = ({
  width,
  height,
  punchItems = [],
  onChangePunchItems,
  isEditable = false,
}) => {
  const { punchImages } = usePunchImages();

  // グリッド全体を描画するコンテナのスタイル
  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `repeat(${width}, ${CELL_DEFAULT_PX_SIZE}px)`,
    gridTemplateRows: `repeat(${height}, ${CELL_DEFAULT_PX_SIZE}px)`,
    gap: "0px",
    backgroundColor: "#fff",
  };

  // グリッドの各セルの位置を計算する関数
  const getCellItem = (x: number, y: number) => {
    return punchItems.find((item) => item.x === x && item.y === y);
  };

  // グリッドからアイテムを削除する関数
  const onDeleteItem = (punchItem: PunchItemsType) => {
    const newItems = punchItems.filter(
      (item) => item.x !== punchItem.x || item.y !== punchItem.y,
    );
    onChangePunchItems && onChangePunchItems(newItems);
  };

  // グリッドのアイテムを回転させる関数
  const onRotateItem = (punchItem: PunchItemsType) => {
    const newRotation =
      punchItem.rotation === 270 ? 0 : punchItem.rotation + 90;

    const newItems = punchItems.map((item) =>
      item.x === punchItem.x && item.y === punchItem.y
        ? { ...item, rotation: newRotation as 0 | 90 | 180 | 270 }
        : item,
    );

    onChangePunchItems && onChangePunchItems(newItems);
  };

  // グリッドにアイテムを追加する関数
  const onAddNewItem = (
    punchImage: PunchImage,
    position: { x: number; y: number },
  ) => {
    const isExist = punchItems.some(
      (item) => item.x === position.x && item.y === position.y,
    );
    if (isExist) {
      return toast.warning("すでにアイテムが存在します");
    }
    const newPunchImageItem: PunchItemsType = {
      geometry: {
        grid_w: punchImage.width,
        grid_h: punchImage.height,
        url: punchImage.path,
        punch_image_id: punchImage.id,
      },
      rotation: 0,
      x: position.x,
      y: position.y,
      offset_x: 0,
      offset_y: 0,
    };

    const newItems = [...punchItems, newPunchImageItem];

    onChangePunchItems && onChangePunchItems(newItems);
  };

  // PunchGridLayout コンポーネント内

  // アイテムのグリッド位置を変更する関数
  const onChangeItemPosition = (
    punchItem: PunchItemsType,
    direction: "up" | "down" | "left" | "right",
  ) => {
    let newX = punchItem.x;
    let newY = punchItem.y;

    switch (direction) {
      case "up":
        newY = Math.max(0, punchItem.y - 1);
        break;
      case "down":
        newY = Math.min(height - 1, punchItem.y + 1);
        break;
      case "left":
        newX = Math.max(0, punchItem.x - 1);
        break;
      case "right":
        newX = Math.min(width - 1, punchItem.x + 1);
        break;
    }

    // すでにその位置にアイテムが存在するかチェック
    const isExist = punchItems.some(
      (item) =>
        item !== punchItem && // 移動対象のアイテム自体を除外
        item.x < newX + punchItem.geometry.grid_w &&
        item.x + item.geometry.grid_w > newX &&
        item.y < newY + punchItem.geometry.grid_h &&
        item.y + item.geometry.grid_h > newY,
    );
    if (isExist) {
      toast.warn("すでにアイテムが存在します");
      return;
    }

    const newItems = punchItems.map((item) =>
      item.x === punchItem.x && item.y === punchItem.y
        ? { ...item, x: newX, y: newY }
        : item,
    );

    onChangePunchItems && onChangePunchItems(newItems);
  };

  // グリッドセルを生成する
  const generateGridCells = () => {
    return Array.from({ length: height * width }, (_, index) => {
      const x = index % (width || 1);
      const y = Math.floor(index / (width || 1));
      const cellItem = getCellItem(x, y);
      const cellKey = `${x}-${y}`;
      return cellItem ? (
        <PunchGridCell
          item={cellItem}
          key={cellKey}
          onDeleteItem={onDeleteItem}
          onRotateItem={onRotateItem}
          onChangeItemPosition={onChangeItemPosition}
          isEditable={isEditable}
        />
      ) : (
        <PunchGridEmptyCell
          key={cellKey}
          isEditable={isEditable}
          punchImages={punchImages}
          onAddItem={onAddNewItem}
          position={{
            x,
            y,
          }}
        />
      );
    });
  };

  return <div style={gridStyle}>{generateGridCells()}</div>;
};
