import React, { FC, ChangeEvent, MouseEvent } from "react";

import { IconType, Label } from "@spesill/components/atoms";

import { ButtonWithTextField } from "../ButtonWithTextField/ButtonWithTextField";

type FieldType = "text" | "email" | "password" | "search";

type PropsType = {
  label: string;
  icon: IconType;
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  error?: string;
};

export const IconButtonWithTextField: FC<PropsType> = ({
  label,
  icon,
  type = "text",
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  className = "",
  onChange,
  onClick,
  error,
}: PropsType) => {
  return (
    <div className={className}>
      <Label
        htmlFor={name}
        labelText={label}
        required={required}
        className={`block ${error ? "text-red-600" : ""}`}
      />
      <ButtonWithTextField
        inputClassName={`mt-1.5 ${error ? "border-red-600" : ""}`}
        icon={icon}
        type={type}
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
      {error && <p className="text-red-600 text-xs mt-2">{error}</p>}
    </div>
  );
};
