import React, { MouseEvent } from "react";
import { toast } from "react-toastify";

import { Icon } from "@spesill/components/atoms";
import {
  IconButton,
  ListMenu,
  MenuItemType,
} from "@spesill/components/molecules";
import { DocumentPreviewModal } from "@spesill/components/organisms";

import { useBoolean, useHover } from "@spesill/hooks";
import { formatDate } from "@spesill/libs";
import { EvaluateDocument, User } from "@spesill/models";

type PropsType = {
  evaluateDocument: EvaluateDocument & { user?: User };
  deleteDocument: (id: string) => void;
};

export const EvaluateDocumentListRow = ({
  evaluateDocument,
  deleteDocument,
}: PropsType) => {
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);
  const {
    isChecked: isOpenDocumentPreview,
    setTrue: onOpenDocumentPreview,
    setFalse: onCloseDocumentPreview,
  } = useBoolean(false);
  const [ref] = useHover<HTMLDivElement>();

  const handleDeleteDocument = async () => {
    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;

    await deleteDocument(evaluateDocument.id);
    toast.success("削除しました");
  };

  const menuItems: MenuItemType[] = [
    {
      label: "削除",
      className: "text-status-danger",
      onClick: () => handleDeleteDocument(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpen();
  };

  const handleOpenDocumentPreview = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpenDocumentPreview();
  };

  return (
    <>
      <div
        ref={ref}
        className="bg-white flex text-blueGray-700 py-3 px-4 rounded-md h-16 items-center hover:bg-blueGray-50"
      >
        <button
          type="button"
          onClick={handleOpenDocumentPreview}
          className="flex-1 gap-x-4 flex items-center"
        >
          <div className="flex items-center space-x-2 flex-1 gap-x-4">
            {evaluateDocument.isExcel() ? (
              <Icon
                icon="riFileExcel2Fill"
                size="2rem"
                color="text-green-700"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            ) : evaluateDocument.isPdf() ? (
              <Icon
                icon="tbPdf"
                size="2rem"
                color="text-status-danger"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            ) : evaluateDocument.isImage() ? (
              <Icon
                icon="faImage"
                size="2rem"
                color="text-blueGray-500"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            ) : (
              <Icon
                icon="riFileWord2Fill"
                size="2rem"
                color="text-primary-400"
                className="bg-blueGray-0 p-1 rounded shadow"
              />
            )}
            <span className="text-body-base-bold">
              {evaluateDocument.systemName}
            </span>
          </div>
          <div className="text-body-base w-1/6">
            {formatDate(evaluateDocument.createdAt)}
          </div>
          <div className="text-body-base w-1/6">
            {evaluateDocument.user?.fullName}
          </div>
        </button>

        <div className="flex gap-6 items-center justify-end w-1/12">
          <div className="relative flex items-center">
            <IconButton
              icon={{
                icon: "bsThreeDots",
                color: "text-blueGray-500",
                size: "1.5rem",
              }}
              srOnlyText="メニューを開く"
              onClick={handleOpen}
            />
            {isOpen && (
              <ListMenu
                items={menuItems}
                onClose={onClose}
                className="right-full mr-2"
              />
            )}
          </div>
        </div>
      </div>
      {isOpenDocumentPreview && (
        <DocumentPreviewModal
          onClose={onCloseDocumentPreview}
          path={evaluateDocument.path}
          systemName={evaluateDocument.systemName}
          extension={evaluateDocument.extension()}
        />
      )}
    </>
  );
};
