import React from "react";

import { useBoolean } from "@spesill/hooks";

type PropsType = {
  onSelect: (text: string) => void;
  className?: string;
  texts: string[];
};

export const TextTemplates = ({
  onSelect,
  className = "",
  texts,
}: PropsType) => {
  const { isChecked, setFalse, toggle } = useBoolean(false);

  const handleSelected = (text: string) => {
    onSelect(text);
    setFalse();
  };

  return (
    <div className={className}>
      {isChecked && (
        <ul className="px-2 py-3 rounded shadow border border-blueGray-50 gap-y-2 max-h-56 overflow-y-auto">
          {texts.map((text, index) => (
            <li key={index} className="hover:bg-blueGray-0">
              <button
                type="button"
                onClick={() => handleSelected(text)}
                className={`text-left w-full p-2 border-blueGray-50 ${index !== 0 && "border-t"}`}
              >
                <p className="text-body-base-bold text-blueGray-900">
                  テンプレート{index + 1}
                </p>
                <p className="text-body-base text-blueGray-500 mt-2">{text}</p>
              </button>
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={() => toggle()}
        className={`p-2 rounded-full border border-blueGray-300 text-caption ${isChecked && "mt-2"}`}
      >
        テンプレートを使用する
      </button>
    </div>
  );
};
