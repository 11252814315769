import ja from "date-fns/locale/ja";
import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ja", ja);

type PropsType = {
  selected: Date;
  onChange: (date: Date) => void;
  required?: boolean;
  className?: string;
  dateFormat?: string;
};
export const DatePicker: React.FC<PropsType> = ({
  selected,
  onChange,
  required = false,
  dateFormat = "yyyy/MM/dd",
  className = "",
}) => {
  return (
    <ReactDatePicker
      locale="ja"
      required={required}
      selected={selected}
      dateFormat={dateFormat}
      onChange={onChange}
      wrapperClassName="w-full"
      className={` w-full px-4 py-2 border rounded ${className}`}
    />
  );
};
