import { Entity } from "./__common__/entity";
import { SourceType } from "./source";

export type ChatRoomMessageSenderType = "USER" | "AI";

export class ChatRoomMessage extends Entity {
  id: string;
  message: string;
  sender: ChatRoomMessageSenderType;
  timestamp: Date;
  sources: SourceType[];

  constructor(arg: ExcludeMethods<ChatRoomMessage>) {
    super(arg);

    this.id = arg.id;
    this.message = arg.message;
    this.sender = arg.sender;
    this.sources = arg.sources;
    this.timestamp = arg.timestamp;
  }
}
