import React, { ChangeEvent } from "react";
import { FileRejection } from "react-dropzone";

import {
  Button,
  FormSubmitButton,
  MultiValueType,
  SingleValueType,
  TextField,
} from "@spesill/components/atoms";
import {
  LabelWithDropdownField,
  LabelWithTextField,
} from "@spesill/components/molecules";

type PropsType = {
  handleDropFile: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => void;
  file: File | undefined;
  fileUrl: string | undefined;
  freeInputKind?: string;
  onChangeFreeInputKind?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onClose: () => void;
  onSubmit: () => void;
  systemName: string;
  onChangeSystemName: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  dropdownValueDocumentType: SingleValueType;
  dropdownSelectableValueDocumentType: MultiValueType;
  onChangeDropdownDocumentType: (newValue: SingleValueType) => void;
  dropdownValueLanguage?: SingleValueType;
  dropdownSelectableValueLanguage: MultiValueType;
  onChangeDropdownLanguage: (newValue: SingleValueType) => void;
};

export const FormatGeneratorFromAi = ({
  onClose,
  onSubmit,
  systemName,
  onChangeSystemName,
  freeInputKind,
  onChangeFreeInputKind,
  dropdownValueDocumentType,
  dropdownSelectableValueDocumentType,
  onChangeDropdownDocumentType,
  // dropdownValueLanguage,
  // dropdownSelectableValueLanguage,
  // onChangeDropdownLanguage,
}: PropsType) => {
  return (
    <form onSubmit={onSubmit}>
      <h4 className="text-h4 text-blueGray-900 px-3">文章を作成</h4>
      <div className="py-8 gap-y-3 flex flex-col px-3">
        <LabelWithTextField
          labelText="文書タイトル"
          type="text"
          name="systemName"
          required
          autoComplete="systemName"
          placeholder="文書タイトル"
          value={systemName}
          onChange={onChangeSystemName}
        />
        <LabelWithDropdownField
          labelText="文書種類"
          name="kind"
          value={dropdownValueDocumentType}
          options={dropdownSelectableValueDocumentType}
          onChange={onChangeDropdownDocumentType}
          required
        />
        {dropdownValueDocumentType?.value === "others" && (
          <TextField
            name="freeInputKind"
            required
            placeholder="文書の種類を入力してください"
            className="mt-4"
            type={"text"}
            value={freeInputKind}
            onChange={onChangeFreeInputKind}
          />
        )}
        {/* <LabelWithDropdownField
          labelText="言語"
          name="kind"
          value={dropdownValueLanguage}
          options={dropdownSelectableValueLanguage}
          onChange={onChangeDropdownLanguage}
        /> */}
      </div>
      <div className="mt-4 text-right flex justify-end">
        <div className="space-x-4 ">
          <Button
            text={"キャンセル"}
            color={"gray"}
            variant={"contained"}
            onClick={onClose}
          />
          <FormSubmitButton
            text={"次へ"}
            color={"primary"}
            variant={"contained"}
          />
        </div>
      </div>
    </form>
  );
};
