import React, { FormEvent, useState } from "react";
import { toast } from "react-toastify";

import { useRouter } from "next/router";

import {
  Button,
  FormSubmitButton,
  Icon,
  Label,
} from "@spesill/components/atoms";
import {
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import {
  useCreateLearningDatabase,
  useInput,
  useTenantLimitManager,
} from "@spesill/hooks";

type PropsType = {
  defaultSystemName?: string;
  onClose: () => void;
  onSuccess?: (id: string) => void;
};

export const CreateLearningDatabaseModal = ({
  defaultSystemName = "",
  onClose,
  onSuccess,
}: PropsType) => {
  const [{ value: systemName, onChange: onChangeSystemName }] =
    useInput(defaultSystemName);
  const { createLearningDatabase, isRequesting } = useCreateLearningDatabase();
  const { isUploadableDatabases } = useTenantLimitManager();
  const { pathname } = useRouter();
  const isDocumentPage = pathname.startsWith("/documents");
  const [structureType, setStructureType] = useState<"unstructured" | "table">(
    "unstructured",
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isDocumentPage) {
      const isConfirmed = confirm(
        "保存されていない編集内容は失われます。データベースを作成しますか?",
      );

      if (!isConfirmed) return;
    }
    const canAddLearningDatabase = await isUploadableDatabases(1);

    if (!canAddLearningDatabase) {
      toast.error(
        "データベース数がプランの上限に達しました。 プランをアップグレードしてください。",
      );
      return;
    }

    const isCreating = toast.loading("データベースを作成しています。");

    try {
      const learningDatabase = await createLearningDatabase({
        systemName,
        structureType,
        onSuccess: (id) => {
          onClose();
          onSuccess && onSuccess(id);
          toast.update(isCreating, {
            render: "データベースの作成が完了しました。",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        },
      });
      if (!learningDatabase) {
        toast.error("文書データベースの作成に失敗しました。");
      }
    } catch (error) {
      toast.dismiss(isCreating);
      toast.error("データベースの作成に失敗しました。");
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="文書データベース作成">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <LabelWithTextField
            labelText="データベース名"
            type="text"
            name="systemName"
            required
            placeholder="データベース名を入力してください。"
            value={systemName}
            onChange={onChangeSystemName}
          />
          <div>
            <Label
              htmlFor="databaseType"
              labelText="データベースタイプ"
              required
              className="block pb-2"
            />
            <div className="flex gap-3">
              <button
                type="button"
                className={`
                  flex-1 p-3 border-gray-300 border rounded flex gap-2 items-center hover:bg-blueGray-50
                  ${
                    structureType === "table"
                      ? "border-primary-400 border-2"
                      : ""
                  }
                `}
                onClick={() => setStructureType("table")}
              >
                <div className="bg-blueGray-0 w-8 h-8 flex items-center justify-center rounded shadow">
                  <Icon icon={"bsTable"} size="1.5rem" />
                </div>
                <div>
                  <p className="pb-1 font-medium text-blueGray-900">
                    構造化データ
                  </p>
                  <p className="text-xs font-medium text-blueGray-500">
                    表形式データなど
                  </p>
                </div>
              </button>
              <button
                type="button"
                className={`
                  flex-1 p-3 border-gray-300 border rounded flex gap-2 items-center hover:bg-blueGray-50
                  ${
                    structureType === "unstructured"
                      ? "border-primary-400 border-2"
                      : ""
                  }
                `}
                onClick={() => setStructureType("unstructured")}
              >
                <div className="bg-blueGray-0 w-8 h-8 flex items-center justify-center rounded shadow">
                  <Icon icon={"ioDocumentOutline"} size="1.5rem" />
                </div>
                <div>
                  <p className="font-medium text-blueGray-900 pb-1">
                    非構造化データ
                  </p>
                  <p className="text-xs font-medium text-blueGray-500">
                    文章形式データなど
                  </p>
                </div>
              </button>
            </div>
          </div>
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="保存"
              color="primary"
              variant="contained"
              disabled={isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
