import React from "react";

import dynamic from "next/dynamic";

import { ModalWrapper } from "@spesill/components/molecules";

import {
  DocumentLocationType,
  PdfLocationType,
  SheetLocationType,
  SourceType,
} from "@spesill/models/source";

import { DocxFileViewer } from "../FileViewer/DocxFileViewer";
import { ImageViewer } from "../FileViewer/imageViewer";

const PdfFileViewer = dynamic(
  () =>
    import("@spesill/components/organisms/FileViewer/PdfFileViewer").then(
      (mod) => mod.PdfFileViewer,
    ),
  {
    ssr: false,
  },
);
const ExcelSheetViewer = dynamic(
  () =>
    import(
      "@spesill/components/organisms/Modal/CreateDocumentFormExcelModal/ExcelSheetViewer"
    ).then((mod) => mod.ExcelSheetViewer),
  {
    ssr: false,
  },
);

type PropsType = {
  onClose: () => void;
  path: string;
  systemName: string;
  extension: string;
  location?: SourceType["location"];
  sourceText?: string;
  isModalPortal?: boolean;
};

export const DocumentPreviewModal = ({
  onClose,
  path,
  systemName,
  extension,
  location,
  sourceText,
  isModalPortal,
}: PropsType) => {
  return (
    <ModalWrapper
      onClose={onClose}
      headerTitle={systemName}
      className="h-full"
      fullContentWidth
      isPortal={isModalPortal}
    >
      {(() => {
        switch (extension) {
          case ".docx":
            return (
              <DocxFileViewer
                path={path}
                systemName={systemName}
                location={location as DocumentLocationType}
                sourceText={sourceText}
              />
            );
          case ".xlsx":
            return (
              <ExcelSheetViewer
                path={path}
                location={location as SheetLocationType}
                sourceText={sourceText}
                hostStyle={{ width: "90vw", height: "600px" }}
              />
            );
          case ".pdf":
            return (
              <PdfFileViewer
                path={path}
                location={location as PdfLocationType[]}
              />
            );
          case ".jpg":
          case ".jpeg":
          case ".png":
          case ".gif":
          case ".webp":
            return <ImageViewer path={path} />;
          default:
            return (
              <div>
                拡張子がdocx,xlsx,pdf,jpg,jpeg,png,gif,webpのファイルを選択してください
              </div>
            );
        }
      })()}
    </ModalWrapper>
  );
};
