import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { ChatRoom } from "@spesill/models";
import { ChatRoomRepository } from "@spesill/repository/db/chatRoom.repository";

const chatRoomRepository = new ChatRoomRepository();

export const useChatRoom = (id: string) => {
  const { currentUser } = useCurrentUser();

  const [chatRoom, setChatRoom] = useState<ChatRoom>();

  const fetchChatRoom = useCallback(async () => {
    if (!currentUser?.tenantId || !id) return;

    const chatRoom = await chatRoomRepository.findById(id);
    if (chatRoom.deletedAt) {
      setChatRoom(undefined);
      return;
    }
    if (chatRoom.createUserId !== currentUser.id) {
      setChatRoom(undefined);
      return;
    }
    setChatRoom(chatRoom);
  }, [currentUser?.id, currentUser?.tenantId, id]);

  useEffect(() => {
    fetchChatRoom();
  }, [fetchChatRoom]);

  return { fetchChatRoom, chatRoom };
};
