import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { Document } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";

const documentRepository = new DocumentRepository();

export const useDocuments = () => {
  const { currentUser } = useCurrentUser();

  const [documents, setDocuments] = useState<Document[]>([]);

  const fetchDocuments = useCallback(async () => {
    if (!currentUser?.tenantId) return;
    const docs = await documentRepository.findByTenantId(currentUser.tenantId);
    const existsDocs = docs.filter((doc) => !doc.deletedAt);
    const sortedDocs = existsDocs.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : 1,
    );
    setDocuments(sortedDocs);
  }, [currentUser]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return { fetchDocuments, documents };
};
