import { Editor } from "@tiptap/core";
import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model";
import { INode } from "react-flow-builder";

import { PunchItemsType } from "@spesill/models/punchLayout";

type returnType = {
  insertText: (text: string, pos: number) => void;
  insertHtml: (html: string, pos: number) => void;
  insertChartValue: (value: string, pos: number) => void;
};

const isPunchLayout = <T extends object>(
  obj: T,
): obj is T & { width: number; height: number; punchItems: PunchItemsType } => {
  return (
    "width" in obj &&
    typeof obj.width === "number" &&
    "height" in obj &&
    typeof obj.height === "number" &&
    "punchItems" in obj &&
    Array.isArray(obj.punchItems)
  );
};

const isFlowchart = <T extends object>(obj: T): obj is T & INode[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (node) =>
        "id" in node &&
        typeof node.id === "string" &&
        "type" in node &&
        typeof node.type === "string" &&
        "name" in node &&
        typeof node.name === "string",
    )
  );
};

export const useInsertTiptapNode = (
  editor: Editor | null,
  learningDatabaseId?: string,
  documentKind?: string,
  freeInputKind?: string,
): returnType => {
  const insertText = (text: string, pos: number) => {
    if (!editor) return;

    const paragraph = editor.schema.nodes.paragraph?.create();
    const textNode = editor.schema.text(text);
    if (!paragraph) return;

    const transaction = editor.view.state.tr
      .insert(pos, paragraph)
      .insert(pos + 1, textNode);
    editor.view.dispatch(transaction);
  };

  const insertHtml = (html: string, pos: number) => {
    if (!editor) return;

    const domParser = new DOMParser();
    const parsedHtml = domParser.parseFromString(html, "text/html");
    // eslint-disable-next-line quotes
    const checkboxes = parsedHtml.querySelectorAll('input[type="checkbox"]');

    if (checkboxes.length > 0) {
      editor
        .chain()
        .focus()
        .command(({ tr }) => {
          const taskList = editor.schema.nodes.taskList;
          const taskItem = editor.schema.nodes.taskItem;
          const paragraph = editor.schema.nodes.paragraph;

          if (!taskList || !taskItem || !paragraph) return false;

          const newTaskItems = Array.from(checkboxes).map((checkbox) => {
            const label = checkbox.nextSibling?.textContent?.trim() || "";
            const checked = checkbox.hasAttribute("checked");
            const paragraphNode = paragraph.createAndFill(
              null,
              editor.schema.text(label),
            );

            const taskItemNode = taskItem.create({ checked }, paragraphNode);

            return taskItemNode;
          });

          const newTaskList = taskList.create(null, newTaskItems);

          tr.insert(pos, newTaskList);

          return true;
        })
        .run();
    } else {
      const contentDOM = parsedHtml.body;
      const contentNode = ProseMirrorDOMParser.fromSchema(editor.schema).parse(
        contentDOM,
      );

      if (contentNode) {
        const transaction = editor.view.state.tr.insert(pos, contentNode);
        editor.view.dispatch(transaction);
      }
    }
  };

  const insertChart = (value: string, pos: number) => {
    if (!editor) return;

    const flowchartNode = editor.schema.nodes.flowchartComponent?.create({
      learningDatabaseId,
      state: value,
      documentKind,
      freeInputKind,
    });

    if (flowchartNode) {
      const transaction = editor.view.state.tr.insert(pos, flowchartNode);
      editor.view.dispatch(transaction);
    }
  };

  const insertPunchLayout = (value: string, pos: number) => {
    if (!editor) return;

    const punchLayoutNode = editor.schema.nodes.punchLayoutComponent?.create({
      state: value,
      learningDatabaseId,
    });

    if (punchLayoutNode) {
      const transaction = editor.view.state.tr.insert(pos, punchLayoutNode);
      editor.view.dispatch(transaction);
    }
  };

  const insertChartValue = (value: string, pos: number) => {
    const valueObj = JSON.parse(value);

    if (isPunchLayout(valueObj)) {
      insertPunchLayout(value, pos);
    } else if (isFlowchart(valueObj)) {
      insertChart(value, pos);
    } else {
      throw new Error("Invalid chart value");
    }
  };

  return {
    insertText,
    insertHtml,
    insertChartValue,
  };
};
