import { useCallback } from "react";

import { useBoolean, useCurrentUser } from "@spesill/hooks";
import { NewUserInvitationRepository } from "@spesill/repository/db/newUserInvitation.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

const userRepository = new UserRepository();
const newUserInvitationRepository = new NewUserInvitationRepository();

export const useDeleteUnregisteredUser = () => {
  const { currentUser } = useCurrentUser();

  const { isChecked: isDeleting, setTrue, setFalse } = useBoolean(false);
  const deleteUnregisteredUser = useCallback(
    async (id: string) => {
      if (!currentUser) return;

      if (currentUser.role !== "ADMIN") {
        throw new Error("権限がありません");
      }

      setTrue();

      const user = await userRepository.findById(id);

      if (!user.isUnregistered) {
        throw new Error("登録済みのユーザーは削除できません");
      }

      await userRepository.deleteById(id);
      await newUserInvitationRepository.deleteByReceiverUserId(id);
      setFalse();
    },
    [currentUser, setFalse, setTrue],
  );

  return {
    isDeleting,
    deleteUnregisteredUser,
  };
};
