import React, { FC, ReactNode } from "react";

import { CountBudge } from "@spesill/components/atoms";
import { IconButton, ModalWrapper } from "@spesill/components/molecules";

type PropsType = {
  onClose: () => void;
  children: ReactNode;
  menus: {
    text: string;
    count?: number;
    onClick: () => void;
  }[];
  currentMenuText: string;
  className?: string;
};

export const RightFloatMenusWrapper: FC<PropsType> = ({
  onClose,
  className = "",
  menus,
  currentMenuText,
  children,
}) => {
  return (
    <>
      <ModalWrapper isWhite allowBackgroundScroll onClose={onClose} />
      <div
        className={`fixed top-16 bottom-0 z-10 right-0 flex items-end ${className}`}
      >
        <div className="pt-4 shadow w-[30rem] flex flex-col flex-1 h-full bg-white">
          <div className="flex items-center justify-between gap-x-4 text-center px-6 border-b border-solid border-blueGray-50">
            <div className="flex space-x-2">
              {menus.map((menu) => (
                <button
                  key={menu.text}
                  type="button"
                  className={`pb-2 flex items-center gap-x-1 ${
                    currentMenuText === menu.text &&
                    "border-primary-400 border-b-2"
                  }`}
                  onClick={menu.onClick}
                >
                  <span>{menu.text}</span>
                  {menu.count && menu.count > 0 ? (
                    <CountBudge count={menu.count} />
                  ) : null}
                </button>
              ))}
            </div>
            <IconButton
              icon={{
                icon: "tbLayoutSidebarRightCollapse",
                size: "1.2rem",
                color: "text-blueGray-500",
              }}
              onClick={onClose}
              tooltip={{
                text: "最小化",
                direction: "bottom",
                className: "text-xs",
              }}
            />
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
