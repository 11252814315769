import React from "react";
import { toast } from "react-toastify";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  IconButton,
  ListMenu,
  MenuItemType,
} from "@spesill/components/molecules";
import { UpdateChatNameModal } from "@spesill/components/organisms";

import { useBoolean, useUpdateChatRoom } from "@spesill/hooks";
import { ChatRoom } from "@spesill/models";

type PropsType = {
  isOpen: boolean;
  chatRoom: ChatRoom;
  onClose: () => void;
  onOpen: () => void;
  isSelected?: boolean;
  fetchChatRooms: () => void;
};

export const ChatListItem = ({
  isSelected = false,
  chatRoom,
  isOpen,
  onClose,
  onOpen,
  fetchChatRooms,
}: PropsType) => {
  const { push } = useRouter();
  const {
    isChecked: isUpdateChatNameModalOpen,
    setTrue: setUpdateChatNameModalOpen,
    setFalse: setUpdateChatNameModalClose,
  } = useBoolean(false);
  const { updateChatRoom } = useUpdateChatRoom(chatRoom.id);
  const handleDeleteItem = async () => {
    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;
    try {
      await updateChatRoom({
        chatRoom: {
          ...chatRoom,
          deletedAt: new Date(),
        } as ChatRoom,
      });
      fetchChatRooms();
      push("/chat");
      toast.success("削除しました");
    } catch (e) {
      toast.error(e instanceof Error ? e.message : "削除に失敗しました");
    } finally {
      onClose();
    }
  };
  const menuItems: MenuItemType[] = [
    {
      label: "名前を変更",
      onClick: () => setUpdateChatNameModalOpen(),
      icon: {
        icon: "biEditAlt",
        color: "text-blueGray-500",
        size: "1.2rem",
      },
    },
    {
      label: "削除",
      className: "text-status-danger",
      onClick: () => handleDeleteItem(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  if (isSelected) {
    return (
      <li className="flex p-2 rounded justify-between items-center w-full cursor-pointer bg-blueGray-50">
        <div className="w-full flex justify-between items-center">
          <span className="body-base truncate">{chatRoom.title}</span>

          <div className="relative flex justify-end w-1/12">
            <IconButton
              icon={{
                icon: "bsThreeDots",
                color: "text-blueGray-500",
                size: "1.5rem",
              }}
              srOnlyText="メニューを開く"
              onClick={onOpen}
            />
            {isOpen && <ListMenu items={menuItems} onClose={onClose} />}
          </div>
        </div>

        {isUpdateChatNameModalOpen && (
          <UpdateChatNameModal
            chatRoom={chatRoom}
            onClose={() => {
              setUpdateChatNameModalClose();
              onClose();
            }}
            onSuccess={fetchChatRooms}
          />
        )}
      </li>
    );
  }
  return (
    <li className="p-2 rounded w-full cursor-pointer hover:bg-blueGray-50">
      <Link
        className="w-full flex justify-between items-center"
        href={`/chat/${chatRoom.id}`}
      >
        <span className="body-base truncate">{chatRoom.title}</span>
      </Link>
    </li>
  );
};
