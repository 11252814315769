import React from "react";

import Image from "next/image";
import Link from "next/link";

import { DocumentLayout } from "@spesill/components/layouts";

export const NotFound = () => {
  return (
    <DocumentLayout>
      <div className="text-center mx-auto">
        <h1 className="text-primary-900 font-bold text-2xl">
          ページが見つかりません
        </h1>
        <p className="mt-5 text-base">
          お探しのページは、移動または削除された可能性があります。
        </p>
        <Image
          src="/error_404.png"
          alt="エラー"
          className="mx-auto mt-5"
          width="300"
          height="300"
        />
        <div className="mt-5 max-w-sm mx-auto">
          <Link
            href="/"
            className="block bg-primary-800 text-white px-4 py-2 font-bold rounded full-width"
          >
            トップに戻る
          </Link>
        </div>
      </div>
    </DocumentLayout>
  );
};
