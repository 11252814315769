import React from "react";

import { Button, LinkButton } from "@spesill/components/atoms";
import { DocumentLayout } from "@spesill/components/layouts";

import { useBoolean } from "@spesill/hooks";

import { FreePlanIntroduction } from "./FreePlanIntroduction";
import { UploadDocumentFormatModal } from "..";
import { GenerateDocumentButton } from "../Document/GenerateDocumentButton/GenerateDocumentButton";

type PropsType = {
  documentId?: string;
};

export const FreePlanIndex = ({ documentId }: PropsType) => {
  const {
    isChecked: isUploadModalOpen,
    setTrue: setUploadModalOpen,
    setFalse: setUploadModalClose,
  } = useBoolean(false);
  return (
    <DocumentLayout>
      <div className="w-full space-y-6">
        {documentId ? (
          <div className="flex">
            <LinkButton
              href={`/documents/${documentId}`}
              text={"文書を編集"}
              color={"primary"}
              variant={"contained"}
              icon={{
                icon: "aiOutlineFile",
                size: "1.5rem",
                color: "text-white",
              }}
            />
          </div>
        ) : (
          <div className="flex space-x-2">
            <GenerateDocumentButton />
            <Button
              text="フォーマットファイルを読込"
              color="primary"
              variant="contained"
              onClick={() => setUploadModalOpen()}
            />
          </div>
        )}
        <FreePlanIntroduction />
      </div>
      {isUploadModalOpen && (
        <UploadDocumentFormatModal onClose={setUploadModalClose} />
      )}
    </DocumentLayout>
  );
};
