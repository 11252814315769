import React, { useState, FormEvent } from "react";
import { toast } from "react-toastify";

import { ModalWrapper } from "@spesill/components/molecules";

import { privateStorage } from "@spesill/config/firebase";
import { useBoolean, useCurrentUser, useFile, useInput } from "@spesill/hooks";
import { uploadBytes, ref, getDownloadURL } from "@spesill/libs/firebase";
import { PunchImage } from "@spesill/models";

import { PunchIconFileUploader } from "./PunchIconFileUploader";
import { PunchIconForm } from "./PunchIconForm";

type PropsType = {
  onClose: () => void;
};
export const AddPunchIconModal = ({ onClose }: PropsType) => {
  const { files, handleDropFile, setFiles } = useFile();
  const [isRequesting, setIsRequesting] = useState(false);
  const { currentUser } = useCurrentUser();
  const modeBack = () => {
    setSimpleMode();
    setFiles([]);
  };

  const file = files[0];

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);
    if (!currentUser || !file) return;

    try {
      const punch = await PunchImage.create({
        name: itemName,
        description: itemDescription,
        height: itemHeight,
        width: itemHeight,
        path: "", // 作成時には仮の値
        tenantId: currentUser.tenantId,
        arrangementType,
        itemTypes: arrangementType === "multi" ? ["PATHWAY"] : [],
      });
      const storageRef = ref(
        privateStorage,
        `tenants/${currentUser.tenantId}/punch_images/${punch.id}`,
      );

      return uploadBytes(storageRef, file).then(async () => {
        const punchImageUrl = await getDownloadURL(storageRef);
        await PunchImage.update({
          ...punch,
          path: punchImageUrl,
          deletedAt: null, // nullでないとAI側でdeletedAtでクエリをかけられないため明示的にnullをセット
        });
        toast.success("アイコンを追加しました");
        onClose();
      });
    } catch (e) {
      toast.error(
        e instanceof Error ? e.message : "アイコンの追加に失敗しました",
      );
    } finally {
      setIsRequesting(false);
    }
  };

  const {
    isChecked: isDetailMode,
    setTrue: setDetailModel,
    setFalse: setSimpleMode,
  } = useBoolean(false);

  const [{ value: itemName, onChange: onChangeItemName }] = useInput("");
  const [arrangementType, onChangeArrangementType] = useState<
    "single" | "multi"
  >("single");
  const [{ value: itemDescription, onChange: onChangeItemDescription }] =
    useInput("");
  const [itemHeight, onChangeItemHeight] = useState(0);

  return (
    <ModalWrapper onClose={onClose} headerTitle="アイコンを追加">
      {isDetailMode ? (
        <PunchIconForm
          modeBack={modeBack}
          onClose={onClose}
          file={file}
          handleSubmit={handleSubmit}
          itemName={itemName}
          onChangeItemName={onChangeItemName}
          itemDescription={itemDescription}
          onChangeItemDescription={onChangeItemDescription}
          itemHeight={itemHeight}
          onChangeItemHeight={onChangeItemHeight}
          arrangementType={arrangementType}
          onChangeArrangementType={onChangeArrangementType}
          isRequesting={isRequesting}
        />
      ) : (
        <PunchIconFileUploader
          onClose={onClose}
          handleDropFile={handleDropFile}
          setDetailModel={setDetailModel}
          files={files}
        />
      )}
    </ModalWrapper>
  );
};
