import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { User } from "@spesill/models";
import { Document } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

import type { OrderByDirection } from "@spesill/libs/firebase";

const userRepository = new UserRepository();
const documentRepository = new DocumentRepository();

export type DocumentWithUser = Document & { user?: User };

type FilterType = {
  createUserId?: string;
};

type SortType = {
  field: "updatedAt";
  order: OrderByDirection;
};

export const useDocumentsWithUser = () => {
  const { currentUser } = useCurrentUser();
  const [documents, setDocuments] = useState<DocumentWithUser[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType>();
  const [sort, setSort] = useState<SortType>({
    field: "updatedAt",
    order: "desc",
  });

  const fetchDocumentsAndUsers = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const users = await userRepository.findByTenantId(currentUser.tenantId);
    const docs = await documentRepository.findByTenantId(
      currentUser.tenantId,
      {
        createUserId: selectedFilter?.createUserId,
      },
      sort,
    );

    const docsWithUser = docs
      .filter((doc) => !doc.deletedAt)
      .map((doc) => {
        const user = users.find((user) => user.id === doc.createUserId);
        return Object.defineProperty(doc, "user", {
          value: user,
          writable: true,
          enumerable: true,
          configurable: true,
        });
      });
    setDocuments(docsWithUser);
  }, [currentUser?.tenantId, selectedFilter, sort]);

  useEffect(() => {
    fetchDocumentsAndUsers();
  }, [currentUser?.tenantId, fetchDocumentsAndUsers]);

  const logicalDeleteDocument = useCallback(
    async (id: string) => {
      if (!currentUser?.tenantId) return;
      const document = await documentRepository.findByTenantIdAndId(
        currentUser.tenantId,
        id,
      );
      const permission = await document.userPermission(currentUser);

      if (permission !== "editor") {
        throw new Error("権限がありません");
      }

      await documentRepository.logicalDeleteById(id);
      await axios.post("/api/mail/notification", {
        notificationType: "delete-Document",
        systemName: (documents.find((doc) => doc.id === id) as Document)
          .systemName,
        operatorUserId: currentUser.id,
        resourceId: id,
      });

      await fetchDocumentsAndUsers();
    },
    [currentUser, documents, fetchDocumentsAndUsers],
  );

  const selectCreateUserId = (createUserId: string) => {
    setSelectedFilter({ createUserId });
  };

  const resetFilterCreateUserId = () => {
    setSelectedFilter({ createUserId: undefined });
  };

  const changeSortOrderDirection = (order: OrderByDirection) => {
    setSort((prev) => ({ ...prev, order }));
  };

  return {
    fetchDocumentsAndUsers,
    documents,
    selectedFilter,
    sort,
    logicalDeleteDocument,
    selectCreateUserId,
    resetFilterCreateUserId,
    changeSortOrderDirection,
  };
};
