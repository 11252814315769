import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { DocumentPromptHistory } from "@spesill/models/DocumentPromptHistory";
import { DocumentPromptHistoryRepository } from "@spesill/repository/db/DocumentPromptHistory.repository";

const documentPromptHistoryRepository = new DocumentPromptHistoryRepository();

export const useFetchDocumentPromptHistories = (documentId: string) => {
  const { currentUser } = useCurrentUser();
  const [documentPromptHistories, setDocumentPromptHistories] = useState<
    DocumentPromptHistory[]
  >([]);

  const fetchPromptHistories = useCallback(async () => {
    if (!currentUser?.id) return;
    const documentPromptHistories =
      await documentPromptHistoryRepository.findByDocumentIdAndUserId(
        currentUser.id,
        documentId,
      );
    setDocumentPromptHistories(documentPromptHistories);
  }, [documentId, currentUser?.id]);

  useEffect(() => {
    fetchPromptHistories();
  }, [currentUser?.id, fetchPromptHistories]);

  return {
    documentPromptHistories,
    fetchPromptHistories,
  };
};
