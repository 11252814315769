import React, { FC } from "react";

import Link from "next/link";

import { TabItem } from "@spesill/components/atoms";

type PropsType = {
  items: {
    link: string;
    text: string;
  }[];
  currentPage: string;
  className?: string;
};

export const TabSwitcherLinks: FC<PropsType> = ({
  currentPage,
  items,
  className = "",
}: PropsType) => {
  return (
    <div className={`flex items-center ${className}`}>
      {items.map((item) => (
        <Link key={item.text} href={item.link}>
          <TabItem
            text={item.text}
            selected={item.link === currentPage}
            className="px-4 font-bold"
          />
        </Link>
      ))}
    </div>
  );
};
