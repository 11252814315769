import React, { useCallback, useState } from "react";

import dynamic from "next/dynamic";

import {
  Button,
  FormSubmitButton,
  MultiValueType,
  SingleValueType,
  TextArea,
} from "@spesill/components/atoms";
import {
  LabelWithDropdownField,
  ModalWrapper,
} from "@spesill/components/molecules";

import { useInput } from "@spesill/hooks";
import { LearningDocument } from "@spesill/models";

const ExcelSheetViewer = dynamic(
  () =>
    import(
      "@spesill/components/organisms/Modal/CreateDocumentFormExcelModal/ExcelSheetViewer"
    ).then((mod) => mod.ExcelSheetViewer),
  {
    ssr: false,
  },
);

type PropsType = {
  databaseSystemName?: string;
  excelDocuments?: LearningDocument[];
  onClose: () => void;
  onSubmit?: (prompt: string, fileName: string, sheetName: string) => void;
};

export const CreateDocumentFormExcelModal = ({
  databaseSystemName,
  excelDocuments = [],
  onClose,
  onSubmit,
}: PropsType) => {
  const [{ value: prompt, onChange: onChangePrompt }] = useInput("");
  const [excelFile, setExcelFile] = useState<SingleValueType>();
  const [excelFileSelectableValue, _setExcelFileSelectableValue] =
    useState<MultiValueType>(
      excelDocuments.map((file) => {
        return { value: file.id, label: file.systemName };
      }),
    );
  const [excelSheet, setExcelSheet] = useState<SingleValueType>();
  const [selectedDocument, setSelectedDocument] = useState<LearningDocument>();
  const [excelSheetSelectableValue, setExcelSheetSelectableValue] =
    useState<MultiValueType>([]);

  const isExcelFilesEmpty = excelDocuments.length === 0;
  const isFormSelected = excelFile && selectedDocument;

  const onChangeExcelFile = useCallback(
    (newValue: SingleValueType | null) => {
      setExcelFile(newValue);
      const document = excelDocuments.find((doc) => doc.id === newValue?.value);
      setExcelSheetSelectableValue([]);
      setSelectedDocument(document);
    },
    [excelDocuments],
  );

  const onChangeExcelSheet = useCallback((newValue: SingleValueType | null) => {
    setExcelSheet(newValue);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (excelFile && excelSheet) {
      onSubmit?.(prompt, excelFile.label, excelSheet.label);
      onClose();
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="Excelシートから文章作成">
      <form className="space-y-6 overflow-y-auto" onSubmit={handleSubmit}>
        <div className="mt-8">
          {isExcelFilesEmpty ? (
            <div>
              <p className="pb-8">使用するExcelファイルを選択してください</p>
              <div className="h-52 w-full bg-blueGray-0 rounded flex items-center justify-center">
                <p className="text-body-lg text-blueGray-500">
                  {databaseSystemName
                    ? `${databaseSystemName} のデータベースにExcelファイルはありません`
                    : "データベースが選択されていません"}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-8 w-full">
              <div className="flex flex-col gap-8 w-full">
                <div className="flex gap-8">
                  <LabelWithDropdownField
                    labelText="使用するExcelファイルを選択してください"
                    name="excelFile"
                    value={excelFile}
                    options={excelFileSelectableValue}
                    onChange={onChangeExcelFile}
                  />

                  <LabelWithDropdownField
                    labelText="使用するシートを選択してください"
                    name="excelSheet"
                    disabled={!excelFile}
                    value={excelSheet}
                    options={excelSheetSelectableValue}
                    onChange={onChangeExcelSheet}
                  />
                </div>
              </div>
              {isFormSelected && selectedDocument ? (
                <ExcelSheetViewer
                  key={selectedDocument.id}
                  path={selectedDocument.path}
                  setSelectableSheets={setExcelSheetSelectableValue}
                  displaySheetName={excelSheet?.label}
                />
              ) : (
                <div className="h-52 w-full bg-blueGray-0 rounded flex items-center justify-center">
                  <p className="text-body-lg text-blueGray-500 whitespace-pre-wrap">
                    {
                      "使用するExcelファイルとシートを選択したら、\nこちらにプレビューが表示されます。"
                    }
                  </p>
                </div>
              )}
            </div>
          )}

          <div className="w-full bg-blueGray-0 rounded flex flex-col p-4 mt-8">
            <p className="flex gap-2 items-center">
              <span className="text-h5">カスタム命令</span>
              <span className="text-blueGray-500 text-body-lg">(任意)</span>
            </p>

            <div className="mt-3">
              <TextArea
                name="prompt"
                value={prompt}
                placeholder={
                  "記入例） この文書はロボットシステムの見積仕様書です。以下のルールに従って文書の一部を作成してください。\nリストのそれぞれの「項目」は'・ 'で始めるようにしてください。"
                }
                onChange={onChangePrompt}
                rows={6}
              />
            </div>
          </div>

          <hr className="mt-8 border-blueGray-50" />
          <div className="space-x-4 text-right mt-4">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="生成"
              color="primary"
              variant="contained"
              className="text-sm"
              disabled={!excelSheet}
            />
          </div>
        </div>
      </form>
    </ModalWrapper>
  );
};
