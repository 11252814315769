import React, { FC } from "react";
import ReactSelect, {
  Props as ReactSelectProps,
  ActionMeta,
  MenuProps,
  MultiValue,
  SingleValue,
  components,
} from "react-select";

import {
  Icon,
  RequiredHiddenField,
  IconPropsType,
} from "@spesill/components/atoms";

export type OptionType = {
  icon?: IconPropsType;
  value: string;
  label: string;
};

export type SingleValueType = SingleValue<OptionType>;
export type MultiValueType = MultiValue<OptionType>;

type CustomMenuProps = MenuProps<OptionType, false> & {
  customMenu: () => JSX.Element;
};

type PropsType = {
  name: string;
  height?: string;
  width?: string;
  options: MultiValueType;
  value?: SingleValueType;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  isClearable?: boolean;
  onChange: (
    newValue: SingleValueType | null,
    actionMeta: ActionMeta<OptionType>,
  ) => void;
  required?: boolean;
  parentClassName?: string;
  customMenu?: () => JSX.Element;
  customMenuPosition?: "top" | "bottom";
  menuPlacement?: ReactSelectProps["menuPlacement"];
  menuIsOpen?: boolean;
};

export const DropdownField: FC<PropsType> = ({
  name,
  height,
  width,
  options,
  value = null,
  disabled,
  placeholder = "選択してください",
  className = "",
  parentClassName = "",
  customMenuPosition = "top",
  menuPlacement,
  required = false,
  isClearable = true,
  menuIsOpen = undefined,
  customMenu,
  onChange,
}: PropsType) => {
  const CustomMenu = ({ customMenu, ...props }: CustomMenuProps) => {
    return customMenuPosition === "bottom" ? (
      <components.Menu {...props}>
        {props.children}
        {customMenu()}
      </components.Menu>
    ) : (
      <components.Menu {...props}>
        {customMenu()}
        {props.children}
      </components.Menu>
    );
  };
  return (
    <div
      className={`relative ${
        disabled ? "cursor-not-allowed" : ""
      } ${parentClassName}`}
    >
      <ReactSelect
        name={name}
        value={value}
        options={options}
        isDisabled={disabled}
        isSearchable
        isClearable={isClearable}
        placeholder={placeholder}
        className={className}
        menuPlacement={menuPlacement}
        menuIsOpen={menuIsOpen}
        onChange={onChange}
        noOptionsMessage={() => "選択肢がありません"}
        components={
          customMenu
            ? {
                Menu: (menuProps) => (
                  <CustomMenu customMenu={customMenu} {...menuProps} />
                ),
                Option: (props) => (
                  <components.Option {...props}>
                    <div className="flex items-center">
                      {!!props.data.icon && (
                        <div className="mr-2 min-w-5 h-5 flex items-center justify-center">
                          <Icon
                            {...props.data.icon}
                            color={
                              props.isSelected
                                ? "text-primary-400"
                                : "text-blueGray-500"
                            }
                          />
                        </div>
                      )}
                      {props.data.label}
                    </div>
                  </components.Option>
                ),
              }
            : undefined
        }
        styles={{
          control: (provided) => ({
            ...provided,
            height,
            width,
          }),
          valueContainer: (provided) => ({
            ...provided,
            height,
            padding: "0 6px",
          }),
          input: (provided) => ({
            ...provided,
            margin: "0px",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height,
          }),
          menu: (provided) => ({
            ...provided,
            width,
          }),
          menuList: (provided) => ({
            ...provided,
            padding: "8px",
          }),
          option: (provided, state) => ({
            ...provided,
            padding: "8px",
            borderRadius: "2px",
            backgroundColor: state.isSelected ? "#F3F5FA" : "white",
            color: state.isSelected ? "#4262FF" : "#141A31",
          }),
        }}
      />
      {required && <RequiredHiddenField value={value?.value || ""} />}
    </div>
  );
};
