import React, { FC } from "react";

import { IconButton } from "@spesill/components/molecules";

import { MenuComponentList } from "./RightFloatSidebar";
import { MenuNameListType } from "./RightFloatSidebar";

type Props = {
  className?: string;
  setCurrentMenu: (menu: MenuNameListType) => void;
  currentMenu: MenuNameListType;
};

export const RightFloatIcons: FC<Props> = ({
  setCurrentMenu,
  currentMenu,
  className = "",
}) => {
  return (
    <div
      className={`flex flex-col shadow bg-white mr-4 p-2 rounded space-y-1
      ${currentMenu ? "" : "fixed right-0 bottom-4 z-10"} ${className}`}
    >
      {Object.keys(MenuComponentList).map((menuName) => {
        const menu = MenuComponentList[menuName as MenuNameListType];
        if (!menu) return null;

        const isSelected = currentMenu === menuName;
        return (
          <div
            key={menuName}
            className={`relative rounded p-1 ${isSelected && "bg-blueGray-0"}`}
          >
            <IconButton
              icon={{
                icon: menu.icon,
                size: "2rem",
                color: "text-blueGray-500",
              }}
              srOnlyText={menuName}
              onClick={() => setCurrentMenu(menuName as MenuNameListType)}
            />
            {isSelected && (
              <div
                className={
                  "absolute top-1 left-1 w-2 h-2 rounded-full bg-red-500"
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
