import React, { useContext } from "react";
import { BuilderContext, useDrawer } from "react-flow-builder";

import { Button } from "@spesill/components/atoms";
import { LabelWithTextField } from "@spesill/components/molecules";

import { useInput } from "@spesill/hooks";

export const ConfigForm = () => {
  const { selectedNode: node } = useContext(BuilderContext);

  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

  const [{ value: name, onChange: onChangeName }] = useInput(node?.name || "");

  const handleSubmit = async () => {
    try {
      save?.({ ...node, name });
    } catch (error) {
      save?.({ ...node, name }, !!error);
    }
  };

  return (
    <div>
      <LabelWithTextField
        labelText="名前"
        type="text"
        name="name"
        required
        autoComplete="name"
        value={name}
        onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onChangeName(e);
        }}
      />
      <div className="flex justify-end space-x-2 mt-4">
        <Button
          onClick={() => cancel()}
          text={"キャンセル"}
          color={"gray"}
          variant={"contained"}
        />
        <Button
          text="保存"
          color="primary"
          variant="contained"
          className="text-sm"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
};
