import React, { FC, ComponentProps } from "react";

import Link from "next/link";

import { Icon } from "@spesill/components/atoms";

type PropsType = {
  titles: {
    text: string;
    link?: string;
  }[];
  className?: string;
  icon?: ComponentProps<typeof Icon>;
};

export const PageTitle: FC<PropsType> = ({ titles, icon, className = "" }) => {
  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      {icon && <Icon {...icon} />}
      {titles.map(({ text, link }, index) => (
        <div
          key={index}
          className="flex items-center space-x-2 font-medium text-left"
        >
          {link ? (
            <Link href={link} className="text-primary-400 text-2xl">
              <h1>{text}</h1>
            </Link>
          ) : (
            <h1 className={"text-blueGray-900 text-2xl"}>{text}</h1>
          )}
          {index !== titles.length - 1 && <span>/</span>}
        </div>
      ))}
    </div>
  );
};
