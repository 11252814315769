import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import React from "react";

import { PunchGridLayoutEditor } from "@spesill/components/organisms/Punch/PunchLayoutEditor/PunchLayoutEditor";

import { PunchItemsType } from "@spesill/models/punchLayout";

export default function TiptapPunchLayoutComponent({
  node: {
    attrs: { state, learningDatabaseId },
  },
  updateAttributes,
}: {
  node: {
    attrs: {
      state: string;
      learningDatabaseId?: string;
    };
  };
  updateAttributes: NodeViewProps["updateAttributes"];
}) {
  const onUpdateState = (state: {
    width: number;
    height: number;
    punchItems: PunchItemsType[];
  }) => {
    updateAttributes({
      state: JSON.stringify(state),
    });
  };
  const { width, height, punchItems } = JSON.parse(state);
  return (
    <NodeViewWrapper>
      <PunchGridLayoutEditor
        width={width}
        height={height}
        punchItems={punchItems}
        learningDatabaseId={learningDatabaseId}
        onUpdateState={onUpdateState}
      />
    </NodeViewWrapper>
  );
}
