import React from "react";

type Status = "danger" | "warning" | "success";

const StatusToStyleMap: Record<Status, string> = {
  warning: "bg-status-warning",
  danger: "bg-status-danger",
  success: "bg-status-success",
};

type Props = {
  status: Status;
  text: string;
};

export const Alert = ({ status, text }: Props) => (
  <div className={`px-4 py-2 ${StatusToStyleMap[status]}`}>{text}</div>
);
