import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { DocumentViewerRepository } from "@spesill/repository/db/documentViewer.repository";

export const useUpsertDocumentViewers = (documentId: string) => {
  const { currentUser } = useCurrentUser();

  const viewDocumentHistory = async () => {
    if (!currentUser?.tenantId) return;

    const documentViewerRepository = new DocumentViewerRepository();

    await documentViewerRepository.upsert(
      currentUser.tenantId,
      documentId,
      currentUser.id,
    );

    return;
  };

  return {
    viewDocumentHistory,
  };
};
