import React from "react";

import { SingleValueType } from "@spesill/components/atoms";

import { Document } from "@spesill/models";

import { TiptapDocumentWordEditor } from "./tiptap/TiptapDocumentWordEditor";

type PropsType = {
  document: Document;
  displayHtml: string;
  isEditable?: boolean;
  handleUploadFile: (html: string) => Promise<void>;
  dropdownValueLearningDatabase?: SingleValueType;
  isFreePlan: boolean;
  fileName: string;
};

export const DocumentWordEditor: React.FC<PropsType> = ({
  document,
  displayHtml,
  isEditable,
  handleUploadFile,
  dropdownValueLearningDatabase,
  isFreePlan,
  fileName,
}) => {
  return isEditable ? (
    <>
      <TiptapDocumentWordEditor
        html={displayHtml}
        handleUploadFile={handleUploadFile}
        currentDatabaseValue={dropdownValueLearningDatabase}
        isEditor
        isFreePlan={isFreePlan}
        fileName={fileName}
        document={document}
      />
    </>
  ) : (
    <TiptapDocumentWordEditor
      html={displayHtml}
      document={document}
      isEditor={false}
    />
  );
};
