import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import {
  Button,
  DropdownField,
  FormSubmitButton,
  SingleValueType,
} from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import {
  useSelectLearningDatabaseDropdownField,
  useUpdateChatRoom,
} from "@spesill/hooks";
import { ChatRoom } from "@spesill/models";

type PropsType = {
  chatRoom: ChatRoom;
  currentDatabaseValue?: SingleValueType;
  onClose: () => void;
  onSuccess: () => void;
};
export const UpdateChatLearningDatabaseModal = ({
  chatRoom,
  onClose,
  onSuccess,
  currentDatabaseValue,
}: PropsType) => {
  const {
    dropdownValue: dropdownValueLearningDatabase,
    dropdownSelectableValue: dropdownSelectableValueLearningDatabase,
    onChange: onChangeDropdownLearningDatabase,
  } = useSelectLearningDatabaseDropdownField(currentDatabaseValue);
  const { updateChatRoom, isRequesting } = useUpdateChatRoom(chatRoom.id);

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!dropdownValueLearningDatabase?.value) {
      toast.error("データベースを選択してください。");
      return;
    }
    try {
      await updateChatRoom({
        chatRoom: {
          ...chatRoom,
          learningDatabaseId: dropdownValueLearningDatabase.value,
        } as ChatRoom,
        onSuccess,
      });

      toast.success("データベースの変更に成功しました。");
      onClose();
    } catch (e) {
      toast.error("データベースの変更に失敗しました。");
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="データベース設定">
      <div>
        <form
          onSubmit={handleUpdate}
          className="mt-6 space-y-6 flex flex-col justify-between"
        >
          <div className="w-full">
            <DropdownField
              value={dropdownValueLearningDatabase}
              options={dropdownSelectableValueLearningDatabase}
              onChange={onChangeDropdownLearningDatabase}
              required
              name={"learningDatabase"}
            />
          </div>
          <div className="space-x-4 text-right pt-48">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="保存"
              color="primary"
              variant="contained"
              disabled={isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
