import { useState, useCallback, useEffect } from "react";

import { MultiValueType, SingleValueType } from "@spesill/components/atoms";

import { DisplayDocumentKind, DocumentKind } from "@spesill/models/document";

type ReturnType = {
  dropdownValue: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (newValue: SingleValueType) => void;
};

const documentKindList = Object.keys(DisplayDocumentKind).map((key) => {
  return {
    value: key,
    label: DisplayDocumentKind[key as DocumentKind],
  };
});

export const useDocumentTypeDropDownField = (
  documentKind?: DocumentKind,
): ReturnType => {
  const [value, setValue] = useState<SingleValueType>({
    value: "meetingMinutes",
    label: DisplayDocumentKind.meetingMinutes,
  });

  const [dropdownSelectableValue] = useState<MultiValueType>(documentKindList);

  const onChangeDropDown = useCallback((newValue: SingleValueType) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    if (!documentKind) return;
    setValue({
      value: documentKind,
      label: DisplayDocumentKind[documentKind],
    });
  }, [documentKind]);

  return {
    dropdownValue: value,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
  };
};
