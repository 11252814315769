import React from "react";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import { LabelWithCheckbox } from "@spesill/components/molecules";

import { useLearningDatabasesWithUser } from "@spesill/hooks";
import { LearningDatabase } from "@spesill/models";

type PropsType = {
  selectedDB: LearningDatabase[];
  onChangeDB: (learningDatabase: LearningDatabase) => void;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
  onSkip: () => void;
  onCreateDB: () => void;
  isLoading?: boolean;
};

export const SelectDB = ({
  selectedDB,
  onChangeDB,
  onClose,
  onBack,
  onSubmit,
  onSkip,
  onCreateDB,
  isLoading,
}: PropsType) => {
  const { learningDatabases } = useLearningDatabasesWithUser();
  return (
    <form onSubmit={onSubmit}>
      <h4 className="text-h4 text-blueGray-900 px-3">文章を作成</h4>
      <div className="pt-8 px-3">
        <div className="flex flex-row justify-between items-center pb-6">
          <h5 className="text-lg font-bold text-blueGray-900">
            参考にしたいデータはありますか？
          </h5>
          <button
            type="button"
            className="link"
            onClick={onSkip}
            disabled={isLoading}
          >
            スキップ
          </button>
        </div>
        <div className="mb-6">
          <Button
            icon={{
              icon: "aiOutlinePlus",
              color: "text-white",
              size: "1.5rem",
            }}
            text={"学習データベースを作成"}
            color={"primary"}
            variant={"contained"}
            onClick={onCreateDB}
          />
        </div>
        <div className="pb-8 flex flex-col gap-y-2 max-h-96 overflow-y-auto">
          {learningDatabases.map((learningDatabase) => (
            <LabelWithCheckbox
              key={learningDatabase.id}
              labelText={learningDatabase.systemName}
              name={learningDatabase.id}
              checked={selectedDB.includes(learningDatabase)}
              labelEnd
              onChange={() => onChangeDB(learningDatabase)}
              className={`px-4 py-2.5 rounded-md ${selectedDB.includes(learningDatabase) ? "border-primary-800 border-2" : "border border-blueGray-50"}`}
              labelClassName="font-bold text-sm ml-4"
              disabled={isLoading}
            />
          ))}
        </div>
      </div>
      <div className="-translate-x-6 w-[calc(100%+3rem)] px-4 pt-4 border-t border-t-blueGray-50">
        <div className="flex justify-between items-center">
          {isLoading ? (
            <p className="text-blueGray-500 text-sm font-medium animate-pulse">
              生成中...
            </p>
          ) : (
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
          )}
          <div className="flex flex-row space-x-4">
            <Button
              text={"戻る"}
              color={"gray"}
              variant={"contained"}
              onClick={onBack}
              disabled={isLoading}
            />
            <FormSubmitButton
              text={"次へ"}
              color={"primary"}
              variant={"contained"}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
