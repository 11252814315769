import { auth as authOrigin } from "@spesill/config/firebase";
import {
  Auth,
  EmailAuthProvider,
  FirebaseError,
  reauthenticateWithCredential,
  updateEmail,
  updatePassword,
} from "@spesill/libs/firebase";

export class AuthRepository {
  private auth: Auth;

  constructor() {
    this.auth = authOrigin;
  }

  /**
   * パスワードを更新する
   */
  changePassword = async (oldPassword: string, newPassword: string) => {
    const user = this.auth.currentUser;
    const credential = this.getCredentialByEmailAuth(oldPassword);

    if (!user) {
      throw new Error(
        "問題が発生しました。恐れ入りますが、もう一度ログインし直してください。",
      );
    }

    /**
     * セキュリティ上重要な操作を実施する前に、直近ログインする必要があるため、再認証する
     * @see https://firebase.google.com/docs/auth/web/manage-users?hl=ja#re-authenticate_a_user
     */
    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
    } catch (e) {
      if (e instanceof FirebaseError) {
        const errorMessage = this.getErrorMessage(e.code);
        throw new Error(errorMessage);
      } else {
        throw e;
      }
    }
  };

  /**
   * メールアドレスを更新する
   */
  changeEmail = async (password: string, newEmail: string) => {
    const user = this.auth.currentUser;
    const credential = this.getCredentialByEmailAuth(password);

    if (!user) {
      throw new Error(
        "問題が発生しました。恐れ入りますが、もう一度ログインし直してください。",
      );
    }

    /**
     * セキュリティ上重要な操作を実施する前に、直近ログインする必要があるため、再認証する
     * @see https://firebase.google.com/docs/auth/web/manage-users?hl=ja#re-authenticate_a_user
     */
    try {
      await reauthenticateWithCredential(user, credential);
      await updateEmail(user, newEmail);
    } catch (e) {
      if (e instanceof FirebaseError) {
        const errorMessage = this.getErrorMessage(e.code);
        throw new Error(errorMessage);
      } else {
        throw e;
      }
    }
  };

  private getCredentialByEmailAuth = (password: string) => {
    const user = this.auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user?.email ?? "",
      password,
    );

    return credential;
  };

  private getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      // パスワードポリシーはアプリケーションで制御するしかないが、最低限6文字以上というチェックだけされる
      case "auth/weak-password":
        return "半角英数字6文字以上で入力してください";
      case "auth/wrong-password":
        return "パスワードが間違っています";
      case "auth/too-many-requests":
        return "認証の失敗回数が多すぎます。後でもう一度やり直してください。";
      case "auth/email-already-exists":
        return "このメールアドレスは既に使用されています";
      case "auth/invalid-email":
        return "メールアドレスの形式が正しくありません";
      case "auth/user-not-found":
        return "ユーザーが見つかりませんでした";
      default:
        return "処理に失敗しました";
    }
  };
}
