import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import {
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import {
  useCreateEvaluateDatabase,
  useInput,
  useTenantLimitManager,
} from "@spesill/hooks";

type PropsType = {
  defaultSystemName?: string;
  onClose: () => void;
  onSuccess?: (id: string) => void;
};
export const CreateEvaluateDatabaseModal = ({
  defaultSystemName = "",
  onClose,
  onSuccess,
}: PropsType) => {
  const [{ value: systemName, onChange: onChangeSystemName }] =
    useInput(defaultSystemName);
  const { createEvaluateDatabase, isRequesting } = useCreateEvaluateDatabase();
  const { isUploadableDatabases } = useTenantLimitManager();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const canAddEvaluateDatabase = await isUploadableDatabases(1);

    if (!canAddEvaluateDatabase) {
      toast.error(
        "データベース数がプランの上限に達しています。 プランをアップグレードしてください。",
      );
      return;
    }
    const isCreating = toast.loading("データベースを作成しています。");

    try {
      const evaluateDatabase = await createEvaluateDatabase({
        systemName,
        onSuccess: (id) => {
          onClose();
          onSuccess && onSuccess(id);
          toast.update(isCreating, {
            render: "データベースの作成が完了しました。",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
        },
      });
      if (!evaluateDatabase) {
        toast.error("学習データベースの作成に失敗しました。");
      }
    } catch (e) {
      toast.dismiss(isCreating);
      toast.error(
        e instanceof Error
          ? e.message
          : "学習データベースの作成に失敗しました。",
      );
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="評価データベース作成">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <LabelWithTextField
            labelText="データベース名"
            type="text"
            name="systemName"
            required
            placeholder="データベース名を入力してください。"
            value={systemName}
            onChange={onChangeSystemName}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="保存"
              color="primary"
              variant="contained"
              disabled={isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
