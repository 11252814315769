import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { SharedDocument } from "@spesill/models";
import { SharedDocumentRepository } from "@spesill/repository/db/sharedDocument.repository";

const sharedDocumentRepository = new SharedDocumentRepository();

export const useSharedDocument = (documentId: string) => {
  const [sharedDocument, setSharedDocument] = useState<SharedDocument>();
  const fetchSharedDocument = useCallback(async () => {
    const docs = await sharedDocumentRepository.findByDocumentId(documentId);

    if (docs.length < 1) {
      setSharedDocument(undefined);
      return;
    } else {
      setSharedDocument(docs[0]);
    }
    return docs[0];
  }, [documentId]);

  const deleteSharedDocument = useCallback(async () => {
    if (!sharedDocument) {
      toast.error("ドキュメントが見つかりませんでした");
      return;
    }

    await sharedDocumentRepository.deleteById(sharedDocument.id);
  }, [sharedDocument]);

  useEffect(() => {
    fetchSharedDocument();
  }, [fetchSharedDocument]);

  return {
    fetchSharedDocument,
    deleteSharedDocument,
    sharedDocument,
  };
};
