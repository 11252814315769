import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

import { useCurrentUser } from "@spesill/hooks";
import { useInput } from "@spesill/hooks";
import { DisplayRole, User } from "@spesill/models";
import { UserRepository } from "@spesill/repository/db/user.repository";

export type RoleFilterType = keyof typeof DisplayRole;
const userRepository = new UserRepository();

export const useFetchUsers = () => {
  const { currentUser } = useCurrentUser();
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<RoleFilterType>();
  const [loading, setLoading] = useState(false);
  const [{ value: keyword, onChange: onChangeKeyword }] = useInput("");

  const filterUsers = useCallback(
    (role: RoleFilterType) => {
      const newFilteredUsers = users.filter((user) => user.role === role);
      setFilteredUsers(newFilteredUsers);
    },
    [users],
  );

  const usersByKeyword = useCallback(
    (value: string) => {
      const newFilteredUsers = users.filter(
        (user) => user.fullName.includes(value) || user.email.includes(value),
      );
      setFilteredUsers(newFilteredUsers);
    },
    [users],
  );

  const fetchUsers = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    setLoading(true);
    try {
      const users = await userRepository.findByTenantId(currentUser.tenantId);
      setUsers(users);
      setFilteredUsers(users);
    } catch (e) {
      toast.error("ユーザーの取得に失敗しました");
    } finally {
      setLoading(false);
    }
  }, [currentUser?.tenantId]);

  useEffect(() => {
    fetchUsers();
  }, [currentUser?.tenantId, fetchUsers]);

  const onChangeFilter = useCallback(
    (filter: RoleFilterType) => {
      filterUsers(filter);
      setSelectedFilter(filter);
    },
    [filterUsers],
  );

  const onResetFilter = () => {
    setSelectedFilter(undefined);
    setFilteredUsers(users);
  };

  const handleChangedKeyword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeKeyword(e);
      usersByKeyword(e.target.value);
    },
    [onChangeKeyword, usersByKeyword],
  );

  return {
    users,
    filteredUsers,
    selectedFilter,
    onChangeFilter,
    onResetFilter,
    keyword,
    onChangeKeyword: handleChangedKeyword,
    fetchUsers,
    loading,
  };
};
