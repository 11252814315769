import React, { FC, MouseEvent } from "react";
import { toast } from "react-toastify";

import { Button, Checkbox } from "@spesill/components/atoms";
import { LabelWithCheckbox } from "@spesill/components/molecules";
import { RightFloatMenusWrapper } from "@spesill/components/organisms";

import {
  useArray,
  useBoolean,
  useCurrentUser,
  usePostsNotifications,
} from "@spesill/hooks";
import { formatTimestamp } from "@spesill/libs";
import {
  PostsNotification,
  UsersPostsNotificationStatus,
} from "@spesill/models";

import { PostsNotificationTitle } from "./PostsNotificationTitle";

type PropsType = {
  onClose: () => void;
  className?: string;
};

type MenusType = "all" | "myself";

export type PostsNotificationsReadType = ExcludeMethods<PostsNotification> & {
  readAt: Date | null;
};

const CurrentMenuInfo = {
  all: (notifications: PostsNotificationsReadType[]) => {
    const postsNotifications = notifications.filter(
      (postsNotification) => postsNotification.scopeTarget === "all",
    );
    const unreadPostsNotifications = postsNotifications.filter(
      (postsNotification) => !postsNotification.readAt,
    );
    return {
      title: "全体通知",
      count: unreadPostsNotifications.length,
      postsNotifications: postsNotifications,
    };
  },

  myself: (notifications: PostsNotificationsReadType[]) => {
    const postsNotifications = notifications.filter(
      (postsNotification) => postsNotification.scopeTarget === "myself",
    );
    const unreadPostsNotifications = postsNotifications.filter(
      (postsNotification) => !postsNotification.readAt,
    );
    return {
      title: "自分の通知",
      count: unreadPostsNotifications.length,
      postsNotifications: postsNotifications,
    };
  },
};

export const PostsNotifications: FC<PropsType> = ({
  onClose,
  className = "",
}) => {
  const { currentUser } = useCurrentUser();
  const { fetchPostsNotifications, postsNotifications } =
    usePostsNotifications();

  const [currentMenu, setCurrentMenu] = React.useState<MenusType>("all");

  const { isChecked, onChange: onChangeChecked, setFalse } = useBoolean(false);

  const {
    items: checkedItems,
    setItems: setCheckedItems,
    pushItem: addCheckedItem,
    findAndRemove: removeCheckedItem,
  } = useArray<string>();

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const alreadyRead = CurrentMenuInfo[currentMenu](postsNotifications)
      .postsNotifications.filter(
        (postsNotification) => postsNotification.readAt,
      )
      .find((postsNotification) => postsNotification.id === e.target.value);

    if (alreadyRead) {
      toast.error("すでに既読になっている通知は選択できません。");
      return;
    }

    if (e.target.checked) {
      addCheckedItem(e.target.value);
      return;
    }

    removeCheckedItem((item) => item === e.target.value);
  };

  const handleChangeCurrentMenu = (menu: MenusType) => {
    setCurrentMenu(menu);

    setCheckedItems([]);
    setFalse();
  };

  const handleBulkRead = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("選択した通知を既読にしますか？");

    if (!isConfirm || !currentUser) return;

    try {
      await UsersPostsNotificationStatus.bulkRead({
        userId: currentUser.id,
        postsNotificationIds: checkedItems,
      });

      fetchPostsNotifications();
      toast.success("通知を既読にしました。");
    } catch (error) {
      toast.error("通知の既読に失敗しました。");
    }
  };

  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeChecked(e);
    if (!e.target.checked) {
      setCheckedItems([]);
      return;
    }

    setCheckedItems(
      CurrentMenuInfo[currentMenu](postsNotifications)
        .postsNotifications.filter(
          (postsNotification) => !postsNotification.readAt,
        )
        .map((postsNotification) => postsNotification.id),
    );
  };

  return (
    <>
      <RightFloatMenusWrapper
        onClose={onClose}
        menus={[
          {
            text: "全体",
            count: CurrentMenuInfo["all"](postsNotifications).count,
            onClick: () => handleChangeCurrentMenu("all"),
          },
          {
            text: "自分",
            count: CurrentMenuInfo["myself"](postsNotifications).count,
            onClick: () => handleChangeCurrentMenu("myself"),
          },
        ]}
        currentMenuText={currentMenu === "all" ? "全体" : "自分"}
        className={className}
      >
        <div className="m-6 flex flex-col h-full overflow-scroll">
          <div>
            <div className="flex items-center justify-between">
              <h6 className="text-h6 text-blueGray-900">
                {CurrentMenuInfo[currentMenu](postsNotifications).title}
              </h6>
              {CurrentMenuInfo[currentMenu](postsNotifications).count > 0 ? (
                <LabelWithCheckbox
                  labelText="すべてにチェック"
                  name="allCheck"
                  checked={isChecked}
                  value="allCheck"
                  onChange={handleAllCheck}
                  labelEnd
                />
              ) : null}
            </div>
            <p className="text-caption text-blueGray-900 mt-6">
              {CurrentMenuInfo[currentMenu](postsNotifications).count > 0
                ? `${
                    CurrentMenuInfo[currentMenu](postsNotifications).count
                  }件の通知が未確認です。内容を確認してチェックを入れてください。`
                : "未確認の通知はありません。"}
            </p>
          </div>
          <div className="mt-6 grow overflow-scroll">
            {CurrentMenuInfo[currentMenu](
              postsNotifications,
            ).postsNotifications.map((postsNotification, index) => (
              <div
                key={postsNotification.id}
                className={`py-4 flex items-center gap-x-2 border-b border-solid border-blueGray-50 ${
                  index === 0 && "border-t"
                }`}
              >
                <Checkbox
                  size="md"
                  name={postsNotification.id}
                  checked={
                    !!postsNotification.readAt ||
                    checkedItems.includes(postsNotification.id)
                  }
                  value={postsNotification.id}
                  disabled={!!postsNotification.readAt}
                  onChange={postsNotification.readAt ? undefined : handleCheck}
                />
                <div className="w-full">
                  <PostsNotificationTitle
                    postsNotification={postsNotification}
                  />
                  <div className="mt-2 flex justify-between items-center">
                    <span className="text-caption text-blueGray-300">
                      {formatTimestamp(postsNotification.createdAt)}
                    </span>
                    {postsNotification.readAt ? (
                      <span className="text-xs bg-blueGray-50 text-blueGray-700 rounded-full px-2.5 py-1.5">
                        確認済
                      </span>
                    ) : (
                      <span className="text-xs bg-status-danger text-white rounded-full px-2.5 py-1.5">
                        未確認
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="border-t border-solid border-blueGray-50 pt-4 flex justify-end gap-x-4">
            <Button
              text="キャンセル"
              color="gray"
              variant="contained"
              onClick={() => onClose()}
            />
            <Button
              text={`${checkedItems.length > 0 ? "確認済みにする" : "保存"}`}
              color="primary"
              variant="contained"
              disabled={checkedItems.length < 1}
              onClick={handleBulkRead}
            />
          </div>
        </div>
      </RightFloatMenusWrapper>
    </>
  );
};
