import { v4 } from "uuid";

import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  where,
  collection,
  getDocs,
  query,
} from "@spesill/libs/firebase";
import { NewUserInvitation } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class NewUserInvitationRepository extends DBRepositoryBase<
  ExcludeMethods<NewUserInvitation>
> {
  private PATH = "newUserInvitations";

  add = async (
    newUserInvitation: Omit<
      ExcludeMethods<NewUserInvitation>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const docId = v4();
    const newNewUserInvitation = {
      ...newUserInvitation,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(
      doc(this.db, this.PATH, docId),
      this.objectToDoc(newNewUserInvitation),
    );

    return new NewUserInvitation({ ...newNewUserInvitation, id: docId });
  };
  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("招待が見つかりませんでした");
    } else {
      return new NewUserInvitation(this.docToObject(document));
    }
  };

  deleteByReceiverUserId = async (userId: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("receiverUserId", "==", userId),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs[0]) {
      throw new Error("招待が見つかりませんでした");
    } else {
      await deleteDoc(docs[0].ref);
    }
  };
}
