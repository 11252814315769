import React, { ComponentProps, FC } from "react";

import Link from "next/link";

import {
  Alert,
  Button,
  CountBudge,
  Icon,
  InitialNameIcon,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";
import {
  GenerateDocumentButton,
  PostsNotifications,
  UploadDocumentFormatModal,
} from "@spesill/components/organisms";

import {
  useBoolean,
  useCurrentUser,
  useIntervalUnreadPostsNotifications,
} from "@spesill/hooks";

type PropsType = {
  className?: string;
  alert?: ComponentProps<typeof Alert>;
};

export const Header: FC<PropsType> = ({ className = "", alert }) => {
  const { currentUser } = useCurrentUser();

  const {
    isChecked: isUploadDocumentModalOpen,
    setTrue: setUploadDocumentModalOpen,
    setFalse: setUploadDocumentModalClose,
  } = useBoolean(false);

  const {
    isChecked: isdNotificationModalOpen,
    setTrue: setNotificationModalOpen,
    setFalse: setNotificationModalClose,
  } = useBoolean(false);

  const { unreadPosts } = useIntervalUnreadPostsNotifications();

  return (
    <header
      className={`sticky top-0 bg-white border-b border-solid z-10 border-blueGray-50 ${className}`}
    >
      <div className="flex items-center justify-end p-3">
        <div className="flex items-center space-x-4">
          <Link
            href="/settings/profile"
            className="hover:bg-blueGray-50 rounded "
          >
            <Icon
              icon="aiOutlineSetting"
              size="1.5rem"
              color="text-blueGray-400"
            />
          </Link>
          <div className="relative flex hover:bg-blueGray-50 rounded">
            <IconButton
              icon={{
                icon: "aiOutlineBell",
                size: "1.5rem",
                color: "text-blueGray-400",
              }}
              onClick={setNotificationModalOpen}
            />
            {unreadPosts.length > 0 && (
              <CountBudge
                count={unreadPosts.length}
                className="absolute -top-2 -right-2"
              />
            )}
          </div>

          <GenerateDocumentButton />
          <Button
            text="フォーマットファイルを読込"
            color="primary"
            variant="contained"
            className="text-sm"
            onClick={() => setUploadDocumentModalOpen()}
          />
          <Link href="/settings/profile">
            <InitialNameIcon
              className="shrink-0"
              name={currentUser?.fullName || ""}
            />
          </Link>
        </div>
        {isUploadDocumentModalOpen && (
          <UploadDocumentFormatModal onClose={setUploadDocumentModalClose} />
        )}
        {isdNotificationModalOpen && (
          <PostsNotifications onClose={setNotificationModalClose} />
        )}
      </div>
      {alert && <Alert {...alert} />}
    </header>
  );
};
