import { useState, useEffect } from "react";

import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { TenantRepository } from "@spesill/repository/db/tenant.repository";

const tenantRepository = new TenantRepository();
export const useTenantUserRestriction = () => {
  const [canAddUser, setCanAddUser] = useState<boolean>(false);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const checkCanAddUser = async () => {
      if (!currentUser) return;
      const tenant = await tenantRepository.findById(currentUser.tenantId);
      const result = await tenant.canAddUser();
      setCanAddUser(result);
    };

    checkCanAddUser();
  }, [currentUser]);

  return { canAddUser };
};
