export type ButtonColorType = "primary" | "gray" | "red" | "planning";
export type ButtonVariantType = "outlined" | "contained" | "text";
export type ButtonSizeType = "small" | "medium" | "large";

export const ButtonColor: Record<
  ButtonVariantType,
  Record<ButtonColorType, string>
> = {
  contained: {
    primary:
      "text-white border border-solid bg-primary-400 hover:bg-primary-900",
    gray: "text-blueGray-900 border border-solid bg-blueGray-50 hover:bg-blueGray-100",
    red: "text-white border border-solid bg-red-500 hover:bg-red-600",
    planning: "text-white bg-planning-500 hover:bg-planning-600",
  },
  outlined: {
    primary:
      "border border-solid bg-white border-primary-400 hover:bg-primary-100 text-primary-400",
    gray: "border border-solid bg-white border-blueGray-500 hover:bg-blueGray-100 text-blueGray-600",
    red: "border border-solid bg-white border-red-500 hover:bg-red-100 text-red-600",
    planning:
      "border border-solid bg-white border-planning-500 hover:bg-planning-200 text-planning-500",
  },
  text: {
    primary: "text-primary-400 hover:bg-primary-100",
    gray: "text-blueGray-500 hover:bg-blueGray-100",
    red: "text-red-500 hover:bg-red-100",
    planning: "text-planning-500 hover:bg-planning-200",
  },
};

export const ButtonSize: Record<ButtonSizeType, string> = {
  small: "px-2 py-1 text-sm",
  medium: "px-4 py-2.5 text-base",
  large: "px-6 py-3 text-lg",
};
