import { v4 } from "uuid";

import { Entity } from "./__common__/entity";

export class DocumentPromptHistory extends Entity {
  id: string;
  userId: string;
  documentId: string;
  tenantId: string;
  customPrompt: string;

  constructor(arg: ExcludeMethods<DocumentPromptHistory>) {
    super(arg);

    this.id = arg.id;
    this.documentId = arg.documentId;
    this.userId = arg.userId;
    this.tenantId = arg.tenantId;
    this.customPrompt = arg.customPrompt;
  }

  static create(
    args: Omit<ConstructorParameters<typeof DocumentPromptHistory>[0], "id"> & {
      userId: string;
    },
  ) {
    return new DocumentPromptHistory({
      id: v4(),
      ...args,
    });
  }
}
