import DOMPurify from "dompurify";
import mammoth from "mammoth";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { DocumentLocationType } from "@spesill/models/source";

type ReturnType = {
  convertToHtml: (
    file: File,
    location?: DocumentLocationType,
    source?: string,
  ) => void;
  html?: string;
  convertResultMessages?: MammothMessageType[];
  insertHtml: ({ matchHTMLElement, insertText }: InsertHtmlPropsType) => void;
  setHtml: (html: string | undefined) => void;
};

type MammothMessageType = {
  type: string;
  message: string;
};

type InsertHtmlPropsType = {
  matchHTMLElement: HTMLElement;
  insertText: string;
};

const UNSUPPORTED_MEDIA_TYPES = ["data:image/x-emf;base64"];

const highlightLine = (
  html: string,
  location?: { line_no_start: number; line_no_end: number },
  source?: string,
): string => {
  if (!location && !source) {
    return html;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  if (location) {
    highlightByLineNumber(doc, location.line_no_start, location.line_no_end);
  }

  if (source) {
    highlightBySourceText(doc, source);
  }

  return doc.body.innerHTML;
};

const highlightByLineNumber = (doc: Document, start: number, end: number) => {
  let line = 0;
  const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (node) =>
      node.nodeName.match(/^(P|H[1-5]|TR)$/)
        ? NodeFilter.FILTER_ACCEPT
        : NodeFilter.FILTER_SKIP,
  });

  let node;
  while ((node = walker.nextNode())) {
    line++;
    if (line >= start && line <= end) {
      (node as Element).classList.add("highlight");
    }
  }
};

const highlightBySourceText = (doc: Document, source: string) => {
  const sourceLines = source
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0);

  // マークダウンテーブル解析
  const markdownTables = parseMarkdownTables(source);

  // テキストノードのハイライト
  for (const line of sourceLines) {
    const walker = document.createTreeWalker(
      doc.body,
      NodeFilter.SHOW_TEXT,
      null,
    );
    let textNode;

    while ((textNode = walker.nextNode())) {
      const nodeContent = textNode.textContent!.trim();
      if (isExactMatch(nodeContent, line)) {
        highlightTextNode(textNode, line);
      }
    }
  }

  // HTMLテーブルのハイライト
  highlightHtmlTables(doc, markdownTables);
};

const highlightHtmlTables = (doc: Document, markdownTables: string[][]) => {
  const tables = doc.querySelectorAll("table");
  if (tables.length === 0) return;

  for (const table of tables) {
    const rows = table.querySelectorAll("tr");
    for (const [rowIndex, row] of rows.entries()) {
      const cells = row.querySelectorAll("th, td");
      for (const [cellIndex, cell] of cells.entries()) {
        const cellContent = cell.textContent?.trim() ?? "";

        // Detailed guard check
        const markdownRow = markdownTables[rowIndex];
        if (markdownRow && markdownRow[cellIndex] !== undefined) {
          if (isExactMatch(cellContent, markdownRow[cellIndex])) {
            table.classList.add("highlight"); // tr要素をハイライト
            break; // 内側のループを終了して次のtr要素へ
          }
        }
      }
    }
  }
};

const highlightTextNode = (node: Node, source: string) => {
  const parent = node.parentNode as Element;
  const regex = new RegExp(escapeRegExp(source), "gi");
  const parts = [];
  let lastIndex = 0;

  const matches = Array.from(node.textContent!.matchAll(regex));
  if (matches.length === 0) return;

  matches.forEach((match) => {
    parts.push(
      document.createTextNode(node.textContent!.slice(lastIndex, match.index!)),
    );
    const p = document.createElement("p");
    p.className = "highlight";
    p.textContent = node.textContent!.slice(
      match.index!,
      match.index! + match[0].length,
    );
    parts.push(p);
    lastIndex = match.index! + match[0].length;
  });
  parts.push(document.createTextNode(node.textContent!.slice(lastIndex)));

  const fragment = document.createDocumentFragment();
  parts.forEach((part) => fragment.appendChild(part));
  parent.replaceChild(fragment, node);
};

const isExactMatch = (text: string, source?: string): boolean => {
  return text === source;
};

const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

// マークダウンテーブルを解析する関数
const parseMarkdownTables = (source: string): string[][] => {
  const rows = source
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
  const table: string[][] = [];

  for (const row of rows) {
    if (row.startsWith("|")) {
      const cells = row
        .split("|")
        .map((cell) => cell.trim())
        .filter((cell) => cell.length > 0);
      table.push(cells);
    }
  }
  return table;
};

export const useWordDocumentsConvertToHtml = (): ReturnType => {
  const [html, setHtml] = useState<string | undefined>();
  const [convertResultMessages, setConvertResultMessages] =
    useState<MammothMessageType[]>();

  // 未対応の属性を持つタグを削除するの関数
  const sanitizeHtml = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const imgTags = Array.from(doc.getElementsByTagName("img"));

    // imgタグのsrc属性が未対応のメディアタイプを含んでいる場合、そのタグを削除
    imgTags.forEach((tag) => {
      if (UNSUPPORTED_MEDIA_TYPES.some((type) => tag?.src.includes(type))) {
        tag.parentNode?.removeChild(tag);
      }
    });

    const sanitizedHtml = doc.documentElement.outerHTML;
    return sanitizedHtml;
  };

  const convertToHtml = useCallback(
    (file: File, location?: DocumentLocationType, source?: string) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        mammoth
          .convertToHtml({ arrayBuffer })
          .then((result) => {
            const highlightedHtml = highlightLine(
              result.value,
              location,
              source,
            );
            const html = DOMPurify.sanitize(highlightedHtml);
            const replacedHtml = html
              .replace(/☐/g, "<input type='checkbox' />")
              .replace(/☑/g, "<input type='checkbox' checked />")
              .replace(/☒/g, "<input type='checkbox' checked />");
            const sanitizedHtml = sanitizeHtml(replacedHtml);
            setHtml(sanitizedHtml);
            setConvertResultMessages(result.messages);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      };

      reader.readAsArrayBuffer(file);
    },
    [],
  );

  const insertHtml = ({
    matchHTMLElement,
    insertText,
  }: InsertHtmlPropsType) => {
    // NOTE: *などが含まれていると、正規表現の*として認識されてしまうため、エスケープする
    const matchHTMLElementOuterHTML = escapeRegExp(matchHTMLElement.outerHTML);
    const match = html?.match(matchHTMLElementOuterHTML);
    if (!html || !match) {
      toast.error("挿入対象のHTMLが見つかりませんでした。");
      return;
    }

    const index = html.indexOf(match[0]) + match[0].length;
    const wrappedInsertText = insertText
      .split("\n")
      .map((line) => `<p>${line}</p>`)
      .join("");
    const newHtml = `${html.slice(0, index)}${wrappedInsertText}${html.slice(
      index,
    )}`;
    setHtml(newHtml);
  };

  const escapeRegExp = (str: string) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  return {
    convertToHtml,
    insertHtml,
    setHtml,
    html,
    convertResultMessages,
  };
};
