import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton, TextArea } from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import { useBoolean, useInput } from "@spesill/hooks";
import { TenantDocumentSettings } from "@spesill/models";
import { TenantDocumentSettingsRepository } from "@spesill/repository/db/tenantDocumentSettings.repository";

type PropsType = {
  tenantDocumentSettings: TenantDocumentSettings;
  defaultBusinessDetails?: string;
  onClose: () => void;
  onSuccess: () => void;
};
export const UpdateTenantBusinessDetailsModal = ({
  tenantDocumentSettings,
  onClose,
  onSuccess,
}: PropsType) => {
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);
  const [{ value: businessDetails, onChange: onChangeBusinessDetails }] =
    useInput(tenantDocumentSettings.businessDetails);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRequestingTrue();
    const tenantDocumentSettingsRepository =
      new TenantDocumentSettingsRepository();
    await tenantDocumentSettingsRepository
      .updateById(tenantDocumentSettings.id, {
        businessDetails: businessDetails,
      })
      .then(() => {
        onSuccess();
        toast.success("事業内容を更新しました");
      })
      .catch((e) => {
        toast.error(
          e instanceof Error ? e.message : "事業内容の更新に失敗しました",
        );
      })
      .finally(() => {
        onClose();
        setRequestingFalse();
      });
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="事業内容">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <TextArea
            className="border-blueGray-400"
            name="businessDetails"
            required
            placeholder="事業内容を入力してください。"
            value={businessDetails}
            onChange={onChangeBusinessDetails}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
              disabled={isRequesting}
            />
            <FormSubmitButton text="保存" color="primary" variant="contained" />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
