import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { ChatRoom } from "@spesill/models";
import { ChatRoomRepository } from "@spesill/repository/db/chatRoom.repository";

const chatRoomRepository = new ChatRoomRepository();

export const useChatRooms = () => {
  const { currentUser } = useCurrentUser();
  const [chatRooms, setChatRooms] = useState<ChatRoom[]>([]);

  const fetchChatRooms = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const docs = await chatRoomRepository.findByUserId(currentUser.id);
    setChatRooms(docs.filter((doc) => !doc.deletedAt));
  }, [currentUser]);

  useEffect(() => {
    fetchChatRooms();
  }, [currentUser?.tenantId, fetchChatRooms]);

  return {
    chatRooms,
    fetchChatRooms,
  };
};
