import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "@spesill/libs/firebase";
import { DocumentViewer } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class DocumentViewerRepository extends DBRepositoryBase<
  ExcludeMethods<DocumentViewer>
> {
  private getCollectionRef(documentId: string) {
    return collection(this.db, "documents", documentId, "viewers");
  }
  private getDocumentRef(documentId: string, userId: string) {
    return doc(this.db, "documents", documentId, "viewers", userId);
  }

  listWithinSeconds = async (
    tenantId: string,
    documentId: string,
    seconds: number,
  ): Promise<DocumentViewer[]> => {
    const q = query(
      this.getCollectionRef(documentId),
      where("viewedAt", ">=", new Date(Date.now() - seconds * 1000)),
      where("tenantId", "==", tenantId),
    );

    const viewers = await getDocs(q).then((res) => res.docs);

    return viewers.map(
      (viewer) => new DocumentViewer(this.docToObject(viewer)),
    );
  };

  upsert = async (
    tenantId: string,
    documentId: string,
    userId: string,
  ): Promise<void> => {
    const ref = this.getDocumentRef(documentId, userId);
    const document = await getDoc(ref);

    if (document.exists()) {
      await updateDoc(ref, {
        viewedAt: new Date(),
        updatedAt: new Date(),
      });
    } else {
      const newViewer = DocumentViewer.create({
        tenantId,
        userId,
        viewedAt: new Date(),
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      await setDoc(ref, this.objectToDoc(newViewer));
    }
  };
}
