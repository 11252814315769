import React, { CSSProperties, FC, ReactNode } from "react";

export type ColorType = "gray800" | "primary50";

type DirectionType = "bottom" | "right" | "left" | "top";

type Props = {
  children: ReactNode;
  direction: DirectionType;
  text?: ReactNode;
  contentChildren?: ReactNode;
  className?: string;
  color?: ColorType;
  style?: CSSProperties;
};

const commonXBorderClass =
  "before:absolute before:inset-y-1/2 before:mt-[-16px] before:z-10 before:border-transparent before:border-[16px] after:border-transparent after:absolute after:inset-y-1/2 after:mt-[-12px] after:z-10 after:border-[12px]";

const commonYBorderClass =
  "before:absolute before:right-5 before:mt-[-4px] before:z-10 before:border-transparent before:border-[10px] after:border-transparent after:absolute after:right-5 after:mt-[-0px] after:z-10 after:border-[9px]";

const tooltipAbsolutePosition = (direction: DirectionType): string => {
  switch (direction) {
    case "bottom": {
      return "top-[calc(100%+15px)] -right-5";
    }
    case "left": {
      return "right-full";
    }
    case "right": {
      return "left-full";
    }
    case "top": {
      return "bottom-[calc(100%+10px)] -right-5";
    }
    default:
      return "";
  }
};

export const Tooltip: FC<Props> = ({
  children,
  text,
  contentChildren,
  direction,
  className,
  color = "gray800",
  style,
}) => {
  const colorClassesMap = {
    gray800: {
      bg: "bg-gray-800",
      text: "text-white",
      topBorder: "border-t-gray-800 absolute -bottom-3 left-5",
      bottomBorder: "border-b-gray-800 absolute -top-3 right-5",
      rightBorder: "border-r-gray-800",
      leftBorder: "border-l-gray-800",
    },
    primary50: {
      bg: "bg-primary-50 w-64 leading-6 whitespace-pre-line border-primary-100 border-2 border-solid",
      text: "text-gray-600",
      topBorder: `${commonYBorderClass} before:-bottom-4 after:-bottom-4 before:border-t-primary-100 after:border-t-primary-50`,
      bottomBorder: `${commonYBorderClass} before:-top-4 after:-top-4 before:border-b-primary-100 after:border-b-primary-50`,
      rightBorder: `${commonXBorderClass} before:-left-4 after:-left-2 before:border-r-primary-100 after:border-r-primary-50`,
      leftBorder: `${commonXBorderClass} before:-right-4 after:-right-2 before:border-l-primary-100 after:border-l-primary-50`,
    },
  };

  const triangleClassNames =
    "w-0 h-0 border-[7px] border-solid border-transparent";

  return (
    <div className={"inline-flex items-center relative group"}>
      <div>{children}</div>
      <div
        style={style}
        className={`hidden absolute whitespace-nowrap group-hover:block z-20 ${tooltipAbsolutePosition(
          direction,
        )}`}
      >
        <div className={"flex items-center"}>
          {direction === "right" && (
            <div
              className={`${triangleClassNames} ${colorClassesMap[color].rightBorder}`}
            />
          )}
          <div
            className={`${colorClassesMap[color].bg} text-base p-2 rounded ${colorClassesMap[color].text} ${className}`}
          >
            {contentChildren || text}
          </div>
          {direction === "left" && (
            <div
              className={`${triangleClassNames} ${colorClassesMap[color].leftBorder} `}
            />
          )}
          {direction === "bottom" && (
            <div
              className={`${triangleClassNames} ${colorClassesMap[color].bottomBorder} `}
            />
          )}
          {direction === "top" && (
            <div
              className={`${triangleClassNames} ${colorClassesMap[color].topBorder} `}
            />
          )}
        </div>
      </div>
    </div>
  );
};
