import React from "react";
import { FileRejection } from "react-dropzone";

import { Button, DropzoneFileField, Icon } from "@spesill/components/atoms";
import { ACCEPT_PUNCH_ICON_FILE_TYPES } from "@spesill/components/atoms/DropzoneFileField/constants";

type PropsType = {
  onClose: () => void;
  handleDropFile: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => void;
  setDetailModel: () => void;
  files: File[];
};
export const PunchIconFileUploader = ({
  handleDropFile,
  files,
  setDetailModel,
  onClose,
}: PropsType) => {
  const file = files[0];

  return (
    <div>
      <div className="px-8 py-10">
        <p className="text-body-lg text-blueGray-900">
          アイコンのデータ
          <span className="text-status-danger text-body-lg-bold">
            （png, jpeg, svg）
          </span>
          をアップロードしてください。
        </p>
        <DropzoneFileField
          onDropFiles={handleDropFile}
          maxFiles={1}
          acceptFileTypes={ACCEPT_PUNCH_ICON_FILE_TYPES}
          fileClassName={
            "mt-8 flex justify-center items-center cursor-pointer rounded bg-white border w-[200px] mx-auto border-dashed border-blueGray-50 "
          }
        >
          <div className="h-full text-center flex flex-col justify-center py-10 px-6">
            {file ? (
              <img
                src={URL.createObjectURL(file)}
                className="w-full h-full"
                alt="icon"
              />
            ) : (
              <>
                <Icon
                  icon="tbFileUpload"
                  size="2rem"
                  color="text-blueGray-500"
                  className="w-full"
                />
                <p className="text-blueGray-500 text-h6 mt-2">
                  ここにファイルをドロップ
                </p>
                <p className="text-blueGray-500 text-caption mt-2">
                  または<span className="link">ファイルを選択</span>
                </p>
              </>
            )}
          </div>
        </DropzoneFileField>
        <span className="block mt-2 text-center mx-auto text-blueGray-900 text-body-base">
          ※<strong className="text-body-base-bold">正方形の画像</strong>
          を推奨します
        </span>
      </div>
      <hr className="border-blueGray-50" />
      <div className="mt-4 space-x-4 text-right">
        <Button
          text={"キャンセル"}
          color={"gray"}
          variant={"contained"}
          onClick={onClose}
        />
        <Button
          text="追加"
          color="primary"
          variant="contained"
          disabled={files.length < 1}
          onClick={() => setDetailModel()}
        />
      </div>
    </div>
  );
};
