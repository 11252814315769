import { DefineMethods } from "aspida";

export enum FeedbackEnum {
  Good = "good",
  Bad = "bad",
}

export type Methods = DefineMethods<{
  post: {
    reqBody: {
      target_response_id: string;
      feedback: FeedbackEnum;
    };
    resBody: {
      success: boolean;
      error: string | null;
    };
  };
}>;
