import { Drawer, Popconfirm, Popover } from "antd";
import React, { useContext, useEffect, useState } from "react";
import FlowBuilder, {
  INode,
  IRegisterNode,
  NodeContext,
} from "react-flow-builder";
import { toast } from "react-toastify";
import { v4 } from "uuid";

import { Button, Icon, LoadingAnimationText } from "@spesill/components/atoms";
import { ButtonWithTextArea } from "@spesill/components/molecules";

import {
  useBoolean,
  useCurrentUser,
  useIncrementAiUsage,
  useInput,
} from "@spesill/hooks";
import { useDownloadDom } from "@spesill/hooks/useDownloadDom";
import { apiClient } from "@spesill/libs/apiClient";
import { DocumentKind } from "@spesill/models/document";

import { ConfigForm } from "./ConfigForm";

const StartNodeDisplay = () => {
  const node = useContext(NodeContext);
  return (
    <div className="bg-black-500 rounded-[192px] p-4 text-white w-36 text-center">
      {node.name}
    </div>
  );
};

const EndNodeDisplay = () => {
  const node = useContext(NodeContext);
  return (
    <div className="bg-black-500 rounded-[192px] p-4 text-white w-36 text-center">
      {node.name}
    </div>
  );
};

const NodeDisplay = () => {
  const node = useContext(NodeContext);
  return (
    <div className="bg-white border-black-500 border-4 rounded p-4 text-black w-36 text-center">
      {node.data ? node.data.name : node.name}
    </div>
  );
};

const registerNodes: IRegisterNode[] = [
  {
    type: "start",
    name: "開始",
    isStart: true,
    displayComponent: StartNodeDisplay,
  },
  {
    type: "end",
    name: "終了",
    isEnd: true,
    displayComponent: EndNodeDisplay,
  },
  {
    type: "node",
    name: "通常",
    addIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.75"
          y="4.75"
          width="18.5"
          height="14.5"
          rx="3.25"
          stroke="#211B32"
          strokeWidth="1.5"
        />
      </svg>
    ),
    displayComponent: NodeDisplay,
    removeConfirmTitle: "このノードを削除しますか？",
    configComponent: ConfigForm,
  },
  {
    type: "condition",
    name: "条件",
    removeConfirmTitle: "この条件を削除しますか？",
    displayComponent: NodeDisplay,
    configComponent: ConfigForm,
  },
  {
    type: "branch",
    name: "分岐",
    conditionNodeType: "condition",
    removeConfirmTitle: "この分岐を削除しますか？",
    addIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4V9.73913M4 15V9.73913H20V15"
          stroke="#211B32"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask id="path-2-inside-1_3464_7519" fill="white">
          <rect x="1" y="15" width="6" height="5" rx="1" />
        </mask>
        <rect
          x="1"
          y="15"
          width="6"
          height="5"
          rx="1"
          stroke="#211B32"
          strokeWidth="3"
          mask="url(#path-2-inside-1_3464_7519)"
        />
        <mask id="path-3-inside-2_3464_7519" fill="white">
          <rect x="17" y="15" width="6" height="5" rx="1" />
        </mask>
        <rect
          x="17"
          y="15"
          width="6"
          height="5"
          rx="1"
          stroke="#211B32"
          strokeWidth="3"
          mask="url(#path-3-inside-2_3464_7519)"
        />
      </svg>
    ),
  },
  {
    type: "loop",
    name: "ループ",
    isLoop: true,
    addIcon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 17H7V14L3 18L7 22V19H19V13H17M7 7H17V10L21 6L17 2V5H5V11H7V7Z"
          fill="#211B32"
        />
      </svg>
    ),
    removeConfirmTitle: "このループを削除しますか？",
    displayComponent: NodeDisplay,
  },
];

// FIXME API側で一意なidを割り当てるように修正後→削除
export const assignUniqueIdsToNodesArray = (nodes: INode[]): INode[] => {
  return nodes.map((node) => {
    // ノードに新しい一意のIDを割り当てる
    const newNode = { ...node, id: v4() };

    // 子ノードが存在する場合、それぞれに対してこの関数を再帰的に呼び出す
    if (newNode.children && newNode.children.length > 0) {
      newNode.children = assignUniqueIdsToNodesArray(newNode.children);
    }

    return newNode;
  });
};

const FlowBuilderWrapper = ({
  state,
  learningDatabaseId,
  headingTitle = "",
  documentKind,
  freeInputKind,
  setIsEditable,
  setNodes,
  onUpdateState,
}: {
  state: INode[];
  learningDatabaseId?: string;
  headingTitle?: string;
  documentKind?: DocumentKind;
  freeInputKind?: string;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setNodes: React.Dispatch<React.SetStateAction<INode[]>>;
  onUpdateState?: (state: INode[]) => void;
}) => {
  const [draftNodes, setDraftNodes] = useState<INode[]>([...state]);
  const [{ value: keyword, onChange: onChangeKeyword, setValue }] =
    useInput("");
  const { currentUser } = useCurrentUser();
  const { incrementAiCallCount } = useIncrementAiUsage();

  const handleChange = (draftNodes: INode[]) => {
    setDraftNodes(draftNodes);
  };
  const { convert, ref } = useDownloadDom();
  const { isChecked: isRequesting, setTrue, setFalse } = useBoolean(false);
  return (
    <div className="flex flex-col w-full h-full rounded shadow">
      <div className="flex flex-col w-full h-full p-4 bg-primary-50 gap-4">
        <div ref={ref}>
          <FlowBuilder
            nodes={draftNodes}
            onChange={handleChange}
            registerNodes={registerNodes}
            zoomTool
            DrawerComponent={Drawer}
            PopoverComponent={Popover}
            PopconfirmComponent={Popconfirm}
          />
        </div>

        <ButtonWithTextArea
          name="question"
          type="text"
          icon="ioMdSend"
          placeholder="指示を入れてください"
          required
          disabledButton={!keyword || isRequesting}
          value={keyword}
          outline={false}
          onChange={onChangeKeyword}
          onClick={async () => {
            setTrue();
            try {
              const res = await apiClient().extract_flowchart_format.$post({
                body: {
                  query: keyword,
                  tenant_id: String(currentUser?.tenantId),
                  group_id: learningDatabaseId,
                  heading: headingTitle,
                  kind: documentKind || "specification",
                  free_input:
                    documentKind === "others" ? freeInputKind : undefined,
                },
              });
              const flowFormat = assignUniqueIdsToNodesArray(
                res.react_flow_builder_format,
              );
              setDraftNodes(flowFormat);
              incrementAiCallCount("extractFlowchartFormat");
              setValue("");
            } catch (error) {
              toast.error("フローチャートの作成に失敗しました。");
            } finally {
              setFalse();
            }
          }}
          rows={1}
          maxRows={15}
        />
        {isRequesting && (
          <LoadingAnimationText
            text={"図を生成中。。。 しばらくお待ちください"}
          />
        )}
      </div>

      <div className="flex justify-between gap-4 p-4">
        <Button
          text="キャンセル"
          color="gray"
          variant="contained"
          onClick={() => {
            setValue("");
            setIsEditable(false);
            setNodes(state);
            onUpdateState?.(state);
          }}
        />

        <div className="flex gap-4">
          <Button
            text="書き出し"
            color="primary"
            variant="outlined"
            icon={{
              icon: "grDocumentDownload",
              size: "1.5rem",
              color: "text-primary-400",
            }}
            onClick={convert}
          />
          <Button
            text="保存"
            color="primary"
            variant="contained"
            onClick={() => {
              setValue("");
              setIsEditable(false);
              setNodes(draftNodes);
              onUpdateState?.(draftNodes);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const FlowChartEditor = ({
  state,
  learningDatabaseId,
  headingTitle = "",
  documentKind,
  freeInputKind,
  onUpdateState,
}: {
  state: INode[];
  learningDatabaseId?: string;
  headingTitle?: string;
  documentKind?: DocumentKind;
  freeInputKind?: string;
  onUpdateState?: (state: INode[]) => void;
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [nodes, setNodes] = useState<INode[]>(state);
  useEffect(() => {
    if (nodes) {
      setIsEditable(false);
    }
  }, [nodes]);

  return (
    <div
      contentEditable={false}
      data-chart-type="flowchart"
      data-chart-state={JSON.stringify(nodes)}
    >
      {!isEditable ? (
        <div>
          <button
            type="button"
            className="flex group space-x-1 w-full"
            onClick={() => {
              setIsEditable(true);
            }}
          >
            <Icon
              icon="tbPencilPlus"
              size="1.7rem"
              color="text-white"
              className="bg-primary-400 p-1 shadow rounded-full hidden group-hover:block"
            />
            <FlowBuilder
              className="w-full h-full"
              nodes={nodes}
              readonly
              onChange={() => {}}
              registerNodes={registerNodes}
              zoomTool
              DrawerComponent={Drawer}
              PopoverComponent={Popover}
              PopconfirmComponent={Popconfirm}
            />
          </button>
        </div>
      ) : (
        <div>
          <FlowBuilderWrapper
            state={nodes}
            learningDatabaseId={learningDatabaseId}
            headingTitle={headingTitle}
            documentKind={documentKind}
            freeInputKind={freeInputKind}
            setIsEditable={setIsEditable}
            setNodes={setNodes}
            onUpdateState={onUpdateState}
          />
        </div>
      )}
    </div>
  );
};
