import React from "react";

import Image from "next/image";

import { Button } from "@spesill/components/atoms";

export const SupportSection = () => {
  return (
    <div className="m-4 px-4 py-6 bg-blueGray-700 rounded flex flex-col gap-4">
      <div className="flex justify-center">
        <Image
          src="/support.png"
          alt="サポートページ"
          width="136"
          height="103"
        />
      </div>

      <span className="text-caption text-blueGray-300">
        サービスに関するご意見・ご要望はこちら
      </span>
      <a
        href="https://forms.gle/TpcQmeSQ6dw5uVLw5"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center space-x-2"
      >
        <Button
          text="お問い合わせ"
          color="planning"
          variant="contained"
          icon={{
            icon: "tbMailForward",
            color: "text-white",
            size: "1.5rem",
          }}
        />
      </a>
    </div>
  );
};
