import { privateStorage } from "@spesill/config/firebase";
import { useBoolean, useCurrentUser } from "@spesill/hooks";
import { uploadBytes, ref } from "@spesill/libs/firebase";
import { LearningDocumentRepository } from "@spesill/repository/db/learningDocument.repository";

type UploadFileType = {
  fileName: string;
  systemName: string;
  file: File | Blob;
  onSuccess?: () => void;
};

type ReturnType = {
  uploadFile: ({
    fileName,
    systemName,
    file,
  }: UploadFileType) => Promise<{ id: string } | null>;
  isUploading: boolean;
};

export const useStorageUploadLearningDocument = (
  learningDatabaseId: string,
): ReturnType => {
  const { currentUser } = useCurrentUser();
  const {
    isChecked: isUploading,
    setTrue: setUploadTrue,
    setFalse: setUploadFalse,
  } = useBoolean(false);

  const uploadFile = async ({
    fileName,
    systemName,
    file,
    onSuccess,
  }: UploadFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    setUploadTrue();
    const documentRepository = new LearningDocumentRepository();

    const storageRef = ref(
      privateStorage,
      `tenants/${currentUser.tenantId}/documents/learning_databases/${learningDatabaseId}/learnings/${fileName}`,
    );

    return uploadBytes(storageRef, file).then(async () => {
      const document = await documentRepository.add({
        learningDatabaseId,
        tenantId: currentUser.tenantId,
        systemName,
        path: storageRef.fullPath,
        createUserId: currentUser.id,
        lastUpdateUserId: currentUser.id,
      });

      onSuccess && onSuccess();

      setUploadFalse();
      return document;
    });
  };

  return {
    uploadFile,
    isUploading,
  };
};
