import React, { FC } from "react";

import { ModalWrapper } from "@spesill/components/molecules";

import { DocumentInsertMenuButton } from "./DocumentInsertMenuButton";

type AiRequestMenuType =
  | "createText"
  | "createTable"
  | "createCheckbox"
  | "createFigure"
  | "createExcel";

type PropsType = {
  className?: string;
  onClose: () => void;
  setSelectAiRequestMenu: (aiRequestMenu: AiRequestMenuType) => void;
  aiRequestMenu: AiRequestMenuType;
  isOnlyCreateText?: boolean;
};

export const DocumentInsertMenus: FC<PropsType> = ({
  className = "",
  onClose,
  setSelectAiRequestMenu,
  aiRequestMenu,
  isOnlyCreateText = false,
}: PropsType) => {
  return (
    <>
      <ModalWrapper onClose={onClose} isWhite />
      <ul
        className={`rounded  absolute top-10 right-0 z-20 bg-white p-2 body-base gap-y-2 w-fit ${className}`}
        contentEditable={false}
      >
        <DocumentInsertMenuButton
          title="文章を作成"
          icon="tbPencilPlus"
          className={`${aiRequestMenu === "createText" ? "bg-primary-50" : ""}`}
          setSelectAiRequestMenu={() => setSelectAiRequestMenu("createText")}
        />
        {!isOnlyCreateText && (
          <>
            <DocumentInsertMenuButton
              title="Excelシートから文章作成"
              icon="faTable"
              className={`${
                aiRequestMenu === "createExcel" ? "bg-primary-50" : ""
              }`}
              setSelectAiRequestMenu={() => {
                setSelectAiRequestMenu("createExcel");
                onClose();
              }}
            />
            <DocumentInsertMenuButton
              title="表を作成"
              icon="aiOutlineTable"
              className={`${
                aiRequestMenu === "createTable" ? "bg-primary-50" : ""
              }`}
              setSelectAiRequestMenu={() => {
                setSelectAiRequestMenu("createTable");
                onClose();
              }}
            />
            <DocumentInsertMenuButton
              title="チェックリストを作成"
              icon="bsListCheck"
              className={`${
                aiRequestMenu === "createCheckbox" ? "bg-primary-50" : ""
              }`}
              setSelectAiRequestMenu={() => {
                setSelectAiRequestMenu("createCheckbox");
                onClose();
              }}
            />
            <DocumentInsertMenuButton
              title="ポンチ絵・図の作成"
              icon="biPalette"
              className={`${
                aiRequestMenu === "createFigure" ? "bg-primary-50" : ""
              }`}
              setSelectAiRequestMenu={() => {
                setSelectAiRequestMenu("createFigure");
                onClose();
              }}
            />
          </>
        )}
      </ul>
    </>
  );
};
