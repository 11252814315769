import React, { FC, ChangeEvent, MouseEvent } from "react";

import { Icon, IconType, TextField } from "@spesill/components/atoms";

type FieldType = "text" | "email" | "password" | "search";

type PropsType = {
  icon: IconType;
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  outline?: boolean;
  disabled?: boolean;
  disabledButton?: boolean;
  className?: string;
  inputClassName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const ButtonWithTextField: FC<PropsType> = ({
  icon,
  type = "text",
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  outline = true,
  disabled = false,
  disabledButton = false,
  className = "",
  inputClassName = "",
  onChange,
  onClick,
}: PropsType) => {
  return (
    <div className={`relative ${className}`}>
      <TextField
        className={inputClassName}
        type={type}
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        outline={outline}
        disabled={disabled}
        onChange={onChange}
      />
      <button
        type="button"
        onClick={onClick}
        disabled={disabledButton}
        className="absolute inset-y-0 right-0 px-2"
      >
        <Icon icon={icon} size="1.5rem" color="text-blueGray-400" />
      </button>
    </div>
  );
};
