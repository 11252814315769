import { Entity } from "./__common__/entity";
import { aiCounterFieldType } from "./tenantAiUsage";

export class AiRequestHistory extends Entity {
  id: string;
  tenantId: string;
  userId: string;
  aiRequest: aiCounterFieldType;

  constructor(arg: ExcludeMethods<AiRequestHistory>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.userId = arg.userId;
    this.aiRequest = arg.aiRequest;
  }

  static create(
    args: Omit<ConstructorParameters<typeof AiRequestHistory>[0], "id"> & {
      userId: string;
    },
  ) {
    return new AiRequestHistory({
      id: args.userId,
      ...args,
    });
  }
}
