import { collection, getDocs, orderBy, query } from "@spesill/libs/firebase";
import { MasterPrompt } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class MasterPromptRepository extends DBRepositoryBase<
  ExcludeMethods<MasterPrompt>
> {
  private PATH = "masterPrompts";

  findByLatest = async () => {
    const q = query(
      collection(this.db, this.PATH),
      orderBy("updatedAt", "desc"),
    );

    const masterPrompts = await getDocs(q).then((res) => res.docs);

    if (!masterPrompts[0]) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      return new MasterPrompt(this.docToObject(masterPrompts[0]));
    }
  };
}
