import { useState, useCallback, useEffect } from "react";

import { MultiValueType, SingleValueType } from "@spesill/components/atoms";

import {
  DisplayDocumentLanguages,
  DocumentLanguageType,
  Document,
} from "@spesill/models/document";

type ReturnType = {
  dropdownValue?: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (newValue: SingleValueType | null) => void;
};

export const useLanguageDropdownField = (
  document?: Document,
  onSuccess?: () => void,
): ReturnType => {
  const [value, setValue] = useState<SingleValueType>({
    value: "DEFAULT",
    label: DisplayDocumentLanguages.DEFAULT,
  });
  // DisplayDocumentLanguagesから選択肢を作成
  const languageList = Object.keys(DisplayDocumentLanguages).map((key) => {
    return {
      value: key,
      label: DisplayDocumentLanguages[key as DocumentLanguageType],
    };
  });

  const [dropdownSelectableValue] = useState<MultiValueType>(languageList);

  const onChangeDropDown = useCallback(
    async (newValue: SingleValueType | null) => {
      setValue(newValue);
      onSuccess && onSuccess();
    },
    [onSuccess],
  );

  useEffect(() => {
    if (!document?.language) return;
    setValue({
      value: document.language,
      label: DisplayDocumentLanguages[document.language],
    });
  }, [document]);
  return {
    dropdownValue: value,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
  };
};
