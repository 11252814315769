import axios, { AxiosError } from "axios";
import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import {
  LabelWithDropdownField,
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import {
  useBoolean,
  useCurrentUser,
  useInput,
  useRoleDropdownField,
  useTenantUserRestriction,
} from "@spesill/hooks";

type PropsType = {
  onClose: () => void;
  refetch: () => void;
};
export const UserInviteModal = ({ onClose, refetch }: PropsType) => {
  const { currentUser } = useCurrentUser();
  const { canAddUser } = useTenantUserRestriction();

  const [{ value: email, onChange: onChangeEmail }] = useInput("");
  const {
    dropdownValue: dropdownValueRole,
    dropdownSelectableValue: dropdownSelectableValueRole,
    onChange: onChangeDropdownRole,
  } = useRoleDropdownField();
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canAddUser) {
      toast.error("ユーザー数がプランの上限に達しました");
      return;
    }

    if (currentUser?.role !== "ADMIN") {
      toast.error("招待する権限がありません");
      return;
    }
    setRequestingTrue();

    // TODO: FunctionClientを抽象化して利用すれば型安全にできる
    await axios
      .post("/api/mail/invite", {
        senderUserId: currentUser.id,
        receiverUserRole: dropdownValueRole?.value,
        receiverUserEmail: email,
      })
      .then(() => {
        onClose();
        toast.success("招待メールを送信しました");
      })
      .catch((e) => {
        if (e instanceof AxiosError) {
          const msg = e.response?.data.error || "招待に失敗しました";
          toast.error(msg);
        }
      })
      .finally(() => {
        setRequestingFalse();
        refetch();
      });
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="ユーザーを招待">
      <div>
        {!canAddUser && (
          <p className="text-status-danger mt-6">
            ユーザー数がプランの上限に達しているため、招待できません。
          </p>
        )}
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <LabelWithTextField
            labelText="メールアドレス"
            type="email"
            name="email"
            required
            autoComplete="email"
            placeholder="例）info@spesill.com"
            value={email}
            onChange={onChangeEmail}
          />
          <LabelWithDropdownField
            labelText="権限"
            name="role"
            value={dropdownValueRole}
            options={dropdownSelectableValueRole}
            onChange={onChangeDropdownRole}
            required
          />
          <p className="text-blueGray-300 text-sm">
            ・管理者：ユーザーを招待することができます。
            <br />
            ・メンバー：ユーザーを招待することができません。
          </p>
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="招待"
              color="primary"
              variant="contained"
              disabled={!canAddUser || isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
