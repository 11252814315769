import React, { FC, ChangeEvent } from "react";

import { TextField, Label } from "@spesill/components/atoms";

type FieldType = "text" | "email" | "password" | "search";

type PropsType = {
  labelText: string;
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  textFieldClassName?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
};

export const LabelWithTextField: FC<PropsType> = ({
  labelText,
  type = "text",
  name,
  value,
  defaultValue,
  autoComplete = "",
  placeholder = "",
  required = false,
  disabled = false,
  textFieldClassName = "",
  className = "",
  onChange,
  error,
}: PropsType) => {
  return (
    <div className={className}>
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        className={`block ${error ? "text-red-600" : ""}`}
      />
      <TextField
        className={`mt-1.5 ${textFieldClassName} ${error ? "border-red-600" : ""}`}
        type={type}
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={onChange}
      />
      {error && <p className="text-red-600 text-xs mt-2">{error}</p>}
    </div>
  );
};
