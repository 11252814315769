import { useCallback } from "react";

import { privateStorage } from "@spesill/config/firebase";
import { useCurrentUser, useFetchTenant } from "@spesill/hooks";
import { getMetadata, listAll, ref } from "@spesill/libs/firebase";
import { PLAN_INFO } from "@spesill/models/constants/plan";
import { EvaluateDatabaseRepository } from "@spesill/repository/db/evaluateDatabase.repository";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";

const learningDatabaseRepository = new LearningDatabaseRepository();
const evaluateDatabaseRepository = new EvaluateDatabaseRepository();

const BYTES_IN_GIGABYTE = 1024 * 1024 * 1024;

const fetchUsedBytes = async (tenantId: string) => {
  try {
    const items = await listAll(
      ref(privateStorage, `tenants/${tenantId}/documents`),
    ).then((res) => res.items);
    const metadata = await Promise.all(items.map((item) => getMetadata(item)));
    return metadata.reduce((acc, cur) => acc + cur.size, 0);
  } catch (error) {
    console.error("Error fetching used bytes:", error);
    return 0;
  }
};

const fetchUsedDatabaseCount = async (tenantId: string) => {
  try {
    const learningDatabases =
      await learningDatabaseRepository.findByTenantId(tenantId);
    const evaluateDatabases =
      await evaluateDatabaseRepository.findByTenantId(tenantId);
    return (
      learningDatabases.filter((doc) => !doc.deletedAt).length +
      evaluateDatabases.filter((doc) => !doc.deletedAt).length
    );
  } catch (error) {
    console.error("Error fetching used databases:", error);
    return 0;
  }
};

export const useTenantLimitManager = () => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useFetchTenant();

  const isUploadable = useCallback(
    async (fileBytes: number): Promise<boolean> => {
      if (!currentUser?.tenantId || !tenant) return false;

      const currentPlan = tenant.plan;
      const uploadableTotalGB =
        PLAN_INFO[currentPlan].maxDataCapacityGB +
        (tenant.additionalDataCapacityGB || 0);
      const uploadableTotalBytes = uploadableTotalGB * BYTES_IN_GIGABYTE;

      const usedBytes = await fetchUsedBytes(currentUser.tenantId);
      return usedBytes + fileBytes < uploadableTotalBytes;
    },
    [currentUser?.tenantId, tenant],
  );

  const isUploadableDatabases = useCallback(
    async (fileCounts: number): Promise<boolean> => {
      if (!currentUser?.tenantId || !tenant) return false;

      const currentPlan = tenant.plan;
      if (currentPlan === "ENTERPRISE") return true;

      const uploadableTotalDatabaseCount =
        PLAN_INFO[currentPlan].maxLearningDatabases;
      const usedDatabaseCount =
        (await fetchUsedDatabaseCount(currentUser.tenantId)) +
        (tenant.additionalDatabaseCount || 0);

      return usedDatabaseCount + fileCounts < uploadableTotalDatabaseCount;
    },
    [currentUser?.tenantId, tenant],
  );

  return { isUploadable, isUploadableDatabases };
};
