import * as GC from "@grapecity/spread-sheets";
import React from "react";

import dynamic from "next/dynamic";
import Link from "next/link";

import {
  DropdownField,
  Button,
  Tooltip,
  SingleValueType,
  MultiValueType,
  PageTitle,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";

import { Document } from "@spesill/models";

const DownloadExcelFileButton = dynamic(
  () =>
    import(
      "@spesill/components/organisms/Document/DocumentEditor/DownloadExcelFileButton"
    ).then((mod) => mod.DownloadExcelFileButton),
  {
    ssr: false,
  },
);

type PropsType = {
  document: Document;
  isEditable: boolean;
  systemName: string;
  onChangeSystemName: (value?: string) => void;
  handleDeleteDocument: () => void;
  userPermission: string;
  dropdownValueLearningDatabase?: SingleValueType;
  dropdownSelectableValueLearningDatabase: MultiValueType;
  onChangeDropdownLearningDatabase: (id?: string) => void;
  onCreateLearningDatabaseModalOpen: () => void;
  onUpdateDocumentCustomPromptModalOpen: () => void;
  onOpenUpdateDocumentNameModal?: () => void;
  onOpenDocumentSettingModal?: () => void;
  onDownloadExcelFile: (
    e: React.MouseEvent<HTMLButtonElement>,
    convertSpreadToExcel: (spread: GC.Spread.Sheets.Workbook) => Promise<Blob>,
  ) => Promise<void> | void;
  onDownloadWordFile: (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<void> | void;
};

export const DocumentEditTopSection = ({
  document,
  isEditable,
  systemName,
  handleDeleteDocument,
  userPermission,
  dropdownValueLearningDatabase,
  dropdownSelectableValueLearningDatabase,
  onChangeDropdownLearningDatabase,
  onCreateLearningDatabaseModalOpen,
  onUpdateDocumentCustomPromptModalOpen,
  onOpenUpdateDocumentNameModal,
  onOpenDocumentSettingModal,
  onDownloadWordFile,
  onDownloadExcelFile,
}: PropsType) => {
  return (
    <div className="flex items-center justify-between">
      {document && isEditable ? (
        <Button
          icon={{
            icon: "aiOutlineReload",
            color: "text-blueGray-500",
            size: "1.5rem",
          }}
          text="文書をリセット"
          color="gray"
          variant="contained"
          onClick={handleDeleteDocument}
        />
      ) : (
        <div className="group flex space-x-2">
          <PageTitle
            titles={[
              {
                text: "すべて",
                link: "/",
              },
              {
                text: document.systemName || "ドキュメント詳細",
              },
            ]}
          />
          <IconButton
            icon={{
              icon: "riPencilLine",
              size: "1.5rem",
            }}
            onClick={onOpenUpdateDocumentNameModal}
            className="p-0.5 opacity-0 group-hover:opacity-100 transition-opacity"
          />
        </div>
      )}
      <div className="flex items-center gap-4">
        {userPermission === "editor" && (
          <>
            <DropdownField
              name="learningDatabase"
              value={dropdownValueLearningDatabase}
              options={dropdownSelectableValueLearningDatabase}
              onChange={(newValue, meta) => {
                meta.action !== "create-option" &&
                  newValue &&
                  onChangeDropdownLearningDatabase(newValue.value);
              }}
              height="47px"
              width="256px"
              isClearable={false}
              placeholder="生成データベースを選択"
              customMenuPosition="bottom"
              customMenu={() =>
                isEditable ? (
                  <Tooltip
                    direction="right"
                    contentChildren={
                      <div>
                        現在のプランではご利用できません。
                        <br />
                        プランの切り替えは
                        <Link className="border-b" href={"/settings/plan"}>
                          こちら
                        </Link>
                      </div>
                    }
                  >
                    <Button
                      text="データベースを新規作成"
                      color="primary"
                      variant="text"
                      className="text-body-base w-full"
                      icon={{
                        icon: "aiOutlinePlus",
                        size: "1rem",
                        color: "text-primary-400",
                      }}
                      disabled
                    />
                  </Tooltip>
                ) : (
                  <Button
                    text="データベースを新規作成"
                    color="primary"
                    variant="text"
                    className="text-body-base w-full"
                    icon={{
                      icon: "aiOutlinePlus",
                      size: "1rem",
                      color: "text-primary-400",
                    }}
                    onClick={() => {
                      onCreateLearningDatabaseModalOpen();
                    }}
                  />
                )
              }
            />
            <Button
              text="カスタム命令"
              color="primary"
              variant="outlined"
              onClick={onUpdateDocumentCustomPromptModalOpen}
            />
          </>
        )}
        {document.documentType === "word" ? (
          <Button
            text="ダウンロード"
            color="primary"
            disabled={!systemName}
            variant="outlined"
            onClick={onDownloadWordFile}
          />
        ) : (
          <DownloadExcelFileButton
            onClick={onDownloadExcelFile}
            disabled={!systemName}
          />
        )}
        <IconButton
          icon={{
            icon: "bsThreeDots",
            color: "text-blueGray-500",
            size: "1.5rem",
          }}
          className="p-1"
          onClick={onOpenDocumentSettingModal}
        />
      </div>
    </div>
  );
};
