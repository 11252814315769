type ReturnType = {
  downloadBlob: (blob: Blob, filename: string) => void;
};

export const useBlobDownload = (): ReturnType => {
  // https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
  const downloadBlob = (blob: Blob, filename: string): void => {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  };

  return { downloadBlob };
};
