import React, { FC } from "react";
import { ActionMeta } from "react-select";

import {
  Label,
  DropdownField,
  MultiValueType,
  SingleValueType,
} from "@spesill/components/atoms";

type OptionType = {
  value: string;
  label: string;
};

type PropsType = {
  labelText: string;
  name: string;
  height?: string;
  width?: string;
  options: MultiValueType;
  value?: SingleValueType;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  isClearable?: boolean;
  onChange: (
    newValue: SingleValueType | null,
    actionMeta: ActionMeta<OptionType>,
  ) => void;
  required?: boolean;
  parentClassName?: string;
  customMenu?: () => JSX.Element;
  customMenuPosition?: "top" | "bottom";
};

export const LabelWithDropdownField: FC<PropsType> = ({
  labelText,
  name,
  height,
  width,
  options,
  value = null,
  disabled,
  placeholder = "選択してください",
  className = "",
  parentClassName = "",
  customMenuPosition = "top",
  required = false,
  isClearable = true,
  customMenu,
  onChange,
}: PropsType) => {
  return (
    <div className={className}>
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        className="block"
      />
      <DropdownField
        name={name}
        height={height}
        width={width}
        options={options}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        className={"mt-1.5"}
        parentClassName={parentClassName}
        customMenuPosition={customMenuPosition}
        isClearable={isClearable}
        customMenu={customMenu}
        onChange={onChange}
      />
    </div>
  );
};
