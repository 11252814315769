import React from "react";

import { formatTimestamp } from "@spesill/libs/dateFns";

type PropsType = {
  isRequesting: boolean;
  isUnLearned: boolean;
  lastLearnedAt?: Date;
};

const getMessage = (
  isRequesting: boolean,
  isUnLearned: boolean,
  lastLearnedAt?: Date,
): string => {
  if (isRequesting) {
    return "AI学習中です。しばらくお待ちください。（数分かかる場合があります）";
  }

  if (isUnLearned) {
    return "アップロードしたデータをAIで使用できるようにAI学習を実行してください。";
  }

  if (lastLearnedAt) {
    return `最後実行日時：${formatTimestamp(lastLearnedAt)}`;
  }

  return "アップロードしたデータをAIで使用できるようにAI学習を実行してください。";
};

export const LearningStatus = ({
  isRequesting,
  isUnLearned,
  lastLearnedAt,
}: PropsType) => {
  const message = getMessage(isRequesting, isUnLearned, lastLearnedAt);
  const isAnimating = isRequesting;

  return (
    <span
      className={`text-blueGray-900 ${
        isAnimating ? "animate-pulse text-primary-800 text-center" : ""
      }`}
    >
      {message}
    </span>
  );
};
