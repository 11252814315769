import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { Button } from "@spesill/components/atoms";
import {
  ButtonWithTextArea,
  FloatingMenu,
} from "@spesill/components/molecules";
import {
  MessageBox,
  UpdateChatLearningDatabaseModal,
} from "@spesill/components/organisms";

import {
  useBoolean,
  useChatRoom,
  useLearningDatabasesWithUser,
  useChatRoomMessages,
  useInput,
  useCurrentUser,
  useIncrementAiUsage,
} from "@spesill/hooks";
import { apiClient } from "@spesill/libs/apiClient";
import { ChatRoomMessage } from "@spesill/models";

import ChatDetailSuggestions from "./Suggestions";
import SuggestionButton from "../SuggestionButton";

type PropsType = {
  chatId: string;
};

enum ActiveTab {
  LearningDatabase,
  ChatGPT,
}

export const ChatDetail = ({ chatId }: PropsType) => {
  const { currentUser } = useCurrentUser();
  const [{ value, onChange, resetValue, setValue }] = useInput();
  const { learningDatabases } = useLearningDatabasesWithUser();
  const { fetchChatRoom, chatRoom } = useChatRoom(chatId);
  const { incrementAiCallCount } = useIncrementAiUsage();
  const { chatRoomMessages, fetchChatRoomMessages } =
    useChatRoomMessages(chatId);
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.ChatGPT);
  const [isOpenTemplate, setIsOpenTemplate] = useState(false);

  const [messages, setMessages] = useState<ChatRoomMessage[]>([]);

  const {
    isChecked: isUpdateChatLearningDatabaseModal,
    setTrue: setUpdateChatLearningDatabaseModalOpen,
    setFalse: setUpdateChatLearningDatabaseModalClose,
  } = useBoolean(false);

  const { isChecked: isRequesting, setTrue, setFalse } = useBoolean(false);

  const onSendMessage = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!currentUser || !chatRoom) return;

    if (!value) {
      toast.error("メッセージを入力してください");
      return;
    }

    // ユーザーからの新規の質問を楽観的更新
    const newMessage = {
      id: "",
      sender: "USER",
      message: value,
      createdAt: new Date(),
      updatedAt: new Date(),
    } as ChatRoomMessage;

    setMessages((prev) => [...prev, newMessage]);

    setTrue();
    resetValue();

    try {
      await apiClient().ai_chat.$post({
        body: {
          user_id: currentUser.id,
          chat_room_id: chatRoom.id,
          tenant_id: currentUser.tenantId,
          group_id:
            activeTab === ActiveTab.LearningDatabase
              ? chatRoom.learningDatabaseId
              : undefined,
          question: value,
        },
      });
      incrementAiCallCount("aiChat");
      fetchChatRoomMessages();
    } catch (e) {
      toast.error("メッセージの送信に失敗しました");
      setMessages(chatRoomMessages);
    } finally {
      setFalse();
    }
  };

  const selectedLearningDatabase = useMemo(
    () =>
      learningDatabases.find(
        (learningDatabase) =>
          learningDatabase.id === chatRoom?.learningDatabaseId,
      ),
    [learningDatabases, chatRoom],
  );

  const isSelectingTable = useMemo(
    () =>
      activeTab === ActiveTab.LearningDatabase &&
      selectedLearningDatabase?.structureType === "table",
    [activeTab, selectedLearningDatabase],
  );

  useEffect(() => {
    setMessages(chatRoomMessages);
  }, [chatRoomMessages]);

  return (
    <div className="w-full flex flex-col max-h-[calc(100vh-66px)]">
      <div className="h-full flex flex-col p-4 overflow-auto">
        <ul className="w-full h-full pb-20">
          {messages.length === 0 && (
            <MessageBox
              isMe={false}
              isInitial={true}
              message="こんにちは。このチャットではChat-GPTもしくはデータベースに蓄積された文書データをもとに、AIがあなたの課題や知識の抽出をサポートします。"
            />
          )}
          {messages.map((chatRoomMessage) => {
            return (
              <MessageBox
                key={chatRoomMessage.id}
                isMe={chatRoomMessage.sender === "USER"}
                lastName={currentUser?.lastName}
                message={chatRoomMessage.message}
                sources={chatRoomMessage.sources}
              />
            );
          })}
          {isRequesting && (
            <>
              <MessageBox isMe={false} message="" isRequesting={isRequesting} />
            </>
          )}
        </ul>
        {messages.length === 0 && isSelectingTable && (
          <div className="flex flex-col gap-3 pb-6">
            <SuggestionButton
              onClick={() =>
                setValue(
                  "<項目名>に〇〇が含まれているデータの<項目名>を抽出して下さい",
                )
              }
            >
              <span className="text-primary-400">{"<項目名>"}</span>
              に〇〇が含まれているデータの
              <span className="text-primary-400">{"<項目名>"}</span>
              を抽出して下さい
            </SuggestionButton>
            <SuggestionButton
              onClick={() => setValue("<項目名>が〇〇の<項目名>を教えて下さい")}
            >
              <span className="text-primary-400">{"<項目名>"}</span>
              が〇〇の
              <span className="text-primary-400">{"<項目名>"}</span>
              を教えて下さい
            </SuggestionButton>
          </div>
        )}
      </div>
      <div className="flex flex-col sticky bottom-0 bg-white">
        <div className="flex flex-row justify-between items-center p-2 bg-blueGray-50">
          <div className="flex flex-row justify-between items-center space-x-1 bg-blueGray-50">
            <Button
              className={`${
                activeTab === ActiveTab.LearningDatabase
                  ? "bg-blueGray-500 text-white"
                  : "text-blueGray-900"
              }`}
              icon={
                selectedLearningDatabase
                  ? {
                      icon:
                        selectedLearningDatabase?.structureType === "table"
                          ? "bsTable"
                          : "ioDocumentOutline",
                      color: "text-blueGray-500",
                      className: `w-5 h-5 ${
                        activeTab === ActiveTab.LearningDatabase
                          ? "bg-white rounded"
                          : ""
                      } ${
                        selectedLearningDatabase?.structureType === "table"
                          ? "p-1"
                          : "p-0.5"
                      }`,
                    }
                  : undefined
              }
              text={
                selectedLearningDatabase?.systemName ?? "選択中のデータベース"
              }
              onClick={() =>
                selectedLearningDatabase
                  ? setActiveTab(ActiveTab.LearningDatabase)
                  : toast.warn("データベースを選択してください")
              }
              color="gray"
              variant="text"
            />
            <Button
              className={`${
                activeTab === ActiveTab.ChatGPT
                  ? "bg-blueGray-500 text-white"
                  : "text-blueGray-900"
              }`}
              text="ChatGPT"
              onClick={() => setActiveTab(ActiveTab.ChatGPT)}
              color="gray"
              variant="text"
            />
          </div>
          <Button
            text="切り替え"
            variant="text"
            className="text-sm"
            color="primary"
            icon={{
              icon: "aiOutlineReload",
              size: "1rem",
              color: "text-primary-400",
            }}
            onClick={setUpdateChatLearningDatabaseModalOpen}
          />
        </div>
        <form className="p-4">
          <ButtonWithTextArea
            name="question"
            type="text"
            icon="ioMdSend"
            className="border-none bg-white outline-0"
            placeholder="メッセージを入力してください"
            required
            disabledButton={!value || isRequesting}
            value={value}
            outline={false}
            onChange={onChange}
            onClick={onSendMessage}
            rows={1}
            maxRows={15}
          />
          {isSelectingTable && (
            <>
              <div className="pt-2 flex flex-row items-center gap-2">
                <span className="text-sm text-blueGray-500">
                  可能な限りデータの項目名と同じ名前で指示するほうが精度が高まります。
                </span>
                {messages.length > 0 && (
                  <div className="relative min-w-[12rem]">
                    <button
                      type="button"
                      className="text-sm text-black-900 border border-blueGray-300 rounded-full px-4 py-1"
                      onClick={() => setIsOpenTemplate(!isOpenTemplate)}
                    >
                      テンプレートを使用する
                    </button>
                    {isOpenTemplate && (
                      <FloatingMenu
                        onClose={() => setIsOpenTemplate(false)}
                        className="right-0 bottom-10"
                      >
                        <div className="w-max">
                          <button
                            type="button"
                            className="block text-sm px-3 py-2 w-full text-left hover:bg-blueGray-0"
                            onClick={() => {
                              setValue(
                                "<項目名>に〇〇が含まれているデータの<項目名>を抽出して下さい",
                              );
                              setIsOpenTemplate(false);
                            }}
                          >
                            <p>
                              <span className="text-primary-400">
                                {"<項目名>"}
                              </span>
                              に〇〇が含まれているデータの
                              <span className="text-primary-400">
                                {"<項目名>"}
                              </span>
                              を抽出して下さい
                            </p>
                          </button>
                          <button
                            type="button"
                            className="block text-sm px-3 py-2 w-full text-left hover:bg-blueGray-0"
                            onClick={() => {
                              setValue(
                                "<項目名>が〇〇の<項目名>を教えて下さい",
                              );
                              setIsOpenTemplate(false);
                            }}
                          >
                            <span className="text-primary-400">
                              {"<項目名>"}
                            </span>
                            が〇〇の
                            <span className="text-primary-400">
                              {"<項目名>"}
                            </span>
                            を教えて下さい
                          </button>
                        </div>
                      </FloatingMenu>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          {selectedLearningDatabase &&
            activeTab === ActiveTab.LearningDatabase && (
              <ChatDetailSuggestions
                tenantId={currentUser?.tenantId}
                groupId={selectedLearningDatabase?.id}
                setValue={setValue}
              />
            )}
        </form>
      </div>
      {isUpdateChatLearningDatabaseModal && chatRoom && (
        <UpdateChatLearningDatabaseModal
          onClose={setUpdateChatLearningDatabaseModalClose}
          chatRoom={chatRoom}
          onSuccess={() => {
            fetchChatRoom();
            setActiveTab(ActiveTab.LearningDatabase);
          }}
        />
      )}
    </div>
  );
};
