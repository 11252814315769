import React, { FC, ChangeEvent } from "react";

type FieldType = "text" | "email" | "password" | "search";

type PropsType = {
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  outline?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const TextField: FC<PropsType> = ({
  type = "text",
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  outline = true,
  className = "",
  onChange,
}: PropsType) => {
  return (
    <input
      type={type}
      name={name}
      value={value}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      className={`w-full px-4 py-2 ${className} ${
        disabled ? "cursor-not-allowed" : ""
      } ${
        outline
          ? "rounded border border-solid border-blueGray-400"
          : "outline-0"
      }`}
      onChange={onChange}
    />
  );
};
