import React, { FC } from "react";

type PropsType = {
  className?: string;
};

export const DotLoadingAnimation: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  return (
    <div className={`flex items-center space-x-4 ${className}`}>
      <span
        className="w-1.5 h-1.5 bg-primary-800 rounded-full animate-bounce"
        style={{ animationDelay: "0s" }}
      />
      <span
        className="w-1.5 h-1.5 bg-primary-800 rounded-full animate-bounce"
        style={{ animationDelay: "150ms" }}
      />
      <span
        className="w-1.5 h-1.5 bg-primary-800 rounded-full animate-bounce"
        style={{ animationDelay: "300ms" }}
      />
    </div>
  );
};
