import React, { FC, ComponentProps } from "react";

import { Icon } from "@spesill/components/atoms";
import { ListMenu } from "@spesill/components/molecules";

type PropsType = {
  text: string;
  className?: string;
  menuItems: ComponentProps<typeof ListMenu>["items"];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const FilterButton: FC<PropsType> = ({
  text,
  className = "",
  menuItems,
  isOpen,
  onOpen,
  onClose,
}: PropsType) => {
  return (
    <div className={`relative ${className}`}>
      <button type="button" onClick={onOpen} className={"flex space-x-2"}>
        <span className={"text-blueGray-900 truncate"}>{text}</span>
        <Icon icon={"grFormFilter"} size="1.5rem" color="text-blueGray-500" />
      </button>
      {isOpen && <ListMenu items={menuItems} onClose={onClose} />}
    </div>
  );
};
