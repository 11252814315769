import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { PostsNotification } from "@spesill/models";
import { PostsNotificationRepository } from "@spesill/repository/db/postsNotification.repository";
import { UsersPostsNotificationStatusRepository } from "@spesill/repository/db/usersPostsNotificationStatus.repository";

const usersPostsNotificationStatusRepository =
  new UsersPostsNotificationStatusRepository();

const postsNotificationRepository = new PostsNotificationRepository();

export type PostsNotificationsReadType = ExcludeMethods<PostsNotification> & {
  readAt: Date | null;
};

export const usePostsNotifications = () => {
  const { currentUser } = useCurrentUser();

  const [postsNotifications, setPostsNotifications] = useState<
    PostsNotificationsReadType[]
  >([]);

  const fetchPostsNotifications = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const fetchedUnreadPostsStatuses =
      await usersPostsNotificationStatusRepository.findByUsersUnreadPostsNotification(
        currentUser,
        30,
      );
    const ids = fetchedUnreadPostsStatuses.map(
      (status) => status.postsNotificationId,
    );

    const notifications = await postsNotificationRepository.findByIds(ids);

    const postsNotificationsReads = notifications
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .map((notification) => {
        const readAt =
          fetchedUnreadPostsStatuses.find(
            (status) => status.postsNotificationId === notification.id,
          )?.readAt || null;

        return { ...notification, readAt };
      });

    setPostsNotifications(postsNotificationsReads);
  }, [currentUser]);

  useEffect(() => {
    fetchPostsNotifications();
  }, [currentUser, fetchPostsNotifications]);

  return {
    fetchPostsNotifications,
    postsNotifications,
  };
};
