import { useContext } from "react";

import { User } from "@spesill/models";
import { DisplayPlan } from "@spesill/models/constants/plan";
import { CurrentUserContext } from "@spesill/providers/CurrentUserProvider";

export const useCurrentUser = (): {
  currentUser: User | undefined;
  refetchCurrentUser: () => void;
  currentPlan?: keyof typeof DisplayPlan;
} => {
  const { currentUser, refetchCurrentUser, currentPlan } =
    useContext(CurrentUserContext);

  return { currentUser, refetchCurrentUser, currentPlan };
};
