import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { OrderByDirection } from "@spesill/libs/firebase";
import { User } from "@spesill/models";
import { EvaluateDocument } from "@spesill/models/evaluateDocument";
import { EvaluateDocumentRepository } from "@spesill/repository/db/evaluateDocument.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

const evaluateDocumentRepository = new EvaluateDocumentRepository();
const userRepository = new UserRepository();

type EvaluateDocumentWithUser = EvaluateDocument & { user?: User };
type FilterType = {
  createUserId?: string;
};

type SortType = {
  field: "updatedAt";
  order: OrderByDirection;
};

export const useEvaluateDocumentsWithUser = (evaluateDatabaseId: string) => {
  const { currentUser } = useCurrentUser();
  const [evaluateDocuments, setEvaluateDocuments] = useState<
    EvaluateDocumentWithUser[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType>();
  const [sort, setSort] = useState<SortType>({
    field: "updatedAt",
    order: "desc",
  });

  const fetchDocumentsWithUser = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const users = await userRepository.findByTenantId(currentUser.tenantId);
    const docs =
      await evaluateDocumentRepository.findByTenantIdAndEvaluateDatabaseId(
        currentUser.tenantId,
        evaluateDatabaseId,
        {
          createUserId: selectedFilter?.createUserId,
        },
        sort,
      );

    const docsWithUser = docs.map((doc) => {
      const user = users.find((user) => user.id === doc.createUserId);
      return Object.defineProperty(doc, "user", {
        value: user,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    });

    setEvaluateDocuments(docsWithUser);
  }, [currentUser?.tenantId, evaluateDatabaseId, selectedFilter, sort]);

  useEffect(() => {
    fetchDocumentsWithUser();
  }, [currentUser?.tenantId, fetchDocumentsWithUser]);

  const deleteDocument = useCallback(
    async (id: string) => {
      if (!currentUser?.tenantId) return;
      const evaluateDocument =
        await evaluateDocumentRepository.findByTenantIdAndId(
          currentUser.tenantId,
          evaluateDatabaseId,
          id,
        );
      await EvaluateDocument.deleteWithStorage({
        evaluateDocument: evaluateDocument,
      });
      await fetchDocumentsWithUser();
    },
    [currentUser, fetchDocumentsWithUser, evaluateDatabaseId],
  );

  const selectCreateUserId = (createUserId: string) => {
    setSelectedFilter({ createUserId });
  };

  const resetFilterCreateUserId = () => {
    setSelectedFilter({ createUserId: undefined });
  };

  const changeSortOrderDirection = (order: OrderByDirection) => {
    setSort((prev) => ({ ...prev, order }));
  };

  useEffect(() => {
    fetchDocumentsWithUser();
  }, [fetchDocumentsWithUser, sort]);

  return {
    fetchDocumentsWithUser,
    deleteDocument,
    selectedFilter,
    sort,
    selectCreateUserId,
    resetFilterCreateUserId,
    changeSortOrderDirection,
    evaluateDocuments,
  };
};
