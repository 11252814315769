import React, { MouseEvent } from "react";

import {
  IconButton,
  ListMenu,
  MenuItemType,
} from "@spesill/components/molecules";

import { useBoolean } from "@spesill/hooks";
import { DisplayRole, User } from "@spesill/models";
import { RoleFilterType } from "@spesill/pages/settings/users";

type PropsType = {
  user: User;
  deleteUnregisteredUser: (id: string) => void;
};

export const UserListRow = ({ user, deleteUnregisteredUser }: PropsType) => {
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  const handleDeleteUnregisteredUser = () => {
    deleteUnregisteredUser(user.id);
    onClose();
  };

  const menuItems: MenuItemType[] = [
    {
      label: "招待を取消",
      className: "text-status-danger",
      onClick: () => handleDeleteUnregisteredUser(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpen();
  };

  return (
    <div
      key={user.id}
      className="bg-white flex gap-x-4 text-blueGray-700 py-3 px-4 rounded-md"
    >
      <div className={`flex-1 ${user.isUnregistered() && "text-blueGray-500"}`}>
        {user.isUnregistered()
          ? "招待中のユーザー"
          : `${user.lastName} ${user.firstName}`}
      </div>
      <div className="truncate w-1/6">{user.email}</div>
      <div className="truncate w-1/6">
        {DisplayRole[user.role as RoleFilterType]}
      </div>
      {user.isUnregistered() ? (
        <div className="relative flex items-center w-1/12">
          <IconButton
            icon={{
              icon: "bsThreeDots",
              color: "text-blueGray-500",
              size: "1.5rem",
            }}
            srOnlyText="メニューを開く"
            onClick={handleOpen}
            disabled={!user.isUnregistered()}
          />
          {isOpen && user.isUnregistered() && (
            <ListMenu
              items={menuItems}
              onClose={onClose}
              className="right-full mr-2"
            />
          )}
        </div>
      ) : (
        <div className="w-1/12" />
      )}
    </div>
  );
};
