import React, { useCallback, useEffect, useState } from "react";

import { apiClient } from "@spesill/libs/apiClient";

type Props = {
  setValue: (value: string) => void;
  tenantId?: string;
  groupId?: string;
};

export default function ChatDetailSuggestions({
  setValue,
  tenantId,
  groupId,
}: Props) {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const getSuggestions = useCallback(async () => {
    if (!tenantId || !groupId) {
      return;
    }
    try {
      const res = await apiClient().suggest_prompts.$post({
        body: {
          tenant_id: tenantId,
          group_id: groupId,
          num_suggestions: 2, // TODO: 何個表示するかは要検討
        },
      });
      setSuggestions(res.suggestions);
    } catch (e) {
      console.error(e);
    }
  }, [tenantId, groupId]);

  useEffect(() => {
    getSuggestions();
  }, [getSuggestions]);

  if (suggestions.length === 0) {
    return null;
  }

  return (
    <div className="mt-1 flex flex-row flex-wrap gap-2">
      {suggestions.map((suggestion) => (
        <button
          key={suggestion}
          type="button"
          className="px-4 py-1 rounded-md bg-blueGray-0"
          onClick={() => setValue(suggestion)}
        >
          <span className="text-sm">{suggestion}</span>
        </button>
      ))}
    </div>
  );
}
