import { useState, useCallback, useEffect } from "react";

import { SingleValueType, OptionType } from "@spesill/components/atoms";

type RecordType = {
  id: string;
  name: string;
};

export function convertToDropdownOption(record: RecordType): OptionType {
  return {
    value: record.id,
    label: record.name,
  };
}

type PropsType = {
  initialValue?: RecordType;
  selectableValue?: RecordType[];
};

type ReturnType = [
  {
    value?: RecordType;
    dropdownValue?: SingleValueType;
    dropdownSelectableValue: OptionType[];
    onChange: (newValue: SingleValueType | null) => void;
    convertToDropdown: (value?: RecordType[]) => OptionType[];
    handleSetSelectableOption: (newValue: RecordType[]) => void;
  },
  (value?: RecordType) => void,
];

export const useDropdown = ({
  initialValue,
  selectableValue = [],
}: PropsType = {}): ReturnType => {
  const [value, setValue] = useState(initialValue);
  const [dropdownSelectableValue, setDropdownSelectableValue] = useState<
    OptionType[]
  >(selectableValue?.map((val) => convertToDropdownOption(val)) || []);
  const convertToDropdownRecords = useCallback((records: RecordType[]) => {
    return records.map((record) => convertToDropdownOption(record));
  }, []);

  const convertToValue = useCallback((record?: SingleValueType) => {
    if (!record) return undefined;
    return {
      id: record.value,
      name: record.label,
    };
  }, []);

  const onChangeDropDown = useCallback(
    (newValue: SingleValueType | null) => {
      const val = newValue ? convertToValue(newValue) : undefined;
      setValue(val);
    },
    [convertToValue],
  );

  const dropdownValue = value ? convertToDropdownOption(value) : undefined;

  const handleSetSelectableOption = useCallback((newValue: RecordType[]) => {
    const options = newValue?.map((val) => convertToDropdownOption(val)) || [];
    setDropdownSelectableValue(options);
  }, []);

  useEffect(() => {
    selectableValue.length && handleSetSelectableOption(selectableValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableValue.length]);

  return [
    {
      value,
      dropdownValue,
      dropdownSelectableValue,
      handleSetSelectableOption,
      onChange: (newValue: SingleValueType | null) =>
        onChangeDropDown(newValue),
      convertToDropdown: useCallback(
        (value?: RecordType[]) => convertToDropdownRecords(value || []),
        [convertToDropdownRecords],
      ),
    },
    useCallback((value?: RecordType) => setValue(value), []),
  ];
};
