import React, { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  DotLoadingAnimation,
  TextArea,
} from "@spesill/components/atoms";
import { LabelWithCheckbox } from "@spesill/components/molecules";
import {
  DocumentInsertFigureModal,
  CreateDocumentFormExcelModal,
} from "@spesill/components/organisms";
import { assignUniqueIdsToNodesArray } from "@spesill/components/organisms/FlowChartEditor";

import {
  useArray,
  useBoolean,
  useCurrentUser,
  useMasterPromptLazy,
  useIncrementAiUsage,
  useInput,
  useFetchTenantDocumentSettings,
  useLearningDatabaseTypeDefault,
  useCreateDocumentPromptHistory,
  useFetchDocumentPromptHistories,
  useLearningDocumentsWithUser,
  useLearningDatabase,
} from "@spesill/hooks";
import { useTenantAiCallRestriction } from "@spesill/hooks";
import { apiClient } from "@spesill/libs/apiClient";
import { Document } from "@spesill/models";
import { RequestGeneratePunchImage } from "@spesill/models/api/generate_punch_image";
import { RequestGeneratePunchImageInDetail } from "@spesill/models/api/generate_punch_image_in_detail";
import { DocumentPromptHistory } from "@spesill/models/DocumentPromptHistory";

import { AnswerType, DocumentAnswers } from "./DocumentAnswers";
import { DocumentInsertMenus } from "./DocumentInsertMenus";
import { DocumentPromptHistoryItem } from "./DocumentPromptHistoryItem";

type PropsType = {
  className?: string;
  documentInfo?: Document;
  systemName: string;
  headingTitle: string;
  learningDatabaseId?: string;
  requestAi: boolean;
  setInsertText: (
    insertText: string,
    isHtml?: boolean,
    isChart?: boolean,
  ) => void;
  addToQueue?: (func: () => Promise<void>) => void;
  removeFromRequestHeadingTitles?: (headingTitle: string) => void;
};

type AiRequestMenuType =
  | "createText"
  | "createTable"
  | "createCheckbox"
  | "createFigure"
  | "createExcel";

export const DocumentInsertTextForm: FC<PropsType> = ({
  documentInfo,
  systemName,
  headingTitle,
  learningDatabaseId,
  requestAi,
  className = "",
  setInsertText,
  addToQueue,
  removeFromRequestHeadingTitles,
}: PropsType) => {
  const { currentUser, currentPlan } = useCurrentUser();
  const { checkCanCallAi } = useTenantAiCallRestriction();
  const { incrementAiCallCount } = useIncrementAiUsage();
  const { fetchMasterPrompt } = useMasterPromptLazy();
  const { createPromptHistory } = useCreateDocumentPromptHistory(
    documentInfo?.id || "",
  );
  const { documentPromptHistories } = useFetchDocumentPromptHistories(
    documentInfo?.id || "",
  );
  const { fetchTenantDocumentSettings } = useFetchTenantDocumentSettings();
  const { fetchLearningDatabaseByTypeDefault } = useLearningDatabaseTypeDefault(
    learningDatabaseId || "",
  );
  const { learningDocuments } = useLearningDocumentsWithUser(
    learningDatabaseId || "",
  );
  const { learningDatabase } = useLearningDatabase(learningDatabaseId || "");

  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);
  const {
    isChecked: isCreateOptionOpen,
    setTrue: setCreateOptionOpenTrue,
    setFalse: setCreateOptionOpenFalse,
  } = useBoolean(false);
  const { isChecked: isCustomOrder, onChange: onChangeCustomOrder } =
    useBoolean(false);
  const [
    {
      value: customOrderText,
      onChange: onChangeCustomOrderText,
      setValue: setCustomOrderTextDirectly,
    },
  ] = useInput("");
  const {
    items: answers,
    pushItem: pushAnswer,
    setEmpty: setEmptyAnswer,
    updateIndexItem: updateAnswer,
  } = useArray<AnswerType>([]);
  const [selectAiRequestMenu, setSelectAiRequestMenu] =
    useState<AiRequestMenuType>("createText");

  const menuMapper: Record<AiRequestMenuType, string> = {
    createText: "文章",
    createTable: "表",
    createCheckbox: "チェックリスト",
    createFigure: "ポンチ絵・図",
    createExcel: "Excelから文章",
  };

  const getRequestType = useCallback(() => {
    switch (selectAiRequestMenu) {
      case "createText":
        return "text";
      case "createTable":
        return "table";
      case "createCheckbox":
        return "checkbox";
      default:
        return undefined;
    }
  }, [selectAiRequestMenu]);

  const aiRequest = useCallback(
    async (e?: MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.preventDefault();
      }
      if (!currentUser) return;

      const canCallAi = await checkCanCallAi();

      if (!canCallAi) {
        toast.error("AIの利用回数が上限に達しました。");
        return;
      }

      const defaultLearningDatabase =
        learningDatabaseId && currentPlan === "FREE"
          ? await fetchLearningDatabaseByTypeDefault()
          : undefined;

      try {
        setRequestingTrue();
        const masterPrompt = await fetchMasterPrompt();
        const tenantDocumentSettings = await fetchTenantDocumentSettings();
        if (isCustomOrder && customOrderText) {
          await createPromptHistory(customOrderText);
        }

        const res = await apiClient().generate_spec.$post({
          body: {
            tenant_id:
              currentPlan === "FREE"
                ? defaultLearningDatabase?.tenantId || ""
                : currentUser.tenantId,
            file_name: systemName,
            group_id: learningDatabaseId,
            kind: documentInfo?.kind || "specification",
            free_input:
              documentInfo?.kind === "others"
                ? documentInfo?.freeInputKind
                : undefined,
            heading: headingTitle,
            request_type: getRequestType(),
            input_prompt: masterPrompt.prompt,
            user_prompt: isCustomOrder
              ? customOrderText
              : documentInfo?.documentCustomPrompt
                ? documentInfo.documentCustomPrompt
                : tenantDocumentSettings?.customPrompt
                  ? tenantDocumentSettings.customPrompt
                  : "",
            language: documentInfo?.language
              ? documentInfo?.language
              : "DEFAULT",
            firestore_document_ids: documentInfo?.referenceDocumentIds,
          },
        });

        incrementAiCallCount("generateSpec");
        pushAnswer({
          responseId: res.response_id,
          spec: res.spec,
          sources: res.sources,
        });
      } catch (error) {
        toast.error(
          "AI入力に失敗しました。時間を開けてご利用いただくか、サポートにお問い合わせください。",
        );
      } finally {
        setRequestingFalse();
      }
    },
    [
      currentUser,
      checkCanCallAi,
      learningDatabaseId,
      currentPlan,
      fetchLearningDatabaseByTypeDefault,
      setRequestingTrue,
      fetchMasterPrompt,
      fetchTenantDocumentSettings,
      isCustomOrder,
      customOrderText,
      systemName,
      documentInfo?.kind,
      documentInfo?.freeInputKind,
      documentInfo?.documentCustomPrompt,
      documentInfo?.language,
      documentInfo?.referenceDocumentIds,
      headingTitle,
      getRequestType,
      incrementAiCallCount,
      pushAnswer,
      createPromptHistory,
      setRequestingFalse,
    ],
  );

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    removeFromRequestHeadingTitles &&
      removeFromRequestHeadingTitles(headingTitle);
    setEmptyAnswer();
  };

  const isHtml = ["createTable", "createCheckbox", "createFigure"].includes(
    selectAiRequestMenu,
  );

  const handleReflectInsertText = (value: string) => {
    setInsertText(value, isHtml, selectAiRequestMenu === "createFigure");
    setEmptyAnswer();
  };

  const promptHistories = Array.from(
    new Set(
      documentPromptHistories.map(
        (history: DocumentPromptHistory) => history.customPrompt,
      ),
    ),
  );

  const onSubmitSimpleLayout = async (
    formData: Pick<RequestGeneratePunchImage, "description">,
  ) => {
    setRequestingTrue();
    if (!currentUser?.tenantId) return;
    try {
      const res = await apiClient().generate_punch_image.$post({
        body: {
          description: formData.description,
          tenant_id: currentUser?.tenantId,
          group_id: learningDatabaseId,
        },
      });
      incrementAiCallCount("generatePunchImage");

      handleReflectInsertText(
        JSON.stringify({
          width: res.width,
          height: res.height,
          punchItems: res.punch_items,
        }),
      );
      return res;
    } catch (error) {
      toast.error("ポンチレイアウトの作成に失敗しました。");
    } finally {
      setRequestingFalse();
    }
  };

  const onSubmitDetailLayout = async (
    formData: RequestGeneratePunchImageInDetail,
  ) => {
    setRequestingTrue();
    if (!currentUser?.tenantId) return;
    try {
      const res = await apiClient().generate_punch_image_in_detail.$post({
        body: {
          base_info: formData.base_info,
          feature_info: formData.feature_info,
          item_configurations: formData.item_configurations,
          horizontal_items: formData.horizontal_items,
          vertical_items: formData.vertical_items,
          width: formData.width,
          height: formData.height,
          tenant_id: currentUser?.tenantId,
          group_id: learningDatabaseId,
        },
      });
      incrementAiCallCount("generatePunchImageInDetail");
      handleReflectInsertText(
        JSON.stringify({
          width: res.width,
          height: res.height,
          punchItems: res.punch_items,
        }),
      );
      return res;
    } catch (error) {
      toast.error("ポンチレイアウトの作成に失敗しました。");
    } finally {
      setRequestingFalse();
    }
  };

  const onSubmitFlowChart = async (
    figureType: "2DFlowchart" | "2DLayout",
    formData: { query: string },
  ) => {
    setRequestingTrue();
    try {
      if (figureType === "2DFlowchart" && currentUser?.tenantId) {
        const res = await apiClient().extract_flowchart_format.$post({
          body: {
            query: formData.query,
            tenant_id: currentUser.tenantId,
            group_id: learningDatabaseId,
            heading: headingTitle,
            kind: documentInfo?.kind || "specification",
            free_input:
              documentInfo?.kind === "others"
                ? documentInfo?.freeInputKind
                : undefined,
          },
        });
        // FIXME API側で一意なidを割り当てるように修正後→削除
        const flowFormat = assignUniqueIdsToNodesArray(
          res.react_flow_builder_format,
        );
        incrementAiCallCount("extractFlowchartFormat");
        const value = JSON.stringify(flowFormat);
        handleReflectInsertText(value);
      }
    } catch (error) {
      toast.error("フローチャートの作成に失敗しました。");
    } finally {
      setRequestingFalse();
    }
  };

  useEffect(() => {
    if (requestAi) {
      addToQueue && addToQueue(aiRequest);
    }
  }, [addToQueue, aiRequest, requestAi]);

  return (
    <div className={className} contentEditable={false}>
      {selectAiRequestMenu === "createFigure" && (
        <DocumentInsertFigureModal
          isRequesting={isRequesting}
          onClose={() => {
            setCreateOptionOpenFalse();
            setSelectAiRequestMenu("createText");
          }}
          onSubmitFlowChart={onSubmitFlowChart}
          onSubmitSimpleLayout={onSubmitSimpleLayout}
          onSubmitDetailLayout={onSubmitDetailLayout}
        />
      )}
      {selectAiRequestMenu === "createExcel" && (
        <CreateDocumentFormExcelModal
          onClose={() => {
            setCreateOptionOpenFalse();
            setSelectAiRequestMenu("createText");
          }}
          databaseSystemName={learningDatabase?.systemName}
          excelDocuments={learningDocuments.filter((doc) => doc.isExcel())}
          onSubmit={async (
            prompt: string,
            fileName: string,
            SheetName: string,
          ) => {
            setRequestingTrue();
            if (!learningDatabaseId) {
              toast.error("学習データベースが設定されていません");
              return;
            }
            if (!currentUser?.tenantId) {
              return;
            }
            try {
              const res = await apiClient().generate_text_from_sheets.$post({
                body: {
                  tenant_id: currentUser.tenantId,
                  heading: headingTitle,
                  user_prompt: prompt,
                  language: documentInfo?.language || "DEFAULT",
                  sheets: [
                    {
                      filename: fileName,
                      sheet_name: SheetName,
                    },
                  ],
                  group_id: learningDatabaseId,
                  documentType: "learning",
                },
              });
              pushAnswer({
                responseId: res.response_id,
                spec: res.text,
                sources: res.sources,
              });
              incrementAiCallCount("generateTextFromSheets");
            } catch (error) {
              toast.error("Excelから文章の作成に失敗しました。");
            } finally {
              setRequestingFalse();
            }
          }}
        />
      )}
      {answers.length > 0 ? (
        <DocumentAnswers
          isRequesting={isRequesting}
          answers={answers}
          handleReflectInsertText={handleReflectInsertText}
          handleCancel={handleCancel}
          handleRegenerate={aiRequest}
          updateAnswer={updateAnswer}
          isHtml={isHtml}
        />
      ) : (
        <div className="mb-4 bg-white p-4 rounded-md shadow-lg w-full flex items-center justify-between">
          {isRequesting ? (
            <DotLoadingAnimation className="justify-center mx-auto my-1.5 opacity-95" />
          ) : (
            <div className="w-full">
              <div className="flex items-center justify-between relative">
                <span className="text-body text-blueGray-300 block">
                  AIに文章を依頼する...
                </span>
                <div className="flex items-center gap-x-4">
                  <LabelWithCheckbox
                    labelText="カスタム命令を追加"
                    name="customOrder"
                    checked={isCustomOrder}
                    onChange={onChangeCustomOrder}
                    value="customOrder"
                    size="lg"
                  />
                  <div className="bg-primary-400 rounded flex items-center">
                    <Button
                      text={`${menuMapper[selectAiRequestMenu]}を作成`}
                      color={"primary"}
                      variant={"contained"}
                      onClick={aiRequest}
                      className="rounded-r-none"
                    />
                    <Button
                      text="▼"
                      color={"primary"}
                      variant={"contained"}
                      className="!px-2 rounded-l-none"
                      onClick={() => setCreateOptionOpenTrue()}
                    />
                  </div>
                </div>
                {isCreateOptionOpen && (
                  <DocumentInsertMenus
                    onClose={() => setCreateOptionOpenFalse()}
                    className=""
                    setSelectAiRequestMenu={setSelectAiRequestMenu}
                    aiRequestMenu={selectAiRequestMenu}
                  />
                )}
              </div>

              {isCustomOrder && (
                <>
                  <TextArea
                    className="mt-2 mb-4"
                    placeholder="カスタム命令を入力"
                    value={customOrderText}
                    onChange={onChangeCustomOrderText}
                    rows={5}
                    name={"customOrderText"}
                  />

                  <div className="space-y-2 max-h-40 overflow-auto">
                    {promptHistories.map((prompt: string, i: number) => (
                      <DocumentPromptHistoryItem
                        key={i}
                        text={prompt}
                        reflectPromptHistory={() =>
                          setCustomOrderTextDirectly(prompt)
                        }
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
