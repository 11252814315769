import React, { useState, FormEvent, useEffect } from "react";
import { toast } from "react-toastify";

import { ModalWrapper } from "@spesill/components/molecules";

import { privateStorage } from "@spesill/config/firebase";
import { useBoolean, useCurrentUser, useFile, useInput } from "@spesill/hooks";
import { uploadBytes, ref, getDownloadURL } from "@spesill/libs/firebase";
import { PunchImage } from "@spesill/models";
import { getFileFromUrl } from "@spesill/utils/fileHelper";

import { PunchIconFileUploader } from "./PunchIconFileUploader";
import { PunchIconForm } from "./PunchIconForm";

type PropsType = {
  onClose: () => void;
  punchImage: ExcludeMethods<PunchImage>;
};
export const EditPunchIconModal = ({ punchImage, onClose }: PropsType) => {
  const {
    isChecked: isDetailMode,
    setTrue: setDetailModel,
    setFalse: setSimpleMode,
  } = useBoolean(true);

  const { files, handleDropFile, setFiles } = useFile();
  const [isRequesting, setIsRequesting] = useState(false);
  const { currentUser } = useCurrentUser();

  const [{ value: itemName, onChange: onChangeItemName }] = useInput(
    punchImage.name,
  );
  const [arrangementType, onChangeArrangementType] = useState<
    "single" | "multi"
  >(punchImage.arrangementType);
  const [{ value: itemDescription, onChange: onChangeItemDescription }] =
    useInput(punchImage.description);
  const [itemHeight, onChangeItemHeight] = useState(punchImage.height);

  const modeBack = () => {
    setSimpleMode();
    setFiles([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (punchImage.path) {
        const fileObj = await getFileFromUrl({
          url: punchImage.path,
          filename: punchImage.name,
        });
        setFiles([fileObj]);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [punchImage.path, setFiles]);

  const file = files[0];

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);
    if (!currentUser || !file) return;

    try {
      const storageRef = ref(
        privateStorage,
        `tenants/${currentUser.tenantId}/punch_images/${punchImage.id}`,
      );

      return uploadBytes(storageRef, file).then(async () => {
        const punchImageUrl = await getDownloadURL(storageRef);
        await PunchImage.update({
          ...punchImage,
          name: itemName,
          description: itemDescription,
          height: itemHeight,
          width: itemHeight,
          arrangementType: arrangementType,
          itemTypes: arrangementType === "multi" ? ["PATHWAY"] : [],
          path: punchImageUrl,
        });
        toast.success("アイコンを変更しました");
        onClose();
      });
    } catch (e) {
      toast.error(
        e instanceof Error ? e.message : "アイコンの変更に失敗しました",
      );
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="アイコンを編集">
      {isDetailMode ? (
        <PunchIconForm
          modeBack={modeBack}
          onClose={onClose}
          file={file}
          handleSubmit={handleSubmit}
          itemName={itemName}
          onChangeItemName={onChangeItemName}
          itemDescription={itemDescription}
          onChangeItemDescription={onChangeItemDescription}
          itemHeight={itemHeight}
          onChangeItemHeight={onChangeItemHeight}
          arrangementType={arrangementType}
          onChangeArrangementType={onChangeArrangementType}
          isRequesting={isRequesting}
        />
      ) : (
        <PunchIconFileUploader
          onClose={onClose}
          handleDropFile={handleDropFile}
          setDetailModel={setDetailModel}
          files={files}
        />
      )}
    </ModalWrapper>
  );
};
