import { useToPng } from "@hugocxl/react-to-image";

export const useDownloadDom = () => {
  const [, convert, ref] = useToPng<HTMLDivElement>({
    onSuccess: (base64) => {
      const link = document.createElement("a");
      link.download = `${new Date().getTime()}.png`;
      link.href = base64;
      link.click();
    },
  });

  return {
    convert,
    ref,
  };
};
