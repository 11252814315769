import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { LearningDocument } from "@spesill/models";
import { LearningDocumentRepository } from "@spesill/repository/db/learningDocument.repository";

const learningDocumentRepository = new LearningDocumentRepository();

export const useLearningDocument = (documentId: string) => {
  const { currentUser } = useCurrentUser();
  const [learningDocument, setLearningDocument] = useState<LearningDocument>();

  const fetchLearningDocument = useCallback(async () => {
    if (!currentUser?.tenantId || !documentId) return;

    const learningDocument =
      await learningDocumentRepository.findById(documentId);
    if (learningDocument.deletedAt) {
      setLearningDocument(undefined);
      return;
    }
    setLearningDocument(learningDocument);
  }, [currentUser?.tenantId, documentId]);

  useEffect(() => {
    fetchLearningDocument();
  }, [fetchLearningDocument]);

  return { fetchLearningDocument, learningDocument };
};
