import React, { Component, ErrorInfo } from "react";

import Image from "next/image";

type PropsType = {
  children: React.ReactNode;
};

export class ErrorBoundary extends Component<PropsType> {
  state = { hasError: false, isNotFoundError: false };

  static getDerivedStateFromError(error: unknown) {
    console.error("Uncaught error:", error);
    if (
      error instanceof Error &&
      error.message.includes("Failed to execute 'removeChild' on 'Node'")
    ) {
      return { hasError: true, isNotFoundError: true };
    }
    if (
      error instanceof Error &&
      error.message.includes("Failed to execute 'insertBefore' on 'Node'")
    ) {
      return { hasError: true, isNotFoundError: true };
    }
    return { hasError: true, isNotFoundError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    // FIXME:NotFoundError Failed to execute 'removeChild' on 'Node' / Failed to execute 'insertBefore' on 'Node'が検出された場合は暫定対応としてchildrenを返す
    // それ以外のエラーの場合、エラー画面を表示する
    if (this.state.hasError && !this.state.isNotFoundError) {
      return (
        <Image
          src="/error_404.png"
          alt="エラー"
          className="mx-auto mt-5"
          width="300"
          height="300"
        />
      );
    }
    return this.props.children;
  }
}
