import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { TenantDocumentSettings } from "@spesill/models";
import { TenantDocumentSettingsRepository } from "@spesill/repository/db/tenantDocumentSettings.repository";

const tenantDocumentSettingsRepository = new TenantDocumentSettingsRepository();

export const useFetchTenantDocumentSettings = () => {
  const { currentUser } = useCurrentUser();
  const [tenantDocumentSettings, setTenantDocumentSettings] =
    useState<TenantDocumentSettings>();

  const fetchTenantDocumentSettings = useCallback(async () => {
    if (!currentUser) {
      return;
    }
    const doc = await tenantDocumentSettingsRepository.findOrAddById(
      currentUser.tenantId,
    );
    setTenantDocumentSettings(doc);

    return doc;
  }, [currentUser]);

  useEffect(() => {
    fetchTenantDocumentSettings();
  }, [fetchTenantDocumentSettings]);

  return { fetchTenantDocumentSettings, tenantDocumentSettings };
};
