import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useRouter } from "next/router";

import { useCurrentUser } from "@spesill/hooks";
import { Document } from "@spesill/models";
import { DocumentUserPermission } from "@spesill/models/document";
import { DocumentRepository } from "@spesill/repository/db/document.repository";

const documentRepository = new DocumentRepository();

export const useDocumentWithPermission = (
  id: string,
  option?: { onlyPermissionCheck?: boolean },
) => {
  const { currentUser } = useCurrentUser();

  const [document, setDocument] = useState<Document>();
  const [userPermission, setUserPermission] =
    useState<DocumentUserPermission>("viewer");

  const router = useRouter();

  const fetchDocumentWithPermission = useCallback(async () => {
    if (!currentUser?.tenantId || !id) return;

    const doc = await documentRepository.findByTenantIdAndId(
      currentUser.tenantId,
      id,
    );

    if (doc.deletedAt) {
      setDocument(undefined);
      return;
    }

    const permission = await doc.userPermission(currentUser);

    if (permission === "none") {
      setUserPermission("none");
      setDocument(undefined);
    } else {
      setUserPermission(permission);
      setDocument(doc);
    }
  }, [currentUser, id]);

  useEffect(() => {
    fetchDocumentWithPermission();
  }, [fetchDocumentWithPermission]);

  // アクセス権がない場合にはトップページにリダイレクトする
  useEffect(() => {
    if (userPermission === "none" && (!option || !option.onlyPermissionCheck)) {
      router.push("/");
      toast.error("閲覧権限がありません");
    }
  }, [userPermission, option, router]);

  return { fetchDocumentWithPermission, document, userPermission };
};
