import React, { useState } from "react";

import { LoadingAnimationText, Icon } from "@spesill/components/atoms";
import {
  ModalWrapper,
  TabSwitcherButtons,
} from "@spesill/components/molecules";
import {
  PunchLayoutSimpleForm,
  FlowChartForm,
  PunchLayoutDetailForm,
} from "@spesill/components/organisms";

import {
  RequestGeneratePunchImage,
  ResponseGeneratePunchImage,
} from "@spesill/models/api/generate_punch_image";
import {
  RequestGeneratePunchImageInDetail,
  ResponseGeneratePunchImageInDetail,
} from "@spesill/models/api/generate_punch_image_in_detail";

type PropsType = {
  onClose: () => void;
  // FIXME: APIの定義に合わせてformDataの型を定義する
  onSubmitFlowChart?: (
    figureType: "2DLayout" | "2DFlowchart",
    formData: { query: string },
  ) => void;
  onSubmitSimpleLayout?: (
    formData: Pick<RequestGeneratePunchImage, "description">,
  ) => Promise<ResponseGeneratePunchImage | undefined>;
  onSubmitDetailLayout?: (
    formData: RequestGeneratePunchImageInDetail,
  ) => Promise<ResponseGeneratePunchImageInDetail | undefined>;
  isRequesting?: boolean;
};

export const DocumentInsertFigureModal = ({
  onClose,
  onSubmitFlowChart,
  onSubmitSimpleLayout,
  onSubmitDetailLayout,
  isRequesting = false,
}: PropsType) => {
  const [createFigureType, setCreateFigureType] = useState<
    "2DLayout" | "2DFlowchart"
  >("2DLayout");

  const [createSettingType, setCreateSettingType] = useState<
    "simple" | "detail"
  >("simple");

  const isButtonStyleMap = (isSelected: boolean) => {
    return isSelected
      ? "bg-primary-50 border-primary-400 text-primary-400"
      : "bg-white border-textGray-900 text-textGray-900";
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="ポンチ絵・図の作成">
      <div className="space-y-6 overflow-y-auto">
        <div className="pt-8">
          <p>AIにどのような図を作成して欲しいですか？</p>
          <div className="grid grid-cols-2 gap-x-4 w-full mt-3">
            <button
              className={`text-center p-4 border border-solid rounded ${isButtonStyleMap(
                createFigureType === "2DLayout",
              )}`}
              type="button"
              onClick={() => setCreateFigureType("2DLayout")}
            >
              <div className="">
                <Icon
                  className="mx-auto"
                  icon="luLayoutDashboard"
                  size="2rem"
                  color={
                    createFigureType === "2DLayout"
                      ? "text-primary-400"
                      : "text-textGray-900"
                  }
                />
                <span className="mt-2">レイアウト図</span>
              </div>
            </button>
            <button
              className={`text-center p-4 border border-solid rounded ${isButtonStyleMap(
                createFigureType === "2DFlowchart",
              )}`}
              type="button"
              onClick={() => setCreateFigureType("2DFlowchart")}
            >
              <div className="">
                <Icon
                  className="mx-auto"
                  icon="riOrganizationChart"
                  size="2rem"
                  color={
                    createFigureType === "2DFlowchart"
                      ? "text-primary-400"
                      : "text-textGray-900"
                  }
                />
                <span className="mt-2">フローチャート図</span>
              </div>
            </button>
          </div>
          <div>
            {createFigureType === "2DLayout" && (
              <div>
                <TabSwitcherButtons
                  items={[
                    {
                      text: "簡易設定",
                      onClick: () => setCreateSettingType("simple"),
                    },
                    {
                      text: "詳細設定",
                      onClick: () => setCreateSettingType("detail"),
                    },
                  ]}
                  currentTabText={
                    createSettingType === "simple" ? "簡易設定" : "詳細設定"
                  }
                  className="mt-8"
                  buttonClassName="w-full"
                />
                {createSettingType === "simple" && onSubmitSimpleLayout && (
                  <PunchLayoutSimpleForm
                    onClose={onClose}
                    onSubmit={onSubmitSimpleLayout}
                    className="mt-5"
                    disabled={isRequesting}
                  />
                )}
                {createSettingType === "detail" && onSubmitDetailLayout && (
                  <PunchLayoutDetailForm
                    onClose={onClose}
                    onSubmit={onSubmitDetailLayout}
                    className="mt-5"
                  />
                )}
              </div>
            )}
            {createFigureType === "2DFlowchart" && (
              <FlowChartForm
                onClose={onClose}
                className="mt-8"
                onSubmit={onSubmitFlowChart}
              />
            )}
          </div>
          {isRequesting && (
            <LoadingAnimationText
              text={"図を生成中。。。 しばらくお待ちください。"}
              className="mt-8"
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
