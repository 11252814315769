import { v4 } from "uuid";

import { doc, setDoc } from "@spesill/libs/firebase";
import { AiRequestHistory } from "@spesill/models/AiRequestHistory";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class AiRequestHistoryRepository extends DBRepositoryBase<
  ExcludeMethods<AiRequestHistory>
> {
  private PATH = "aiRequestHistories";

  add = async (
    aiRequestHistory: Omit<
      ExcludeMethods<AiRequestHistory>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const docId = v4();
    const newAiRequestHistory = {
      ...aiRequestHistory,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(
      doc(this.db, this.PATH, docId),
      this.objectToDoc(newAiRequestHistory),
    );

    return new AiRequestHistory({ ...newAiRequestHistory, id: docId });
  };
}
