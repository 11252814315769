import React from "react";

import dynamic from "next/dynamic";

import { Button } from "@spesill/components/atoms";

import { LearningDocument } from "@spesill/models";

import { DocxFileViewer } from "../../FileViewer/DocxFileViewer";
import { ImageViewer } from "../../FileViewer/imageViewer";
import { OfficeFileViewer } from "../../FileViewer/OfficeFileViewer";

const DynamicPdfFileViewer = dynamic(
  () =>
    import("@spesill/components/organisms/FileViewer/PdfFileViewer").then(
      (mod) => mod.PdfFileViewer,
    ),
  {
    ssr: false,
  },
);

type Props = {
  dbName: string;
  document: LearningDocument;
  onBack: () => void;
};

const Preview = ({ dbName, document, onBack }: Props) => {
  return (
    <div>
      <div className="mb-4">
        <h4 className="text-h4 text-blueGray-900">
          {document.systemName}のプレビュー
        </h4>
        <p className="text-blueGray-500">{dbName}</p>
      </div>
      <div className="bg-blueGray-50 text-sm -translate-x-6 w-[calc(100%+3rem)] overflow-y-auto h-96">
        {(() => {
          switch (document.extension()) {
            case ".docx":
              return (
                <DocxFileViewer
                  path={document.path}
                  systemName={document.systemName}
                />
              );
            case ".xlsx": // TODO: xlsxファイルのlocationを受け取る
              return <OfficeFileViewer path={document.path} />;
            case ".pdf":
              return <DynamicPdfFileViewer path={document.path} />;
            case ".jpg":
            case ".jpeg":
            case ".png":
            case ".gif":
            case ".webp":
              return <ImageViewer path={document.path} />;
            default:
              return (
                <div>拡張子がdocx,xlsx,pdfのファイルを選択してください</div>
              );
          }
        })()}
      </div>
      <div className="-translate-x-6 w-[calc(100%+3rem)] px-4 pt-4 border-t border-t-blueGray-50">
        <div className="flex justify-end">
          <Button
            text={"戻る"}
            color={"gray"}
            variant={"contained"}
            onClick={onBack}
          />
        </div>
      </div>
    </div>
  );
};

export default Preview;
