import { useState, useCallback } from "react";

import { MultiValueType, SingleValueType } from "@spesill/components/atoms";

import { DocumentPermissionType } from "@spesill/models/document";

type ReturnType = {
  dropdownValue: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (newValue: SingleValueType) => void;
};

const PermissionLabelHash = {
  all: "会社全体",
  specific: "特定のユーザーと共有",
};

export const useDocumentPermissionDropdownField = (
  initialPermissionLevel: DocumentPermissionType,
): ReturnType => {
  const initialLabel = PermissionLabelHash[initialPermissionLevel];
  const [value, setValue] = useState<SingleValueType>({
    value: initialPermissionLevel,
    label: initialLabel,
  });

  const [dropdownSelectableValue] = useState<MultiValueType>([
    { value: "all", label: "会社全体" },
    { value: "specific", label: "特定のユーザーと共有" },
  ]);

  const onChangeDropDown = useCallback((newValue: SingleValueType) => {
    setValue(newValue);
  }, []);

  return {
    dropdownValue: value,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
  };
};
