import { v4 } from "uuid";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
} from "@spesill/libs/firebase";
import { PunchLayout } from "@spesill/models/punchLayout";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class PunchLayoutRepository extends DBRepositoryBase<
  ExcludeMethods<PunchLayout>
> {
  private PATH = "punchLayouts";

  add = async (
    document: Omit<
      ExcludeMethods<PunchLayout>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const docId = v4();
    const newLayout = {
      ...document,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
      deletedAt: undefined,
    };

    await setDoc(doc(this.db, this.PATH, docId), this.objectToDoc(newLayout));

    return new PunchLayout({ ...newLayout, id: docId });
  };

  findById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      return new PunchLayout(this.docToObject(document));
    }
  };

  updateById = async (
    id: string,
    layout: Omit<ExcludeMethods<PunchLayout>, "id" | "createdAt" | "updatedAt">,
  ) => {
    const ref = doc(this.db, this.PATH, id);
    const documentData = await getDoc(ref);

    if (!documentData.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        ...this.objectToDoc(layout),
        updatedAt: new Date(),
      });
      return new PunchLayout(this.docToObject(documentData));
    }
  };

  findByTenantIdAndId = async (tenantId: string, id: string) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
      where("__name__", "==", id),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    if (!docs[0]) {
      throw new Error("ドキュメントが見つかりませんでした");
    }
    return new PunchLayout(this.docToObject(docs[0]));
  };

  findAll = async ({ tenantId }: { tenantId: string }) => {
    const q = query(
      collection(this.db, this.PATH),
      where("tenantId", "==", tenantId),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    return docs.map((doc) => new PunchLayout(this.docToObject(doc)));
  };

  deleteById = async (id: string) => {
    const ref = doc(this.db, this.PATH, id);
    const document = await getDoc(ref);

    if (!document.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await deleteDoc(ref);
    }
  };
}
