import { PunchLayoutRepository } from "@spesill/repository/db/punchLayout.repository";

import { Entity } from "./__common__/entity";

export type PunchItemsType = {
  x: number;
  y: number;
  geometry: {
    punch_image_id: string;
    url: string;
    grid_w: number;
    grid_h: number;
  };
  rotation: 0 | 90 | 180 | 270;
  offset_x: number;
  offset_y: number;
};

export class PunchLayout extends Entity {
  id: string;
  tenantId: string;

  width: number;
  height: number;
  punchItems: PunchItemsType[];
  punchLayoutRequestHistoryId: string;

  constructor(arg: ExcludeMethods<PunchLayout>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.width = arg.width;
    this.height = arg.height;
    this.punchItems = arg.punchItems;
    this.punchLayoutRequestHistoryId = arg.punchLayoutRequestHistoryId;
  }

  static create(
    args: Omit<
      ConstructorParameters<typeof PunchLayout>[0],
      "id" | "createdAt" | "updatedAt"
    >,
  ) {
    const newPunch = {
      deletedAt: undefined,
      ...args,
    };
    const punchLayoutRepository = new PunchLayoutRepository();
    return punchLayoutRepository.add({ ...newPunch });
  }

  static async update(
    args: Omit<
      ConstructorParameters<typeof PunchLayout>[0],
      "createdAt" | "updatedAt"
    >,
  ) {
    const punchLayoutRepository = new PunchLayoutRepository();
    const newPunch = {
      deletedAt: undefined,
      ...args,
    };
    punchLayoutRepository.updateById(args.id, { ...newPunch });
  }
}
