import { NodeViewWrapper, NodeViewContent, NodeViewProps } from "@tiptap/react";
import React, { useState } from "react";

import { Icon } from "@spesill/components/atoms";

import { Document } from "@spesill/models";

import { DocumentInsertTextForm } from "../../ConvertedHTMLDetail/DocumentHeadingTitleForm/DocumentInsertTextForm/DocumentInsertTextForm";

type PropsType = {
  node: NodeViewProps["node"];
  documentInfo: Document;
  insertValue: (value: string, isHtml?: boolean, isChart?: boolean) => void;
};

export const HeadingWithMenu = ({
  node,
  documentInfo,
  insertValue,
}: PropsType) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleInsertText = (
    text: string,
    isHtml?: boolean,
    isChart?: boolean,
  ) => {
    insertValue(text, isHtml, isChart);
    setIsOpen(false);
  };

  return (
    <NodeViewWrapper>
      <div className="relative">
        <button
          type="button"
          className="flex items-center group space-x-1 w-full"
          onClick={toggleMenu}
        >
          <Icon
            icon="tbPencilPlus"
            size="1.7rem"
            color="text-white"
            className="bg-primary-400 p-1 shadow rounded-full hidden group-hover:block"
          />
          <div className="w-full text-left">
            {/* // NOTE:元のhtmlの見出し(h1~h5)のコンテンツはココ*/}
            <NodeViewContent
              className={`heading-${node.attrs.level}`}
              as={`h${node.attrs.level}` as "h1" | "h2" | "h3" | "h4" | "h5"}
            />
          </div>
        </button>
        <div className="remove-element">
          {isOpen && (
            <DocumentInsertTextForm
              systemName={documentInfo.systemName}
              headingTitle={node.textContent}
              documentInfo={documentInfo}
              requestAi={false}
              setInsertText={handleInsertText}
              learningDatabaseId={documentInfo?.learningDatabaseId || undefined}
            />
          )}
        </div>
      </div>
    </NodeViewWrapper>
  );
};
