import React from "react";

type PropsType = {
  children: React.ReactNode;
  onClick: () => void;
};

export default function SuggestionButton({ children, onClick }: PropsType) {
  return (
    <button
      type="button"
      className="bg-white block px-4 py-3 rounded-md border border-blueGray-50 hover:border-2 hover:border-primary-400"
      onClick={onClick}
    >
      <p className="text-left">{children}</p>
    </button>
  );
}
