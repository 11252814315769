import React, { FC } from "react";

type PropsType = {
  text: string;
  className?: string;
};

export const LoadingAnimationText: FC<PropsType> = ({
  text,
  className = "",
}: PropsType) => {
  return (
    <div
      className={`text-primary-700 animate-pulse flex justify-center ${className}`}
    >
      <span>{text}</span>
    </div>
  );
};
