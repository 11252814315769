import React, { FC } from "react";

type PropsType = {
  className?: string;
};

export const TextLoadingAnimation: FC<PropsType> = ({
  className = "",
}: PropsType) => {
  return (
    <div className={`rounded-md max-w-sm w-full mx-auto ${className}`}>
      <div className="space-y-3">
        <div
          className="h-2 bg-blueGray-100 dark:bg-blueGray-300 rounded-full animate-pulse"
          style={{ animationDelay: "0s", animationDuration: "1.5s" }}
        />
        <div
          className="h-2 bg-blueGray-100 dark:bg-blueGray-300 rounded-full animate-pulse w-5/6"
          style={{ animationDelay: "0.2s", animationDuration: "1.5s" }}
        />
        <div
          className="h-2 bg-blueGray-100 dark:bg-blueGray-300 rounded-full animate-pulse"
          style={{ animationDelay: "0.4s", animationDuration: "1.5s" }}
        />
      </div>
    </div>
  );
};
