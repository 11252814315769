import React, { FC } from "react";

import { PostsNotification } from "@spesill/models";

type PropsType = {
  postsNotification: ExcludeMethods<PostsNotification>;
  className?: string;
};

export const PostsNotificationTitle: FC<PropsType> = ({
  postsNotification,
  className = "",
}) => {
  const { scopeTarget, createUserName, itemType, resourceTitle, action } =
    postsNotification;

  const userNameText =
    scopeTarget === "myself" ? "自分が" : `${createUserName}さんが`;
  const itemTypeText = itemType === "Document" ? "文書" : "データベース";
  const actionText = action === "create" ? "作成" : "削除";

  return (
    <p className={`text-caption ${className}`}>
      <span className="font-bold">{userNameText}</span>
      {scopeTarget === "myself" && "作成者の"}
      <span className="font-bold">
        {itemTypeText}「{resourceTitle}」
      </span>
      を<span className="font-bold">{actionText}</span>
      しました。
    </p>
  );
};
