import React, { FC } from "react";

import { Icon, Tooltip } from "@spesill/components/atoms";

type PropsType = {
  text: string;
  direction: "right" | "left" | "top" | "bottom";
  className?: string;
};

export const HintTooltip: FC<PropsType> = ({
  text,
  direction,
  className = "",
}) => {
  return (
    <div className={`flex items-center ${className}`}>
      <Tooltip
        direction={direction}
        text={text}
        className="w-max"
        color="primary50"
      >
        <Icon icon="bsQuestionCircle" size="1.2rem" />
      </Tooltip>
    </div>
  );
};
