import { Editor } from "@tiptap/react";
import React, { useCallback } from "react";

import { IconButton } from "@spesill/components/molecules";

const iconSize = "1.5rem";
const iconColor = "text-blueGray-500";

export const TiptapEditorToolbar = ({ editor }: { editor: Editor | null }) => {
  const getButtonClassName = useCallback(
    (format: string, attributes?: object) => {
      return editor?.isActive(format, attributes) ? "bg-blueGray-100" : "";
    },
    [editor],
  );
  const toggleBold = useCallback(
    () => editor?.chain().focus().toggleBold().run(),
    [editor],
  );
  const toggleItalic = useCallback(
    () => editor?.chain().focus().toggleItalic().run(),
    [editor],
  );
  const toggleStrike = useCallback(
    () => editor?.chain().focus().toggleStrike().run(),
    [editor],
  );
  const toggleUnderline = useCallback(
    () => editor?.chain().focus().toggleUnderline().run(),
    [editor],
  );
  const toggleHeading1 = useCallback(
    () => editor?.chain().focus().toggleHeading({ level: 1 }).run(),
    [editor],
  );
  const toggleHeading2 = useCallback(
    () => editor?.chain().focus().toggleHeading({ level: 2 }).run(),
    [editor],
  );
  const toggleHeading3 = useCallback(
    () => editor?.chain().focus().toggleHeading({ level: 3 }).run(),
    [editor],
  );
  const toggleBulletList = useCallback(
    () => editor?.chain().focus().toggleBulletList().run(),
    [editor],
  );
  const toggleOrderedList = useCallback(
    () => editor?.chain().focus().toggleOrderedList().run(),
    [editor],
  );
  const toggleTaskList = useCallback(
    () => editor?.chain().focus().toggleTaskList().run(),
    [editor],
  );
  const undo = useCallback(
    () => editor?.chain().focus().undo().run(),
    [editor],
  );
  const redo = useCallback(
    () => editor?.chain().focus().redo().run(),
    [editor],
  );

  return (
    <div className="flex flex-wrap border-b border-blueGray-50 bg-white h-14 items-center">
      <div className="border-r border-blueGray-50 space-x-4 px-5">
        <IconButton
          onClick={toggleBold}
          icon={{
            icon: "luBold",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("bold")}
          tooltip={{
            text: "太字",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={toggleItalic}
          icon={{
            icon: "luItalic",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("italic")}
          tooltip={{
            text: "斜体",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={toggleStrike}
          icon={{
            icon: "luStrikethrough",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("strike")}
          tooltip={{
            text: "取り消し線",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={toggleUnderline}
          icon={{
            icon: "luUnderline",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("underline")}
          tooltip={{
            text: "下線",
            direction: "bottom",
            className: "text-xs",
          }}
        />
      </div>
      <div className="border-r border-blueGray-50 px-5 space-x-4">
        <IconButton
          onClick={toggleHeading1}
          icon={{
            icon: "luHeading1",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("heading", { level: 1 })}
          tooltip={{
            text: "見出し1",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={toggleHeading2}
          icon={{
            icon: "luHeading2",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("heading", { level: 2 })}
          tooltip={{
            text: "見出し2",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={toggleHeading3}
          icon={{
            icon: "luHeading3",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("heading", { level: 3 })}
          tooltip={{
            text: "見出し3",
            direction: "bottom",
            className: "text-xs",
          }}
        />
      </div>
      <div className="border-r border-blueGray-50 px-5 space-x-4">
        <IconButton
          onClick={toggleBulletList}
          icon={{
            icon: "faList",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("bulletList")}
          tooltip={{
            text: "箇条書き",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={toggleOrderedList}
          icon={{
            icon: "faListOl",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("orderedList")}
          tooltip={{
            text: "番号付きリスト",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={toggleTaskList}
          icon={{
            icon: "faListCheck",
            size: iconSize,
            color: iconColor,
          }}
          className={getButtonClassName("taskList")}
          tooltip={{
            text: "タスクリスト",
            direction: "bottom",
            className: "text-xs",
          }}
        />
      </div>
      <div className="space-x-5 px-5">
        <IconButton
          onClick={undo}
          icon={{
            icon: "luUndo2",
            size: iconSize,
            color: iconColor,
          }}
          tooltip={{
            text: "元に戻す",
            direction: "bottom",
            className: "text-xs",
          }}
        />
        <IconButton
          onClick={redo}
          icon={{
            icon: "luRedo2",
            size: iconSize,
            color: iconColor,
          }}
          tooltip={{
            text: "やり直し",
            direction: "bottom",
            className: "text-xs",
          }}
        />
      </div>
    </div>
  );
};
