import { privateStorage } from "@spesill/config/firebase";
import { useCurrentUser } from "@spesill/hooks";
import { uploadBytes, ref, deleteObject } from "@spesill/libs/firebase";
import { PostsNotification, Document } from "@spesill/models";
import { DocumentKind, DocumentLanguageType } from "@spesill/models/document";
import { DocumentRepository } from "@spesill/repository/db/document.repository";

type UploadFirebaseType = {
  fileName: string;
  systemName: string;
  kind: string;
  freeInputKind: string;
  tenantName: string;
  tenantBusinessDetails: string;
  userDepartment: string;
  userJobDescription: string;
  file: File | Blob;
  language: DocumentLanguageType;
  learningDatabaseId?: string;
  referenceDocumentIds?: string[];
  onSuccess?: (document: Document) => void;
};

type UploadWordFileType = {
  documentType: "word";
  html: string;
} & UploadFirebaseType;

type UploadExcelFileType = {
  documentType: "excel";
  html: undefined;
} & UploadFirebaseType;

type UploadFileType = UploadWordFileType | UploadExcelFileType;

type UpdateFirebaseType = {
  id: string;
  fileName: string;
  systemName: string;
  file: File | Blob;
  onSuccess?: () => void;
};

type UpdateWordFileType = {
  html: string;
} & UpdateFirebaseType;

type UpdateExcelFileType = {
  html: undefined;
} & UpdateFirebaseType;

type UpdateFileType = UpdateWordFileType | UpdateExcelFileType;

type ReturnType = {
  updateFile: ({
    id,
    fileName,
    systemName,
    file,
    html,
  }: UpdateFileType) => Promise<{ id: string } | null>;
  uploadFile: ({
    fileName,
    systemName,
    file,
    html,
  }: UploadFileType) => Promise<{ id: string } | null>;
};

export const useStorageUploadFile = (): ReturnType => {
  const { currentUser } = useCurrentUser();

  const updateFile = async ({
    id,
    fileName,
    systemName,
    file,
    html,
    onSuccess,
  }: UpdateFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    const documentRepository = new DocumentRepository();
    const doc = await documentRepository.findByTenantIdAndId(
      currentUser.tenantId,
      id,
    );
    const deleteStorageRef = ref(privateStorage, doc.path);

    const storageRef = ref(
      privateStorage,
      `tenants/${currentUser.tenantId}/documents/${fileName}`,
    );

    await uploadBytes(storageRef, file);
    await deleteObject(deleteStorageRef);
    const document = await documentRepository.updateById(id, {
      tenantId: currentUser.tenantId,
      systemName,
      path: storageRef.fullPath,
      createUserId: currentUser.id,
      lastUpdateUserId: currentUser.id,
      html,
    });

    onSuccess && onSuccess();
    return document;
  };
  const uploadFile = async ({
    fileName,
    systemName,
    kind,
    tenantName,
    tenantBusinessDetails,
    userDepartment,
    userJobDescription,
    freeInputKind,
    file,
    html,
    language,
    documentType,
    learningDatabaseId,
    referenceDocumentIds,
    onSuccess,
  }: UploadFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    const storageRef = ref(
      privateStorage,
      `tenants/${currentUser.tenantId}/documents/${fileName}`,
    );

    return uploadBytes(storageRef, file).then(async () => {
      const document = await Document.create({
        tenantId: currentUser.tenantId,
        systemName,
        kind: kind as DocumentKind,
        freeInputKind,
        tenantName,
        tenantBusinessDetails,
        userDepartment,
        userJobDescription,
        path: storageRef.fullPath,
        createUserId: currentUser.id,
        lastUpdateUserId: currentUser.id,
        html,
        language,
        documentType,
        learningDatabaseId,
        referenceDocumentIds,
      });
      PostsNotification.insertNewNotification({
        resource: document,
        createUser: currentUser,
        action: "create",
      });
      onSuccess && onSuccess(document);
      return document;
    });
  };

  return {
    uploadFile,
    updateFile,
  };
};
