import React, { FC } from "react";

type PropsType = {
  href: string;
  text: string;
  className?: string;
};

export const ExternalLink: FC<PropsType> = ({
  href,
  text,
  className = "",
}: PropsType) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={`link ${className}`}
    >
      {text}
    </a>
  );
};
