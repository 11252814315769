import React from "react";

import Link from "next/link";

type BreadcrumbItemType = {
  path: string;
  label: string;
};

type PropsType = {
  breadcrumbList: BreadcrumbItemType[];
};

export const Breadcrumb = ({ breadcrumbList }: PropsType) => (
  <nav>
    <ol className="flex items-center space-x-2">
      {breadcrumbList.map((breadcrumb, index) => {
        const isLast = index === breadcrumbList.length - 1;
        return (
          <React.Fragment key={index}>
            <li className={`${isLast ? "" : "text-primary-500"} text-lg`}>
              {isLast ? (
                breadcrumb.label
              ) : (
                <Link href={breadcrumb.path}>{breadcrumb.label}</Link>
              )}
            </li>
            {!isLast && <li>/</li>}
          </React.Fragment>
        );
      })}
    </ol>
  </nav>
);
