// BubbleMenuContent.tsx
import React from "react";

import {
  Button,
  DotLoadingAnimation,
  TextArea,
} from "@spesill/components/atoms";
import { ListMenu, MenuItemType } from "@spesill/components/molecules";

import { refineType } from "@spesill/models/api/refine_text";

import {
  AnswerType,
  DocumentAnswers,
} from "../../../ConvertedHTMLDetail/DocumentHeadingTitleForm/DocumentInsertTextForm/DocumentAnswers";

type BubbleMenuContentProps = {
  requestState: "Requesting" | "NoRequest";
  answers: AnswerType[];
  items: MenuItemType[];
  refineType: refineType;
  handleClose: () => void;
  handleCancel: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleReflectInsertText: (
    value: string,
    isHtml?: boolean,
    isChart?: boolean,
  ) => void;
  handleRegenerate: (refineType: refineType) => Promise<void>;
  updateAnswer: (newItem: AnswerType, index: number) => void;
  customOrderText: string;
  onChangeCustomOrderText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  aiRequest: (type: refineType) => void;
  isCustomOrder: boolean;
};

export const BubbleMenuContent: React.FC<BubbleMenuContentProps> = ({
  requestState,
  answers,
  items,
  refineType,
  handleClose,
  handleCancel,
  handleReflectInsertText,
  handleRegenerate,
  updateAnswer,
  customOrderText,
  onChangeCustomOrderText,
  aiRequest,
  isCustomOrder,
}) => {
  if (requestState === "Requesting") {
    return (
      <DotLoadingAnimation className="min-w-[40vw] justify-center bg-white shadow-md opacity-95 h-11 rounded-full" />
    );
  }

  if (refineType === "concretize" && isCustomOrder) {
    return (
      <div className="min-w-[40vw] p-4 bg-white shadow opacity-95 rounded-lg flex flex-col">
        <TextArea
          className="mt-2 mb-4"
          placeholder="AIへの指示を行いたい場合は指示を入力してください。"
          value={customOrderText}
          onChange={onChangeCustomOrderText}
          rows={5}
          name={"customOrderText"}
        />
        <div className="flex justify-end gap-2">
          <Button
            text={"キャンセル"}
            color={"gray"}
            variant={"contained"}
            onClick={handleCancel}
          />
          <Button
            text={"生成"}
            color={"primary"}
            variant={"contained"}
            onClick={() => aiRequest("concretize")}
          />
        </div>
      </div>
    );
  }
  if (answers.length === 0) {
    return <ListMenu items={items} onClose={handleClose} className="px-4" />;
  }

  return (
    <DocumentAnswers
      isRequesting={false}
      answers={answers}
      handleCancel={handleCancel}
      handleReflectInsertText={handleReflectInsertText}
      handleRegenerate={() => handleRegenerate(refineType)}
      updateAnswer={updateAnswer}
      className="min-w-[40vw]"
      isHtml={
        refineType === "convert_checkbox" || refineType === "convert_table"
      }
      isModalPortal={true}
    />
  );
};
