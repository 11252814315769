import { useState, useCallback, useEffect } from "react";

import { MultiValueType, SingleValueType } from "@spesill/components/atoms";

import { useLearningDatabasesWithUser } from "@spesill/hooks";

type ReturnType = {
  dropdownValue?: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (newValue: SingleValueType | null) => void;
};

export const useSelectLearningDatabaseDropdownField = (
  currentDatabase?: SingleValueType,
): ReturnType => {
  const [value, setValue] = useState<SingleValueType>(
    currentDatabase ? currentDatabase : null,
  );

  const [dropdownSelectableValue, setDropdownSelectableValue] =
    useState<MultiValueType>([]);
  const { learningDatabases } = useLearningDatabasesWithUser();

  const onChangeDropDown = useCallback((newValue: SingleValueType | null) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    if (!learningDatabases) return;
    setDropdownSelectableValue(
      learningDatabases.map((learningDatabase) => {
        return {
          value: learningDatabase.id,
          label: learningDatabase.systemName,
        };
      }),
    );
  }, [learningDatabases]);

  return {
    dropdownValue: value,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
  };
};
