import React, { FC, ChangeEvent } from "react";

import { NumberField, Label } from "@spesill/components/atoms";

type PropsType = {
  labelText: string;
  name: string;
  value?: number;
  defaultValue?: number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  numberFieldClassName?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const LabelWithNumberField: FC<PropsType> = ({
  labelText,
  name,
  value,
  defaultValue,
  placeholder = "",
  required = false,
  disabled = false,
  min,
  max,
  numberFieldClassName = "",
  className = "",
  onChange,
}: PropsType) => {
  return (
    <div className={className}>
      <Label
        htmlFor={name}
        labelText={labelText}
        required={required}
        className="block"
      />
      <NumberField
        name={name}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        min={min}
        max={max}
        onChange={onChange}
        className={`mt-1.5 ${numberFieldClassName}`}
      />
    </div>
  );
};
