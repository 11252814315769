import React, { FC } from "react";

import Link from "next/link";

import { Icon, IconType, Tooltip } from "@spesill/components/atoms";

type PropsType = {
  href: string;
  icon: IconType;
  isActive: boolean;
  text: string;
  isCollapsed?: boolean;
};

export const NavLink: FC<PropsType> = ({
  href,
  icon,
  isActive,
  text,
  isCollapsed,
}) => {
  return (
    <li>
      <Link
        href={href}
        className={`pr-6 pl-2 py-4 flex items-center gap-2 hover:bg-blueGray-600 ${
          isActive ? "bg-blueGray-600 rounded-l-lg" : ""
        }`}
      >
        {isCollapsed ? (
          <Tooltip direction={"right"} text={text} className="text-sm p-2">
            <Icon icon={icon} color="text-cyan-600" size="2rem" />
          </Tooltip>
        ) : (
          <>
            <Icon icon={icon} color="text-cyan-600" size="1.5rem" />
            <span className="text-blueGray-300">{text}</span>
          </>
        )}
      </Link>
    </li>
  );
};
