import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import {
  DisplayablePasswordFiled,
  LabelWithTextField,
  ModalWrapper,
} from "@spesill/components/molecules";

import { useCurrentUser, useInput, useChangeEmail } from "@spesill/hooks";

type PropsType = {
  onClose: () => void;
};
export const ChangeEmailModal = ({ onClose }: PropsType) => {
  const { currentUser } = useCurrentUser();
  const [{ value: password, onChange: onChangePassword }] = useInput("");
  const [{ value: newEmail, onChange: onChangeEmail }] = useInput("");

  const { changeEmail, loading } = useChangeEmail();

  const handleChangeEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) {
      toast.error("ログインしていません");
      return;
    }
    await changeEmail({
      userId: currentUser.id,
      password: password,
      newEmail: newEmail,
    })
      .then(() => {
        toast.success("メールアドレスを変更しました");
      })
      .catch((e) => {
        toast.error(
          e instanceof Error ? e.message : "プロフィールの更新に失敗しました",
        );
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="メールアドレス変更">
      <form onSubmit={handleChangeEmail} className="mt-6 space-y-6">
        <LabelWithTextField
          labelText="メールアドレス"
          type="email"
          name="email"
          required
          autoComplete="email"
          placeholder="例）info@spesill.com"
          value={newEmail}
          onChange={onChangeEmail}
        />
        <DisplayablePasswordFiled
          name="password"
          required
          label="パスワード"
          placeholder="パスワードを入力してください"
          autoComplete="password"
          onChange={onChangePassword}
        />
        <p className="text-blueGray-300 text-sm">
          ＊メールアドレスの変更には、パスワードの入力が必要です。
        </p>

        <div className="space-x-4 text-right">
          <Button
            text={"キャンセル"}
            color={"gray"}
            variant={"contained"}
            onClick={onClose}
          />
          <FormSubmitButton
            text="変更"
            color="primary"
            variant="contained"
            className="text-sm"
            disabled={loading}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};
