import { useState } from "react";

import { AuthRepository } from "@spesill/repository/auth";

export const useChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const changePassword = async (oldPassword: string, newPassword: string) => {
    setLoading(true);
    try {
      const authRepository = new AuthRepository();
      await authRepository.changePassword(oldPassword, newPassword);
    } finally {
      setLoading(false);
    }
  };

  return { changePassword, loading };
};
