import React, { MouseEvent } from "react";
import { toast } from "react-toastify";

import Link from "next/link";

import { Icon } from "@spesill/components/atoms";
import {
  IconButton,
  ListMenu,
  MenuItemType,
} from "@spesill/components/molecules";

import { useBoolean, useHover } from "@spesill/hooks";
import { formatDate } from "@spesill/libs";
import { User } from "@spesill/models";
import { EvaluateDatabase } from "@spesill/models";

type PropsType = {
  evaluateDatabase: EvaluateDatabase & { user?: User };
  deleteEvaluateDatabase: (id: string) => void;
  disabled?: boolean;
};

export const EvaluateDatabaseListRow = ({
  evaluateDatabase,
  disabled = false,
  deleteEvaluateDatabase,
}: PropsType) => {
  const [ref] = useHover<HTMLDivElement>();
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  const handleDeleteEvaluateDatabase = async () => {
    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;

    const deletingStatus = toast.loading("削除中です。しばらくお待ちください");

    try {
      await deleteEvaluateDatabase(evaluateDatabase.id);
      toast.update(deletingStatus, {
        render: "削除が完了しました。",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      toast.dismiss(deletingStatus);
      toast.error("削除に失敗しました。");
    }
  };

  const menuItems: MenuItemType[] = [
    {
      label: "削除",
      className: "text-status-danger",
      onClick: () => handleDeleteEvaluateDatabase(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpen();
  };

  return (
    <div
      ref={ref}
      className="bg-white flex text-blueGray-700 py-3 px-4 rounded-md h-16 hover:bg-blueGray-50"
    >
      <Link
        key={evaluateDatabase.id}
        href={`/evaluates/${evaluateDatabase.id}`}
        className="flex-1 gap-x-4 flex items-center"
      >
        <div className="flex items-center space-x-2 flex-1 gap-x-4">
          <Icon
            icon="bsDatabase"
            size="2rem"
            color="text-primary-400"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
          <span className="text-body-base-bold">
            {evaluateDatabase.systemName}
          </span>
        </div>
        <div className="text-body-base w-1/6">
          {formatDate(evaluateDatabase.createdAt)}
        </div>
        <div className="text-body-base w-1/6">
          {evaluateDatabase.user?.fullName}
        </div>
      </Link>

      <div className="flex gap-6 items-center justify-end w-1/12">
        {/* <IconButton
          icon={{
            icon: "bsPersonPlus",
            color: "text-blueGray-500",
            size: "1.5rem",
          }}
          srOnlyText="TODO"
        /> */}
        <div className="relative flex items-center">
          <IconButton
            icon={{
              icon: "bsThreeDots",
              color: "text-blueGray-500",
              size: "1.5rem",
            }}
            srOnlyText="メニューを開く"
            onClick={handleOpen}
          />
          {isOpen && (
            <ListMenu
              items={menuItems}
              onClose={onClose}
              disabled={disabled}
              className="right-full mr-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};
