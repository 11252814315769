import React, { FormEvent, useState } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton } from "@spesill/components/atoms";
import {
  DisplayablePasswordFiled,
  ModalWrapper,
} from "@spesill/components/molecules";

import { useCurrentUser, useInput, useChangePassword } from "@spesill/hooks";
import {
  specialCharacters,
  validatePassword,
} from "@spesill/utils/ValidatePassword";

type PropsType = {
  onClose: () => void;
};
export const ChangePasswordModal = ({ onClose }: PropsType) => {
  const { currentUser } = useCurrentUser();
  const [{ value: oldPassword, onChange: onChangeOldPassword }] = useInput("");
  const [{ value: newPassword, onChange: onChangeNewPassword }] = useInput("");
  const [
    {
      value: newPasswordConfirmation,
      onChange: onChangeNewPasswordConfirmation,
    },
  ] = useInput("");
  const { changePassword, loading } = useChangePassword();
  const [error, setError] = useState<string | null>(null);

  const handleChangePassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) return;
    if (!validatePassword(newPassword)) {
      setError(
        "パスワードは最低10文字で、小文字・数字・特殊文字を含む必要があります。",
      );
      return;
    }
    if (newPassword !== newPasswordConfirmation) {
      setError("新しいパスワードと、新しいパスワード（確認）が一致しません。");
      return;
    }
    await changePassword(oldPassword, newPassword)
      .then(() => {
        toast.success("パスワードを変更しました");
      })
      .catch((e) => {
        toast.error(e instanceof Error ? e.message : "失敗しました");
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <ModalWrapper onClose={onClose}>
      <h1 className="text-xl font-semibold">パスワード再設定</h1>
      <form onSubmit={handleChangePassword} className="mt-6 space-y-6">
        <DisplayablePasswordFiled
          name="password"
          required
          label="現在のパスワード"
          placeholder="10文字以上入ります"
          autoComplete="password"
          onChange={onChangeOldPassword}
        />
        <DisplayablePasswordFiled
          name="new-password"
          required
          label="新しいパスワード"
          placeholder="10文字以上で入力してください"
          onChange={onChangeNewPassword}
        />
        <p className="text-blueGray-300 text-sm">
          以下の要件をすべて満たしたパスワードを設定してください。 <br />
          ・10文字〜128文字の半角英数字 <br />
          ・1文字以上の数字を含む <br />
          ・1文字以上の英小文字を含む <br />
          ・1文字以上の特殊文字（{specialCharacters}）を含む <br />
        </p>
        <DisplayablePasswordFiled
          name="new-password-confirmation"
          required
          label="新しいパスワード(確認)"
          placeholder="10文字以上で入力してください"
          onChange={onChangeNewPasswordConfirmation}
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <div className="space-x-4 text-right">
          <Button
            text={"キャンセル"}
            color={"gray"}
            variant={"contained"}
            onClick={onClose}
          />
          <FormSubmitButton
            text="変更"
            color="primary"
            variant="contained"
            className="text-sm"
            disabled={loading}
          />
        </div>
      </form>
    </ModalWrapper>
  );
};
