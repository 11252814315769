import React, { useState } from "react";
import { toast } from "react-toastify";

import { Button, Icon, LoadingAnimationText } from "@spesill/components/atoms";
import { ButtonWithTextArea } from "@spesill/components/molecules";

import {
  useBoolean,
  useCurrentUser,
  useIncrementAiUsage,
  useInput,
} from "@spesill/hooks";
import { useDownloadDom } from "@spesill/hooks/useDownloadDom";
import { apiClient } from "@spesill/libs/apiClient";
import { PunchItemsType } from "@spesill/models/punchLayout";

import { PunchGridLayout } from "../PunchGridLayout/PunchGridLayout";

const PunchGridLayoutWrapper = ({
  learningDatabaseId,
  setIsEditable,
  setPunchItems,
  onUpdateState,
  punchItems,
  width,
  height,
}: {
  width: number;
  height: number;
  punchItems: PunchItemsType[];
  learningDatabaseId?: string;
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  setPunchItems: React.Dispatch<React.SetStateAction<PunchItemsType[]>>;
  onUpdateState?: (state: {
    width: number;
    height: number;
    punchItems: PunchItemsType[];
  }) => void;
}) => {
  const [{ value: keyword, onChange: onChangeKeyword, setValue }] =
    useInput("");
  const { currentUser } = useCurrentUser();
  const { incrementAiCallCount } = useIncrementAiUsage();

  const handleChangePunchItems = (items: PunchItemsType[]) => {
    setPunchItems(items);
    onUpdateState && onUpdateState({ width, height, punchItems: items });
  };

  const { convert, ref } = useDownloadDom();

  const { isChecked: isRequesting, setTrue, setFalse } = useBoolean(false);

  return (
    <div className="flex flex-col w-full h-full rounded shadow">
      <div className="flex flex-col w-full h-full p-4 bg-primary-50 gap-4">
        <div className="p-4  flex justify-center w-full h-full bg-white">
          <div ref={ref}>
            <PunchGridLayout
              width={width}
              height={height}
              punchItems={punchItems}
              onChangePunchItems={handleChangePunchItems}
              isEditable={true}
            />
          </div>
        </div>
        <ButtonWithTextArea
          name="question"
          type="text"
          icon="ioMdSend"
          placeholder="指示を入れてください"
          required
          disabledButton={!keyword || isRequesting}
          value={keyword}
          outline={false}
          onChange={onChangeKeyword}
          onClick={async () => {
            setTrue();
            try {
              const res = await apiClient().generate_punch_image.$post({
                body: {
                  description: keyword,
                  tenant_id: String(currentUser?.tenantId),
                  group_id: learningDatabaseId,
                },
              });
              incrementAiCallCount("generatePunchImage");
              handleChangePunchItems(res.punch_items);
              setValue("");
            } catch (error) {
              toast.error("フローチャートの作成に失敗しました。");
            } finally {
              setFalse();
            }
          }}
          rows={1}
          maxRows={15}
        />
        {isRequesting && (
          <LoadingAnimationText
            text={"図を生成中。。。 しばらくお待ちください"}
          />
        )}
      </div>

      <div className="flex justify-between gap-4 p-4">
        <Button
          text="キャンセル"
          color="gray"
          variant="contained"
          onClick={() => {
            setValue("");
            setIsEditable(false);
          }}
        />

        <div className="flex gap-4">
          <Button
            text="書き出し"
            color="primary"
            variant="outlined"
            icon={{
              icon: "grDocumentDownload",
              size: "1.5rem",
              color: "text-primary-400",
            }}
            onClick={convert}
          />
          <Button
            text="保存"
            color="primary"
            variant="contained"
            onClick={() => {
              setValue("");
              setIsEditable(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const PunchGridLayoutEditor = ({
  width,
  height,
  punchItems,
  learningDatabaseId,
  onUpdateState,
}: {
  width: number;
  height: number;
  punchItems: PunchItemsType[];
  learningDatabaseId?: string;
  onUpdateState?: (state: {
    width: number;
    height: number;
    punchItems: PunchItemsType[];
  }) => void;
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [punches, setPunches] = useState<PunchItemsType[]>(punchItems);

  return (
    <div
      contentEditable={false}
      data-chart-type="punchLayout"
      data-chart-state={JSON.stringify({
        punch_items: punches,
        width: width,
        height: height,
      })}
    >
      {!isEditable ? (
        <button
          type="button"
          className="flex space-x-1 w-full mx-auto"
          onClick={() => {
            setIsEditable(true);
          }}
        >
          <Icon
            icon="tbPencilPlus"
            size="1.7rem"
            color="text-white"
            className="bg-primary-400 p-1 shadow rounded-full hidden group-hover:block"
          />
          <PunchGridLayout width={width} height={height} punchItems={punches} />
        </button>
      ) : (
        <PunchGridLayoutWrapper
          width={width}
          height={height}
          punchItems={punches}
          learningDatabaseId={learningDatabaseId}
          setIsEditable={setIsEditable}
          setPunchItems={setPunches}
          onUpdateState={onUpdateState}
        />
      )}
    </div>
  );
};
