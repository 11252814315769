import React, { FC, MouseEvent, CSSProperties } from "react";

import { Icon, IconPropsType } from "@spesill/components/atoms";

import {
  ButtonColorType,
  ButtonVariantType,
  ButtonColor,
  ButtonSize,
  ButtonSizeType,
} from "./constants";

type PropsType = {
  style?: CSSProperties;
  text: string;
  outline?: boolean;
  disabled?: boolean;
  className?: string;
  color: ButtonColorType;
  variant: ButtonVariantType;
  size?: ButtonSizeType;
  icon?: IconPropsType;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const Button: FC<PropsType> = ({
  style,
  text,
  className = "",
  disabled = false,
  color,
  variant,
  size = "medium",
  icon,
  onClick,
}: PropsType) => {
  return (
    <button
      style={style}
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${ButtonColor[variant][color]} ${ButtonSize[size]} ${
        disabled ? "cursor-not-allowed opacity-80" : "cursor-pointer"
      } ${icon && "flex items-center space-x-2"} rounded ${className}`}
    >
      {icon && <Icon {...icon} />}
      <span>{text}</span>
    </button>
  );
};
