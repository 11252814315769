import { useCallback, useState } from "react";

import { useInterval } from "@spesill/hooks";
import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { UsersPostsNotificationStatus } from "@spesill/models";
import { UsersPostsNotificationStatusRepository } from "@spesill/repository/db/usersPostsNotificationStatus.repository";

const usersPostsNotificationStatusRepository =
  new UsersPostsNotificationStatusRepository();

export const useIntervalUnreadPostsNotifications = () => {
  const { currentUser } = useCurrentUser();

  const [unreadPosts, setUnreadPosts] = useState<
    UsersPostsNotificationStatus[]
  >([]);

  const fetchUnreadPosts = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const fetchedUnreadPosts =
      await usersPostsNotificationStatusRepository.findByUsersUnreadPostsNotification(
        currentUser,
      );

    setUnreadPosts(fetchedUnreadPosts);
  }, [currentUser]);

  useInterval(fetchUnreadPosts, 10000);

  return {
    fetchUnreadPosts,
    unreadPosts,
  };
};
