import React from "react";

import Image from "next/image";

import { Button, Icon } from "@spesill/components/atoms";

type PropsType = {
  handleModalOpen: () => void;
};

export const LearningDatabaseIntroduction = ({
  handleModalOpen,
}: PropsType) => {
  return (
    <div className="bg-white w-full flex p-20 rounded-md gap-16 justify-center items-center">
      <Image
        src="/leanings.png"
        alt="文書データベース作成"
        width={400}
        height={300}
      />

      <div className="flex flex-col justify-center gap-8">
        <p className="text-h4 whitespace-pre-wrap">
          専門分野に特化したデータベースの活用で
          <br />
          手間なく品質の高い文章を作成
        </p>

        <p className="text-body-lg">
          特定の文書に特化したデータベースを独自に作り上げ、 <br />
          専門性の高い文章の作成を可能にします。 <br />
          作成したデータベースは文書作成、AIチャットで利用できます。
        </p>

        <div className="flex flex-col gap-y-4">
          <p className="text-body-base-bold text-primary-500 flex items-center gap-x-2">
            <Icon icon="tbPencilPlus" size="1.5rem" color="text-primary-400" />
            データベースの作り方
          </p>

          <div className="flex flex-col gap-y-2">
            <div className="p-4 flex gap-x-2 bg-blueGray-0 rounded w-[400px]">
              <span className="rounded-full bg-primary-400 w-6 h-6 flex items-center justify-center text-white text-body-base-bold">
                1
              </span>
              <span className="text-body-base-bold">データベース名を入力</span>
            </div>

            <div className="p-4 flex gap-x-2 bg-blueGray-0 rounded w-[400px]">
              <span className="rounded-full bg-primary-400 w-6 h-6 flex items-center justify-center text-white text-body-base-bold">
                2
              </span>
              <span className="text-body-base-bold">
                学習用データをアップロード（Word、Excel、PDF）
              </span>
            </div>
          </div>
        </div>

        <div>
          <Button
            text="文書データベース作成"
            color="primary"
            variant="contained"
            icon={{
              icon: "aiOutlinePlus",
              size: "1.5rem",
              color: "text-white",
            }}
            onClick={() => handleModalOpen()}
          />
        </div>
      </div>
    </div>
  );
};
