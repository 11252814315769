import { useCallback } from "react";
import { toast } from "react-toastify";

import { useCurrentUser } from "@spesill/hooks";
import { DocumentPromptHistoryRepository } from "@spesill/repository/db/DocumentPromptHistory.repository";

const documentPromptHistoryRepository = new DocumentPromptHistoryRepository();

export const useCreateDocumentPromptHistory = (documentId: string) => {
  const { currentUser } = useCurrentUser();
  const createPromptHistory = useCallback(
    async (prompt?: string) => {
      if (!currentUser) return;
      if (!prompt) return;
      try {
        await documentPromptHistoryRepository.add(documentId, {
          documentId: documentId,
          userId: currentUser.id,
          tenantId: currentUser.tenantId,
          customPrompt: prompt,
        });
      } catch (error) {
        toast.error("プロンプトの保存に失敗しました");
      }
    },
    [currentUser, documentId],
  );

  return { createPromptHistory };
};
