import { PunchLayoutRequestHistoryRepository } from "@spesill/repository/db/punchLayoutRequestHistory.repository";

import { Entity } from "./__common__/entity";

export class PunchLayoutRequestHistory extends Entity {
  id: string;
  tenantId: string;
  userId: string;
  description?: string;
  baseInfo?: string;
  featureInfo?: string;
  itemConfigurations?: {
    punchImageId: string;
    punchImageName: string;
  }[];
  horizontalItems?: {
    punchImageId: string;
  }[];
  verticalItems?: {
    punchImageId: string;
  }[];
  width?: number;
  height?: number;
  groupId?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(arg: ExcludeMethods<PunchLayoutRequestHistory>) {
    super(arg);

    this.id = arg.id;
    this.tenantId = arg.tenantId;
    this.userId = arg.userId;
    this.baseInfo = arg.baseInfo;
    this.featureInfo = arg.featureInfo;
    this.itemConfigurations = arg.itemConfigurations;
    this.horizontalItems = arg.horizontalItems;
    this.verticalItems = arg.verticalItems;
    this.width = arg.width;
    this.height = arg.height;
    this.groupId = arg.groupId;
    this.createdAt = arg.createdAt;
    this.updatedAt = arg.updatedAt;
  }

  static create(
    args: Omit<
      ConstructorParameters<typeof PunchLayoutRequestHistory>[0],
      "id" | "createdAt" | "updatedAt"
    >,
  ) {
    const newPunch = {
      deletedAt: undefined,
      ...args,
    };
    const punchLayoutRequestHistory = new PunchLayoutRequestHistoryRepository();
    return punchLayoutRequestHistory.add({ ...newPunch });
  }

  static async update(
    args: Omit<
      ConstructorParameters<typeof PunchLayoutRequestHistory>[0],
      "createdAt" | "updatedAt"
    >,
  ) {
    const punchLayoutRequestHistory = new PunchLayoutRequestHistoryRepository();
    const newPunch = {
      deletedAt: undefined,
      ...args,
    };
    punchLayoutRequestHistory.updateById(args.id, { ...newPunch });
  }
}
