import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

import {
  IconPropsType,
  MultiValueType,
  SingleValueType,
} from "@spesill/components/atoms";

import { useLearningDatabasesWithUser } from "@spesill/hooks";
import { Document } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";

type ReturnType = {
  dropdownValue?: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (id?: string) => void;
  refreshOptions: () => Promise<void>;
};

export const useLearningDatabaseDropdownField = (
  document?: Document,
  isFreePlan?: boolean,
  onSuccess?: () => void,
): ReturnType => {
  const [dropdownSelectableValue, setDropdownSelectableValue] =
    useState<MultiValueType>([]);
  const { learningDatabases, fetchLearningDatabasesAndUsers } =
    useLearningDatabasesWithUser(isFreePlan);
  const learningDatabase = learningDatabases.find(
    (learningDatabase) => learningDatabase.id === document?.learningDatabaseId,
  );

  const onChangeDropDown = useCallback(
    async (id?: string) => {
      if (!document) return;
      const documentRepository = new DocumentRepository();
      await documentRepository.updateById(document.id, {
        ...document,
        learningDatabaseId: id,
      });
      toast.success("文書データベースを設定しました。");
      onSuccess && onSuccess();
    },
    [document, onSuccess],
  );

  useEffect(() => {
    if (!learningDatabases) return;
    setDropdownSelectableValue([
      ...learningDatabases.map((learningDatabase) => {
        return {
          value: learningDatabase.id,
          label: learningDatabase.systemName,
          icon: {
            icon:
              learningDatabase.structureType === "table"
                ? "bsTable"
                : "ioDocumentOutline",
            size: "1rem",
          } as IconPropsType,
        };
      }),
      {
        value: "",
        label: "データベースの選択を解除",
      },
    ]);
  }, [learningDatabases]);

  return {
    dropdownValue: learningDatabase
      ? {
          value: learningDatabase.id,
          label: learningDatabase.systemName,
        }
      : undefined,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
    refreshOptions: fetchLearningDatabasesAndUsers,
  };
};
