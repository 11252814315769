import { BubbleMenu, Editor } from "@tiptap/react";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { MenuItemType } from "@spesill/components/molecules";

import {
  useArray,
  useIncrementAiUsage,
  useInput,
  useInsertTiptapNode,
  useTenantAiCallRestriction,
} from "@spesill/hooks";
import { apiClient } from "@spesill/libs/apiClient";
import { Document } from "@spesill/models";
import { refineType } from "@spesill/models/api/refine_text";

import { BubbleMenuContent } from "./BubbleMenuComponent";
import { AnswerType } from "../../../ConvertedHTMLDetail/DocumentHeadingTitleForm/DocumentInsertTextForm/DocumentAnswers";
type PropsType = {
  editor: Editor;
  learningDatabaseId: string;
  documentKind: string;
  freeInputKind?: string;
  systemName: string;
  documentInfo: Document;
};

const REFINE_MODES = {
  HTML: ["convert_checkbox", "convert_table", "make_flowchart", "make_layout"],
  CHART: ["make_flowchart", "make_layout"],
};

export const TiptapBubbleMenu: React.FC<PropsType> = ({
  editor,
  learningDatabaseId,
  documentKind,
  freeInputKind,
  documentInfo,
}) => {
  const { from, to } = editor.state.selection;
  const [refineMode, setRefineMode] = useState<refineType>("concretize");
  const { insertHtml, insertText, insertChartValue } = useInsertTiptapNode(
    editor,
    learningDatabaseId,
    documentKind,
    freeInputKind,
  );

  const {
    items: answers,
    pushItem: pushAnswer,
    setEmpty: setEmptyAnswer,
    updateIndexItem: updateAnswer,
  } = useArray<AnswerType>([]);

  const [{ value: customOrderText, onChange: onChangeCustomOrderText }] =
    useInput("");

  const [loading, setLoading] = useState(false);
  const [isCustomOrder, setIsCustomOrder] = useState(false);
  const { incrementAiCallCount } = useIncrementAiUsage();
  const { checkCanCallAi } = useTenantAiCallRestriction();
  const handleCopy = () => {
    const selectedText =
      editor?.state.selection.content().content.firstChild?.textContent;
    if (selectedText) {
      navigator.clipboard
        .writeText(selectedText)
        .catch((err) => console.error("Failed to copy text: ", err))
        .finally(() => handleClose());
    } else {
      toast.warn("コピーするテキストが選択されていません。");
    }
  };

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        editor?.chain().focus().insertContent(text).run();
      })
      .catch((err) => console.error("Failed to paste text: ", err))
      .finally(() => handleClose());
  };

  const handleCut = () => {
    const selectedText =
      editor?.state.selection.content().content.firstChild?.textContent;
    if (selectedText) {
      navigator.clipboard
        .writeText(selectedText)
        .then(() => {
          editor?.chain().focus().deleteSelection().run();
        })
        .catch((err) => console.error("Failed to cut text: ", err));
    }
    handleClose();
  };

  const dividerClass = "border-t border-blueGray-200 mt-4";

  const items: MenuItemType[] = [
    {
      label: "文章を改善する",
      icon: { icon: "faWandMagicSparkles", size: "1.2rem" },
      onClick: () => {
        setIsCustomOrder(true);
        setRefineMode("concretize");
      },
    },
    {
      label: "文章を要約する",
      icon: { icon: "IoIosColorWand", size: "1.2rem" },
      onClick: () => {
        setRefineMode("summarize");
        aiRequest("summarize");
      },
    },
    {
      label: "表を生成",
      icon: { icon: "aiOutlineTable", size: "1.2rem" },
      onClick: () => {
        setRefineMode("convert_table");
        aiRequest("convert_table");
      },
      className: dividerClass,
    },
    {
      label: "チェックリストを生成",
      icon: { icon: "faListCheck", size: "1.2rem" },
      onClick: () => {
        setRefineMode("convert_checkbox");
        aiRequest("convert_checkbox");
      },
    },
    {
      label: "フローチャートを生成",
      icon: { icon: "biPalette", size: "1.2rem" },
      onClick: () => {
        setRefineMode("make_flowchart");
        aiRequest("make_flowchart");
      },
    },
    {
      label: "切り取り",
      icon: { icon: "mdContentCut", size: "1.2rem" },
      onClick: handleCut,
      className: dividerClass,
    },
    {
      label: "コピー",
      icon: { icon: "mdContentCopy", size: "1.2rem" },
      onClick: handleCopy,
    },
    {
      label: "貼り付け",
      icon: { icon: "mdContentPaste", size: "1.2rem" },
      onClick: handlePaste,
    },
  ];

  const insertValue = useCallback(
    (value: string, isHtml?: boolean, isChart?: boolean) => {
      if (!editor) return;

      const pos = editor.state.selection.from;
      // 選択範囲を削除
      editor.chain().focus().deleteSelection().run();
      if (isHtml) {
        isChart ? insertChartValue(value, pos) : insertHtml(value, pos);
        return;
      } else {
        insertText(value, pos);
        return;
      }
    },
    [editor, insertChartValue, insertHtml, insertText],
  );

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEmptyAnswer();
    setIsCustomOrder(false);
  };

  const handleClose = useCallback(() => {
    setEmptyAnswer();
  }, [setEmptyAnswer]);

  const handleReflectInsertText = useCallback(
    (value: string) => {
      const isHtml = REFINE_MODES.HTML.includes(refineMode);
      const isChart = REFINE_MODES.CHART.includes(refineMode);

      insertValue(value, isHtml, isChart);
      handleClose();
    },
    [handleClose, insertValue, refineMode],
  );

  const selectedText = editor.state.doc.textBetween(from, to);

  const aiRequest = useCallback(
    async (requestType: refineType) => {
      setLoading(true);

      const targetText = selectedText;
      if (!targetText) return;
      const canCallAi = await checkCanCallAi();
      if (!canCallAi) {
        setLoading(false);
        toast.warn(
          "AIの月間利用回数が上限に達しました。プランをアップグレードしてください。",
        );
        return;
      }
      await incrementAiCallCount("refineText");
      await apiClient()
        .refine_text.$post({
          body: {
            tenant_id: documentInfo.tenantId,
            target_text: targetText,
            document_path: documentInfo.path,
            refine_type: requestType,
            refine_prompt: customOrderText,
            group_id: documentInfo.learningDatabaseId || undefined,
            firestore_document_ids: documentInfo.referenceDocumentIds,
            language: documentInfo.language,
            kind: documentInfo.kind,
            free_input: documentInfo.freeInputKind,
          },
        })
        .then((res) => {
          requestType === "make_flowchart"
            ? insertValue(res.refined_text, true, true)
            : pushAnswer({
                spec: res.refined_text,
                sources: res.sources,
                responseId: res.response_id,
              });
        })
        .catch((e) => {
          console.error(e);
          toast.error(
            "AI文章改善のリクエストに失敗しました。しばらく時間をおいてから再度お試しください。",
          );
        })
        .finally(() => {
          setLoading(false);
          setIsCustomOrder(false);
        });
    },
    [
      selectedText,
      checkCanCallAi,
      documentInfo.tenantId,
      documentInfo.path,
      documentInfo.learningDatabaseId,
      documentInfo.referenceDocumentIds,
      documentInfo.language,
      documentInfo.kind,
      documentInfo.freeInputKind,
      customOrderText,
      incrementAiCallCount,
      insertValue,
      pushAnswer,
    ],
  );

  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        onHide: handleClose,
        placement: "bottom-start",
        interactive: true,
        zIndex: 10,
      }}
    >
      <BubbleMenuContent
        requestState={loading ? "Requesting" : "NoRequest"}
        answers={answers}
        items={items}
        handleClose={handleClose}
        handleCancel={handleCancel}
        handleReflectInsertText={handleReflectInsertText}
        handleRegenerate={aiRequest}
        updateAnswer={updateAnswer}
        refineType={refineMode}
        customOrderText={customOrderText}
        onChangeCustomOrderText={onChangeCustomOrderText}
        aiRequest={aiRequest}
        isCustomOrder={isCustomOrder}
      />
    </BubbleMenu>
  );
};
