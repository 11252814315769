/**
 * パスワードポリシーを満たしているかを検証する
 * @see [パスワードポリシー](https://www.notion.so/firstam/13258fc1f04349c398f75195acd3bb8e)
 */
export const validatePassword = (password: string) => {
  if (password.length < 10) return false;
  if (password.length > 128) return false;

  const hasLowerCaseLetter = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[-+_!@#$%^&*.,?]/.test(password);

  return hasLowerCaseLetter && hasNumber && hasSpecialChar;
};

export const specialCharacters =
  "^ $ * . { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =";
