type GridCell = {
  x: number;
  y: number;
};

export const CELL_DEFAULT_PX_SIZE = 50;

// 1マスのサイズ(cm)
export const GRID_CELL_SIZE_CM = 100;

export const createGrid = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): GridCell[][] => {
  const gridWidth = width * GRID_CELL_SIZE_CM; // メートルを10cm(仮)単位に変換
  const gridHeight = height * GRID_CELL_SIZE_CM; // メートルを10cm(仮)単位に変換

  return Array.from({ length: gridHeight }, (_, y) =>
    Array.from({ length: gridWidth }, (_, x) => ({ x, y })),
  );
};
