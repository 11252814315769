import axios from "axios";

import { useBoolean, useCurrentUser } from "@spesill/hooks";
import { PostsNotification } from "@spesill/models";
import { LearningDatabaseRepository } from "@spesill/repository/db/learningDatabase.repository";

type UploadFileType = {
  systemName: string;
  structureType: "unstructured" | "table";
  onSuccess?: (id: string) => void;
};

type ReturnType = {
  isRequesting: boolean;
  createLearningDatabase: ({
    systemName,
  }: UploadFileType) => Promise<{ id: string } | null>;
};

export const useCreateLearningDatabase = (): ReturnType => {
  const { currentUser } = useCurrentUser();
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);

  const createLearningDatabase = async ({
    systemName,
    structureType,
    onSuccess,
  }: UploadFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    setRequestingTrue();
    const learningDatabaseRepository = new LearningDatabaseRepository();
    const learningDatabase = await learningDatabaseRepository.add({
      tenantId: currentUser.tenantId,
      systemName,
      createUserId: currentUser.id,
      lastUpdateUserId: currentUser.id,
      structureType,
    });
    await PostsNotification.insertNewNotification({
      resource: learningDatabase,
      createUser: currentUser,
      action: "create",
    });

    await axios.post("/api/mail/notification", {
      notificationType: "create-LearningDatabase",
      systemName,
      operatorUserId: currentUser.id,
      resourceId: learningDatabase.id,
    });
    onSuccess && onSuccess(learningDatabase.id);
    setRequestingFalse();
    return learningDatabase;
  };

  return {
    createLearningDatabase,
    isRequesting,
  };
};
