import { collection, getDocs, orderBy, query } from "@spesill/libs/firebase";
import { ChatRoomMessage } from "@spesill/models/chatRoomMessage";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class ChatRoomMessageRepository extends DBRepositoryBase<ChatRoomMessage> {
  private PATH = "chatRooms";
  private readonly SUB_COLLECTION_PATH = "messages";

  private getCollectionRef(chatRoomId: string) {
    return collection(this.db, this.PATH, chatRoomId, this.SUB_COLLECTION_PATH);
  }

  findByChatRoomMessageId = async (chatRoomId: string) => {
    const q = query(
      this.getCollectionRef(chatRoomId),
      orderBy("createdAt", "asc"),
    );
    const docs = await getDocs(q).then((res) => res.docs);

    return docs.map((doc) => new ChatRoomMessage(this.docToObject(doc)));
  };
}
