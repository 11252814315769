import { useState, useEffect, useCallback } from "react";

import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { TenantRepository } from "@spesill/repository/db/tenant.repository";
const tenantRepository = new TenantRepository();
export const useTenantAiCallRestriction = () => {
  const [canCallAi, setCanCallAi] = useState<boolean>(false);
  const { currentUser } = useCurrentUser();

  const checkCanCallAi = useCallback(async () => {
    if (!currentUser) return false; // Updated this to return false explicitly
    const tenant = await tenantRepository.findById(currentUser.tenantId);
    const result = await tenant.canCallAi();
    setCanCallAi(result);
    return result;
  }, [currentUser]);

  useEffect(() => {
    checkCanCallAi();
  }, [checkCanCallAi]);

  return { canCallAi, checkCanCallAi };
};
