import React, { MouseEvent } from "react";
import { toast } from "react-toastify";

import Link from "next/link";

import { Icon } from "@spesill/components/atoms";
import {
  IconButton,
  ListMenu,
  MenuItemType,
} from "@spesill/components/molecules";

import { useBoolean } from "@spesill/hooks";
import { formatDate } from "@spesill/libs";
import { User } from "@spesill/models";
import { Document } from "@spesill/models";

type PropsType = {
  document: Document & { user?: User };
  deleteDocument: (id: string) => void;
  handleOpenShareModal: (document: Document) => void;
};

export const DocumentListRow = ({
  document,
  deleteDocument,
  handleOpenShareModal,
}: PropsType) => {
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  const handleDeleteDocument = async () => {
    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;

    const deletingStatus = toast.loading("削除中です。しばらくお待ちください");

    try {
      await deleteDocument(document.id);
      toast.update(deletingStatus, {
        render: "削除が完了しました。",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (e) {
      toast.dismiss(deletingStatus);
      toast.error(e instanceof Error ? e.message : "削除に失敗しました");
      onClose();
    }
  };

  const menuItems: MenuItemType[] = [
    {
      label: "削除",
      className: "text-status-danger",
      onClick: () => handleDeleteDocument(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  const fileIcon =
    document.documentType === "excel" ? "riFileExcel2Fill" : "riFileWord2Fill";
  const iconColor =
    document.documentType === "excel" ? "text-green-700" : "text-primary-700";

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onOpen();
  };

  return (
    <div className="bg-white flex text-blueGray-700 py-3 px-4 rounded-md h-16 hover:bg-blueGray-50">
      <Link
        key={document.id}
        href={`/documents/${document.id}`}
        className="flex-1 gap-x-4 flex items-center"
      >
        <div className="flex items-center space-x-2 flex-1 gap-x-4">
          <Icon
            icon={fileIcon}
            color={iconColor}
            size="2rem"
            className="bg-blueGray-0 p-1 rounded shadow"
          />
          <span className="text-body-base-bold">{document.systemName}</span>
        </div>
        <div className="text-body-base w-1/6">
          {formatDate(document.updatedAt)}
        </div>
        <div className="text-body-base w-1/6">{document.user?.fullName}</div>
      </Link>

      <div className="relative flex gap-6 items-center justify-end w-1/12">
        <IconButton
          icon={{
            icon: "bsPersonPlus",
            color: "text-blueGray-500",
            size: "1.5rem",
          }}
          srOnlyText="TODO"
          onClick={() => handleOpenShareModal(document)}
        />
        <div className="relative flex items-center">
          <IconButton
            icon={{
              icon: "bsThreeDots",
              color: "text-blueGray-500",
              size: "1.5rem",
            }}
            srOnlyText="メニューを開く"
            onClick={handleOpen}
          />
          {isOpen && (
            <ListMenu
              items={menuItems}
              onClose={onClose}
              className="right-full mr-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};
