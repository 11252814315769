import React, { FC } from "react";

import Head from "next/head";

type PropsType = {
  title?: string;
};

export const OriginalHead: FC<PropsType> = ({
  title = "SPESILL | 生成AIツール",
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content="生成AIツール" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="noindex" />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};
