import React, { FC, ChangeEvent, MouseEvent } from "react";

import { IconButtonWithTextField } from "@spesill/components/molecules";

import { useBoolean } from "@spesill/hooks";

type PropsType = {
  label: string;
  name: string;
  autoComplete?: string;
  required?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
};

export const DisplayablePasswordFiled: FC<PropsType> = ({
  label,
  name,
  autoComplete,
  required = false,
  disabled = false,
  className = "",
  placeholder = "password",
  onChange,
  error,
}: PropsType) => {
  const { isChecked: isPasswordView, toggle: togglePasswordView } =
    useBoolean(false);

  const handleToggledPasswordView = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    togglePasswordView();
  };

  return (
    <IconButtonWithTextField
      icon={isPasswordView ? "hiOutlineEye" : "hiOutlineEyeOff"}
      label={label}
      type={isPasswordView ? "text" : "password"}
      required={required}
      placeholder={placeholder}
      name={name}
      autoComplete={autoComplete}
      onChange={onChange}
      onClick={handleToggledPasswordView}
      className={className}
      disabled={disabled}
      error={error}
    />
  );
};
