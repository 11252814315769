import { privateStorage } from "@spesill/config/firebase";
import { useCurrentUser } from "@spesill/hooks";
import { uploadBytes, ref } from "@spesill/libs/firebase";
import { EvaluateDocumentRepository } from "@spesill/repository/db/evaluateDocument.repository";

type UploadFileType = {
  fileName: string;
  systemName: string;
  file: File | Blob;
  onSuccess?: () => void;
};

type ReturnType = {
  uploadFile: ({
    fileName,
    systemName,
    file,
  }: UploadFileType) => Promise<{ id: string } | null>;
};

export const useStorageUploadEvaluateDocument = (
  evaluateDatabaseId: string,
): ReturnType => {
  const { currentUser } = useCurrentUser();

  const uploadFile = async ({
    fileName,
    systemName,
    file,
    onSuccess,
  }: UploadFileType): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    const evaluateDocumentRepository = new EvaluateDocumentRepository();
    const storageRef = ref(
      privateStorage,
      `tenants/${currentUser.tenantId}/documents/evaluate_databases/${evaluateDatabaseId}/evaluates/${fileName}`,
    );

    return uploadBytes(storageRef, file).then(async () => {
      const document = await evaluateDocumentRepository.add({
        evaluateDatabaseId,
        tenantId: currentUser.tenantId,
        systemName,
        path: storageRef.fullPath,
        createUserId: currentUser.id,
        lastUpdateUserId: currentUser.id,
      });

      onSuccess && onSuccess();

      return document;
    });
  };

  return {
    uploadFile,
  };
};
