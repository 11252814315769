import React from "react";

import { Tooltip } from "@spesill/components/atoms";
import { ListMenu } from "@spesill/components/molecules";

import { useBoolean } from "@spesill/hooks";
import { usePunchImage } from "@spesill/hooks/firestore/punchImages/usePunchImage";
import { PunchItemsType } from "@spesill/models/punchLayout";

import { CELL_DEFAULT_PX_SIZE } from "../PunchKeys";

type PropsType = {
  item: PunchItemsType;
  onDeleteItem?: (item: PunchItemsType) => void;
  onRotateItem?: (item: PunchItemsType) => void;
  onChangeItemPosition?: (
    item: PunchItemsType,
    direction: "up" | "down" | "left" | "right",
  ) => void;
  isEditable?: boolean;
};
export const PunchGridCell = ({
  item,
  onDeleteItem,
  onRotateItem,
  onChangeItemPosition,
  isEditable = false,
}: PropsType) => {
  const {
    isChecked: isContextMenuOpen,
    setTrue: setContextMenuOpen,
    setFalse: setContextMenuClose,
  } = useBoolean(false);

  const cellStyle = {
    width: `${item.geometry.grid_w * CELL_DEFAULT_PX_SIZE}px`,
    height: `${item.geometry.grid_h * CELL_DEFAULT_PX_SIZE}px`,
    transform: `rotate(${item.rotation}deg)`,
    transformOrigin: "center",
  };

  const punchImage = usePunchImage(item?.geometry?.punch_image_id);

  const handleContextMenuOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setContextMenuOpen();
  };

  const menuItems = [
    {
      label: "削除",
      onClick: () => {
        onDeleteItem && onDeleteItem(item);
      },
      icon: {
        icon: "bsTrash" as const,
        size: "1rem" as const,
      },
      className: "list-none",
    },
    {
      label: "回転",
      onClick: () => {
        onRotateItem && onRotateItem(item);
      },
      icon: {
        icon: "aiOutlineReload" as const,
        size: "1rem" as const,
      },
      className: "list-none",
    },
    {
      label: "上に移動",
      onClick: () => {
        onChangeItemPosition && onChangeItemPosition(item, "up");
      },
      icon: {
        icon: "bsArrowUp" as const,
        size: "1rem" as const,
      },
      className: "list-none",
    },
    {
      label: "下に移動",
      onClick: () => {
        onChangeItemPosition && onChangeItemPosition(item, "down");
      },
      icon: {
        icon: "bsArrowDown" as const,
        size: "1rem" as const,
      },
      className: "list-none",
    },
    {
      label: "左に移動",
      onClick: () => {
        onChangeItemPosition && onChangeItemPosition(item, "left");
      },
      icon: {
        icon: "bsArrowLeft" as const,
        size: "1rem" as const,
      },
      className: "list-none",
    },
    {
      label: "右に移動",
      onClick: () => {
        onChangeItemPosition && onChangeItemPosition(item, "right");
      },
      icon: {
        icon: "bsArrowRight" as const,
        size: "1rem" as const,
      },
      className: "list-none",
    },
  ];

  return (
    <div className="relative">
      <Tooltip direction={"bottom"} text={punchImage?.name}>
        <div
          className={`${
            isContextMenuOpen && isEditable
              ? "border-2 border-dashed border-primary-500 bg-primary-50"
              : "border border-blueGray-50"
          }`}
          style={cellStyle}
          onContextMenu={handleContextMenuOpen}
        >
          {punchImage && (
            <img
              alt={punchImage.name}
              src={punchImage.path}
              className="w-full h-full"
            />
          )}
        </div>
      </Tooltip>
      {isContextMenuOpen && isEditable && (
        <ListMenu items={menuItems} onClose={setContextMenuClose} />
      )}
    </div>
  );
};
