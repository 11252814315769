import React, { FormEvent } from "react";

import { Button, FormSubmitButton, TextArea } from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import { useInput } from "@spesill/hooks";

type PropsType = {
  defaultDocumentCustomPrompt?: string;
  onSubmit: (documentCustomPrompt: string) => void;
  onClose: () => void;
  refetch?: () => void;
  isRequesting?: boolean;
};
export const UpdateDocumentCustomPromptModal = ({
  defaultDocumentCustomPrompt = "",
  onSubmit,
  onClose,
  refetch,
  isRequesting = false,
}: PropsType) => {
  const [
    { value: documentCustomPrompt, onChange: onChangeDocumentCustomPrompt },
  ] = useInput(defaultDocumentCustomPrompt);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSubmit(documentCustomPrompt);
    refetch && refetch();
    onClose();
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="カスタム命令">
      <div>
        <p className="text-body mt-6">
          AIにどのように文章を作成してほしいですか？
        </p>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <TextArea
            name="insertText"
            value={documentCustomPrompt}
            placeholder={`記入例）
この文書はロボットシステムの見積仕様書です。以下のルールに従って文書の一部を作成してください。
1. リストのそれぞれの「項目」は'・ 'で始めるようにしてください。`}
            onChange={onChangeDocumentCustomPrompt}
            rows={8}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="保存"
              color="primary"
              variant="contained"
              disabled={isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
