import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton, TextField } from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import { useInput, useUpdateChatRoom } from "@spesill/hooks";
import { ChatRoom } from "@spesill/models";

type PropsType = {
  chatRoom: ChatRoom;
  defaultSystemName?: string;
  onClose: () => void;
  onSuccess: () => void;
};
export const UpdateChatNameModal = ({
  chatRoom,
  onClose,
  onSuccess,
}: PropsType) => {
  const [{ value: systemName, onChange: onChangeSystemName }] = useInput(
    chatRoom.title,
  );
  const { updateChatRoom, isRequesting } = useUpdateChatRoom(chatRoom.id);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await updateChatRoom({
        chatRoom: {
          ...chatRoom,
          title: systemName,
        } as ChatRoom,
        onSuccess,
      });

      toast.success("名前の変更に成功しました。");
      onClose();
    } catch (e) {
      toast.error("名前の変更に失敗しました。");
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="名前を変更">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <TextField
            type="text"
            name="systemName"
            required
            placeholder="名前を入力してください。"
            value={systemName}
            onChange={onChangeSystemName}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="保存"
              color="primary"
              variant="contained"
              disabled={isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
