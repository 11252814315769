import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useCurrentUser } from "@spesill/hooks";
import { Tenant } from "@spesill/models";
import { TenantRepository } from "@spesill/repository/db/tenant.repository";

const tenantRepository = new TenantRepository();

export const useFetchTenant = () => {
  const { currentUser } = useCurrentUser();
  const [tenant, setTenant] = useState<Tenant>();

  const FetchTenant = useCallback(async () => {
    if (!currentUser) {
      return;
    }
    const doc = await tenantRepository.findById(currentUser.tenantId);
    if (!doc) {
      toast.error("テナントが見つかりません");
      return;
    }
    setTenant(doc);
  }, [currentUser]);

  useEffect(() => {
    FetchTenant();
  }, [FetchTenant]);

  return { FetchTenant, tenant };
};
