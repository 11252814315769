import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton, TextField } from "@spesill/components/atoms";
import {
  LabelWithDropdownField,
  ModalWrapper,
} from "@spesill/components/molecules";

import {
  useDocumentTypeDropDownField,
  useInput,
  useLanguageDropdownField,
  useUpdateDocument,
} from "@spesill/hooks";
import { Document } from "@spesill/models";
import { DocumentKind, DocumentLanguageType } from "@spesill/models/document";

type PropsType = {
  document: Document;
  onClose: () => void;
  refetch?: () => void;
};
export const UpdateDocumentSettingModal = ({
  document,
  onClose,
  refetch,
}: PropsType) => {
  const {
    dropdownValue: dropdownValueLanguage,
    dropdownSelectableValue: dropdownSelectableValueLanguage,
    onChange: onChangeDropdownLanguage,
  } = useLanguageDropdownField(document);
  const {
    dropdownValue: dropdownValueDocumentType,
    dropdownSelectableValue: dropdownSelectableValueDocumentType,
    onChange: onChangeDropdownDocumentType,
  } = useDocumentTypeDropDownField(document.kind);
  const [{ value: freeInputKind, onChange: onChangeFreeInputKind }] = useInput(
    document.freeInputKind,
  );

  const { onUpdateDocument, isRequesting } = useUpdateDocument(
    document,
    refetch,
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!dropdownValueLanguage?.value) return;
    if (!dropdownValueDocumentType?.value) return;
    if (dropdownValueDocumentType?.value === "others" && !freeInputKind) {
      toast.error("文書の種類を入力してください。");
      return;
    }

    const kind = dropdownValueDocumentType.value as DocumentKind;
    const language = dropdownValueLanguage.value as DocumentLanguageType;

    try {
      await onUpdateDocument({ language, kind, freeInputKind });
      toast.success("設定の変更に成功しました。");
      onClose();
    } catch (e) {
      toast.error("設定の変更に失敗しました。");
    }
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="文書の設定">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto">
          <LabelWithDropdownField
            labelText="文書種類"
            name="kind"
            value={dropdownValueDocumentType}
            options={dropdownSelectableValueDocumentType}
            onChange={(newValue, meta) => {
              meta.action !== "create-option" &&
                newValue &&
                onChangeDropdownDocumentType(newValue);
            }}
            isClearable={false}
          />
          {dropdownValueDocumentType?.value === "others" && (
            <TextField
              name="freeInputKind"
              required
              placeholder="文書の種類を入力してください"
              type={"text"}
              value={freeInputKind}
              onChange={onChangeFreeInputKind}
            />
          )}
          <LabelWithDropdownField
            labelText="言語"
            name="language"
            value={dropdownValueLanguage}
            options={dropdownSelectableValueLanguage}
            onChange={(newValue, meta) => {
              meta.action !== "create-option" &&
                newValue &&
                onChangeDropdownLanguage(newValue);
            }}
            isClearable={false}
            placeholder="言語選択"
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
            />
            <FormSubmitButton
              text="保存"
              color="primary"
              variant="contained"
              disabled={isRequesting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
