import { useCallback } from "react";
import { toast } from "react-toastify";

import { useBoolean } from "@spesill/hooks";
import { Document } from "@spesill/models";
import { DocumentRepository } from "@spesill/repository/db/document.repository";

type ReturnType = {
  onChange: (documentCustomPrompt: string) => void;
  isRequesting: boolean;
};
export const useUpdateDocumentCustomPrompt = (
  document?: Document,
  onSuccess?: () => void,
): ReturnType => {
  const { isChecked: isRequesting, setTrue, setFalse } = useBoolean(false);

  const onChange = useCallback(
    async (documentCustomPrompt: string) => {
      if (!document) return;
      const documentRepository = new DocumentRepository();
      setTrue();
      await documentRepository.updateById(document.id, {
        ...document,
        documentCustomPrompt,
      });
      toast.success("カスタム命令を更新しました。");
      onSuccess && onSuccess();
      setFalse();
    },
    [document, onSuccess, setFalse, setTrue],
  );

  return {
    onChange,
    isRequesting,
  };
};
