import React from "react";

import Image from "next/image";

import { LinkButton } from "@spesill/components/atoms";

export const FreePlanIntroduction = () => {
  return (
    <div className="bg-white w-full flex p-20 rounded-md gap-16 justify-center items-center">
      <Image
        src="/leanings.png"
        alt="文書データベース作成"
        width={400}
        height={300}
      />

      <div className="flex flex-col justify-center gap-8">
        <p className="text-h4 whitespace-pre-wrap">
          専門分野に特化したデータベースの活用で
          <br />
          手間なく品質の高い文章を作成
        </p>

        <p className="text-body-lg">
          特定の文書に特化したデータベースを独自に作り上げ、 <br />
          専門性の高い文章の作成を可能にします。 <br />
          ※データベースの作成はスタータープランからご利用いただけます。
        </p>
        <div>
          <LinkButton
            text="プランを切り替える"
            color="primary"
            variant="outlined"
            href={"/settings/plan"}
          />
        </div>
      </div>
    </div>
  );
};
