import React, { FC } from "react";
import { toast } from "react-toastify";

import { Icon } from "@spesill/components/atoms";
import {
  IconButton,
  ListMenu,
  MenuItemType,
} from "@spesill/components/molecules";

import { useBoolean } from "@spesill/hooks";
import { formatDate } from "@spesill/libs";
import { Document, EvaluateDatabase, LearningDatabase } from "@spesill/models";

type PropsType = {
  item: Document | LearningDatabase | EvaluateDatabase;
  type: "document" | "database";
  restoreItem: (id: string) => void;
  deleteItem: (id: string) => void;
  disabled?: boolean;
};

export const TrashItemRow: FC<PropsType> = ({
  item,
  type,
  disabled = false,
  restoreItem,
  deleteItem,
}) => {
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  const handleRestoreItem = async () => {
    const isConfirmed = window.confirm("本当に復元しますか？");
    if (!isConfirmed) return;

    await restoreItem(item.id);
    toast.success("復元しました");
  };

  const handleDeleteItem = async () => {
    const isConfirmed = window.confirm("本当に削除しますか？");
    if (!isConfirmed) return;
    const deletingStatus = toast.loading("削除中です。しばらくお待ちください");
    try {
      await deleteItem(item.id);
      toast.update(deletingStatus, {
        render: "削除が完了しました。",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (e) {
      toast.dismiss(deletingStatus);
      toast.error(e instanceof Error ? e.message : "削除に失敗しました");
    } finally {
      onClose();
    }
  };

  const menuItems: MenuItemType[] = [
    {
      label: "復元",
      onClick: () => handleRestoreItem(),
      icon: {
        icon: "aiOutlineReload",
        color: "text-blueGray-500",
        size: "1.2rem",
      },
    },
    {
      label: "削除",
      className: "text-status-danger",
      onClick: () => handleDeleteItem(),
      icon: {
        icon: "bsTrash",
        color: "text-status-danger",
        size: "1.2rem",
      },
    },
  ];

  return (
    <div className="bg-white flex gap-x-4 text-blueGray-700 py-3 px-4 rounded-md hover:bg-blueGray-50">
      <div className="flex-1 flex items-center space-x-2">
        <Icon
          icon={type === "document" ? "faRegFileLines" : "bsDatabase"}
          size="2rem"
          color="text-primary-800"
          className="bg-blueGray-0 p-1 rounded-md shadow"
        />
        <span>{item.systemName}</span>
      </div>
      <div className="w-1/6">
        {item.deletedAt ? formatDate(item.deletedAt) : ""}
      </div>
      <div className="w-1/6">
        {type === "document" ? (
          <span>{(item as Document).extension()}</span>
        ) : (
          <span>
            {item instanceof LearningDatabase
              ? "文書データベース"
              : "評価データベース"}
          </span>
        )}
      </div>
      <div className="relative flex justify-end w-1/12">
        <IconButton
          icon={{
            icon: "bsThreeDots",
            color: "text-blueGray-500",
            size: "1.5rem",
          }}
          srOnlyText="メニューを開く"
          onClick={onOpen}
        />
        {isOpen && (
          <ListMenu items={menuItems} onClose={onClose} disabled={disabled} />
        )}
      </div>
    </div>
  );
};
