import { useState, useCallback, ChangeEvent } from "react";

type ReturnType = [
  {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    resetValue: () => void;
    setValue: (value: string) => void;
  },
  (value?: string) => void,
];

export const useInput = (initialValue = ""): ReturnType => {
  const [value, setValue] = useState(initialValue);

  return [
    {
      value,
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setValue(e.target.value),
      resetValue: () => setValue(""),
      setValue,
    },
    useCallback((value?: string) => setValue(value || ""), []),
  ];
};
