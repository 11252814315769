import { useCallback } from "react";

import { MasterPromptRepository } from "@spesill/repository/db/masterPrompt.repository";

const masterPromptRepository = new MasterPromptRepository();

export const useMasterPromptLazy = () => {
  const fetchMasterPrompt = useCallback(() => {
    return masterPromptRepository.findByLatest();
  }, []);

  return { fetchMasterPrompt };
};
