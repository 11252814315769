import { v4 } from "uuid";

import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "@spesill/libs/firebase";
import { DocumentPromptHistory } from "@spesill/models/DocumentPromptHistory";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class DocumentPromptHistoryRepository extends DBRepositoryBase<
  ExcludeMethods<DocumentPromptHistory>
> {
  private readonly PATH = "documents";
  private readonly SUB_PATH = "promptHistories";

  private getCollectionRef(documentId: string) {
    return collection(this.db, this.PATH, documentId, this.SUB_PATH);
  }

  add = async (
    documentId: string,
    documentPromptHistory: Omit<
      ExcludeMethods<DocumentPromptHistory>,
      "id" | "createdAt" | "updatedAt"
    >,
  ) => {
    const docId = v4();
    const newDocumentPromptHistory = {
      ...documentPromptHistory,
      createdAt: new Date(),
      updatedAt: new Date(),
      id: undefined,
    }; // idをobjectに詰めるとdocIdとは別にidというフィールドができてしまうためundefinedに

    await setDoc(
      doc(this.db, this.PATH, documentId, this.SUB_PATH, docId),
      this.objectToDoc(newDocumentPromptHistory),
    );

    return new DocumentPromptHistory({
      ...newDocumentPromptHistory,
      id: docId,
    });
  };

  findByDocumentIdAndUserId = async (
    userId: string,
    documentId: string,
    order: "asc" | "desc" = "desc",
  ): Promise<DocumentPromptHistory[]> => {
    const q = query(
      this.getCollectionRef(documentId),
      where("userId", "==", userId),
      orderBy("createdAt", order),
    );

    const histories = await getDocs(q).then((res) => res.docs);

    return histories.map(
      (history) => new DocumentPromptHistory(this.docToObject(history)),
    );
  };
}
