import { useBoolean, useCurrentUser } from "@spesill/hooks";
import { ChatRoom } from "@spesill/models";
import { ChatRoomRepository } from "@spesill/repository/db/chatRoom.repository";

type UpdateChatRoomArgs = {
  chatRoom: ChatRoom;
  onSuccess?: () => void;
};

type ReturnType = {
  isRequesting: boolean;
  updateChatRoom: (args: UpdateChatRoomArgs) => Promise<{ id: string } | null>;
};

export const useUpdateChatRoom = (id: string): ReturnType => {
  const { currentUser } = useCurrentUser();
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);

  const updateChatRoom = async ({
    chatRoom,
    onSuccess,
  }: UpdateChatRoomArgs): Promise<{ id: string } | null> => {
    if (!currentUser?.tenantId) return null;
    setRequestingTrue();
    const chatRoomRepository = new ChatRoomRepository();
    return chatRoomRepository.updateById(id, chatRoom).then((res) => {
      onSuccess && onSuccess();
      setRequestingFalse();
      return res;
    });
  };

  return {
    updateChatRoom,
    isRequesting,
  };
};
