import React, { FC, MouseEvent, useMemo, useState } from "react";

import { Icon } from "@spesill/components/atoms";
import { DocumentPreviewModal } from "@spesill/components/organisms/Modal/DocumentPreviewModal";

import { useBoolean } from "@spesill/hooks";
import { SourceType } from "@spesill/models/source";
import { getExtension } from "@spesill/utils";

const SourceItem = ({
  source,
  index,
  databaseName,
  isModalPortal,
}: {
  source: SourceType;
  index: number;
  databaseName?: string;
  isModalPortal?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const extension = useMemo(() => {
    switch (source.document_type) {
      case "document":
        return ".docx";
      case "sheet":
        return ".xlsx";
      case "pdf":
        return ".pdf";
      case "other":
        return getExtension(source.document_path, true) || "";
      default:
        return "";
    }
  }, [source.document_path, source.document_type]);

  return (
    <div>
      <div className="flex flex-row items-center gap-3 mb-2">
        <p className="text-sm font-bold">
          参照データ {index + 1} ： {databaseName} - {source?.document_name}
        </p>
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="flex flex-row items-center gap-1 p-2"
        >
          <Icon icon="VscLinkExternal" size="1rem" color="text-primary-400" />
          <span className="text-primary-400 text-sm font-medium">
            参照データを見る
          </span>
        </button>
      </div>
      <div className="flex flex-row gap-2">
        <div className="w-[2px] rounded-full bg-blueGray-100" />
        <p className="text-sm">{source.source}</p>
      </div>
      {isOpen && (
        <DocumentPreviewModal
          onClose={() => setIsOpen(false)}
          path={source.document_path}
          extension={extension}
          systemName={source.document_name}
          sourceText={source.source}
          location={source.location}
          isModalPortal={isModalPortal}
        />
      )}
    </div>
  );
};

type PropsType = {
  className?: string;
  sources?: SourceType[];
  databaseName?: string;
  isModalPortal?: boolean;
};

export const DocumentSource: FC<PropsType> = ({
  sources,
  className = "",
  databaseName,
  isModalPortal = false,
}: PropsType) => {
  const {
    isChecked: isSourceDisplay,
    setTrue: setSourceDisplayTrue,
    setFalse: setSourceDisplayFalse,
  } = useBoolean(false);

  const handleSourceDisplay = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSourceDisplayTrue();
  };

  const handleSourceClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSourceDisplayFalse();
  };

  if (!sources || sources.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <button
        type="button"
        onClick={isSourceDisplay ? handleSourceClose : handleSourceDisplay}
        className="text-body-base pt-6 w-full flex justify-end items-center space-x-1 text-primary-400"
      >
        <Icon icon="hiOutlineEye" size="1.3rem" color="text-primary-400" />
        <span>
          {isSourceDisplay ? "データソースを非表示" : "データソースを表示"}
        </span>
      </button>
      {isSourceDisplay && (
        <div className="max-h-80 overflow-y-auto bg-blueGray-0 py-6 px-4 rounded-md">
          {sources?.map((source, index) => (
            <SourceItem
              key={`source-${index}`}
              source={source}
              index={index}
              databaseName={databaseName}
              isModalPortal={isModalPortal}
            />
          ))}
        </div>
      )}
    </div>
  );
};
