import { useCallback, useEffect, useState } from "react";

import { privateStorage } from "@spesill/config/firebase";
import { useCurrentUser } from "@spesill/hooks";
import { OrderByDirection, deleteObject, ref } from "@spesill/libs/firebase";
import { User } from "@spesill/models";
import { LearningDocument } from "@spesill/models/learningDocument";
import { LearningDocumentRepository } from "@spesill/repository/db/learningDocument.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

const learningDocumentRepository = new LearningDocumentRepository();
const userRepository = new UserRepository();

type LearningDocumentWithUser = LearningDocument & { user?: User };
type FilterType = {
  createUserId?: string;
};

type SortType = {
  field: "updatedAt";
  order: OrderByDirection;
};

export const useLearningDocumentsWithUser = (learningDatabaseId: string) => {
  const { currentUser } = useCurrentUser();
  const [learningDocuments, setLearningDocuments] = useState<
    LearningDocumentWithUser[]
  >([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterType>();
  const [sort, setSort] = useState<SortType>({
    field: "updatedAt",
    order: "desc",
  });

  const fetchDocumentsWithUser = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const users = await userRepository.findByTenantId(currentUser.tenantId);
    const docs =
      await learningDocumentRepository.findByTenantIdAndLearningDatabaseId(
        currentUser.tenantId,
        learningDatabaseId,
        {
          createUserId: selectedFilter?.createUserId,
        },
        sort,
      );

    const docsWithUser = docs.map((doc) => {
      const user = users.find((user) => user.id === doc.createUserId);
      return Object.defineProperty(doc, "user", {
        value: user,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    });

    setLearningDocuments(docsWithUser);

    return docsWithUser;
  }, [currentUser?.tenantId, learningDatabaseId, selectedFilter, sort]);

  useEffect(() => {
    fetchDocumentsWithUser();
  }, [currentUser?.tenantId, fetchDocumentsWithUser]);

  const deleteDocument = useCallback(
    async (id: string) => {
      if (!currentUser?.tenantId) return;
      const doc = await learningDocumentRepository.findByTenantIdAndId(
        currentUser.tenantId,
        learningDatabaseId,
        id,
      );

      const storageRef = ref(privateStorage, doc.path);
      await deleteObject(storageRef);
      await learningDocumentRepository.deleteById(id);
      await fetchDocumentsWithUser();
    },
    [currentUser, fetchDocumentsWithUser, learningDatabaseId],
  );

  const learnDocuments = useCallback(async () => {
    const learningDocuments = await fetchDocumentsWithUser();
    const ids = (learningDocuments ?? []).map((doc) => doc.id);
    const promises = ids.map((id) =>
      learningDocumentRepository.learnedById(id),
    );

    const results = await Promise.all(promises);
    return results;
  }, [fetchDocumentsWithUser]);

  const selectCreateUserId = (createUserId: string) => {
    setSelectedFilter({ createUserId });
  };

  const resetFilterCreateUserId = () => {
    setSelectedFilter({ createUserId: undefined });
  };

  const changeSortOrderDirection = (order: OrderByDirection) => {
    setSort((prev) => ({ ...prev, order }));
  };

  useEffect(() => {
    fetchDocumentsWithUser();
  }, [fetchDocumentsWithUser, sort]);

  return {
    fetchDocumentsWithUser,
    deleteDocument,
    learnDocuments,
    selectedFilter,
    sort,
    selectCreateUserId,
    resetFilterCreateUserId,
    changeSortOrderDirection,
    learningDocuments,
  };
};
