import { useState } from "react";
import { FileRejection } from "react-dropzone";
import { toast } from "react-toastify";

type ReturnType = {
  files: File[];
  setFiles: (value: File[]) => void;
  handleDropFile: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => void;
  handleDeleteFileByIndex: (index: number) => void;
};

export const useFile = (): ReturnType => {
  const [files, setFiles] = useState<File[]>([]);

  const handleDropFile = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    setFiles(acceptedFiles);
  };

  const handleDeleteFileByIndex = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return {
    files,
    setFiles,
    handleDropFile,
    handleDeleteFileByIndex,
  };
};
