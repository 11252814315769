import { useCallback, useMemo } from "react";

import { TenantRepository } from "@spesill/repository/db/tenant.repository";

import { useFetchTenant } from "../firestore/tenants/useFetchTenant";

const tenantRepository = new TenantRepository();

export const useEnable2fa = () => {
  const { tenant, FetchTenant } = useFetchTenant();

  const isEnable2fa = useMemo(() => tenant?.isEnable2fa, [tenant]);

  const toggleEnable2fa = useCallback(async () => {
    if (!tenant) {
      return;
    }
    await tenantRepository.update(tenant.id, {
      isEnable2fa: !tenant?.isEnable2fa,
    });
    FetchTenant();
  }, [FetchTenant, tenant]);

  return { toggleEnable2fa, isEnable2fa };
};
