import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import "katex/dist/katex.min.css";

// TeX記法をレンダリングできる記法（$）に変換する関数
const transformTeX = (text: string): string => {
  // \( ... \) を $...$ に変換
  text = text.replace(/\\\(([^()]+)\\\)/g, (_, group) => `$${group}$`);

  // \[ ... \] を $$...$$ に変換
  text = text.replace(/\\\[(.*?)\\\]/gs, (_, group) => `$$${group}$$`);

  // \begin{equation} ... \end{equation} を $$...$$ に変換
  text = text.replace(
    /\\begin\{equation\}(.*?)\\end\{equation\}/gs,
    (_, group) => `$$${group}$$`,
  );

  return text;
};

export const MarkdownRenderer: React.FC<{ text: string }> = ({ text }) => {
  const transformedText = transformTeX(text);
  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeKatex]}
      components={{
        p: ({ children }) => <p className="text-gray-800">{children}</p>,
        code: ({ children }) => {
          return (
            <pre className="bg-blueGray-700 text-white p-2 rounded">
              <code>{children}</code>
            </pre>
          );
        },
        del: ({ children, ...props }) => (
          <del
            style={{
              color: "red",
              textDecoration: "line-through",
              backgroundColor: "rgba(255, 0, 0, 0.1)",
              padding: "0 2px",
              borderRadius: "2px",
            }}
            {...props}
          >
            {children}
          </del>
        ),
      }}
    >
      {transformedText}
    </ReactMarkdown>
  );
};
