import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks/providers/useCurrentUser";
import { PunchImage } from "@spesill/models";
import { PunchImageRepository } from "@spesill/repository/db/punchImage.repository";

const punchImageRepository = new PunchImageRepository();

export const usePunchImages = () => {
  const { currentUser } = useCurrentUser();
  const [punchImages, setPunchImages] = useState<PunchImage[]>();

  const fetchPunchImages = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const fetchedPunchImages = await punchImageRepository.findAll({
      tenantId: currentUser.tenantId,
    });
    const filteredPunchImages = fetchedPunchImages.filter(
      (punchImage) => !punchImage.deletedAt,
    );
    setPunchImages(filteredPunchImages);
  }, [currentUser?.tenantId]);

  useEffect(() => {
    fetchPunchImages();
  }, [fetchPunchImages]);

  return { fetchPunchImages, punchImages };
};
