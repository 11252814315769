import { useState, useCallback, useEffect } from "react";

import { MultiValueType, SingleValueType } from "@spesill/components/atoms";

import { useEvaluateDatabasesWithUser } from "@spesill/hooks";
import { EvaluateDatabase } from "@spesill/models";

type ReturnType = {
  dropdownValue?: SingleValueType;
  dropdownSelectableValue: MultiValueType;
  onChange: (newValue: SingleValueType) => void;
  fetchEvaluateDatabases: () => Promise<EvaluateDatabase[] | undefined>;
};

export const useEvaluateDatabaseDropdownField = (): ReturnType => {
  const [dropdownSelectableValue, setDropdownSelectableValue] =
    useState<MultiValueType>([]);
  const { evaluateDatabases, fetchEvaluateDatabasesAndUsers } =
    useEvaluateDatabasesWithUser();
  const [value, setValue] = useState<SingleValueType>();

  const onChangeDropDown = useCallback((newValue: SingleValueType) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    if (!evaluateDatabases) return;
    setDropdownSelectableValue([
      ...evaluateDatabases.map((evaluateDatabase) => {
        return {
          value: evaluateDatabase.id,
          label: evaluateDatabase.systemName,
        };
      }),
    ]);
  }, [evaluateDatabases]);

  return {
    dropdownValue: value,
    dropdownSelectableValue,
    onChange: onChangeDropDown,
    fetchEvaluateDatabases: fetchEvaluateDatabasesAndUsers,
  };
};
