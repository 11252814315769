import React, { FC, ComponentProps } from "react";

import { Icon, IconPropsType } from "@spesill/components/atoms";
import { ListMenu } from "@spesill/components/molecules/ListMenu/ListMenu";

import { useBoolean } from "@spesill/hooks";

import {
  ButtonColorType,
  ButtonVariantType,
  ButtonColor,
} from "../../atoms/Button/constants";

type PropsType = {
  text: string;
  menuItems: ComponentProps<typeof ListMenu>["items"];
  disabled?: boolean;
  className?: string;
  listClassName?: string;
  color: ButtonColorType;
  variant: ButtonVariantType;
  icon?: IconPropsType;
};

export const MenuButton: FC<PropsType> = ({
  text,
  menuItems,
  className = "",
  listClassName = "",
  disabled = false,
  color,
  variant,
  icon,
}: PropsType) => {
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);

  return (
    <div className="relative">
      <button
        type="button"
        onClick={onOpen}
        className={`flex ${ButtonColor[variant][color]} ${
          disabled ? "cursor-not-allowed opacity-80" : "cursor-pointer"
        }  flex items-center px-4 py-2.5 rounded ${className}`}
      >
        <div>{icon && <Icon {...icon} />}</div>
        <span className="min-w-[5rem]">{text}</span>
        <div className="border-l border-blueGray-300 pl-1 ml-2 flex items-center text-center">
          <Icon icon={"aiOutlineDown"} size="1rem" color="text-white" />
        </div>
      </button>
      {isOpen && (
        <ListMenu
          items={menuItems}
          onClose={onClose}
          className={`${listClassName}`}
        />
      )}
    </div>
  );
};
