import { doc, getDoc, setDoc, updateDoc } from "@spesill/libs/firebase";
import { TenantDocumentSettings } from "@spesill/models";

import { DBRepositoryBase } from "./__common__/DBRepositoryBase";

export class TenantDocumentSettingsRepository extends DBRepositoryBase<
  ExcludeMethods<TenantDocumentSettings>
> {
  private readonly TENANTS_PATH = "tenants";
  private readonly SUB_COLLECTION_PATH = "tenantDocumentSettings";

  private getDocumentRef(tenantId: string) {
    return doc(
      this.db,
      this.TENANTS_PATH,
      tenantId,
      this.SUB_COLLECTION_PATH,
      tenantId,
    );
  }

  add = async (
    tenantId: string,
    tenantDocumentSettings: Omit<ExcludeMethods<TenantDocumentSettings>, "id">,
  ) => {
    const now = new Date();
    const newSettings = {
      ...tenantDocumentSettings,
      createdAt: now,
      updatedAt: now,
      id: undefined,
      tenantId: tenantId,
    };

    await setDoc(this.getDocumentRef(tenantId), this.objectToDoc(newSettings));

    return new TenantDocumentSettings({ ...newSettings, id: tenantId });
  };

  findOrAddById = async (tenantId: string) => {
    const ref = this.getDocumentRef(tenantId);
    const docs = await getDoc(ref);

    if (!docs.exists()) {
      const now = new Date();
      const defaultTenantDocumentSettings = {
        customPrompt: "",
        createdAt: now,
        updatedAt: now,
        tenantId: tenantId,
      };
      return this.add(tenantId, defaultTenantDocumentSettings);
    }
    return new TenantDocumentSettings(this.docToObject(docs));
  };

  updateById = async (
    tenantId: string,
    tenantDocumentSettings: Partial<
      Omit<
        ExcludeMethods<TenantDocumentSettings>,
        "id" | "createdAt" | "updatedAt"
      >
    >,
  ) => {
    const ref = this.getDocumentRef(tenantId);
    const docs = await getDoc(ref);

    if (!docs.exists()) {
      throw new Error("ドキュメントが見つかりませんでした");
    } else {
      await updateDoc(ref, {
        ...tenantDocumentSettings,
        updatedAt: new Date(),
      });
      return { id: tenantId };
    }
  };
}
