import React, { FC } from "react";

import { Icon, IconColorType } from "@spesill/components/atoms";

type PropsType = {
  text: string;
  textClassName?: string;
  iconColorClassName?: IconColorType;
  className?: string;
  order: "desc" | "asc";
  onChange: (order: "desc" | "asc") => void;
};

export const SortButton: FC<PropsType> = ({
  text,
  textClassName = "text-blueGray-900",
  iconColorClassName = "text-blueGray-500",
  className = "",
  order,
  onChange,
}: PropsType) => {
  return (
    <button
      type="button"
      className={`flex space-x-2 ${className}`}
      onClick={() => {
        onChange(order === "desc" ? "asc" : "desc");
      }}
    >
      <span className={`truncate ${textClassName}`}>{text}</span>
      {order === "desc" ? (
        <Icon icon="bsArrowDown" size="1.3rem" color={iconColorClassName} />
      ) : (
        <Icon icon="bsArrowUp" size="1.3rem" color={iconColorClassName} />
      )}
    </button>
  );
};
