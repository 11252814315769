import React, { FC, ChangeEvent } from "react";

import { TextField } from "..";
import { Icon, IconType } from "../Icon/Icon";

type FieldType = "text" | "email" | "password" | "search";

type PropsType = {
  type: FieldType;
  name: string;
  value?: string;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  icon: IconType;
};

export const TextFieldWithIcon: FC<PropsType> = ({
  type = "text",
  name,
  value,
  defaultValue,
  autoComplete,
  placeholder = "",
  required = false,
  disabled = false,
  className = "",
  icon,
  onChange,
}: PropsType) => {
  return (
    <div className="relative">
      <span className={"absolute inset-y-0 flex items-center left-0 pl-2 "}>
        <Icon icon={icon} size="1rem" />
      </span>
      <TextField
        type={type}
        name={name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        className={`w-full px-6 py-2 rounded border border-solid border-blueGray-400 ${className} ${
          disabled ? "cursor-not-allowed" : ""
        }`}
        onChange={onChange}
      />
    </div>
  );
};
