import React from "react";

type Props = {
  id?: string;
  disabled?: boolean;
  checked: boolean;
  name: string;
  value?: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  classNames?: string;
};

const RadioButton = ({
  id,
  checked,
  disabled = false,
  classNames,
  name,
  value,
  onChange,
  ...rest
}: Props) => (
  <input
    id={id}
    name={name}
    value={value}
    className={`
      cursor-pointer form-radio border-solid border border-gray-300 p-1 focus:outline-none
      ${classNames}
      ${disabled ? "opacity-70 cursor-not-allowed" : ""}
    `}
    disabled={disabled}
    type="radio"
    onChange={onChange}
    checked={checked}
    {...rest}
  />
);

export { RadioButton };
