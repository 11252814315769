import { useCallback, useEffect, useState } from "react";

import { useCurrentUser } from "@spesill/hooks";
import { User } from "@spesill/models";
import { DocumentViewer } from "@spesill/models";
import { DocumentViewerRepository } from "@spesill/repository/db/documentViewer.repository";
import { UserRepository } from "@spesill/repository/db/user.repository";

const userRepository = new UserRepository();
const documentViewerRepository = new DocumentViewerRepository();

type DocumentViewerWithUser = DocumentViewer & { user?: User };

export const useDocumentViewers = (documentId: string) => {
  const { currentUser } = useCurrentUser();
  const [documentViewers, setDocumentViewers] = useState<
    DocumentViewerWithUser[]
  >([]);

  const fetchDocumentViewers = useCallback(async () => {
    if (!currentUser?.tenantId) return;

    const users = await userRepository.findByTenantId(currentUser.tenantId);
    const documentViewers = await documentViewerRepository.listWithinSeconds(
      currentUser.tenantId,
      documentId,
      30,
    );

    const withUser = documentViewers
      .filter((documentViewer) => currentUser.id !== documentViewer.id)
      .map((documentViewer) => {
        const user = users.find((user) => user.id === documentViewer.id);
        return Object.defineProperty(documentViewer, "user", {
          value: user,
          writable: true,
          enumerable: true,
          configurable: true,
        }) as DocumentViewerWithUser;
      })
      .filter((documentViewer) => documentViewer.user);
    setDocumentViewers(withUser);
  }, [currentUser?.tenantId, documentId, currentUser?.id]);

  useEffect(() => {
    fetchDocumentViewers();
  }, [currentUser?.tenantId, fetchDocumentViewers]);

  return {
    documentViewers,
    fetchDocumentViewers,
  };
};
