import { mergeAttributes, Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import { DocumentKind } from "@spesill/models/document";

import TiptapFlowchartComponent from "./TiptapFlowchartComponent";

export const TiptapFlowchartExtension = ({
  learningDatabaseId,
  headingTitle = "",
  documentKind,
  freeInputKind,
}: {
  learningDatabaseId?: string;
  headingTitle?: string;
  documentKind?: DocumentKind;
  freeInputKind?: string;
}) =>
  Node.create({
    name: "flowchartComponent",

    group: "block",

    atom: true,

    addAttributes() {
      return {
        state: {
          default: [],
        },
        learningDatabaseId: {
          default: learningDatabaseId,
        },
        headingTitle: {
          default: headingTitle,
        },
        documentKind: {
          default: documentKind,
        },
        freeInputKind: {
          default: freeInputKind,
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: "flowchart",
        },
        //ConvertedHtmlDetailで作成されたフローチャートとの互換性を保つために、div[data-chart-type='flowchart']を追加
        {
          tag: "div[data-chart-type='flowchart']",
          getAttrs: (dom) => {
            const element = dom as HTMLElement;
            const chartState = element.getAttribute("data-chart-state");
            if (!chartState) {
              return {};
            }
            try {
              return {
                state: chartState,
              };
            } catch (error) {
              console.error("Error parsing chart state:", error);
              return {};
            }
          },
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return [
        "flowchart",
        mergeAttributes(HTMLAttributes, { "data-chart-type": "flowchart" }), //htmlをクエリする時に都合がいいので、data-chart-typeを追加
      ];
    },

    addNodeView() {
      return ReactNodeViewRenderer(TiptapFlowchartComponent);
    },
  });
