import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useStorageDownloadBlob } from "@spesill/hooks";
import { PdfLocationType } from "@spesill/models/source";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

type PropsType = {
  path: string;
  location?: PdfLocationType[];
};

export const PdfFileViewer = ({ path, location }: PropsType) => {
  const { url } = useStorageDownloadBlob(path);
  const [numPages, setNumPages] = useState(0);

  // PDF.jsのWorkerのソースファイルの場所を設定
  // https://github.com/wojtekmaj/react-pdf/blob/main/packages/react-pdf/README.md#configure-pdfjs-worker
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url,
  ).toString();

  return (
    <div className="flex justify-center bg-blueGray-50  max-h-[90vh] overflow-y-auto">
      <Document
        file={url}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {[...Array(numPages)].map((_, index) => (
          <div key={`page_${index + 1}`} className="relative">
            <Page
              pageNumber={index + 1}
              inputRef={(ref) => {
                if (!location || index + 1 !== Number(location[0]?.page_no)) {
                  return;
                }
                ref?.scrollIntoView();
              }}
            />
            {location
              ?.filter((l) => Number(l.page_no) === index)
              .map((l, locIndex) => (
                <div
                  key={`location_${index + 1}_${locIndex}`}
                  className="absolute bg-primary-800 opacity-50"
                  style={{
                    top: `${l.y * 100}%`,
                    left: `${l.x * 100}%`,
                    width: `${l.width * 100}%`,
                    height: `${l.height * 100}%`,
                  }}
                />
              ))}
          </div>
        ))}
      </Document>
    </div>
  );
};
