import { useState } from "react";

import { AuthRepository } from "@spesill/repository/auth";
import { UserRepository } from "@spesill/repository/db/user.repository";

export const useChangeEmail = () => {
  const [loading, setLoading] = useState(false);

  const changeEmail = async ({
    userId,
    password,
    newEmail,
  }: {
    userId: string;
    password: string;
    newEmail: string;
  }) => {
    setLoading(true);
    try {
      const authRepository = new AuthRepository();
      const userRepository = new UserRepository();

      await authRepository.changeEmail(password, newEmail).then(async () => {
        await userRepository.update(userId, {
          email: newEmail,
          updatedAt: new Date(),
        });
      });
    } finally {
      setLoading(false);
    }
  };

  return { changeEmail, loading };
};
