import React, { FormEvent } from "react";
import { toast } from "react-toastify";

import { Button, FormSubmitButton, TextArea } from "@spesill/components/atoms";
import { ModalWrapper } from "@spesill/components/molecules";

import { useBoolean, useInput } from "@spesill/hooks";
import { User } from "@spesill/models";
import { UserRepository } from "@spesill/repository/db/user.repository";

type PropsType = {
  user: User;
  defaultJobDescription?: string;
  onClose: () => void;
  onSuccess: () => void;
};
export const UpdateUserJobDescriptionModal = ({
  user,
  onClose,
  onSuccess,
}: PropsType) => {
  const {
    isChecked: isRequesting,
    setTrue: setRequestingTrue,
    setFalse: setRequestingFalse,
  } = useBoolean(false);
  const [{ value: jobDescription, onChange: onChangeJobDescription }] =
    useInput(user.jobDescription);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRequestingTrue();
    const userRepository = new UserRepository();
    await userRepository
      .update(user.id, {
        jobDescription: jobDescription,
        updatedAt: new Date(),
      })
      .then(() => {
        onSuccess();
        toast.success("業務内容を更新しました");
      })
      .catch((e) => {
        toast.error(
          e instanceof Error ? e.message : "業務内容の更新に失敗しました",
        );
      })
      .finally(() => {
        onClose();
        setRequestingFalse();
      });
  };

  return (
    <ModalWrapper onClose={onClose} headerTitle="業務内容">
      <div>
        <form onSubmit={handleSubmit} className="mt-6 space-y-6 mx-auto w-full">
          <TextArea
            name="jobDescription"
            required
            placeholder="業務内容を入力してください。"
            value={jobDescription}
            onChange={onChangeJobDescription}
          />
          <div className="space-x-4 text-right">
            <Button
              text={"キャンセル"}
              color={"gray"}
              variant={"contained"}
              onClick={onClose}
              disabled={isRequesting}
            />
            <FormSubmitButton text="保存" color="primary" variant="contained" />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
