import React, { FC, ReactNode } from "react";

type PropsType = {
  children: ReactNode;
  className?: string;
  onClose: () => void;
};

export const FloatingMenu: FC<PropsType> = ({
  children,
  className = "",
  onClose,
}) => {
  return (
    <>
      <button
        type="button"
        className="bg-transparent fixed top-0 bottom-0 left-0 right-0 z-10"
        onClick={onClose}
      />
      <ul
        className={`z-10 absolute py-2.5 px-1 rounded bg-white shadow ${className}`}
      >
        {children}
      </ul>
    </>
  );
};
