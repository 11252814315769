import React, { FormEvent } from "react";

import { Button, FormSubmitButton, TextArea } from "@spesill/components/atoms";
import { TextTemplates } from "@spesill/components/organisms";

import { useCurrentUser, useInput } from "@spesill/hooks";
import {
  RequestGeneratePunchImage,
  ResponseGeneratePunchImage,
} from "@spesill/models/api/generate_punch_image";
import { PunchLayout } from "@spesill/models/punchLayout";
import { PunchLayoutRequestHistory } from "@spesill/models/punchLayoutRequestHistory";

import { TemplateTexts } from "../utils/LayoutTemplateText";

type PropsType = {
  className?: string;
  onClose: () => void;
  onSubmit: (
    formData: Pick<RequestGeneratePunchImage, "description">,
  ) => Promise<ResponseGeneratePunchImage | undefined>;
  disabled?: boolean;
};

export const PunchLayoutSimpleForm = ({
  onClose,
  onSubmit,
  className = "",
  disabled = false,
}: PropsType) => {
  const { currentUser } = useCurrentUser();
  const [
    {
      value: layoutPrompt,
      onChange: onChangeLayoutPrompt,
      setValue: setLayoutPrompt,
    },
  ] = useInput("");

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!currentUser) return;

    const res = await onSubmit({
      description: layoutPrompt,
    });
    if (!res) return;
    const request = await PunchLayoutRequestHistory.create({
      tenantId: currentUser.tenantId,
      userId: currentUser.id,
      description: layoutPrompt,
    });
    PunchLayout.create({
      ...res,
      punchItems: res.punch_items,
      tenantId: currentUser.tenantId,
      punchLayoutRequestHistoryId: request.id,
    });
  };

  return (
    <form className={`${className}`} onSubmit={handleSubmit}>
      <p className="text-body-lg">
        どのように生成してほしいかを入力してください。
      </p>
      <p className="text-body-base text-blueGray-500">
        業界・工程・製品の情報 / アイテム構成情報 / 広さ・環境情報 / 特長情報 /
        配置・設置向き情報
      </p>
      <div className="mt-2">
        <TextArea
          name="insertText"
          value={layoutPrompt}
          placeholder={`例文：
    ・自動車部品製造工場の「エンジン部品」組立生産ラインのポンチ絵を描いてください
    ・これらのシステムは安全柵で囲われており、システムを設置するエリアは10m×10mです
    ・供給用コンベア - 組立ロボット - 払出用コンベア という配置にしてください`}
          onChange={onChangeLayoutPrompt}
          rows={8}
          disabled={disabled}
        />
      </div>
      <TextTemplates
        texts={TemplateTexts}
        onSelect={(text: string) => setLayoutPrompt(text)}
        className="mt-3"
      />
      <hr className="mt-8 border-blueGray-50" />
      <div className="space-x-4 text-right mt-4">
        <Button
          text={"キャンセル"}
          color={"gray"}
          variant={"contained"}
          onClick={onClose}
        />
        <FormSubmitButton
          text="作成"
          color="primary"
          variant="contained"
          className="text-sm"
          disabled={disabled}
        />
      </div>
    </form>
  );
};
